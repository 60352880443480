import { AfterContentInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { AgrRendererService } from 'src/app/_shared/directives/agr-renderer.service';

@Directive({
  selector: '[agrAutoFocus]'
})
export class AgrAutofocusDirective implements AfterContentInit, OnChanges {
  @Input() agrAutoFocus: boolean;

  constructor(private el: ElementRef, private agrRendererService: AgrRendererService) {}

  ngOnChanges(): void {
    if (!this.agrAutoFocus) {
      return;
    }
    this.focus();
  }

  ngAfterContentInit(): void {
    this.focus();
  }

  private focus(): void {
    setTimeout(() => {
      this.agrRendererService.invokeElementMethod(this.el, 'focus');
    }, 1);
  }
}
