import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from 'src/app/_core/authorization/auth.service';

@Pipe({
  name: 'agrFeature'
})
export class FeaturePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(featureRef: string): any {
    return this.authService.hasFeature(featureRef);
  }
}
