import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

/**
 * Show data as link.
 * Doc: https://www.ag-grid.com/javascript-grid-cell-rendering-components/#angular-cell-render-components
 * Note: Row groups do not have params.data object.
 */

@Component({
  selector: 'agr-ag-grid-checkbox-header',
  templateUrl: './ag-grid-checkbox-header.component.html'
})
export class AgGridCheckboxHeaderComponent implements IHeaderAngularComp {
  params: IHeaderParams;
  agIconCheckboxClass: 'ag-checked' | 'ag-unchecked' | 'ag-indeterminate';

  constructor() {}

  // called on init
  agInit(params: IHeaderParams): void {
    this.params = params;
    this.agIconCheckboxClass = 'ag-unchecked';
    this.addSelectionChangedEvent();
  }

  refresh(): boolean {
    return true;
  }

  //////////////////////////////////////////

  toggleChecked(): void {
    const selectedRowsCount = this.params.api.getSelectedRows().length;
    if (selectedRowsCount === this.getRowCount()) {
      this.params.api.deselectAllFiltered();
      this.agIconCheckboxClass = 'ag-unchecked';
    } else {
      this.params.api.selectAllFiltered();
      this.agIconCheckboxClass = 'ag-checked';
    }
  }

  private addSelectionChangedEvent(): void {
    this.params.api.addEventListener('selectionChanged', () => {
      const selectedRowsCount = this.params.api.getSelectedRows().length;
      if (selectedRowsCount === 0) {
        this.agIconCheckboxClass = 'ag-unchecked';
      } else if (selectedRowsCount === this.getRowCount()) {
        this.agIconCheckboxClass = 'ag-checked';
      } else {
        this.agIconCheckboxClass = 'ag-indeterminate';
      }
    });
  }

  /**
   * Count rows after filter minus the row groups
   */
  private getRowCount(): number {
    let count = 0;
    this.params.api.forEachNodeAfterFilter((node) => {
      if (!node.hasChildren()) {
        count += 1;
      }
    });
    return count;
  }
}
