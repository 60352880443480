/* eslint-disable max-classes-per-file */
import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQuery, DeQueryFilter } from 'src/app/_core/de-query/model/de-query.model';
import { WorkspaceFilter, WorkspaceFilterType } from 'src/app/workspaces/details/filters/models/workspace-filter.model';

export class WorkspaceLookupFilterValue {
  name: string;
  id: string | number;

  constructor(query: {}, filter: WorkspaceLookupFilter) {
    this.id = query[filter.idColumnName];
    this.name = `${query[filter.name]}`;
    this.name = this.name === '' || this.name === null || this.name === 'null' ? '[no value]' : this.name;
  }
}

export class WorkspaceLookupFilter extends WorkspaceFilter {
  selectedType: 'CHECKBOX' | 'LOOKUP' = 'LOOKUP';
  lookupValues: WorkspaceLookupFilterValue[] = [];
  selectedValues: WorkspaceLookupFilterValue[] = [];
  deQuerySelected: (string | number)[] = [];
  excludeSelected = false;
  lookupColumn: string;
  filterType: WorkspaceFilterType = 'WorkspaceLookupFilter';

  constructor(contextLine?: ContextLine) {
    super(contextLine);
    if (!contextLine) {
      return;
    }
    this.selectedType = !!contextLine.lowCardinality ? 'CHECKBOX' : 'LOOKUP';
    this.lookupColumn = contextLine.lookupColumn;
  }

  toggleNextType(): void {
    this.selectedType = this.selectedType !== 'LOOKUP' ? 'LOOKUP' : 'CHECKBOX';
  }

  getLookupValueQuery(): DeQuery {
    return {
      queryType: 'distinct',
      columns: [{ name: this.name }]
    };
  }

  toDeQueryFilter(): DeQueryFilter {
    return {
      column: {
        name: this.idColumnName
      },
      values: this.selectedValues.map((value) => value.id),
      operator: this.excludeSelected ? 'NOTIN' : 'IN',
      ui: {
        column: {
          name: this.name
        },
        selectedSubtypeId: this.selectedType === 'CHECKBOX' ? 'checklist' : 'lookup',
        fixed: this.locked,
        class: this.filterType
      }
    };
  }

  updateFromDeQueryFilter(query: DeQueryFilter): void {
    this.selectedType = query.ui.selectedSubtypeId === 'checklist' ? 'CHECKBOX' : 'LOOKUP';
    this.excludeSelected = query.operator === 'NOTIN';
    this.deQuerySelected = Array.isArray(query.values) ? query.values : [];
    this.selectedValues = this.deQuerySelected.map((value) => {
      return { name: `${value}`, id: isNaN(+value) ? value : +value };
    });
    this.locked = !!query.ui.fixed;
  }

  setLookupValues(lookupValues: WorkspaceLookupFilterValue[]): void {
    this.lookupValues = lookupValues;
    this.selectedValues = [];
    this.deQuerySelected.map((queryFilter) => {
      const lookupValue = this.lookupValues.find((value) => `${value.id}` === `${queryFilter}`);
      if (lookupValue) {
        this.selectedValues.push(lookupValue);
      }
    });
  }
}
