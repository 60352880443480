<div class="container-fluid">
  <div id="error404" class="agr-layout">
    <img src="assets/images/404-bg.png" class="page-bg" />

    <div class="page-content">
      <img src="assets/images/404-code.png" class="page-code" />
      <h1>{{ 'PAGE_NOT_FOUND' | agrTranslate }}</h1>
      <p class="subtitle">{{ 'SORRY_THE_PAGE_YOU_REQUESTED_WAS_NOT_FOUND' | agrTranslate }}</p>
      <a class="btn btn-primary" href="javascript:history.back()" id="back-link">
        <i class="fa fa-arrow-left"></i>
        {{ 'GO_BACK' | agrTranslate }}
      </a>
      <a href="/#/" class="home-link"> ...{{ 'GO_TO_HOME_PAGE' | agrTranslate }}</a>
    </div>
  </div>
</div>
