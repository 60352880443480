<!-- Navbar left -->
<nav class="agr-nav {{ barColor }}">
  <div id="primary-nav">
    <!-- Standard Navigation -->
    <a
      *ngIf="!mainNav.hasModularNavigation && activeModule.homepage"
      id="nav-logo-one-module"
      class="agr-nav-btn"
      routerLink="/{{ activeModule.homepage }}">
      <img alt="App logo" src="assets/images/logo-white@2x.png" style="padding-right: 8px" />
      <strong>{{ activeModule.name | agrTranslate }}</strong>
    </a>

    <span *ngIf="!mainNav.hasModularNavigation && !activeModule.homepage" id="nav-logo-one-module" class="agr-nav-btn disabled">
      <img alt="App logo" src="assets/images/logo-white@2x.png" style="padding-right: 8px" />
      <strong>{{ activeModule.name | agrTranslate }}</strong>
    </span>

    <!-- Modular Navigation -->
    <a
      *ngIf="mainNav.hasModularNavigation && activeModule.homepage"
      id="nav-logo"
      class="agr-nav-btn"
      routerLink="/{{ activeModule.homepage }}">
      <img alt="App logo" src="assets/images/logo-white@2x.png" />
    </a>

    <span *ngIf="mainNav.hasModularNavigation && !activeModule.homepage" id="nav-logo" class="agr-nav-btn disabled">
      <img alt="App logo" src="assets/images/logo-white@2x.png" />
    </span>

    <!-- dropdown button -->
    <div *ngIf="mainNav.hasModularNavigation" ngbDropdown #dropdown="ngbDropdown" [autoClose]="'outside'" #moduleDropdown="ngbDropdown">
      <button ngbDropdownToggle id="nav-modular-dropdown" class="agr-nav-btn fw-bold" type="button">
        <a>{{ activeModule.name | agrTranslate }}</a>
      </button>
      <div ngbDropdownMenu>
        <!-- modules of mainNav modules-->
        <li class="dropdown-header">
          <input
            type="text"
            id="navigation-filter"
            class="form-control"
            [(ngModel)]="filterText"
            placeholder="{{ 'FILTER' | agrTranslate }}"
            (input)="onNavigationFilterInput()"
            [agrAutoFocus]="dropdown.isOpen()" />
        </li>

        <ng-container *ngFor="let module of mainNav.modules; let index = index">
          <div class="dropdown-divider" *ngIf="index > 0"></div>
          <h6
            class="dropdown-header text-{{ module.color }} u-cursor-pointer"
            *ngIf="module.homepage"
            routerLink="/{{ module.homepage }}"
            (click)="moduleDropdown.close()">
            {{ module.name | agrTranslate }}
            <i class="fa fa-home text-gray-500"></i>
          </h6>

          <h6 class="dropdown-header text-{{ module.color }}" *ngIf="!module.homepage">
            {{ module.name | agrTranslate }}
          </h6>

          <ng-container *ngFor="let nav of module.navigationDropdown | filter: { key: 'caption', value: filterText }; let index = index">
            <button
              *ngIf="!(nav.submenu.length > 0)"
              type="button"
              [id]="'nav-item-' + nav.caption"
              ngbDropdownItem
              routerLinkActive="active"
              routerLink="/{{ nav.path }}"
              (click)="moduleDropdown.close()">
              <i *ngIf="nav.icon" class="fa fa-{{ nav.icon }}"></i>
              {{ nav.caption | agrTranslate }}
            </button>
            <button
              *ngIf="nav.submenu.length > 0"
              class="dropdown-item dropdown-item-submenu-toggle dropdown-toggle"
              data-toggle="collapse"
              type="button"
              [attr.data-target]="'#collapse-' + nav.caption"
              [id]="'nav-item-' + nav.caption"
              aria-expanded="false"
              [attr.aria-controls]="'collapse-' + nav.caption">
              <i *ngIf="nav.icon" class="fa fa-{{ nav.icon }}"></i>
              {{ nav.caption | agrTranslate }}
            </button>
            <div class="collapse" [id]="'collapse-' + nav.caption" *ngIf="nav.submenu.length > 0">
              <ng-container *ngFor="let sub of nav.submenu">
                <button
                  class="dropdown-item-submenu-item"
                  type="button"
                  *ngIf="!sub.divider"
                  [id]="'nav-item-' + sub.caption"
                  ngbDropdownItem
                  routerLinkActive="active"
                  routerLink="/{{ sub.path }}"
                  (click)="moduleDropdown.close()">
                  <i *ngIf="sub.icon" class="fa fa-{{ sub.icon }}"></i>
                  {{ sub.caption | agrTranslate }}
                </button>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- App Navigation -->
    <ng-container *ngFor="let appNav of activeModule.navigation">
      <!-- normal button -->
      <a class="agr-nav-btn" *ngIf="appNav.submenu.length === 0" routerLinkActive="active" routerLink="/{{ appNav.path }}">
        <i *ngIf="appNav.icon" class="fa fa-{{ appNav.icon }}" [class.caption-with-icon]="appNav.icon && appNav.caption"></i>
        {{ appNav.caption | agrTranslate }}
      </a>
      <!-- dropdown button -->
      <div class="dropdown" *ngIf="appNav.submenu.length > 0">
        <button class="dropdown-toggle agr-nav-btn" type="button" data-bs-toggle="dropdown">
          <i *ngIf="appNav.icon" class="fa fa-{{ appNav.icon }}"></i>
          {{ appNav.caption | agrTranslate }}
        </button>
        <div class="dropdown-menu">
          <ng-container *ngFor="let option of appNav.submenu">
            <a class="dropdown-item" *ngIf="!option.divider" routerLink="/{{ option.path }}">
              <i *ngIf="option.icon" class="fa fa-{{ option.icon }}"></i>
              {{ option.caption | agrTranslate }}
            </a>
            <div class="dropdown-divider" *ngIf="option.divider && (option.feature === undefined || (option.feature | agrFeature))"></div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <div id="secondary-nav">
    <!-- Userlane -->
    <button *ngIf="userlaneEnabled" type="button" class="agr-nav-btn" (click)="assistantClicked()">
      <i class="fa fa-mortar-board"></i>
    </button>
    <!-- Execution list -->
    <agr-execution-list></agr-execution-list>

    <!-- Settings -->
    <div id="nav-settings-dd" class="dropdown">
      <button type="button" class="agr-nav-btn" data-bs-toggle="dropdown">
        <i class="fa fa-cog"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <a
          id="custom-actions-itm"
          *ngIf="'customActions' | agrFeature"
          class="dropdown-item u-cursor-pointer"
          (click)="customActionsModal.open()"
          translate>
          CUSTOM_ACTIONS
        </a>
        <div class="dropdown-divider" *ngIf="'customActions' | agrFeature"></div>

        <!-- Settings navigation -->
        <ng-container *ngFor="let option of settingsNav.navigation">
          <a class="dropdown-item" *ngIf="!option.divider" routerLink="/{{ option.path }}" translate>
            <i *ngIf="option.icon" class="fa fa-{{ option.icon }}"></i>
            {{ option.caption }}
          </a>
          <div class="dropdown-divider" *ngIf="option.divider"></div>
        </ng-container>

        <div class="dropdown-divider" *ngIf="settingsNav.navigation.length > 0"></div>

        <a id="clear-settings" class="dropdown-item u-cursor-pointer" (click)="openClearBrowserDataModal()">
          {{ 'CLEAR_BROWSER_DATA' | agrTranslate }}...
        </a>
      </div>
    </div>

    <!-- Info dropdown -->
    <div id="nav-info-dd" class="dropdown">
      <button type="button" class="agr-nav-btn" data-bs-toggle="dropdown" data-cy="nav-info-btn">
        <i class="fa fa-question-circle"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <ng-container *ngFor="let infoDropdownItem of infoDropdown">
          <a
            id="{{ infoDropdownItem.caption.toLowerCase() }}"
            class="dropdown-item"
            href="{{ infoDropdownItem.url }}"
            target="_blank"
            *ngIf="infoDropdownItem.isEnabled">
            {{ infoDropdownItem.caption | agrTranslate }}
          </a>
        </ng-container>
        <div class="dropdown-divider"></div>
        <a id="about-agr" class="dropdown-item u-cursor-pointer" (click)="openAboutModal()" translate>ABOUT_AGR</a>
      </div>
    </div>

    <!-- User dropdown -->
    <div id="nav-user-dd" class="dropdown">
      <button class="agr-nav-btn" data-bs-toggle="dropdown">
        {{ sessionService.user?.name }}
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <div class="dropdown-header">
          <h6 id="nav-user-name" class="d-inline">{{ sessionService.user?.name }}</h6>
          <br />
          <span id="nav-user-email">{{ sessionService.user?.email }}</span>
        </div>

        <div class="dropdown-divider"></div>

        <button type="button" id="my-profile" class="dropdown-item" (click)="myProfileModal.open()" translate>NAV_MY_PROFILE</button>

        <div class="dropdown-divider"></div>

        <button
          type="button"
          id="log-out"
          class="dropdown-item text-center"
          routerLink="/login"
          [queryParams]="{ action: 'LOGOUT' }"
          translate>
          LOG_OUT
        </button>
      </div>
    </div>
  </div>
</nav>

<!-- Modals -->
<agr-my-profile (userUpdated)="('onUserUpdated($event)')" #myProfileModal></agr-my-profile>
<agr-custom-actions #customActionsModal></agr-custom-actions>
