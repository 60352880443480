export interface ConfigSerieFormatDTO {
  caption?: string;
  format?: string;
  aggregation?: SerieFormatAggregation;
  displayDiv?: number;
  checkboxes?: boolean;
}

type SerieFormatAggregation = 'min' | 'max' | 'count' | 'countDistinct' | 'minMax';

export class ConfigSerieFormat {
  caption?: string;
  format?: string;
  aggregation?: SerieFormatAggregation;
  displayDiv?: number;

  constructor(dto?: ConfigSerieFormatDTO) {
    if (!dto) {
      return;
    }
    this.caption = dto.caption;
    this.format = dto.format;
    this.aggregation = dto.aggregation;
    this.displayDiv = dto.displayDiv;
  }
}
