/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-classes-per-file */
import { TimezoneService } from 'src/app/_core/timezone.service';
import { EventModelDTO, ItemDTO } from 'src/app/item-card/models/item-card-dto.model';
import { ItemOrderRoute } from 'src/app/item-card/models/item-order-route.model';

export type ItemEditableProperty =
  | 'additionalLeadTime'
  | 'orderFrequency'
  | 'minStock'
  | 'maxStock'
  | 'confidenceFactor'
  | 'skipForecastToDate'
  | 'forecastFromDate'
  | 'forecastToDate'
  | 'eventModel';

class EventModel {
  id: number;
  name: string;

  constructor(eventModelDto?: EventModelDTO) {
    if (!eventModelDto) {
      return;
    }
    this.id = eventModelDto.id;
    this.name = eventModelDto.name;
  }
}

export class ItemDetails {
  // Basic
  id: number;
  location: string;
  name: string;
  articleNo: string;
  itemGroup: string;
  abcGrouping: string;
  closed: boolean;
  imageId: string;
  // Item Details
  size: string;
  color: string;
  style: string;
  description: string;
  weight: number;
  volume: number;
  salePrice: number;
  additionalLeadTime: number;
  additionalLeadTimeOverwrite = false;
  orderFrequency: number;
  orderFrequencyOverwrite = false;
  // Stock Info
  stock: number;
  undelivered: number;
  wastageDays: number;
  minStock: number;
  minStockOverwrite = false;
  maxStock: number;
  maxStockOverwrite = false;
  // Forecast Settings
  confidenceFactor: number;
  confidenceFactorOverwrite = false;
  skipForecastToDate: Date;
  skipForecastToDateOverwrite = false;
  forecastFromDate: Date;
  forecastFromDateOverwrite = false;
  forecastToDate: Date;
  forecastToDateOverwrite = false;
  eventModel: EventModel = new EventModel();
  eventModelOverwrite = false;
  eventModels: EventModel[] = [];
  // Order Routes
  primaryOrderRoute: ItemOrderRoute = new ItemOrderRoute();
  selectedOrderRoute = new ItemOrderRoute();
  orderRoutes: ItemOrderRoute[] = [];
  minOrder: number;
  costPrice: number;
  leadTimeDays: number;
  quantityPerUnit: number;
  quantityPerLayer: number;
  quantityPerPallet: number;
  private selectedOrderRouteIndex = 0;
  // This is private for a reason, don't use
  private itemDto: ItemDTO;

  constructor(item?: ItemDTO) {
    if (!item) {
      return;
    }
    this.id = item.id;
    this.location = item.location.name;
    this.name = item.name;
    this.articleNo = item.articleNo;
    this.itemGroup = item.itemGroup.name;
    this.abcGrouping = item.abcGrouping;
    this.closed = item.closed;
    this.imageId = item.imageId;
    this.size = item.size;
    this.color = item.color;
    this.style = item.style;
    this.description = item.description;
    this.weight = item.weight;
    this.volume = item.volume;
    this.salePrice = item.salePrice;
    this.additionalLeadTime = this.getAdditionalLeadTime(item);
    this.additionalLeadTimeOverwrite = item.itemDetail.additionalLeadTimeDaysOverwrite !== null;
    this.orderFrequency = this.getOrderFrequency(item);
    this.orderFrequencyOverwrite = item.itemDetail.orderFrequencyDaysOverwrite !== null;
    this.stock = item.totalStock;
    this.undelivered = item.totalUndelivered;
    this.wastageDays = item.wastageDays;
    this.minStock = this.getMinStock(item);
    this.minStockOverwrite = item.itemDetail.minStockOverwrite !== null;
    this.maxStock = this.getMaxStock(item);
    this.maxStockOverwrite = item.itemDetail.maxStockOverwrite !== null;
    this.confidenceFactor = this.getConfidenceFactor(item);
    this.confidenceFactorOverwrite = item.itemDetail.confidenceFactorOverwrite !== null;
    this.skipForecastToDate = this.getSkipForecastToDate(item);
    this.skipForecastToDateOverwrite = item.itemDetail.skipForcToDateOverwrite !== null;
    this.forecastFromDate = this.getForecastFromDate(item);
    this.forecastFromDateOverwrite = item.itemDetail.forecastFromDateOverwrite !== null;
    this.forecastToDate = this.getForecastToDate(item);
    this.forecastToDateOverwrite = item.itemDetail.forecastToDateOverwrite !== null;
    this.eventModel = this.getEventModel(item);
    this.eventModels =
      item.itemDetail.eventModelOptions && item.itemDetail.eventModelOptions.length > 0
        ? item.itemDetail.eventModelOptions.map((eventModel) => new EventModel(eventModel))
        : [];
    this.primaryOrderRoute = new ItemOrderRoute(item.primaryOrderRoute);
    this.orderRoutes =
      item.itemOrderRoutes && item.itemOrderRoutes.length > 0
        ? item.itemOrderRoutes.map((orderRouteDTO) => new ItemOrderRoute(orderRouteDTO))
        : [];
    this.selectedOrderRouteIndex = this.orderRoutes.findIndex((route) => route.isPrimary);
    this.selectedOrderRouteIndex = this.selectedOrderRouteIndex === -1 ? 0 : this.selectedOrderRouteIndex;
    this.selectedOrderRoute = this.orderRoutes.length > 0 ? this.orderRoutes[this.selectedOrderRouteIndex] : new ItemOrderRoute();
    this.itemDto = item;
  }

  toggleNextSelectedOrderRoute(): void {
    this.selectedOrderRouteIndex = (this.selectedOrderRouteIndex + 1) % this.orderRoutes.length;
    this.selectedOrderRoute = this.orderRoutes[this.selectedOrderRouteIndex];
  }

  selectNextEventModel(): void {
    if (!this.eventModel || this.eventModels.length === 0) {
      return;
    }
    let selectedEventModel = this.eventModels.findIndex((eventModel) => eventModel.id === this.eventModel.id);
    selectedEventModel = selectedEventModel === -1 ? 0 : selectedEventModel;
    const nextEventModel = (selectedEventModel + 1) % this.eventModels.length;
    this.eventModel = this.eventModels[nextEventModel];
    this.eventModelOverwrite = this.eventModel.id !== null;
  }

  getPropertyValue(lookup: string): string {
    let value = this[lookup];
    value = value ? value : this.itemDto[lookup];
    return value ? `${value}` : '';
  }

  update(property: ItemEditableProperty, value: number | Date): void {
    const newValue = typeof value === 'number' && !isNaN(value) && value < 0 ? 0 : value; // Fix negative values
    switch (property) {
      case 'additionalLeadTime':
        this.additionalLeadTimeOverwrite = true;
        this.additionalLeadTime = +newValue;
        break;
      case 'orderFrequency':
        this.orderFrequencyOverwrite = true;
        this.orderFrequency = +newValue;
        break;
      case 'minStock':
        this.setMinStock(+newValue);
        break;
      case 'maxStock':
        this.setMaxStock(+newValue);
        break;
      case 'confidenceFactor':
        this.setConfidenceFactor(+newValue);
        break;
      case 'skipForecastToDate':
        this.skipForecastToDateOverwrite = true;
        this.skipForecastToDate = new Date(value);
        break;
      case 'forecastFromDate':
        this.forecastFromDateOverwrite = true;
        this.forecastFromDate = new Date(value);
        break;
      case 'forecastToDate':
        this.forecastToDateOverwrite = true;
        this.forecastToDate = new Date(value);
        break;
      default:
    }
  }

  reset(property: ItemEditableProperty): void {
    switch (property) {
      case 'additionalLeadTime':
        this.additionalLeadTimeOverwrite = false;
        this.additionalLeadTime = this.getAdditionalLeadTime(this.itemDto);
        break;
      case 'orderFrequency':
        this.orderFrequencyOverwrite = false;
        this.orderFrequency = this.getOrderFrequency(this.itemDto);
        break;
      case 'minStock':
        this.minStockOverwrite = false;
        this.minStock = this.getMinStock(this.itemDto);
        break;
      case 'maxStock':
        this.maxStockOverwrite = false;
        this.maxStock = this.getMaxStock(this.itemDto);
        break;
      case 'confidenceFactor':
        this.confidenceFactorOverwrite = false;
        this.confidenceFactor = this.getConfidenceFactor(this.itemDto);
        break;
      case 'skipForecastToDate':
        this.skipForecastToDateOverwrite = false;
        this.skipForecastToDate = this.getSkipForecastToDate(this.itemDto);
        break;
      case 'forecastFromDate':
        this.forecastFromDateOverwrite = false;
        this.forecastFromDate = this.getForecastFromDate(this.itemDto);
        break;
      case 'forecastToDate':
        this.forecastToDateOverwrite = false;
        this.forecastToDate = this.getForecastToDate(this.itemDto);
        break;
      default:
    }
  }

  toUpdateForecastSettingsDto(): ForecastSettingsDTO {
    return {
      overwriteConfidenceFactor: this.confidenceFactorOverwrite,
      newConfidenceFactor: this.confidenceFactorOverwrite ? this.confidenceFactor : undefined,
      overwriteSkipForcToDate: this.skipForecastToDateOverwrite,
      newSkipForcToDate: this.skipForecastToDateOverwrite ? TimezoneService.dateToString(this.skipForecastToDate) : undefined,
      overwriteForecastFromDate: this.forecastFromDateOverwrite,
      newForecastFromDate: this.forecastFromDateOverwrite ? TimezoneService.dateToString(this.forecastFromDate) : undefined,
      overwriteForecastToDate: this.forecastToDateOverwrite,
      newForecastToDate: this.forecastToDateOverwrite ? TimezoneService.dateToString(this.forecastToDate) : undefined,
      overwriteOrderFrequency: this.orderFrequencyOverwrite,
      newOrderFrequency: this.orderFrequencyOverwrite ? this.orderFrequency : undefined,
      overwriteAdditionalLeadTime: this.additionalLeadTimeOverwrite,
      newAdditionalLeadTime: this.additionalLeadTimeOverwrite ? this.additionalLeadTime : undefined,
      overwriteMinStock: this.minStockOverwrite,
      newMinStock: this.minStockOverwrite ? this.minStock : undefined,
      overwriteMaxStock: this.maxStockOverwrite,
      newMaxStock: this.maxStockOverwrite ? this.maxStock : undefined,
      historyLength: 0,
      forecastLength: 0,
      eventModel: this.eventModel.id === null ? { id: undefined, name: undefined } : this.eventModel
    };
  }

  private getAdditionalLeadTime(item: ItemDTO): number {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.additionalLeadTimeDays;
    const fallback = item.itemDetail.additionalLeadTimeDaysDefault;
    if (value !== null && !isNaN(value)) {
      return value;
    }
    if (fallback !== null && !isNaN(fallback)) {
      return fallback;
    }
    return undefined;
  }

  private getOrderFrequency(item: ItemDTO): number {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.orderFrequencyDays;
    const fallback = item.itemDetail.orderFrequencyDaysDefault;
    if (value !== null && !isNaN(value)) {
      return value;
    }
    if (fallback !== null && !isNaN(fallback)) {
      return fallback;
    }
    return undefined;
  }

  private getMinStock(item: ItemDTO): number {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.minStock;
    const fallback = item.itemDetail.minStockDefault;
    if (value !== null && !isNaN(value)) {
      return value;
    }
    if (fallback !== null && !isNaN(fallback)) {
      return fallback;
    }
    return undefined;
  }

  private getMaxStock(item: ItemDTO): number {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.maxStock;
    const fallback = item.itemDetail.maxStockDefault;
    if (value !== null && !isNaN(value)) {
      return value;
    }
    if (fallback !== null && !isNaN(fallback)) {
      return fallback;
    }
    return undefined;
  }

  private getConfidenceFactor(item: ItemDTO): number {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.confidenceFactor;
    const fallback = item.itemDetail.confidenceFactorDefault;
    if (value !== null && !isNaN(value)) {
      return value;
    }
    if (fallback !== null && !isNaN(fallback)) {
      return fallback;
    }
    return undefined;
  }

  private getSkipForecastToDate(item: ItemDTO): Date | undefined {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.skipForcToDate;
    const fallback = item.itemDetail.skipForcToDateDefault;
    if (value) {
      return new Date(value);
    }
    if (fallback) {
      return new Date(fallback);
    }
    return undefined;
  }

  private getForecastFromDate(item: ItemDTO): Date | undefined {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.forecastFromDate;
    const fallback = item.itemDetail.forecastFromDateDefault;
    if (value) {
      return new Date(value);
    }
    if (fallback) {
      return new Date(fallback);
    }
    return undefined;
  }

  private getForecastToDate(item: ItemDTO): Date | undefined {
    if (!item) {
      return undefined;
    }
    const value = item.itemDetail.forecastToDate;
    const fallback = item.itemDetail.forecastToDateDefault;
    if (value) {
      return new Date(value);
    }
    if (fallback) {
      return new Date(fallback);
    }
    return undefined;
  }

  private getEventModel(item: ItemDTO): EventModel {
    let eventModel = new EventModel(item.itemDetail.eventModel);
    this.eventModelOverwrite = eventModel.id !== null;
    if (!this.eventModelOverwrite && item.itemDetail.eventModelOptions.length > 0) {
      eventModel = new EventModel(item.itemDetail.eventModelOptions[0]);
    }
    return eventModel;
  }

  private setMinStock(value: number): void {
    this.minStock = value;
    this.minStockOverwrite = true;
  }

  private setMaxStock(value: number): void {
    this.maxStock = value;
    this.maxStockOverwrite = true;
  }

  private setConfidenceFactor(value: number): void {
    let newValue = value < 0 ? 0 : value;
    newValue = newValue > 0 && newValue < 50 ? 50 : newValue;
    newValue = newValue > 99.9999 ? 99.9999 : newValue;
    this.confidenceFactorOverwrite = true;
    this.confidenceFactor = newValue;
  }
}

export interface ForecastSettingsDTO {
  overwriteConfidenceFactor: boolean;
  newConfidenceFactor: number;
  overwriteSkipForcToDate: boolean;
  newSkipForcToDate: string;
  overwriteForecastFromDate: boolean;
  newForecastFromDate: string;
  overwriteForecastToDate: boolean;
  newForecastToDate: string;
  overwriteOrderFrequency: boolean;
  newOrderFrequency: number;
  overwriteAdditionalLeadTime: boolean;
  newAdditionalLeadTime: number;
  overwriteMinStock: boolean;
  newMinStock: number;
  overwriteMaxStock: boolean;
  newMaxStock: number;
  historyLength: number;
  forecastLength: number;
  eventModel: EventModelDTO;
}
