import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';
import { ItemCardDetailsService } from 'src/app/item-card/details/item-card-details.service';

@Component({
  selector: '[agr-details-string-columns]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './editable-string-columns.component.html',
  styleUrls: ['../details-columns.component.scss']
})
export class EditableStringColumnsComponent {
  @Input() column: DetailsColumn;
  @Input() itemId: number;
  @Input() formGroup: FormGroup;
  @Input() bulkUpdate: boolean;
  @Output() readonly changed = new EventEmitter();

  editing: Boolean = false;
  useGlobal: Boolean;

  constructor(private itemCardDetailsService: ItemCardDetailsService) {}

  getValue(): number {
    return this.column.overwriteValue ? this.column.overwriteValue : this.column.value;
  }

  blur(): void {
    this.formGroup.get(this.column.name).setValue(this.column.value);
    this.formGroup.get(this.column.name).markAsTouched();
    this.editing = false;
  }

  startEditing(): void {
    this.editing = true;
  }

  updateString(): void {
    this.editing = false;
    this.column.overwriteValue = this.formGroup.get(this.column.name).value;
    if (!this.bulkUpdate) {
      this.update();
    }
    this.blur();
  }

  hasOverwriteValue(): boolean {
    if (this.column.overwriteValue || this.column.overwriteValue === 0) {
      return true;
    }
    return false;
  }

  toggleGlobal(): void {
    this.useGlobal = !this.useGlobal;
    if (this.useGlobal) {
      this.column.overwriteValue = undefined;
      this.formGroup.get(this.column.name).markAsDirty();
    }
    if (!this.useGlobal) {
      this.formGroup.get(this.column.name).markAsPristine();
    }
  }

  private update(): void {
    this.itemCardDetailsService.updateValues(this.column, this.column.overwriteValue, this.itemId).subscribe(() => {
      this.blur();
      this.changed.emit();
    });
  }
}
