import { FormStyle, TranslationWidth, getLocaleDayNames, getLocaleMonthNames } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { FormatService } from 'src/app/_core/format.service';

@Injectable()
export class DatepickerI18n extends NgbDatepickerI18n {
  constructor(private formatService: FormatService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return getLocaleDayNames(this.formatService.dateLocale, FormStyle.Standalone, TranslationWidth.Abbreviated)[weekday % 7];
  }
  getMonthShortName(month: number): string {
    return getLocaleMonthNames(this.formatService.dateLocale, FormStyle.Standalone, TranslationWidth.Abbreviated)[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return new Date(`${date.year}-${date.month}-${date.day}`).toDateString();
  }
}
