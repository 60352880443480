/* eslint-disable max-lines */
import {
  GridOptions,
  ICellRendererParams,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { cloneDeep, get, merge } from 'lodash';

import { FormatService } from 'src/app/_core/format.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { TimezoneService } from 'src/app/_core/timezone.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { AgGridService } from 'src/app/_shared/ag-grid/ag-grid.service';
import { AgrGridOptions } from 'src/app/_shared/ag-grid/agr-grid-options.model';
import { AgGridBitRendererComponent } from 'src/app/_shared/ag-grid/components/ag-grid-bit-renderer.component';
import { AgGridClosedRendererComponent } from 'src/app/_shared/ag-grid/components/ag-grid-closed-renderer.component';
import { AgGridTooltipComponent } from 'src/app/_shared/ag-grid/components/ag-grid-tooltip.component';

@Injectable({
  providedIn: 'root'
})
export class AgGridOptionsService {
  /**
   * Common options.
   * The default options used across all grids in AGR 6 apart from the simple grid found in erp setup.
   */
  private commonOptions: AgrGridOptions = {
    accentedSort: this.settingsService.accentedSort(),
    autoGroupColumnDef: { comparator: this.groupComparator.bind(this) },
    enableRangeSelection: true,
    groupSelectsFiltered: true,
    pivotMode: false,
    suppressRowDeselection: false,
    rowGroupPanelShow: 'always',
    suppressAggFuncInHeader: true,
    sideBar: {
      toolPanels: ['columns', 'filters'],
      defaultToolPanel: ''
    },
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    },
    suppressCopyRowsToClipboard: true,
    excelStyles: [
      { id: 'booleanType', dataType: 'Boolean' },
      { id: 'stringType', dataType: 'String' },
      { id: 'dateType', dataType: 'DateTime' }
    ],
    popupParent: document.querySelector('body'),
    postProcessPopup: (params: PostProcessPopupParams) => {
      if (params.type !== 'columnMenu') {
        return;
      }
      setTimeout(() => {
        const menuWidth = params.ePopup.offsetWidth;
        const leftPos = params.ePopup.offsetLeft;
        if (leftPos + menuWidth + 25 > window.innerWidth) {
          params.ePopup.style.left = `${window.innerWidth - menuWidth - 25}px`;
        }
      });
    },
    components: {
      gridTooltipComponent: AgGridTooltipComponent
    },
    /**
     * Events.
     */
    onDisplayedColumnsChanged: (event): void => {
      this.agGridService.autoResizeColumnHeaders(event.api);
    },
    onColumnResized: (event): void => {
      this.agGridService.autoResizeColumnHeaders(event.api);
    },
    onRowDataChanged: (event): void => {
      this.agGridService.autoResizeColumnHeaders(event.api);
    },

    /**
     * Default column defs.
     */
    defaultColDef: {
      enableRowGroup: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      enableValue: true,
      resizable: true,
      sortable: true,
      tooltipComponent: 'gridTooltipComponent',
      cellClassRules: {
        'text-end': (params) => params.colDef.aligning === 'right',
        'text-center': (params) => params.colDef.aligning === 'center',
        'ag-cell-number': (params) => this.getColumnType(params.colDef.format) === 'number',
        'ag-cell-integer': (params) => this.getColumnType(params.colDef.format) === 'integer',
        'ag-cell-currency': (params) => this.getColumnType(params.colDef.format) === 'currency',
        editable: (params) => {
          if (typeof params.colDef.editable === 'function') {
            const isEditable = params.colDef.editable({ ...params, column: params.columnApi.getColumn(params.colDef) });
            return isEditable && !params.node.group;
          }
          return params.colDef.editable && !params.node.group;
        },
        locked: (params) => params.node.locked
      }
    },
    /**
     * Column types.
     */
    columnTypes: {
      bit: {
        aligning: 'center',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: (params: ValueGetterParams) => {
            return params.data[params.colDef.field] ? `${TranslationsService.get('YES')}` : `${TranslationsService.get('NO')}`;
          }
        },
        cellRenderer: AgGridBitRendererComponent
      },
      closed: {
        allowedAggFuncs: ['distinct'],
        filter: 'agSetColumnFilter',
        cellRenderer: AgGridClosedRendererComponent
      },
      checkbox: {
        cellRenderer: (params: ICellRendererParams) =>
          `<input type='checkbox' ${params.getValue() ? 'checked' : ''} ${!params.colDef.editable ? 'disabled="disabled"' : ''} />`,
        filter: false // True False values can be sorted, no real need for filtering
      },
      currency: {
        aggFunc: 'sum',
        aligning: 'right',
        filter: 'agNumberColumnFilter',
        valueParser: this.parseNumeric.bind(this),
        valueFormatter: (params: ValueFormatterParams) => this.formatNumeric(params, 'integer')
      },
      date: {
        filter: 'agDateColumnFilter',
        filterParams: {
          inRangeInclusive: true,
          buttons: ['clearButton'],
          filterOptions: ['lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange']
        },
        valueFormatter: (params: ValueFormatterParams) => this.formatTime(params, 'date')
      },
      datetime: {
        filter: 'agDateColumnFilter',
        filterParams: {
          inRangeInclusive: true,
          buttons: ['clearButton'],
          filterOptions: ['lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange']
        },
        valueFormatter: (params: ValueFormatterParams) => this.formatTime(params, 'datetime')
      },
      time: {
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => this.formatTime(params, 'time')
      },
      duration: {
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => {
          return !isNaN(+params.value) ? this.formatNumeric(params, 'duration') : this.formatTime(params, 'duration');
        }
      },
      string: { filter: 'agTextColumnFilter' },
      translateString: {
        filter: 'agTextColumnFilter',
        valueFormatter: (params) => {
          return this.translateString(params.value);
        }
      },
      lookup: { filter: 'agSetColumnFilter' },
      integer: {
        aggFunc: 'sum',
        aligning: 'right',
        filter: 'agNumberColumnFilter',
        valueParser: this.parseNumeric.bind(this),
        valueFormatter: (params: ValueFormatterParams) => this.formatNumeric(params, 'integer')
      },
      number: {
        aggFunc: 'sum',
        aligning: 'right',
        filter: 'agNumberColumnFilter',
        valueParser: this.parseNumeric.bind(this),
        valueFormatter: (params: ValueFormatterParams) => this.formatNumeric(params, 'number')
      },
      percent: {
        aggFunc: 'avg',
        aligning: 'right',
        filter: 'agNumberColumnFilter',
        valueParser: this.parseNumeric.bind(this),
        valueFormatter: (params: ValueFormatterParams) => this.formatNumeric(params, 'percent')
      },
      id: {
        aligning: 'right',
        filter: 'agNumberColumnFilter',
        valueParser: this.parseNumeric.bind(this),
        valueFormatter: (params: ValueFormatterParams) => this.formatService.format(params.value, 'id')
      }
    },

    /**
     * Templates
     */
    overlayLoadingTemplate: `<div class="loading-dots yellow big">
      <div class="dots">
        <div class="bounce bounce1"></div>
        <div class="bounce bounce2"></div>
        <div class="bounce bounce3"></div>
      </div>
    </div>`,
    overlayNoRowsTemplate: `<p class="agr-no-data u-slow-appear">
      <img alt="App logo" src="assets/images/logo-big@2x.png"><br>
      <span ng-if="defaultMessage">
        <span> ${TranslationsService.get('NO_DATA_TO_DISPLAY')}</span><br>
      </span>
    </p>`,

    /**
     * Internationalization.
     * Doc: https://www.ag-grid.com/javascript-grid-internationalisation/
     */
    localeText: {
      // for filter panel
      page: TranslationsService.get('AG_GRID_PAGE'),
      more: TranslationsService.get('AG_GRID_MORE'),
      to: TranslationsService.get('AG_GRID_TO'),
      of: TranslationsService.get('AG_GRID_OF'),
      next: TranslationsService.get('AG_GRID_NEXT'),
      last: TranslationsService.get('AG_GRID_LAST'),
      first: TranslationsService.get('AG_GRID_FIRST'),
      previous: TranslationsService.get('AG_GRID_PREVIOUS'),
      loadingOoo: TranslationsService.get('AG_GRID_LOADING_OOO'),
      // for set filter
      selectAll: TranslationsService.get('AG_GRID_SELECT_ALL'),
      searchOoo: TranslationsService.get('AG_GRID_SEARCH_OOO'),
      blanks: TranslationsService.get('AG_GRID_BLANKS'),
      // for number filter and text filter
      filterOoo: TranslationsService.get('AG_GRID_FILTER_OOO'),
      applyFilter: TranslationsService.get('AG_GRID_APPLY_FILTER'),
      notEqual: TranslationsService.get('AG_GRID_NOT_EQUAL'),
      blank: TranslationsService.get('AG_GRID_BLANK'),
      notBlank: TranslationsService.get('AG_GRID_NOT_BLANK'),
      // for number filter
      equals: TranslationsService.get('AG_GRID_EQUALS'),
      lessThan: TranslationsService.get('AG_GRID_LESS_THAN'),
      greaterThan: TranslationsService.get('AG_GRID_GREATER_THAN'),
      lessThanOrEqual: TranslationsService.get('AG_GRID_LESS_THAN_OR_EQUAL'),
      greaterThanOrEqual: TranslationsService.get('AG_GRID_GREATER_THAN_OR_EQUAL'),
      inRange: TranslationsService.get('AG_GRID_IN_RANGE'),
      inRangeStart: TranslationsService.get('AG_GRID_RANGE_START'),
      inRangeEnd: TranslationsService.get('AG_GRID_RANGE_END'),
      // for text filter
      contains: TranslationsService.get('AG_GRID_CONTAINS'),
      notContains: TranslationsService.get('AG_GRID_NOT_CONTAINS'),
      startsWith: TranslationsService.get('AG_GRID_STARTS_WITH'),
      endsWith: TranslationsService.get('AG_GRID_ENDS_WITH'),
      // the header of the default group column
      group: TranslationsService.get('AG_GRID_GROUP'),
      // tool panel
      columns: TranslationsService.get('AG_GRID_COLUMNS'),
      filters: TranslationsService.get('AG_GRID_FILTERS'),
      rowGroupColumns: TranslationsService.get('AG_GRID_ROW_GROUP_COLUMNS'),
      rowGroupColumnsEmptyMessage: TranslationsService.get('AG_GRID_ROW_GROUP_COLUMNS_EMPTY_MESSAGE'),
      valueColumns: TranslationsService.get('AG_GRID_VALUE_COLUMNS'),
      pivotMode: TranslationsService.get('AG_GRID_PIVOT_MODE'),
      groups: TranslationsService.get('AG_GRID_GROUPS'),
      values: TranslationsService.get('AG_GRID_VALUES'),
      pivots: TranslationsService.get('AG_GRID_PIVOTS'),
      valueColumnsEmptyMessage: TranslationsService.get('AG_GRID_VALUE_COLUMNS_EMPTY_MESSAGE'),
      pivotColumnsEmptyMessage: TranslationsService.get('AG_GRID_PIVOT_COLUMNS_EMPTY_MESSAGE'),
      toolPanelButton: TranslationsService.get('AG_GRID_TOOL_PANEL_BUTTON'),
      // other
      noRowsToShow: TranslationsService.get('AG_GRID_NO_ROWS_TO_SHOW'),
      // enterprise menu
      pinColumn: TranslationsService.get('AG_GRID_PIN_COLUMN'),
      valueAggregation: TranslationsService.get('AG_GRID_VALUE_AGGREGATION'),
      autosizeThiscolumn: TranslationsService.get('AG_GRID_AUTOSIZE_THIS_COLUMN'),
      autosizeAllColumns: TranslationsService.get('AG_GRID_AUTOSIZE_ALL_COLUMNS'),
      groupBy: TranslationsService.get('AG_GRID_GROUP_BY'),
      ungroupBy: TranslationsService.get('AG_GRID_UNGROUP_BY'),
      resetColumns: TranslationsService.get('AG_GRID_RESET_COLUMNS'),
      expandAll: TranslationsService.get('AG_GRID_EXPAND_ALL'),
      collapseAll: TranslationsService.get('AG_GRID_COLLAPSE_ALL'),
      toolPanel: TranslationsService.get('AG_GRID_TOOL_PANEL'),
      export: TranslationsService.get('AG_GRID_EXPORT'),
      csvExport: TranslationsService.get('AG_GRID_CSV_EXPORT'),
      excelExport: TranslationsService.get('AG_GRID_EXCEL_EXPORT'),
      // enterprise menu pinning
      pinLeft: TranslationsService.get('AG_GRID_PIN_LEFT'),
      pinRight: TranslationsService.get('AG_GRID_PIN_RIGHT'),
      noPin: TranslationsService.get('AG_GRID_NO_PIN'),
      // enterprise menu aggregation and status panel
      avg: TranslationsService.get('AG_GRID_AVERAGE'),
      sum: TranslationsService.get('AG_GRID_SUM'),
      min: TranslationsService.get('AG_GRID_MIN'),
      max: TranslationsService.get('AG_GRID_MAX'),
      distinct: TranslationsService.get('AG_GRID_DISTINCT'),
      none: TranslationsService.get('AG_GRID_NONE'),
      count: TranslationsService.get('AG_GRID_COUNT'),
      average: TranslationsService.get('AG_GRID_AVERAGE'),
      // status bar
      rowCount: TranslationsService.get('AG_GRID_TOTAL_ROWS'),
      // standard menu
      copy: TranslationsService.get('AG_GRID_COPY'),
      copyWithHeaders: TranslationsService.get('AG_GRID_COPY_WITH_HEADERS'),
      ctrlC: TranslationsService.get('AG_GRID_CTRL_C'),
      paste: TranslationsService.get('AG_GRID_PASTE'),
      ctrlV: TranslationsService.get('AG_GRID_CTRL_V')
    },
    /**
     * Clipboard processing should be identical to excel/csv export in ag-grid.service.ts
     */
    processCellForClipboard: (params: ProcessCellForExportParams) => {
      if (params.value instanceof Date && params.column.getColDef().type === 'date') {
        return TimezoneService.dateToString(params.value);
      }
      if (params.value instanceof Date && params.column.getColDef().type === 'datetime') {
        return TimezoneService.toISOStringLocal(params.value);
      }
      return params.value;
    }
  };

  /**
   * List Grid.
   * Report list, Plans list, Users etc.
   * Note that all properties defined in dataOptions must also be set in listOptions (will otherwise affect each other).
   */
  private listOptions: GridOptions = {
    rowSelection: 'single',
    rowHeight: 30,
    enableCellChangeFlash: true
  };

  /**
   * List Grid with multi select.
   * E.g. Scheduler grid
   * Note that all properties defined in dataOptions must also be set in listOptions (will otherwise affect each other).
   */
  private listMultiSelectOptions: GridOptions = {
    enableCellChangeFlash: true,
    rowSelection: 'multiple',
    rowHeight: 30,
    // doc: https://www.ag-grid.com/javascript-grid-context-menu/#context-menu
    getContextMenuItems: (params) => {
      return [
        {
          name: TranslationsService.get('AG_GRID_SELECT_ALL'),
          action: () => {
            params.api.selectAllFiltered();
          }
        },
        {
          name: TranslationsService.get('AG_GRID_DESELECT_ALL'),
          action: () => {
            params.api.deselectAllFiltered();
          }
        },
        'separator',
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        {
          name: TranslationsService.get('AG_GRID_EXPORT'),
          subMenu: ['excelExport', 'csvExport']
        }
      ];
    }
  };

  /**
   * Data Grid.
   * Report Details, Orders, Translations.
   */
  private dataOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true, // Use AgGridService.addRowSelectColumn instead
    groupSelectsChildren: true,
    rowHeight: 21,
    rowClassRules: {
      'ag-row-child': (params) => params.node.level !== 0
    },
    // doc: https://www.ag-grid.com/javascript-grid-context-menu/#context-menu
    getContextMenuItems: (params) => {
      return [
        {
          name: TranslationsService.get('AG_GRID_SELECT_ALL'),
          action: () => {
            params.api.selectAllFiltered();
          }
        },
        {
          name: TranslationsService.get('AG_GRID_DESELECT_ALL'),
          action: () => {
            params.api.deselectAllFiltered();
          }
        },
        'separator',
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'expandAll',
        'contractAll',
        'separator',
        {
          name: TranslationsService.get('AG_GRID_EXPORT'),
          icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
          subMenu: ['excelExport', 'csvExport']
        }
      ];
    }
  };

  /**
   * Simple Grid.
   * ERP Setup grids: Vendors, Locations etc.
   */
  private simpleOptions: GridOptions = {
    rowHeight: 28,
    sideBar: false,
    rowGroupPanelShow: 'never',
    statusBar: { statusPanels: [] }
  };

  ////////////////////////////

  constructor(
    private agGridService: AgGridService,
    private formatService: FormatService,
    private settingsService: SettingsService,
    private toasterService: ToasterService
  ) {}

  getListOptions(): GridOptions {
    return merge(cloneDeep(this.commonOptions), cloneDeep(this.listOptions));
  }

  getListMultiSelectOptions(): GridOptions {
    return merge(cloneDeep(this.commonOptions), cloneDeep(this.listMultiSelectOptions));
  }

  getDataOptions(): GridOptions {
    return merge(cloneDeep(this.commonOptions), cloneDeep(this.dataOptions));
  }

  getSimpleOptions(): GridOptions {
    return merge(this.getDataOptions(), cloneDeep(this.simpleOptions));
  }

  getColumnType(columnFormat: string): string {
    if (!columnFormat) {
      return '';
    }
    const type = columnFormat.split(':')[0];
    const types = {
      bit: 'bit',
      closed: 'closed',
      currency: 'currency',
      date: 'date',
      datetime: 'datetime',
      decimal: 'number',
      duration: 'duration',
      float: 'number',
      id: 'id',
      int: 'integer',
      integer: 'integer',
      number: 'number',
      percent: 'percent',
      percentage: 'percent',
      string: 'string',
      time: 'time',
      translateString: 'translateString'
    };
    if (types[type]) {
      return types[type];
    }
    this.toasterService.pop('warning', `${TranslationsService.get('COLUMN_FORMAT_UNKOWN')} ${columnFormat}.`);
    return type;
  }

  // private

  /**
   * Sort grouped columns whether they include numbers or strings.
   */
  groupComparator(a: string | number | Date, b: string | number | Date): number {
    if (!a || !b) {
      return 0;
    }
    if (isNaN(Number(a)) || isNaN(Number(b))) {
      if (this.isDate(a as string) || this.isDate(b as string)) {
        return this.sortDate(a as Date, b as Date);
      }
      return this.sortString(a as string, b as string);
    }
    return (a as number) - (b as number);
  }

  private isDate(a: string): boolean {
    return !isNaN(Date.parse(a));
  }

  private sortDate(a: Date, b: Date): number {
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (new Date(a) < new Date(b)) {
      return -1;
    }
    if (new Date(a) > new Date(b)) {
      return 1;
    }
    return 0;
  }

  private sortString(a: string, b: string): number {
    if (this.settingsService.accentedSort()) {
      return a.localeCompare(b, this.settingsService.locale());
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  // Format and parse
  private translateString(params: string): string {
    return TranslationsService.get(params);
  }

  private formatTime(params: ValueFormatterParams, format: string): string {
    if (this.matchesDistinctAggregation(params.value)) {
      return params.value;
    }
    const formatVal = get(params.colDef, 'format', format);
    const value = params.value && !isNaN(Date.parse(params.value)) ? this.formatService.format(params.value, formatVal) : params.value;
    return value === 'undefined' ? '' : value;
  }

  private formatNumeric(params: ValueFormatterParams, format: string): string {
    const formatVal = get(params.colDef, 'format', format);
    const value = !isNaN(params.value) ? this.formatService.format(params.value, formatVal) : params.value;
    return value === 'undefined' ? '' : value;
  }

  private parseNumeric(params: ValueParserParams): number {
    return params.newValue === '' ? undefined : +`${params.newValue}`.replace(',', '.');
  }

  private matchesDistinctAggregation(value: any): boolean {
    const val = Array.isArray(value) ? value[0] : value;
    const match = /^[0-9]+\s\([^0-9]+\)$/.exec(val);
    return val && typeof val !== 'object' && !!match; // matches "123 (distinct)" or "123 (some text)";
  }
}
