import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IndexDbCacheService } from 'src/app/_core/de-query/index-db-cache.service';
import { ContextLine, ContextLineDTO } from 'src/app/_core/de-query/model/context-line.model';
import { Context } from 'src/app/_core/de-query/model/context.model';
import { DeQuery } from 'src/app/_core/de-query/model/de-query.model';
import { API_ROOT } from 'src/app/_core/models/api-route.model';

export interface DeQueryAvailabilityParams {
  query: DeQuery;
  allActions?: boolean;
  workspaceId?: number;
  tableSetId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  contextLines: ContextLine[];

  constructor(private httpClient: HttpClient, private indexDbCacheService: IndexDbCacheService) {}

  getContext(query: DeQuery, tableSetId: number): Observable<Context> {
    return this.getContextLines({
      tableSetId,
      query,
      allActions: false
    }).pipe(map((contextLines: ContextLine[]) => new Context(contextLines)));
  }

  getMasterContext(workspaceId: number): Observable<Context> {
    return this.getContextLines({
      workspaceId,
      query: {},
      allActions: true
    }).pipe(map((contextLines: ContextLine[]) => new Context(contextLines)));
  }

  private getContextLines(params: DeQueryAvailabilityParams): Observable<ContextLine[]> {
    return this.indexDbCacheService
      .getContextLineDtos(params, this.getContextLinesFromApi(params))
      .pipe(switchMap((dtos) => of(dtos.map((dto) => new ContextLine(dto)))));
  }

  private getContextLinesFromApi(params: DeQueryAvailabilityParams): Observable<ContextLineDTO[]> {
    return this.httpClient.post<ContextLineDTO[]>(`${API_ROOT}/dequery/availability`, {
      query: JSON.stringify(params.query),
      allActions: params.allActions,
      deWorkspaceId: params.workspaceId,
      typeId: params.tableSetId
    });
  }
}
