import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { IndexDbCacheService } from 'src/app/_core/de-query/index-db-cache.service';
import { DeQuery } from 'src/app/_core/de-query/model/de-query.model';
import { GridColumnMetaData, GridColumnMetaDataDTO } from 'src/app/_core/de-query/model/grid-column-meta-data';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { TranslationsService } from 'src/app/_core/translations.service';

@Injectable({
  providedIn: 'root'
})
export class DeQueryService {
  constructor(private httpClient: HttpClient, private indexDbCacheService: IndexDbCacheService) {}

  /**
   * Execute DeQuery.
   * Label is used to better identify what is being queried.
   */
  execQuery(deQuery: DeQuery, label?: string, tableSetId?: number): Observable<any> {
    const url = !label ? `${API_ROOT}/dequery` : `${API_ROOT}/dequery?${label}`;
    const query = this.fixMaxLimitColumns(deQuery);
    return this.httpClient.post(url, {
      query: JSON.stringify(query),
      type_id: tableSetId
    });
  }

  execQueryCached(deQuery: DeQuery, label?: string, tableSetId?: number): Observable<any> {
    const apiRequest = this.execQuery(deQuery, label, tableSetId);
    return this.indexDbCacheService.getFromCache(deQuery, apiRequest, tableSetId);
  }

  execColumnQuery(deQuery: DeQuery): Observable<GridColumnMetaData[]> {
    const query = this.fixMaxLimitColumns(deQuery);
    query.filters = query.filters.filter((filter) => !isEqual(filter.values, [])); // de_query_columns doesn't handle empty filters
    const apiObservable: Observable<GridColumnMetaDataDTO[]> = this.httpClient.post(`${API_ROOT}/dequery/columns`, {
      query: JSON.stringify(query)
    }) as Observable<GridColumnMetaDataDTO[]>;
    return this.indexDbCacheService.getGridColumnMetaDataDtos(query, apiObservable).pipe(
      map((dtos: GridColumnMetaDataDTO[]) => {
        return dtos.map((dto) => {
          dto.caption = TranslationsService.get(dto.caption);
          return new GridColumnMetaData(dto);
        });
      })
    );
  }

  execAction(action: string, args: any, deQuery: DeQuery): Observable<any> {
    const query = this.fixMaxLimitColumns(deQuery);
    if (args && args.testDelay) {
      return this.httpClient
        .post(`${API_ROOT}/dequery/actions/${action}`, { query: JSON.stringify({ args, query }), cancellationTokenNone: false })
        .pipe(delay(args.testDelay));
    }
    return this.httpClient.post(`${API_ROOT}/dequery/actions/${action}`, {
      query: JSON.stringify({ args, query }),
      cancellationTokenNone: false
    });
  }

  // executes action with cancellation token none
  execActionCancellationTokenNone(action: string, args: any, deQuery: DeQuery): Observable<any> {
    const query = this.fixMaxLimitColumns(deQuery);
    if (args && args.testDelay) {
      return this.httpClient
        .post(`${API_ROOT}/dequery/actions/${action}`, { query: JSON.stringify({ args, query }), cancellationTokenNone: true })
        .pipe(delay(args.testDelay));
    }
    return this.httpClient.post(`${API_ROOT}/dequery/actions/${action}`, {
      query: JSON.stringify({ args, query }),
      cancellationTokenNone: true
    });
  }

  /**
   * Sets max column limit in case there is no period filter which would otherwise limit the range
   */
  private fixMaxLimitColumns(deQuery: DeQuery): DeQuery {
    if (!deQuery.series || deQuery.series.length === 0) {
      return deQuery;
    }
    const query = cloneDeep(deQuery); // Important to clone, otherwise we are changing the query passed by reference.
    query.pivot.paging.limit = 1000;
    return query;
  }
}
