<div class="column-label d-flex justify-content-between">
  <span>{{ column.caption | agrTranslate }}</span>
  <ng-template #lookupColumnDescription>
    <h6>{{ column.caption | agrTranslate }}</h6>
    {{ column.description | agrTranslate }}
  </ng-template>
  <button type="button" class="btn btn-help" [ngbPopover]="lookupColumnDescription" popoverTitle="" container="">
    <i class="fa fa-question-circle"></i>
  </button>
  <div *ngIf="bulkUpdate" class="form-check form-switch">
    <input type="checkbox" class="form-check-input" [(ngModel)]="useGlobal" (input)="toggleGlobal()" />
    <label class="w-100 form-check-label"></label>
  </div>
</div>
<div class="column-value-editable" [formGroup]="formGroup" (click)="getNextLookupValue()" [ngClass]="{ useGlobal: useGlobal }">
  <div class="d-flex" [class.justify-content-between]="useGlobal">
    <span [ngClass]="{ edited: hasOverwriteValue(), 'me-2': !hasOverwriteValue() }"> </span>
    <span [formGroup]="formGroup">{{ getLookupValue() }}</span>
  </div>
  <button class="btn btn-col" *ngIf="hasOverwriteValue() && !useGlobal" type="button" (click)="reset(); $event.stopPropagation()">
    <i class="fa fa-close"></i>
  </button>
</div>
