import { ApiVersion } from 'src/app/_core/version/api-version.model';
import { ClientVersion } from 'src/app/_core/version/client-version.model';
import { DBVersion } from 'src/app/_core/version/db-version.model';

/**
 * Version info from AGR Pool.
 */
export interface ProjectVersionDTO {
  id: number;
  project: string;
  client: string;
  api: string;
  databases: string;
}

/**
 * Current app version info and versions from AGR Pool.
 */
export class AppInfo {
  client = new ClientVersion();
  api = new ApiVersion();
  database = new DBVersion();
  project: string;
  hasNewPatch = false;

  constructor(clientVersion?: ClientVersion) {
    if (!clientVersion) {
      return;
    }
    this.client = clientVersion;
    this.project = this.client.versionName;
  }

  updateFromVersionList(versions: ProjectVersionDTO[]): void {
    this.setProjectVersion(versions);
    this.determineHasNewPatch(versions);
  }

  /**
   * When current app versions do not mach with any pool versions.
   */
  hasNoMatchingProject(): boolean {
    return !this.project;
  }

  private setProjectVersion(poolVersions: ProjectVersionDTO[]): void {
    if (!poolVersions) {
      return;
    }
    const matchingVersion = poolVersions.find((poolVersion) => {
      const matchingClient = poolVersion.client === this.client.version;
      const matchingApi = poolVersion.api === this.api.version;
      const matchingDatabases = poolVersion.databases === this.database.version;
      return matchingClient && matchingApi && matchingDatabases;
    });
    this.project = matchingVersion ? matchingVersion.project : `${this.client.version.split('.')[0]}.${this.client.version.split('.')[1]}`;
  }

  private determineHasNewPatch(versions: ProjectVersionDTO[]): void {
    if (!versions || !this.project) {
      return;
    }
    const currentMajor = +this.project.split('.')[0]; // e.g 6 from 6.1-r8
    const currentMinor = +this.project.split('-r')[0].split('.')[1]; // e.g 1 from 6.1-r8
    const currentPatch = +this.project.split('-r')[1]; // 8 from 6.1-r8
    this.hasNewPatch =
      versions.findIndex((versionObj) => {
        const major = +versionObj.project.split('.')[0]; // e.g 6 from 6.1-r8
        const minor = +versionObj.project.split('-r')[0].split('.')[1]; // e.g 1 from 6.1-r8
        const patch = +versionObj.project.split('-r')[1]; // 8 from 6.1-r8
        return major === currentMajor && minor === currentMinor && patch > currentPatch;
      }) > -1;
  }
}
