import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
@Component({
  selector: 'agr-ag-grid-closed-component',
  template: `<span class="badge {{ bgClass }}" [hidden]="isUndefined">{{ caption | agrTranslate }}</span>`,
  styles: [
    `
      .badge {
        display: inline;
        padding: 2px 6px !important;
        font-size: 12px;
        height: 17px !important;
        position: relative;
        top: -0.5px;
      }
    `
  ]
})
export class AgGridClosedRendererComponent implements ICellRendererAngularComp {
  isClosed: boolean;
  isUndefined: boolean;
  bgClass: string;
  caption: string;

  agInit(params: ICellRendererParams): void {
    this.setIsClosed(params.getValue());
    this.isUndefined = this.isClosed === undefined;
    this.bgClass = this.isClosed ? 'bg-secondary' : 'bg-success';
    this.caption = this.isClosed ? 'CLOSED' : 'OPEN';
  }

  setIsClosed(value: boolean | string): void {
    if (value === undefined) {
      return;
    }
    // the value is a string when grouped ('true' | 'false') but otherwise a boolean
    if (typeof value === 'string') {
      this.isClosed = value === 'true' ? true : value === 'false' ? false : undefined;
    } else if (typeof value === 'boolean') {
      this.isClosed = value;
    }
  }

  refresh(): boolean {
    return true;
  }
}
