import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { FormatService } from 'src/app/_core/format.service';

/**
 * Impure pipe (rendered every digest cycle)
 * so moment.fromNow() can update its return value
 */
@Pipe({
  name: 'agrFromNow',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false
})
export class FromNowPipe implements PipeTransform {
  constructor(private formatService: FormatService) {}

  transform(value: number | string | Date): string {
    return moment(value).locale(this.formatService.dateLocale).fromNow();
  }
}
