import { ItemOrderRouteDTO } from 'src/app/item-card/models/item-card-dto.model';

export class ItemOrderRoute {
  id: number;
  name: string;
  isPrimary: boolean;
  minOrder: number;
  costPrice: number;
  leadTimeDays: number;
  quantityPerUnit: number;
  quantityPerLayer: number;
  quantityPerPallet: number;

  constructor(orderRouteDTO?: ItemOrderRouteDTO) {
    if (!orderRouteDTO) {
      return;
    }
    this.id = orderRouteDTO.id;
    this.name = orderRouteDTO.label;
    this.isPrimary = orderRouteDTO.primaryRoute;
    this.minOrder = orderRouteDTO.minOrder;
    this.costPrice = orderRouteDTO.costPrice;
    this.leadTimeDays = orderRouteDTO.leadTimeDays;
    this.quantityPerUnit = orderRouteDTO.orderMultiple;
    this.quantityPerLayer = orderRouteDTO.qtyLayer;
    this.quantityPerPallet = orderRouteDTO.qtyPallet;
  }
}
