import { Pipe, PipeTransform } from '@angular/core';

interface Search {
  key: string;
  value: string;
}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], search: Search): any[] {
    if (!items || items.length === 0) {
      return [];
    }
    if (!search || !search.key || !search.value) {
      return items;
    }
    return items.filter((item: any) => {
      return item[search.key].toLowerCase().indexOf(search.value.toLowerCase()) !== -1;
    });
  }
}
