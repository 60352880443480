/* eslint-disable no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { StoreService } from 'src/app/_core/store.service';
import { VersionService } from 'src/app/_core/version/version.service';
import { AboutModalComponent } from 'src/app/nav/about-modal/about-modal.component';
import { ClearDataModalComponent } from 'src/app/nav/clear-data-modal/clear-data-modal.component';
import { AgrNav, MainNavigation, ModularNav, SettingsNavigation } from 'src/app/nav/nav.model';

declare global {
  interface Window {
    Userlane: any;
  }
}

interface InfoDropdownItem {
  caption: string;
  url: string;
  isEnabled?: boolean;
}

export interface NavPers {
  activeModuleName?: string; // Name of active module
}

@Component({
  selector: 'agr-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  mainNav: MainNavigation;
  settingsNav: SettingsNavigation;
  currentUrl: string;
  filterText: string;
  activeModule: ModularNav = new ModularNav();
  hasAssistant = true;

  barColor = 'nav-blue';
  persKey = 'navigation';
  pers: NavPers = {
    activeModuleName: undefined
  };

  infoDropdown: InfoDropdownItem[] = [];
  userlaneEnabled: boolean;

  constructor(
    private globalEventsService: GlobalEventsService,
    private modal: NgbModal,
    private router: Router,
    private settingsService: SettingsService,
    private storeService: StoreService,
    private versionService: VersionService,
    public authService: AuthService,
    public sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.listenForChanges();
    this.setModularNavigation();
    this.setSettingsNavigation();
    this.listenForRouteChanges();
    this.loadPers();
    this.loadActiveModule();
    this.setCssColors();
    this.setInfoDropdown();
    this.userlaneEnabled = !!this.settingsService.getValue('userlane_enabled');
  }

  listenForChanges(): void {
    this.globalEventsService.redrawNavBarEvent$.subscribe(() => {
      this.setModularNavigation();
      this.setSettingsNavigation();
      this.loadActiveModule();
    });
  }

  listenForRouteChanges(): void {
    this.router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart && routerEvent.url !== undefined) {
        this.currentUrl = routerEvent.url;
        this.loadActiveModule();
      }
    });
  }

  openClearBrowserDataModal(): void {
    this.modal.open(ClearDataModalComponent, { size: 'sm' });
  }

  openAboutModal(): void {
    this.modal.open(AboutModalComponent, { size: 'sm' });
  }

  onNavigationFilterInput(): void {
    if (this.filterText.length === 0) {
      this.mainNav.modules.forEach((module) => module.resetDropdownNavigation());
    } else {
      this.mainNav.modules.forEach((module) => module.flattenDropdownNavigation());
    }
  }

  assistantClicked(): void {
    this.hasAssistant ? window.Userlane('hide') : window.Userlane('openAssistant');
    this.hasAssistant = !this.hasAssistant;
  }

  /**
   * The appNavigation is cloned to be able to filter features and modules for each user without affecting the others.
   * The navigation (features) of each module is then filtered according to user access.
   * The modules are then filtered; if no feature is accessible within a module, the module will not be displayed.
   */
  private setModularNavigation(): void {
    this.mainNav = this.settingsService.appNavigation();
    this.mainNav.modules.forEach((modularNav) => {
      modularNav.navigation = this.filterFeatureless(modularNav.navigationAll);
    });
    this.mainNav.modules = this.mainNav.modules.filter((mod) => mod.navigation.length > 0);
  }

  private setSettingsNavigation(): void {
    this.settingsNav = this.settingsService.settingsNavigation();
    this.settingsNav.navigation = this.filterFeatureless(this.settingsNav.navigation);
  }

  private setInfoDropdown(): void {
    this.infoDropdown = [
      this.setCustomUserManual(),
      this.setUserManual(),
      this.setTechnicalManual(),
      this.setServiceDeskDropdownItem('GENERAL_INQUIRIES'),
      this.setServiceDeskDropdownItem('REPORT_PROBLEM'),
      this.setServiceDeskDropdownItem('IMPROVEMENT_IDEA'),
      this.setServiceDeskDropdownItem('CHANGE_REQUEST')
    ];
  }

  private setUserManual(): InfoDropdownItem {
    const isEssentials = this.settingsService.getValue('agr_system') === 'essentials';
    return {
      caption: 'USER_MANUAL',
      url: isEssentials
        ? `https://docs.agrdynamics.com/essentials/user/general/`
        : `https://docs.agrdynamics.com/${this.versionService.getClientVersion().versionName}/user`,
      isEnabled: true
    };
  }

  private setTechnicalManual(): InfoDropdownItem {
    return {
      caption: 'TECHNICAL_MANUAL',
      url: `https://docs.agrdynamics.com/${this.versionService.getClientVersion().versionName}/technical`,
      isEnabled: this.authService.hasFeature('developmentMode')
    };
  }

  private setCustomUserManual(): InfoDropdownItem {
    const customUserManual: InfoDropdownItem = {
      caption: this.settingsService.getValue('documentation_caption'),
      url: this.settingsService.getValue('documentation_url')
    };
    customUserManual.isEnabled = customUserManual.caption && customUserManual.caption.length > 0;
    return customUserManual;
  }

  private setServiceDeskDropdownItem(caption: string): InfoDropdownItem {
    let urlEnding = '';
    switch (caption) {
      case 'GENERAL_INQUIRIES': {
        urlEnding = '1';
        break;
      }
      case 'REPORT_PROBLEM': {
        urlEnding = '5';
        break;
      }
      case 'IMPROVEMENT_IDEA': {
        urlEnding = '41';
        break;
      }
      case 'CHANGE_REQUEST': {
        urlEnding = '4';
        break;
      }
      default:
        urlEnding = '1';
    }
    const serviceUrl = 'https://agrinventory.atlassian.net/servicedesk/customer/portal/1/group/1/create/';
    return {
      caption,
      url: `${serviceUrl}${urlEnding}`,
      isEnabled: caption !== 'CHANGE_REQUEST' ? true : this.settingsService.isEnterprise()
    };
  }

  /**
   * The URL decides which module we are in. If no specific module owns the url (e.g. /settings),
   * nor has it as a homepage, then pers is retrieved.
   * The activeModuleName is saved immediately to pers so that it can be accessed anywhere in the app.
   */
  private loadActiveModule(): void {
    this.mainNav.modules.forEach((modularNav) => {
      const activeNav = modularNav.navigation.find((agrNav) => `/${agrNav.path}` === this.currentUrl);
      if (activeNav) {
        this.selectModule(modularNav);
        return;
      }
      if (`/${modularNav.homepage}` === this.currentUrl) {
        this.selectModule(modularNav);
        return;
      }
    });
    this.selectModule(this.mainNav.modules.find((module) => module.name === this.pers.activeModuleName));
  }

  private selectModule(modularNav?: ModularNav): void {
    this.activeModule = modularNav ? modularNav : this.mainNav.defaultModule;
    this.pers.activeModuleName = this.activeModule.name;
    this.setCssColors();
    this.savePers();
  }

  /**
   * Sets the color defined in module to the navigation bar
   */
  private setCssColors(): void {
    this.barColor = this.activeModule.color ? `nav-${this.activeModule.color}` : 'nav-blue';
  }

  private loadPers(): void {
    this.pers = this.storeService.load(this.persKey, this.pers) as NavPers;
  }

  private savePers(): void {
    this.storeService.set(this.persKey, this.pers);
  }

  private filterFeatureless(menuItems: AgrNav[]): AgrNav[] {
    const menu = menuItems.filter((menuItem: AgrNav) => {
      return !menuItem.feature || this.authService.hasFeature(menuItem.feature);
    });
    menu.forEach((menuItem: AgrNav, index: number) => {
      if (menuItem.submenu.length > 0) {
        menuItem.submenu = this.filterFeatureless(menuItem.submenu);
        if (menuItem.submenu.length === 0) {
          // Delete the submenu if it is empty
          menu.splice(index, 1);
        }
      }
    });
    return menu;
  }
}
