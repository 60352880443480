import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { BehaviorSubject } from 'rxjs';

import { API_ROOT } from 'src/app/_core/models/api-route.model';

/**
 * Signal R
 *
 * Config set in core.module.ts
 * Connection is started and stopped in app.component.ts
 *
 * API:
 * Hub defined in Common/Hubs/NotificationsHub.cs
 * See also: Common/Utils/HubUtils.cs
 *
 * When action is started TaskUtils.cs is used:
 * StartExecutionTask() -> ExecuteAsyncQuery() -> HubUtils.SendNotification(notifications) -> Pops up in Execution List dropdown
 */

export enum SignalRStatus {
  off = 0,
  on = 1,
  lost = 2
}

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  connection: signalR.HubConnection | undefined;
  status: SignalRStatus = SignalRStatus.off;
  statusSubject: BehaviorSubject<SignalRStatus>;
  private retryIntervalMs = 20000;

  constructor(private httpClient: HttpClient, private ngZone: NgZone) {}

  createConnection(): void {
    this.ngZone.runOutsideAngular(() => {
      this.statusSubject = new BehaviorSubject(undefined);
      this.connection = new signalR.HubConnectionBuilder().withUrl('/signalr').build();
      this.connection.onclose(this.onSignalRclose.bind(this));
      this.startSignalr();
    });
  }

  startSignalr(): void {
    this.connection.start().then(() => {
      this.status = SignalRStatus.on;
      this.statusSubject.next(this.status);
      this.register();
    });
  }

  register(): void {
    this.connection.invoke('GetConnectionId').then((connectionId) => {
      this.httpClient.put(`${API_ROOT}/notifications/register-signalr-id/${connectionId}`, {}).subscribe();
    });
  }

  stopConnection(): void {
    this.status = SignalRStatus.off;
    if (this.connection) {
      this.connection.stop();
    }
  }

  private onSignalRclose(): void {
    if (this.status === SignalRStatus.off) {
      this.statusSubject.next(this.status);
      return;
    }
    this.status = SignalRStatus.lost;
    this.statusSubject.next(this.status);
    setTimeout(this.retryConnection.bind(this), this.retryIntervalMs);
  }

  private retryConnection(): void {
    if (this.status !== SignalRStatus.lost) {
      return;
    }
    try {
      this.startSignalr();
    } catch (ex) {
      // Ignore errors to not clutter up the console, since errors can be expected
    }
  }
}
