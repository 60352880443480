import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Login } from 'src/app/_core/authorization/login.model';
import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { Auth365Config, BaseConfig } from 'src/app/_core/models/base-config.model';
import { UserDTO } from 'src/app/_core/models/user.model';
import { StoreService } from 'src/app/_core/store.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  auth365Config: Auth365Config;
  sessionKeys = ['session.user', 'session.token', 'session.authData', 'session.features'];

  get token(): string {
    return this.storeService.get('session.token') as string;
  }
  set token(token: string) {
    this.storeService.set('session.token', token);
  }
  get authData(): Login {
    this.validateAuthData();
    return this.storeService.get('session.authData') as Login;
  }
  set authData(authData: Login) {
    this.storeService.set('session.authData', authData);
  }
  get user(): UserDTO {
    return this.storeService.get('session.user') as UserDTO;
  }
  set user(user: UserDTO) {
    this.storeService.set('session.user', user);
  }
  get currentFeatures(): string[] {
    return this.storeService.get('session.features') as string[];
  }
  set currentFeatures(features: string[]) {
    this.storeService.set('session.features', features);
  }
  get returnUrl(): string {
    return this.storeService.get('session.returnUrl') as string;
  }
  set returnUrl(value: string) {
    if (value) {
      this.storeService.set('session.returnUrl', value);
      return;
    }
    this.storeService.remove('session.returnUrl');
  }

  constructor(private globalEventsService: GlobalEventsService, private httpClient: HttpClient, private storeService: StoreService) {}

  /**
   * Initialized at app startup from core.module.ts
   * Set session properties:
   * - Check if api uses auth365. null if not being used.
   */
  async initialize(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(`${API_ROOT}/settings/base`).subscribe(
        (baseConfig: BaseConfig) => {
          this.auth365Config = baseConfig.auth365;
          if (this.user) {
            this.globalEventsService.showNavBar();
          }
          if (this.user && this.userAndAppConfigMismatch()) {
            this.clearUserData();
          }
          resolve({});
        },
        (err) => {
          window.alert('AGR Error: Failed to load settings from API');
          console.error('AGR: Failed to load settings from API');
          reject(err);
        }
      );
    });
  }

  /**
   * Clear user login data.
   */
  clearUserData(): void {
    this.sessionKeys.map((key) => this.storeService.remove(key));
  }

  /**
   * Clear local storage but keep user login data and any data stored on keys in @notDelete.
   */
  clear(): void {
    const notDeletedKeys = [...this.sessionKeys, 'lastUsedLanguage'];
    const tempStore = new Map<string, any>();
    notDeletedKeys.forEach((key) => {
      tempStore.set(key, this.storeService.get(key));
    });
    localStorage.clear();
    tempStore.forEach((value, key) => this.storeService.set(key, value));
  }

  private userAndAppConfigMismatch(): boolean {
    const userHasAuth365 = !!this.user.auth365;
    const appHasAuth365 = !!this.auth365Config;
    return userHasAuth365 !== appHasAuth365;
  }

  private validateAuthData(): void {
    if (!this.storeService.isValidModel('session.authData', new Login())) {
      this.clearUserData();
    }
  }
}
