import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'agr-ag-grid-tooltip-component',
  template: `
    <strong>{{ headerName }}</strong>
    <div [innerHTML]="description"></div>
  `,
  styleUrls: ['./ag-grid-tooltip.component.scss']
})
export class AgGridTooltipComponent implements ITooltipAngularComp {
  params: any;
  headerName: string;
  description: string;
  isHeader: boolean;
  getGui: any;

  agInit(params: ITooltipParams): void {
    this.params = params;
    this.isHeader = params.rowIndex === undefined;
    this.headerName = params.colDef.headerName;
    this.description = params.value || '';
  }
}
