import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { FeatAuthGuard } from 'src/app/_core/authorization/feat-auth-guard.service';
import { FormatService } from 'src/app/_core/format.service';
import { LoaderInterceptor } from 'src/app/_core/loader.interceptor';
import { NoAccessComponent } from 'src/app/_core/403-and-404/no-access.component';
import { NotFoundComponent } from 'src/app/_core/403-and-404/not-found.component';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { SignalRService } from 'src/app/_core/signal-r.service';
import { TimezoneService } from 'src/app/_core/timezone.service';
import { TokenInterceptor } from 'src/app/_core/token.interceptor';
import { SharedModule } from 'src/app/_shared/shared.module';

@NgModule({
  imports: [SharedModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TranslateModule.forChild(), NgbModule],
  declarations: [NoAccessComponent, NotFoundComponent],
  providers: [
    FormatService,
    AuthService,
    FeatAuthGuard,
    LoaderInterceptor,
    SessionService,
    SignalRService,
    SettingsService,
    TimezoneService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

/**
 * Make sure the core module will not be loaded more than once for the app.
 *
 * Code from the Angular Styleguide:
 * https://angular.io/guide/styleguide#prevent-re-import-of-the-core-module
 */
function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}
