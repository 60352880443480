import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContextService } from 'src/app/_core/de-query/context.service';
import { DeQueryService } from 'src/app/_core/de-query/de-query.service';
import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DetailsColumn, LookupOption } from 'src/app/item-card/details/details-columns/details-column-model';
import { Context } from 'src/app/_core/de-query/model/context.model';

@Injectable({
  providedIn: 'root'
})
export class ItemCardDetailsService {
  httpClient: any;
  http: any;

  constructor(private contextService: ContextService, private deQueryService: DeQueryService) {}

  getColumns(): Observable<ContextLine[]> {
    return this.contextService.getContext({}, 3).pipe(map((context: Context) => context.getColumns()));
  }

  // eslint-disable-next-line max-lines-per-function
  getDetails(allColumns: ContextLine[], itemId?: number): Observable<DetailsColumn[]> {
    const allDetailColumns = allColumns.filter((column) => {
      return column.groupName !== '_undelivered' && column.groupName !== '_order_routes_info' && !column.name.includes('exp');
    });
    return this.deQueryService
      .execQuery({
        queryType: 'distinct',
        columns: allDetailColumns.map((column) => ({ name: column.name })),
        filters: itemId ? [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }] : undefined
      })
      .pipe(
        map((details) => {
          return allColumns.reduce((result, column) => {
            if (!column.name.includes('global') && !column.name.includes('overwrite')) {
              return result.concat(
                new DetailsColumn(
                  column,
                  details[0][column.name],
                  details[0][`${column.name}_overwrite`],
                  details[0][`${column.name}_global`]
                )
              );
            }
            return result;
          }, []);
        }),
        map((detailsData) => {
          return this.getLookupOptions(detailsData, itemId);
        })
      );
  }

  getBulkUpdateDetails(allColumns: ContextLine[], itemId: number): Observable<DetailsColumn[]> {
    return this.getDetails(allColumns, itemId).pipe(
      map((detailsData) => {
        return detailsData
          .filter((column) => column.isBulkUpdateEnabled())
          .map((column) => {
            column.value = undefined;
            return column;
          });
      })
    );
  }

  getUndeliveredOrders(allColumns: ContextLine[], itemId: number): Observable<DetailsColumn[][]> {
    const undeliveredColumns = allColumns.filter((column) => column.groupName === '_undelivered');
    return this.deQueryService
      .execQuery({
        queryType: 'distinct',
        columns: undeliveredColumns.map((column) => ({ name: column.name })),
        filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }]
      })
      .pipe(
        map((undeliveredOrders) => {
          return undeliveredOrders.map((undeliveredOrder) => {
            return undeliveredColumns.map((column) => {
              return new DetailsColumn(column, undeliveredOrder[column.name]);
            });
          });
        })
      );
  }

  getOrderRoutes(allColumns: ContextLine[], itemId: number): Observable<DetailsColumn[][]> {
    const orderRouteColumns = allColumns.filter((column) => column.groupName === '_order_route_info');
    return this.deQueryService
      .execQuery({
        queryType: 'distinct',
        columns: orderRouteColumns.map((column) => ({ name: column.name })),
        filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }]
      })
      .pipe(
        map((orderRoutes) => {
          return orderRoutes.map((orderRoute) => {
            return orderRouteColumns.map((column) => {
              return new DetailsColumn(column, orderRoute[column.name]);
            });
          });
        })
      );
  }

  getExpiringStock(allColumns: ContextLine[], itemId: number): Observable<DetailsColumn[][]> {
    const expStockColumn = allColumns.filter((column) => column.name.includes('exp') && !column.name.includes('undelivered'));
    return this.deQueryService
      .execQuery({
        queryType: 'distinct',
        columns: expStockColumn.map((column) => ({ name: column.name })),
        filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }]
      })
      .pipe(
        map((expStocks) => {
          return expStocks.map((expStock) => {
            return expStockColumn.map((column) => {
              return new DetailsColumn(column, expStock[column.name]);
            });
          });
        })
      );
  }

  getLookupOptions(columns: DetailsColumn[], itemId: number): any {
    return columns.map((column) => {
      if (column.config && column.isLookupColumn()) {
        if (column.config.edit.lookupConfig.fixedOptions) {
          column.lookupOptions = column.config.edit.lookupConfig.fixedOptions;
          return column;
        } else {
          this.getLookupOptionsByQuery(column.config, itemId).subscribe((option) => {
            column.lookupOptions = option;
            return column;
          });
          return column;
        }
      }
      return column;
    });
  }

  getLookupOptionsByQuery(config: any, itemId: number): Observable<LookupOption[]> {
    return this.deQueryService
      .execQuery({
        queryType: 'distinct',
        columns: config.edit.lookupConfig.query.columns,
        filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }]
      })
      .pipe(
        map((contextLines) => {
          return contextLines.map((contextLine) => {
            return {
              id: contextLine[config.edit.lookupConfig.idColumn],
              value: contextLine[config.edit.lookupConfig.nameColumn]
            };
          });
        })
      );
  }

  getOverwriteValues(columns: ContextLine[], itemId: number): Observable<any> {
    return this.deQueryService.execQuery({
      columns: columns.map((column) => ({ name: column.name })),
      filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }]
    });
  }

  getGlobalSettingValues(columns: ContextLine[], itemId: number): Observable<any> {
    return this.deQueryService.execQuery({
      columns: columns.map((column) => ({ name: column.name })),
      filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }]
    });
  }

  updateValues(updateColumn: DetailsColumn, newValue: any, itemId: number): Observable<any> {
    return this.deQueryService.execAction(
      'update_value',
      {
        newValue,
        oldValue: updateColumn.value,
        targetColumn: updateColumn.config.edit.targetIdColumn,
        targetColumnChanged: updateColumn.config.edit.targetIdColumn
      },
      { filters: [{ column: { name: '_item_id' }, values: [itemId], operator: 'IN' }] }
    );
  }
}
