import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';

import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from 'src/app/_shared/shared.module';
import { ItemCardChartComponent } from 'src/app/item-card/chart/item-card-chart.component';
import { EditableDateColumnsComponent } from 'src/app/item-card/details/details-columns/editable-date-columns/editable-date-columns.component';
import { EditableLookupColumnsComponent } from 'src/app/item-card/details/details-columns/editable-lookup-columns/editable-lookup-columns.component';
import { EditableNumberColumnsComponent } from 'src/app/item-card/details/details-columns/editable-number-columns/editable-number-columns.component';
import { EditableStringColumnsComponent } from 'src/app/item-card/details/details-columns/editable-string-columns/editable-string-columns.component';
import { NonEditableColumnsComponent } from 'src/app/item-card/details/details-columns/non-editable-columns/non-editable-columns.component';
import { ItemCardDetailsComponent } from 'src/app/item-card/details/item-card-details.component';
import { ItemCardGridComponent } from 'src/app/item-card/grid/item-card-grid.component';
import { ItemCardComponent } from 'src/app/item-card/item-card.component';

@NgModule({
  declarations: [
    ItemCardComponent,
    ItemCardDetailsComponent,
    ItemCardChartComponent,
    ItemCardGridComponent,
    EditableDateColumnsComponent,
    EditableNumberColumnsComponent,
    EditableLookupColumnsComponent,
    EditableStringColumnsComponent,
    NonEditableColumnsComponent
  ],
  imports: [AgGridModule.withComponents([]), CommonModule, FormsModule, HighchartsChartModule, ReactiveFormsModule, SharedModule],
  exports: [
    ItemCardComponent,
    EditableDateColumnsComponent,
    EditableNumberColumnsComponent,
    EditableLookupColumnsComponent,
    EditableStringColumnsComponent,
    NonEditableColumnsComponent
  ]
})
export class ItemCardModule {}
