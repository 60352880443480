import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SessionService } from 'src/app/_core/session.service';
import { SignalRService, SignalRStatus } from 'src/app/_core/signal-r.service';
import { StoreService } from 'src/app/_core/store.service';
import { ExecutionListService } from 'src/app/nav/execution-list/execution-list.service';
import { ExecutionNotification } from 'src/app/nav/execution-list/execution-notification.model';

interface ExecutionListState {
  showActionsFromOtherUsers: boolean;
}

@Component({
  selector: 'agr-execution-list',
  templateUrl: './execution-list.component.html',
  styleUrls: ['./execution-list.component.scss']
})
export class ExecutionListComponent implements OnInit {
  connectionStatus: SignalRStatus = SignalRStatus.off;
  activeExecutionsCount: number;
  allActions: ExecutionNotification[] = [];
  actions: ExecutionNotification[] = [];
  userActionsCount = 0;
  get allUserActions(): ExecutionNotification[] {
    return this.allActions.filter((action) => action.userId === this.sessionService.user.id);
  }

  state: ExecutionListState = {
    showActionsFromOtherUsers: false
  };

  private stateKey = 'executions.list';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private executionListService: ExecutionListService,
    public sessionService: SessionService,
    private storeService: StoreService,
    private signalRService: SignalRService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loadState();
      this.initializeNotifications();
      this.startNotificationsListener();
      this.startSignalrStatusListener();
      // For some reason this fixes returnUrl not working
    }, 1000);
  }

  clearAction(id: number, event: Event): void {
    this.stopEvent(event);
    this.executionListService.clearAction(id).subscribe(() => {
      this.allActions = this.allActions.filter((action) => action.id !== id);
      this.setVisibleActions();
    });
  }

  clearAllUserActions(event: Event): void {
    this.stopEvent(event);
    this.executionListService.clearMultipleActions(this.allUserActions).subscribe();
    this.allActions = this.allActions.filter((action) => action.userId !== this.sessionService.user.id || action.progressPercent !== 100);
    this.setVisibleActions();
  }

  toggleActionsFromOtherUsers(event: Event): void {
    this.stopEvent(event);
    this.state.showActionsFromOtherUsers = !this.state.showActionsFromOtherUsers;
    this.setVisibleActions();
    this.saveState();
  }

  /**
   * Stopping mouse click to propagate (prevents dropdown from closing).
   */
  stopEvent(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Return the appropriate tooltip text for the tasks icon
   */
  getTaskTooltip(): string {
    // If connection status is connected (or not yet determined)
    if (this.connectionStatus === SignalRStatus.on) {
      if (this.activeExecutionsCount === 0) {
        return 'NO_PENDING_USER_ACTIONS';
      }
      if (this.activeExecutionsCount === 1) {
        return 'PENDING_USER_ACTION';
      }
      return 'PENDING_USER_ACTIONS';
    }
    if (this.connectionStatus === SignalRStatus.lost) {
      return 'SERVER_CONNECTION_LOST';
    }
    return 'ATTEMPTING_SERVER_RECONNECTION';
  }

  // private

  private startSignalrStatusListener(): void {
    // Give it a few seconds before we start listening to the signalR status
    setTimeout(() => {
      this.connectionStatus = this.signalRService.status;
      this.signalRService.statusSubject.subscribe((connectionStatus) => {
        this.connectionStatus = connectionStatus ? connectionStatus : this.connectionStatus;
      });
    }, 5000);
  }

  private initializeNotifications(): void {
    this.executionListService.getActions().subscribe((actions) => {
      this.setupActions(actions);
    });
  }

  private startNotificationsListener(): void {
    this.executionListService.listenForActions().subscribe((actions) => {
      this.setupActions(actions);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setupActions(actions: ExecutionNotification[]): void {
    this.allActions = actions;
    this.activeExecutionsCount = actions.filter((action) => {
      return action.progressPercent !== 100 && (this.state.showActionsFromOtherUsers || action.userId === this.sessionService.user.id);
    }).length;
    this.setVisibleActions();
  }

  private setVisibleActions(): void {
    this.actions = this.state.showActionsFromOtherUsers ? this.allActions : this.allUserActions;
    this.userActionsCount = this.allUserActions.length;
  }

  private loadState(): void {
    this.state = this.storeService.load(this.stateKey, this.state) as ExecutionListState;
  }

  private saveState(): void {
    this.storeService.set(this.stateKey, this.state);
  }
}
