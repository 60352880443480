export interface ConfigColumnRowDTO {
  autoRefresh?: boolean;
  refreshWholeGrid?: boolean;
}

export class ConfigColumnRow {
  autoRefresh?: boolean;
  refreshWholeGrid?: boolean;

  constructor(dto?: ConfigColumnRowDTO) {
    if (!dto) {
      return;
    }
    this.autoRefresh = dto.autoRefresh;
    this.refreshWholeGrid = dto.refreshWholeGrid;
  }
}
