import { Component } from '@angular/core';

@Component({
  selector: 'agr-no-data-to-display',
  template: `
    <div class="no-data-to-display-wrapper h-100 w-100">
      <div class="no-data-to-display u-slow-appear">
        <div>
          <img alt="App logo" src="assets/images/logo-big@2x.png" />
          <div>{{ 'NO_DATA_TO_DISPLAY' | agrTranslate }}</div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./no-data-to-display.component.scss']
})
export class NoDataToDisplayComponent {}
