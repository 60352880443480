import * as expr from 'expression-eval';
import { flatten, uniq } from 'lodash';
import * as moment from 'moment';

export class Expression {
  readonly identifiers: string[];
  readonly jsExpression?: string;
  readonly parseTree: expr.parse.Expression;

  constructor(exprOrString: string | expr.parse.Expression) {
    if (typeof exprOrString === 'string') {
      this.jsExpression = exprOrString;
      this.parseTree = this.parseExpression();
    } else {
      this.parseTree = exprOrString;
    }
    this.identifiers = uniq(flatten(Array.from(new Set<string>(this.extractIdsFromNode(this.parseTree)))));
  }

  evaluate(params: (string | number | undefined)[]): string | number | undefined {
    const context = {
      pc: (oldVal, newVal) => {
        if (!oldVal) {
          return undefined;
        }
        return ((newVal - oldVal) / oldVal) * 100;
      },
      getDateInt: () => parseInt(moment().format('YYYYMMDD'), 10),
      null: undefined
    };
    this.identifiers.forEach((id, idx) => (context[id] = params[idx] ? params[idx] : 0));
    const retVal = expr.eval(this.parseTree, context);
    if (typeof retVal === 'number' && isNaN(retVal)) {
      return undefined;
    }
    return retVal;
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  replaceIdentifiers(node: any, replacementFun: (identifierNode: any) => any): any {
    switch (node.type) {
      case 'ArrayExpression':
        return {
          type: 'ArrayExpression',
          elements: node.elements.map((element) => this.replaceIdentifiers(element, replacementFun))
        };
      case 'BinaryExpression':
        return {
          type: 'BinaryExpression',
          operator: node.operator,
          left: this.replaceIdentifiers(node.left, replacementFun),
          right: this.replaceIdentifiers(node.right, replacementFun)
        };
      case 'CallExpression':
        return {
          type: 'CallExpression',
          arguments: node.arguments.map((argument) => this.replaceIdentifiers(argument, replacementFun)),
          callee: node.callee
        };
      case 'Compound':
        return {
          type: 'Compound',
          body: node.body.map((body) => this.replaceIdentifiers(body, replacementFun))
        };
      case 'ConditionalExpression':
        return {
          type: 'ConditionalExpression',
          test: this.replaceIdentifiers(node.test, replacementFun),
          consequent: this.replaceIdentifiers(node.consequent, replacementFun),
          alternate: this.replaceIdentifiers(node.alternate, replacementFun)
        };
      case 'Identifier':
        return replacementFun(node);
      case 'Literal':
        return {
          type: 'Literal',
          value: node.value,
          raw: node.raw
        };
      case 'LogicalExpression':
        return {
          type: 'LogicalExpression',
          operator: node.operator,
          left: this.replaceIdentifiers(node.left, replacementFun),
          right: this.replaceIdentifiers(node.right, replacementFun)
        };
      case 'MemberExpression':
        return {
          type: 'MemberExpression',
          computed: node.computed,
          object: this.replaceIdentifiers(node.object, replacementFun),
          property: this.replaceIdentifiers(node.property, replacementFun)
        };
      case 'ThisExpression':
        return {
          type: 'ThisExpression'
        };
      case 'UnaryExpression':
        return {
          type: 'UnaryExpression',
          operator: node.operator,
          argument: this.replaceIdentifiers(node.argument, replacementFun),
          prefix: node.prefix
        };
      default:
        throw new Error(`Unexpected node type: '${node.type}'`);
    }
  }

  private parseExpression(): expr.parse.Expression {
    try {
      return expr.parse(this.jsExpression.replace(/ or /g, ' || '));
    } catch (error) {
      console.warn(`AGR: Error parsing jsExpression: '${this.jsExpression}'`, error.toString());
      return expr.parse(`'error'`);
    }
  }
  // eslint-disable-next-line complexity
  private extractIdsFromNode(node: any): string[] {
    switch (node.type) {
      case 'ArrayExpression':
        return node.elements.map((subNode) => this.extractIdsFromNode(subNode));
      case 'BinaryExpression':
        return this.extractIdsFromNode(node.left).concat(this.extractIdsFromNode(node.right));
      case 'CallExpression':
        return node.arguments.map((subNode) => this.extractIdsFromNode(subNode));
      case 'Compound':
        return node.body.map((subNode) => this.extractIdsFromNode(subNode));
      case 'ConditionalExpression':
        return this.extractIdsFromNode(node.test)
          .concat(this.extractIdsFromNode(node.consequent))
          .concat(this.extractIdsFromNode(node.alternate));
      case 'Identifier':
        return [node.name];
      case 'LogicalExpression':
        return this.extractIdsFromNode(node.left).concat(this.extractIdsFromNode(node.right));
      case 'MemberExpression':
        return this.extractIdsFromNode(node.object).concat(this.extractIdsFromNode(node.property));
      case 'UnaryExpression':
        return this.extractIdsFromNode(node.argument);
      default:
        return [];
    }
  }
}
