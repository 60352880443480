import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';

import { AuthService, TokenValidityState } from 'src/app/_core/authorization/auth.service';
import { TranslationsService } from 'src/app/_core/translations.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshOnGoingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private whiteList = [
    // routes that don't require token
    'base',
    'login',
    'login_365',
    'refresh-365',
    'pool.agrdynamics.com',
    'googleapis.com'
  ];

  constructor(private toasterService: ToasterService, private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.requiresToken(req.url)) {
      return this.handleState(req, next);
    }

    const state = this.authService.checkTokenValidity();
    switch (state) {
      case TokenValidityState.expired:
        return this.rerouteLogin(req, next);
      case TokenValidityState.requiresRefresh:
        return this.refreshToken(req, next);
      case TokenValidityState.refreshing:
        return this.waitForRefresh(req, next);
      case TokenValidityState.valid:
        return this.handleState(this.addToken(req), next);
      default:
        return this.handleState(req, next);
    }
  }

  // private:

  private requiresToken(url: string): boolean {
    if (!url.includes('api')) {
      return false;
    }

    return !this.whiteList.some((entry) => {
      return url.includes(entry);
    });
  }

  // eslint-disable-next-line max-lines-per-function
  private handleState(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('pool.agrdynamics.com')) {
      return next.handle(req);
    }
    // eslint-disable-next-line complexity
    return next.handle(req).pipe(
      // eslint-disable-next-line complexity
      catchError((error: HttpErrorResponse) => {
        if (['/login', 'refresh_365'].some((substring) => this.router.url.includes(substring))) {
          return throwError(error);
        }
        switch (error.status) {
          case 401:
            return this.authService.isRefreshingToken ? this.waitForRefresh(req, next) : this.refreshToken(req, next);
          case 403:
            if (!this.authService.isRefreshingToken) {
              const restrictedAccess = TranslationsService.get('RESTRICTED_ACCESS');
              const noPermission = TranslationsService.get('YOU_DO_NOT_HAVE_PERMISSION_TO_COMPLETE_ACTION');
              const resource = TranslationsService.get('RESOURCE');
              this.toasterService.pop('error', restrictedAccess, `${noPermission} ${resource}: ${error.url}`);
            }
            break;
          default:
            if (this.skipReportingError(error)) {
              break;
            }
            const message = error.error?.Message || error.error || error.message || error;
            try {
              if (typeof message !== 'string') {
                this.toasterService.pop('error', TranslationsService.get('OOOOPS'), TranslationsService.get('AN_ERROR_HAS_OCCURRED'));
              } else {
                this.toasterService.pop('error', TranslationsService.get('OOOOPS'), TranslationsService.get(message));
              }
            } catch (error) {}
        }
        return throwError(error);
      }) as any
    );
  }

  private skipReportingError(error: HttpErrorResponse): boolean {
    // When writing Advanced filter query, 500 error is expected
    return this.authService.isRefreshingToken || error.url.includes('pool.agrdynamics.com') || error.url.includes('mbe_reports/-999');
  }

  private rerouteLogin(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap(() => {
        this.authService.logout();
        this.router.navigate(['/login']);
        return next.handle(req);
      }),
      catchError(() => {
        this.authService.logout();
        this.router.navigate(['/login']);
        return next.handle(req);
      })
    );
  }

  private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      mergeMap((success) => {
        this.refreshOnGoingSubject.next(success);
        return next.handle(this.addToken(req));
      }),
      catchError((error) => {
        this.refreshOnGoingSubject.error(error);
        return this.rerouteLogin(req, next);
      })
    );
  }

  private waitForRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshOnGoingSubject.pipe(
      filter((result) => result),
      take(1),
      switchMap(() => this.intercept(req, next)),
      catchError(() => this.intercept(req, next))
    );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!this.authService.token) {
      return request;
    }
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this.authService.token}`)
    });
  }
}
