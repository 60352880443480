<div class="column-label d-flex justify-content-between">
  <span>{{ column.caption | agrTranslate }}</span>
  <ng-template #numberColumnDescription>
    <h6>{{ column.caption | agrTranslate }}</h6>
    {{ column.description | agrTranslate }}
  </ng-template>
  <button type="button" class="btn btn-help" [ngbPopover]="numberColumnDescription" popoverTitle="" container="">
    <i class="fa fa-question-circle"></i>
  </button>
  <div *ngIf="bulkUpdate" class="form-check form-switch">
    <input type="checkbox" class="form-check-input" [(ngModel)]="useGlobal" (input)="toggleGlobal()" />
    <label class="w-100 form-check-label"></label>
  </div>
</div>
<div class="column-value-editable" [formGroup]="formGroup" (click)="startEditing()" [ngClass]="{ useGlobal: useGlobal }">
  <span [hidden]="editing">
    <span [ngClass]="{ edited: hasOverwriteValue(), 'me-2': !hasOverwriteValue() }"></span>
    {{ getValue() | agrFormat: column.type + ':2' }}
  </span>
  <input
    [hidden]="!editing"
    type="number"
    (blur)="blur()"
    formControlName="{{ column.name }}"
    (keyup.enter)="updateNumber()"
    #numberInput
    [agrAutoFocus]="editing" />
  <span [hidden]="!(bulkUpdate && useGlobal)" [formGroup]="formGroup">{{ column.globalSettingValue }}</span>
</div>
