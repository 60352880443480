<ng-template #content>
  <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()" [class.submitted]="submitted" novalidate>
    <div class="modal-header">
      <h4 class="modal-title" translate>NAV_MY_PROFILE</h4>
      <button type="button" class="btn-close" (click)="onDismissModal()"></button>
    </div>

    <div class="modal-body">
      <!-- Name -->
      <div id="my-profile-name" class="form-group">
        <label for="my-profile-name-input" translate>NAME</label>
        <input type="text" id="my-profile-name-input" class="form-control" formControlName="name" ngbAutofocus />
        <div class="invalid-feedback">
          <div *ngIf="name.getError('required')" translate>REQUIRED</div>
          <div *ngIf="name.getError('maxlength')" translate>INPUT_IS_TOO_LONG</div>
        </div>
      </div>

      <!-- Language -->
      <div id="my-profile-language" class="form-group">
        <label for="my-profile-language-select" translate>LANGUAGE</label>

        <span
          class="agr-mini-tools amt-area pull-right"
          [ngbTooltip]="'Disable translation overrides'"
          *ngIf="authService.hasFeature('developmentMode')">
          <button
            type="button"
            class="amt-btn"
            [class.active]="disableFallbackTranslations"
            [class.text-danger]="disableFallbackTranslations"
            (click)="toggleFallbackTranslations()">
            <i class="fa fa-wrench"></i>
          </button>
        </span>

        <select id="my-profile-language-select" class="form-control" formControlName="language">
          <option *ngFor="let language of availableLanguages; trackBy: trackLanguage" [ngValue]="language.code">
            {{ language.caption }}
          </option>
        </select>

        <div class="invalid-feedback">
          <div *ngIf="name.getError('required')" translate>REQUIRED</div>
          <div *ngIf="name.getError('maxlength')" translate>INPUT_IS_TOO_LONG</div>
        </div>
      </div>

      <!-- Locale / Date and number formatting -->
      <div id="my-profile-locale" class="form-group">
        <label for="my-profile-locale-select" translate>LOCALE</label>
        <ng-template #localeInfoPopContent>
          <p>{{ 'LOCALE_INFO' | agrTranslate }}</p>
          <p>
            {{ 'DATE_LOCALE' | agrTranslate }}: <strong>{{ formatService.dateLocale }}</strong
            ><br />
            {{ 'NUMBER_LOCALE' | agrTranslate }}: <strong>{{ formatService.numberLocale }}</strong>
          </p>
          <strong>{{ 'DATE' | agrTranslate }}</strong
          >: {{ now | agrFormat: 'date:fullDate' }}<br />
          <strong>{{ 'CURRENCY' | agrTranslate }}</strong
          >: {{ 10015463.51 | agrFormat: 'currency' }}<br />
          <strong>{{ 'NUMBER' | agrTranslate }}</strong
          >: {{ 10500.2 | agrFormat: 'number' }}<br />
          <strong>{{ 'PERCENTAGE' | agrTranslate }}</strong
          >: {{ 0.245 | agrFormat: 'percent' }}
        </ng-template>
        <button type="button" id="locale-info" class="btn btn-help" placement="right" [ngbPopover]="localeInfoPopContent" popoverTitle="">
          <i class="fa fa-question-circle ms-2"></i>
        </button>
        <select id="my-profile-locale-select" class="form-control" formControlName="locale" (change)="onLocaleChange()">
          <option *ngFor="let locale of availableLocales" [ngValue]="locale.code">
            {{ locale.caption }}
          </option>
        </select>
      </div>

      <!-- User info -->
      <dl id="my-profile-user-info" class="agr-dl">
        <div class="row" data-cy="my-profile-username-row">
          <dt class="col-3" translate>USERNAME</dt>
          <dd class="col-9" title="{{ user.username }}">{{ [user.username] }}</dd>
        </div>
        <div class="row" data-cy="my-profile-email-row">
          <dt class="col-3" translate>EMAIL</dt>
          <dd class="col-9" title="{{ user.email }}">{{ user.email }}</dd>
        </div>
        <div class="row" data-cy="my-profile-roles-row">
          <dt class="col-3" translate>ROLES</dt>
          <dd class="col-9" title="{{ userRoles }}">{{ userRoles }}</dd>
        </div>
        <div class="row" data-cy="my-profile-access-groups-row">
          <dt class="col-3" *ngIf="accessGroupsActive" translate>ACCESS_GROUPS</dt>
          <dd class="col-9" *ngIf="accessGroupsActive" title="{{ userAccessGroups }}">{{ userAccessGroups }}</dd>
        </div>
      </dl>

      <!-- Password Elements -->
      <div class="card bg-light" [ngClass]="{ 'card bg-light': editingPassword }">
        <div class="card-body pb-1 pt-2" [class.card-body]="editingPassword">
          <!-- Buttons -->
          <div id="my-profile-password-buttons" class="form-group">
            <button type="button" class="btn btn-link ps-0" (click)="showChangePassword()" [hidden]="editingPassword" translate>
              CHANGE_PASSWORD
            </button>
            <button type="button" class="btn-close" (click)="hideChangePassword()" [hidden]="!editingPassword"></button>
          </div>

          <div [formGroup]="newPassword" class="form-group" [hidden]="!editingPassword">
            <!-- Current Password -->
            <div id="my-profile-current-password" class="form-group">
              <label for="my-profile-current-password-input" translate>CURRENT_PASSWORD</label>
              <input
                type="password"
                id="my-profile-current-password-input"
                class="form-control"
                formControlName="currentPassword"
                autocomplete="off" />
              <div class="invalid-feedback">
                <div *ngIf="currentPassword.getError('required')" translate>REQUIRED</div>
                <div *ngIf="currentPassword.getError('incorrect')" translate>INCORRECT_PASSWORD</div>
                <div *ngIf="currentPassword.getError('minlength')" translate>PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS</div>
              </div>
            </div>

            <!-- New Password -->
            <div id="my-profile-password" class="form-group">
              <label for="my-profile-password-input" translate>NEW_PASSWORD</label>
              <input type="password" id="my-profile-password-input" class="form-control" formControlName="password" autocomplete="off" />
              <div class="invalid-feedback">
                <div *ngIf="password.getError('required')" translate>REQUIRED</div>
                <div *ngIf="password.getError('minlength')" translate>PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS</div>
                <div *ngIf="password.getError('maxlength')" translate>INPUT_IS_TOO_LONG</div>
              </div>
            </div>

            <!-- Confirm Password -->
            <div id="my-profile-confirm-password" class="form-group">
              <label for="my-profile-confirm-password-input" translate>CONFIRM_PASSWORD</label>
              <input
                type="password"
                id="my-profile-confirm-password-input"
                class="form-control"
                [class.is-invalid]="submitted && (confirmPassword.errors || newPassword.getError('mismatch'))"
                formControlName="confirmPassword"
                autocomplete="off" />
              <div class="invalid-feedback">
                <div *ngIf="submitted && newPassword.getError('mismatch')" translate>PASSWORDS_DO_NOT_MATCH</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-link" (click)="onDismissModal()" translate>CANCEL</button>
      <button type="submit" class="btn btn-success" translate>SAVE</button>
    </div>
  </form>
</ng-template>
