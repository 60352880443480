import { EventEmitter, Injectable } from '@angular/core';
import { debounce } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { ItemCardUpdateMessage } from 'src/app/item-card/models/item-card-update-message.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {
  showNavBarEvent$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  redrawNavBarEvent$ = new EventEmitter();
  updateItemCardEvent$ = new EventEmitter<ItemCardUpdateMessage>();
  refreshItemCardEvent$ = new EventEmitter<ItemCardUpdateMessage>();

  refreshItemCard = debounce(
    (itemId?) => {
      this.triggerRefreshItemCard(itemId);
    },
    400,
    { leading: true, trailing: true }
  );
  updateItemCard = debounce(
    (itemId, orderId?) => {
      this.triggerUpdateItemCard(itemId, orderId);
    },
    400,
    { leading: true, trailing: true }
  );
  refreshDetailsColumn = debounce(
    (column) => {
      this.triggerRefreshItemCardDetailsColumn(column);
    },
    400,
    { leading: true, trailing: true }
  );

  private hasBroadcastChannel = 'BroadcastChannel' in self;
  private itemBroadcastChannel: BroadcastChannel;

  constructor() {
    if (!this.hasBroadcastChannel) {
      return;
    }
    this.itemBroadcastChannel = new BroadcastChannel('item');
    this.itemBroadcastChannel.onmessage = this.itemMessageReceived.bind(this);
  }

  showNavBar(): void {
    this.showNavBarEvent$.next(true);
  }

  hideNavBar(): void {
    this.showNavBarEvent$.next(false);
  }

  redrawNavBar(): void {
    this.redrawNavBarEvent$.emit();
  }

  refreshOtherItemCards(itemId?: number): void {
    if (!this.hasBroadcastChannel) {
      return;
    }
    this.itemBroadcastChannel.postMessage({ itemId, refresh: true });
  }

  refreshOtherItemCardsDetailsColumns(column?: string): void {
    if (!this.hasBroadcastChannel) {
      return;
    }
    this.itemBroadcastChannel.postMessage({ column, refresh: true });
  }

  itemMessageReceived(event: MessageEvent): void {
    const message: ItemCardUpdateMessage = { ...event.data, ...{ isBroadcast: true } };
    message.refresh ? this.refreshItemCardEvent$.emit(message) : this.updateItemCardEvent$.emit(message);
  }

  private triggerRefreshItemCard(itemId?: number): void {
    this.refreshItemCardEvent$.emit({ itemId });
    this.refreshOtherItemCards(itemId);
  }

  private triggerRefreshItemCardDetailsColumn(column?: string): void {
    this.updateItemCardEvent$.emit({ column });
    this.refreshOtherItemCardsDetailsColumns(column);
  }

  private triggerUpdateItemCard(itemId: number, orderId?: number): void {
    this.updateItemCardEvent$.emit({ itemId, orderId });
    if (!this.hasBroadcastChannel) {
      return;
    }
    this.itemBroadcastChannel.postMessage({ itemId, orderId });
  }
}
