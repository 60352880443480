/* eslint-disable max-classes-per-file */
import { TimezoneService } from 'src/app/_core/timezone.service';

interface CustomActionParameterDTO {
  id?: number;
  customActionId?: number;
  orderNum?: number;
  name?: string;
  description?: string;
  procParamName?: string;
  required?: boolean;
  createdAt?: string; // Date
  updatedAt?: string; // Date
  paramValue?: string;
}

export class CustomActionParameter {
  id: number;
  name: string;
  value: string | undefined;
  description: string;
  customActionId: number;
  required: boolean;
  procedureParameterName: string;
  orderNumber: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(actionParameter?: CustomActionParameterDTO) {
    if (!actionParameter) {
      return;
    }
    this.id = actionParameter.id;
    this.name = actionParameter.name;
    this.value = actionParameter.paramValue;
    this.description = actionParameter.description;
    this.customActionId = actionParameter.customActionId;
    this.required = actionParameter.required;
    this.procedureParameterName = actionParameter.procParamName;
    this.orderNumber = actionParameter.orderNum;
    this.createdAt = TimezoneService.serverTimeToClientTime(actionParameter.createdAt);
    this.updatedAt = TimezoneService.serverTimeToClientTime(actionParameter.updatedAt);
  }

  get formName(): string {
    return `parameter-${this.id}`;
  }

  toCustomActionParameter(): CustomActionParameterDTO {
    return {
      id: this.id,
      customActionId: this.customActionId,
      required: this.required,
      procParamName: this.procedureParameterName,
      paramValue: this.value
    };
  }
}

export interface CustomActionDTO {
  id?: number;
  name?: string;
  description?: string;
  storedProcedure?: string;
  requiredRole?: string;
  createdAt?: string; // Date
  updatedAt?: string; // Date
  customActionParameters?: CustomActionParameterDTO[];
}

export interface CustomActionExecRequest {
  customActionId: number;
  customActionParameters: CustomActionParameterDTO[];
  storedProcedure: string;
}

export class CustomAction {
  id: number;
  name: string;
  description: string;
  storedProcedure: string;
  requiredRole: string;
  createdAt: Date;
  updatedAt: Date;
  customActionParameters: CustomActionParameter[];

  constructor(action?: CustomActionDTO) {
    if (!action) {
      return;
    }
    this.id = action.id;
    this.name = action.name;
    this.description = action.description;
    this.storedProcedure = action.storedProcedure;
    this.requiredRole = action.requiredRole;
    this.createdAt = TimezoneService.serverTimeToClientTime(action.createdAt);
    this.updatedAt = TimezoneService.serverTimeToClientTime(action.updatedAt);
    this.customActionParameters = action.customActionParameters
      ? action.customActionParameters.map((parameter) => new CustomActionParameter(parameter))
      : [];
  }

  toActionExecutionRequest(): CustomActionExecRequest {
    return {
      customActionId: this.id,
      customActionParameters: this.customActionParameters.map((actionParam) => actionParam.toCustomActionParameter()),
      storedProcedure: this.storedProcedure
    };
  }
}
