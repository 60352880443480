import { get, set } from 'lodash';

import { TranslationsService } from 'src/app/_core/translations.service';
import { ConfigColumnBaseDTO } from 'src/app/workspaces/details/config/config-column.model';
import { ConfigSerieBaseDTO } from 'src/app/workspaces/details/config/config-serie.model';
import { WorkspaceActionConfigDTO } from 'src/app/workspaces/models/workspace-action.model';

export interface ContextLineDTO {
  name?: string;
  object_type?: string;

  action_json?: string;
  action_type?: string;
  aggregation?: string;
  available?: number; // Is 1 or 0
  caption?: string;
  config?: string;
  default_display_div?: number;
  description?: string;
  format?: string;
  group_caption?: string;
  group_name?: string;
  lookup_column?: string;
  lookup?: boolean;
  low_cardinality?: boolean;
  object_dependency?: string;
  period_group?: boolean;
  read_only?: boolean;
  refresh_profile?: string;
  selected_by_default?: boolean;
  series_affected?: string;
  series_js_formula_value?: string;
  series_type?: string;
  summary?: boolean;
  type?: string;
}

type ObjectType = 'calculatedColumns' | 'columns' | 'series' | 'filters' | 'planActions';

export class ContextLine {
  name: string;
  objectType: ObjectType;

  actionJson: WorkspaceActionConfigDTO;
  actionType?: string;
  aggregation?: string;
  available?: boolean; // Is 1 or 0
  caption?: string;
  config?: ConfigColumnBaseDTO | ConfigSerieBaseDTO;
  // defaultDisplayDiv?: number; // legacy
  description?: string;
  format?: string;
  groupCaption?: string;
  groupName?: string;
  lookup?: boolean;
  lookupColumn?: string;
  lowCardinality?: boolean;
  objectDependency?: string;
  periodGroup?: boolean;
  readOnly?: boolean;
  refreshProfile?: string;
  selectedByDefault?: boolean;
  seriesAffected?: string;
  seriesJsFormulaValue?: string;
  seriesType?: string;
  summary?: boolean;
  type?: string;

  // eslint-disable-next-line max-lines-per-function
  constructor(contextLineDto?: ContextLineDTO) {
    if (!contextLineDto) {
      return;
    }
    this.name = contextLineDto.name;
    this.objectType = contextLineDto.object_type as ObjectType;

    this.actionJson = this.parseActionJson(contextLineDto.action_json);
    this.actionType = contextLineDto.action_type;
    this.aggregation = contextLineDto.aggregation;
    this.available = contextLineDto.available === 1; // Is 1 or 0
    this.caption = TranslationsService.get(contextLineDto.caption);
    this.config = this.parseConfig(contextLineDto.config);
    this.description = contextLineDto.description;
    this.format = contextLineDto.format;
    this.groupCaption = TranslationsService.get(contextLineDto.group_caption);
    this.groupName = contextLineDto.group_name;
    this.lookup = contextLineDto.lookup;
    this.lookupColumn = contextLineDto.lookup_column;
    this.lowCardinality = contextLineDto.low_cardinality;
    this.objectDependency = contextLineDto.object_dependency;
    this.periodGroup = contextLineDto.period_group;
    this.readOnly = contextLineDto.read_only;
    this.refreshProfile = contextLineDto.refresh_profile;
    this.selectedByDefault = contextLineDto.selected_by_default;
    this.seriesAffected = contextLineDto.series_affected;
    this.seriesJsFormulaValue = contextLineDto.series_js_formula_value;
    this.seriesType = contextLineDto.series_type;
    this.summary = contextLineDto.summary;
    this.type = contextLineDto.type;

    this.migrateDisplayDiv(contextLineDto);
  }

  get idColumnName(): string {
    return this.lookupColumn ? this.lookupColumn : this.name;
  }

  //////////////////////////////////////////

  private parseConfig(config: string): ConfigColumnBaseDTO | ConfigSerieBaseDTO {
    try {
      if (!config) {
        return {};
      }
      return JSON.parse(this.handleIrregularWhiteSpaces(config));
    } catch (error) {
      const location = `${this.objectType} > ${this.name}`;
      console.warn(`AGR: Could not parse config JSON in: ${location}. Error: ${error.toString()}. JSON: ${config}`);
      return {};
    }
  }

  private parseActionJson(actionJson: string): WorkspaceActionConfigDTO {
    try {
      return JSON.parse(actionJson);
    } catch (error) {
      const location = `${this.objectType} > ${this.name}`;
      console.warn(`AGR: Could not parse action JSON in: ${location}. Error: ${error.toString()}. JSON: ${actionJson}`);
      return undefined;
    }
  }

  /**
   * The whitespace inside the replace regex is NOT space but non-breaking space (character code 160)
   */
  private handleIrregularWhiteSpaces(config: string): string {
    // eslint-disable-next-line no-irregular-whitespace
    return config.replace(/ /g, ' ');
  }

  /**
   * Move `data_elements.default_display_div` into `data_elements.config > format`.
   */
  private migrateDisplayDiv(contextLineDto: ContextLineDTO): void {
    if (contextLineDto.default_display_div && !get(this, 'config.format.displayDiv')) {
      set(this, 'config.format.displayDiv', contextLineDto.default_display_div);
    }
  }
}
