import { ResizableView } from 'src/app/_shared/directives/resizable.directive';
import { ChartDataParams, ChartDataParamsDTO } from 'src/app/item-card/models/chart-data-params.model';

export class ItemCardState {
  showChart = true;
  showGrid = false;
  showDetails = false;
  showDeDetails = false;
  imageSidebar: ResizableView = {
    visible: false,
    size: 200
  };
  sidebar: ResizableView = {
    visible: true,
    size: 360
  };
  activeCard = 'chart';
  showTitleItemNo = false; // shows either the itemNo or item name in the card header
  private activeCardIndex = 0;
  private cards = ['chart', 'grid', 'details'];
  private activeChartIndex = 2;
  private periodTypes = { 0: 'DAYS', 1: 'WEEKS', 2: 'MONTHS' };
  private charts: ChartDataParams[] = [
    new ChartDataParams({ periodType: 'day', history: 21, forecast: 7 }),
    new ChartDataParams({ periodType: 'week', history: 12, forecast: 4 }),
    new ChartDataParams({ periodType: 'month', history: 24, forecast: 6 })
  ];
  private jumpInterval = {
    day: 7,
    week: 4,
    month: 6
  };

  constructor(state?: ItemCardStateDTO) {
    if (!state) {
      return;
    }
    this.showChart = state.showChart;
    this.showGrid = state.showGrid;
    this.showDetails = state.showDetails;
    this.showTitleItemNo = state.showTitleItemNo;
    this.imageSidebar = state.imageSidebar;
    this.sidebar = state.sidebar;
    this.charts = state.charts.map((params: ChartDataParamsDTO) => new ChartDataParams(params));
    this.activeChartIndex = state.activeChartIndex;
    this.activeCard = state.activeCard;
    this.activeCardIndex = state.activeCardIndex;
  }

  get chart(): ChartDataParams {
    return this.charts[this.activeChartIndex];
  }
  get selectedPeriodType(): string {
    return this.periodTypes[this.activeChartIndex];
  }

  toDto(): ItemCardStateDTO {
    return {
      showChart: this.showChart,
      showGrid: this.showGrid,
      showDetails: this.showDetails,
      showTitleItemNo: this.showTitleItemNo,
      imageSidebar: this.imageSidebar,
      sidebar: this.sidebar,
      activeChartIndex: this.activeChartIndex,
      charts: this.charts.map((chart) => chart.toDto()),
      activeCard: this.activeCard,
      activeCardIndex: this.activeCardIndex
    };
  }

  nextCard(): void {
    this.activeCardIndex = (this.activeCardIndex + 1) % this.cards.length;
    this.activeCard = this.cards[this.activeCardIndex];
    this.hideAllTabs();
    const activeCardMap = { 0: 'showChart', 1: 'showGrid', 2: 'showDetails' };
    this[activeCardMap[this.activeCardIndex]] = true;
  }

  nextChart(): void {
    this.activeChartIndex = (this.activeChartIndex + 1) % this.charts.length;
  }

  toggleItemTitle(): void {
    this.showTitleItemNo = !this.showTitleItemNo;
  }

  toggle(tab: 'showChart' | 'showGrid' | 'showDetails'): void {
    this.hideAllTabs();
    this[tab] = true;
    this.updateActiveCard(tab);
  }

  stepIncrementHistory(): void {
    const interval = this.jumpInterval[this.chart.periodType];
    const remainder = this.chart.historyLength % interval;
    this.chart.historyLength += remainder ? interval - remainder : interval;
    this.reassignChart();
  }

  stepIncrementForecast(): void {
    const interval = this.jumpInterval[this.chart.periodType];
    const remainder = this.chart.forecastLength % interval;
    this.chart.forecastLength += remainder ? interval - remainder : interval;
    this.reassignChart();
  }

  updateHistoryLength(value: number): void {
    this.chart.historyLength = value;
    this.reassignChart();
  }

  updateForecastLength(value: number): void {
    this.chart.forecastLength = value;
    this.reassignChart();
  }

  /**
   * Used for change detection in child components that receive the chartParams as input
   */
  private reassignChart(): void {
    this.charts[this.activeChartIndex] = new ChartDataParams(this.charts[this.activeChartIndex].toDto());
  }

  private hideAllTabs(): void {
    this.showChart = false;
    this.showGrid = false;
    this.showDetails = false;
    this.showDeDetails = false;
  }

  private updateActiveCard(tab: 'showChart' | 'showGrid' | 'showDetails'): void {
    const index = { showChart: 0, showGrid: 1, showDetails: 2 };
    this.activeCardIndex = index[tab];
    this.activeCard = this.cards[this.activeCardIndex];
  }
}

export interface ItemCardStateDTO {
  showChart: boolean;
  showGrid: boolean;
  showDetails: boolean;
  showTitleItemNo: boolean;
  imageSidebar: ResizableView;
  sidebar: ResizableView;
  activeChartIndex: number;
  charts: ChartDataParamsDTO[];
  activeCard: string;
  activeCardIndex: number;
}
