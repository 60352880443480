import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQueryColumnAggrAs } from 'src/app/_core/de-query/model/de-query.model';
import { Expression } from 'src/app/_shared/utils/expression.model';
import { ConfigColumn, ConfigColumnBaseDTO } from 'src/app/workspaces/details/config/config-column.model';
import { WorkspaceGridRow } from 'src/app/workspaces/details/grid/workspace-grid.component';

export interface WorkspaceViewColumnDTO {
  name: string;
  aggrAs: DeQueryColumnAggrAs;
  config?: ConfigColumnBaseDTO;
}

/**
 * Workspace Column.
 * Created from context and update from dto object that comes from view settings.
 */
export class WorkspaceColumn {
  contextLine: ContextLine;

  name: string;
  get caption(): string {
    return this.config.format.caption || this.contextLine.caption;
  }
  description: string;
  idColumnName: string;
  lookupColumn: string;
  type: string; // e.g. 'int' | 'string'
  aggrAs: DeQueryColumnAggrAs;
  groupName: string;
  groupCaption: string;
  get format(): string {
    return this.config.format.format || this.contextLine.format || this.contextLine.type || 'string';
  }
  get align(): 'left' | 'right' | 'center' {
    return this.config.format.align || (['decimal', 'int'].includes(this.type) ? 'right' : 'left');
  }
  get columnFilter(): string {
    const filterMap = {
      number: 'agNumberColumnFilter',
      decimal: 'agNumberColumnFilter',
      int: 'agNumberColumnFilter',
      string: 'agTextColumnFilter'
    };
    return filterMap[this.contextLine.type] ? filterMap[this.contextLine.type] : 'agSetColumnFilter';
  }
  config = new ConfigColumn();
  jsFormula?: Expression;

  isCalculatedColumn = false;

  static getAggregatedValue(row: WorkspaceGridRow, colName: string, aggrAs: string): string | number {
    if (aggrAs === 'minMax') {
      return WorkspaceColumn.minMaxValue(row, colName);
    }
    return row[`${colName}$${aggrAs}`];
  }

  constructor(contextLine: ContextLine, viewDto?: WorkspaceViewColumnDTO) {
    if (!contextLine) {
      return;
    }
    this.contextLine = contextLine;

    this.name = contextLine.name;
    this.description = contextLine.description;
    this.idColumnName = contextLine.idColumnName;
    this.lookupColumn = contextLine.lookupColumn;
    this.type = contextLine.type;
    this.groupName = contextLine.groupName;
    this.groupCaption = contextLine.groupCaption;
    this.config.setDefaultConfig(contextLine.config as ConfigColumnBaseDTO);

    this.isCalculatedColumn = contextLine.objectType === 'calculatedColumns';

    if (viewDto) {
      this.updateFromViewDto(viewDto);
    }

    this.setCalculatedData();
  }

  private static minMaxValue(row: WorkspaceGridRow, colName: string): string | number {
    const maxValue = row[`${colName}$max`];
    const minValue = row[`${colName}$min`];
    if (row[`${colName}$single`]) {
      return WorkspaceColumn.isNil(maxValue) ? '' : maxValue;
    }
    if (WorkspaceColumn.isNil(maxValue) && WorkspaceColumn.isNil(minValue)) {
      return '';
    }

    return `[${minValue}-${maxValue}]`;
  }

  private static isNil(value: string | number): boolean {
    return value === null || value === undefined;
  }

  idValueFromRow(row: WorkspaceGridRow): string | number {
    if (row.inserted) {
      return row[this.idColumnName];
    }
    if (this.aggrAs) {
      return WorkspaceColumn.getAggregatedValue(row, this.idColumnName, this.aggrAs);
    }
    return row[this.idColumnName];
  }

  valueFromRow(row: WorkspaceGridRow): string | number {
    if (row.inserted) {
      return row[this.name];
    }
    if (this.aggrAs) {
      return WorkspaceColumn.getAggregatedValue(row, this.name, this.aggrAs);
    }
    return row[this.name];
  }

  toDto(): WorkspaceViewColumnDTO {
    return {
      name: this.name,
      aggrAs: this.aggrAs,
      config: this.config.getViewConfig() as ConfigColumnBaseDTO
    };
  }

  isIdColumnOverridden(): boolean {
    return this.config.edit.lookupConfig.idColumn && this.config.edit.lookupConfig.idColumn !== this.idColumnName;
  }

  isValueColumnOverridden(): boolean {
    return this.config.edit.lookupConfig.valueColumn && this.config.edit.lookupConfig.valueColumn !== this.name;
  }

  // private

  private setCalculatedData(): void {
    if (this.contextLine.objectType === 'calculatedColumns' && (!this.config.jsFormula || this.config.jsFormula.length === 0)) {
      console.warn(`AGR: Calculated column: '${this.name}' has no "config.jsFormula" which is required`);
      return;
    }
    if (!this.config.jsFormula) {
      return;
    }
    this.jsFormula = new Expression(this.config.jsFormula);
  }

  private updateFromViewDto(dto: WorkspaceViewColumnDTO): void {
    this.aggrAs = dto.aggrAs;
    if (dto.config) {
      this.config.setViewConfig(dto.config);
    }
  }
}
