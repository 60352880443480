<ng-template #content let-close="close" let-dismiss="dismiss">
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title"><span translate>CUSTOM_ACTIONS</span></h4>
    <button type="button" class="btn-close" (click)="onClose()"></button>
  </div>

  <!-- Form -->
  <form class="needs-validation" [formGroup]="form" (ngSubmit)="onExecute()" [class.submitted]="submitted">
    <!-- Modal body -->
    <div id="all-custom-actions" class="modal-body">
      <!-- Select Custom Action -->
      <div id="custom-action" class="form-group">
        <label class="control-label" for="custom-action-dropdown">{{ 'CUSTOM_ACTION' | agrTranslate }}</label>
        <ng-select
          id="custom-action-dropdown"
          [items]="customActions$ | async"
          bindLabel="name"
          [(ngModel)]="selectedCustomAction"
          [ngModelOptions]="{ standalone: true }"
          (change)="onActionSelectionChanged()"
          ngbAutofocus></ng-select>
      </div>

      <div *ngIf="selectedCustomAction">
        <div class="form-group small">
          <strong>
            <span>{{ 'DESCRIPTION' | agrTranslate }} </span>: </strong
          >{{ selectedCustomAction.description }}
        </div>
        <div [formGroup]="form">
          <div id="parameter-controls" class="form-group" *ngFor="let param of selectedCustomAction.customActionParameters">
            <label class="control-label" for="param-name-{{ param.id }}">
              {{ param.name }}
              <span *ngIf="!param.required">{{ 'OPTIONAL' | agrTranslate }}</span>
            </label>
            <ng-template #tipContent>
              <strong>{{ param.description }}</strong>
            </ng-template>
            <button type="button" class="btn btn-help" placement="top" [ngbTooltip]="tipContent" triggers="click:blur">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </button>
            <input type="text" id="param-name-{{ param.id }}" class="form-control" formControlName="{{ param.name }}" />
            <div *ngIf="form.get(param.name).errors && (form.get(param.name).dirty || submitted)" class="invalid-feedback d-block">
              <span>{{ 'REQUIRED' | agrTranslate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer">
      <div class="form-group">
        <button
          id="execute-ca-btn"
          type="submit"
          class="btn"
          [ngClass]="{ 'btn-success': selectedCustomAction, 'btn-secondary': !selectedCustomAction }"
          [disabled]="!selectedCustomAction"
          translate>
          EXECUTE
        </button>
      </div>
    </div>
  </form>
</ng-template>
