import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatAuthGuard } from 'src/app/_core/authorization/feat-auth-guard.service';
import { NoAccessComponent } from 'src/app/_core/403-and-404/no-access.component';
import { ItemCardComponent } from 'src/app/item-card/item-card.component';
import { LoginComponent } from 'src/app/login/login.component';
import { NotFoundComponent } from 'src/app/_core/403-and-404/not-found.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((x) => x.DashboardModule) },
  { path: 'items', loadChildren: () => import('./reports/reports.module').then((x) => x.ReportsModule) },
  { path: 'selected-item', component: ItemCardComponent },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then((x) => x.OrdersModule) },
  { path: 'scheduler', loadChildren: () => import('./scheduler/scheduler.module').then((x) => x.SchedulerModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then((x) => x.ReportsModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then((x) => x.SettingsModule) },
  { path: 'executions', loadChildren: () => import('./executions/executions.module').then((x) => x.ExecutionsModule) },
  { path: 'workspaces', loadChildren: () => import('./workspaces/workspaces.module').then((x) => x.WorkspacesModule) },
  { path: 'erp-setup', loadChildren: () => import('./setup/setup.module').then((x) => x.SetupModule) },
  { path: 'translations', loadChildren: () => import('./translations/translations.module').then((x) => x.TranslationsModule) },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  providers: [FeatAuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {}
