import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { SignalRService } from 'src/app/_core/signal-r.service';

import { cypressExpose } from 'src/app/_core/cypress-expose';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { ExecutionNotification, ExecutionNotificationDTO } from 'src/app/nav/execution-list/execution-notification.model';

@Injectable({
  providedIn: 'root'
})
export class ExecutionListService {
  constructor(private httpClient: HttpClient, private signalRService: SignalRService) {
    cypressExpose(this);
  }

  listenForActions(): Observable<ExecutionNotification[]> {
    const message = new Subject<ExecutionNotification[]>();
    this.signalRService.connection.on('Notify', (dtos: ExecutionNotificationDTO[]) => {
      message.next(dtos.map((dto) => new ExecutionNotification(dto)));
    });
    return message.asObservable();
  }

  getActions(): Observable<ExecutionNotification[]> {
    return this.httpClient
      .get<ExecutionNotificationDTO[]>(`${API_ROOT}/notifications`)
      .pipe(map((dtos) => dtos.map((actionExecutionDTO) => new ExecutionNotification(actionExecutionDTO))));
  }

  clearAction(id: number): Observable<any> {
    return this.httpClient.delete(`${API_ROOT}/notifications/${id}`);
  }

  clearMultipleActions(actions: ExecutionNotification[]): Observable<any> {
    return forkJoin(actions.map((action) => this.clearAction(action.id)));
  }

  clearAllActions(): Observable<any> {
    return this.httpClient.delete(`${API_ROOT}/notifications`); // Marks all actions as read (cleared in client) irrespective of user
  }
}
