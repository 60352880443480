type AggregationType = 'sum' | 'avg';
type DistMethodType = 'proportional' | 'simple';

export interface ConfigColumnAggregationDTO {
  enabled: boolean;
  type: AggregationType;
  editable?: boolean;
  distMethod?: DistMethodType;
  action?: string;
  actionArgs?: object;
}

export class ConfigColumnAggregation {
  enabled: boolean;
  type: AggregationType;
  editable?: boolean;
  distMethod?: DistMethodType;
  action?: string;
  actionArgs?: object;

  constructor(dto?: ConfigColumnAggregationDTO) {
    if (!dto) {
      return;
    }
    this.enabled = dto.enabled;
    this.type = dto.type;
    this.editable = dto.editable;
    this.distMethod = dto.distMethod;
    this.action = dto.action;
    if (dto.actionArgs) {
      this.actionArgs = {};
      Object.assign(this.actionArgs, dto.actionArgs);
    }
  }
}
