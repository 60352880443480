import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { Auth365Config } from 'src/app/_core/models/base-config.model';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { SignalRService } from 'src/app/_core/signal-r.service';
import { StoreService } from 'src/app/_core/store.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { AppInfo } from 'src/app/_core/version/app-info.model';
import { VersionService } from 'src/app/_core/version/version.service';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Userlane: any;
  }
}

declare let pendo: any;

@Component({
  selector: 'agr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  date = new Date();
  loginError: boolean;
  user: string;
  password: string;
  showLostPasswordMsg: boolean;

  showVersionDetails = false;
  appInfo = new AppInfo();

  constructor(
    public sessionService: SessionService,
    private versionService: VersionService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private globalEventsService: GlobalEventsService,
    private router: Router,
    private settingsService: SettingsService,
    private storeService: StoreService,
    private translationsService: TranslationsService,
    private toasterService: ToasterService,
    private signalRService: SignalRService
  ) {}

  ngOnInit(): void {
    this.globalEventsService.hideNavBar();
    window.Userlane('hide');
    this.checkLogout();
    this.setReturnUrl();
    this.getAppInfo();
    if (this.sessionService.auth365Config) {
      this.check365Authentication();
    } else if (this.authService.isLoggedIn()) {
      this.enterApp();
    }
  }

  checkLogout(): void {
    if (this.activatedRoute.snapshot.queryParamMap.get('action') !== 'LOGOUT') {
      return;
    }
    this.sessionService.clearUserData();
    if (!this.sessionService.auth365Config) {
      this.router.navigateByUrl('/login');
    }
  }

  setReturnUrl(): void {
    const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if (returnUrl) {
      this.sessionService.returnUrl = returnUrl !== '/' ? returnUrl : undefined;
    }
  }

  getAppInfo(): void {
    this.versionService.getAppInfo().subscribe((appInfo) => {
      this.appInfo = appInfo;
      if (this.appInfo.hasNoMatchingProject()) {
        this.showVersionDetails = true;
      }
    });
  }

  check365Authentication(): void {
    if (this.activatedRoute.snapshot.queryParamMap.get('action') === 'LOGOUT') {
      return;
    }
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');
    if (code) {
      this.authService.auth365Login(code, this.getAzureRedirectUrl()).subscribe(
        (ok) => {
          if (ok) {
            this.enterApp();
          }
        },
        () => {
          this.toasterService.pop('info', TranslationsService.get('MICROSOFT_LOGIN_ERROR_MESSAGE'));
          this.loginError = true;
        }
      );
      return;
    }

    this.authService.isLoggedIn() ? this.enterApp() : (document.location.href = this.buildAuth365Url(this.sessionService.auth365Config));
  }

  //////////////////////////////////////////

  login(credentials: any): void {
    if (this.sessionService.auth365Config) {
      document.location.href = this.buildAuth365Url(this.sessionService.auth365Config);
      return;
    }
    if (!credentials.user || !credentials.password) {
      return;
    }
    this.authService.classicLogin(credentials.user, credentials.password).subscribe(
      (ok) => {
        if (ok) {
          this.enterApp();
        }
      },
      () => {
        this.loginError = true;
      }
    );
  }

  // private

  private enterApp(): void {
    this.storeService.remove('navigation'); // Refresh the navigation in local storage
    this.globalEventsService.showNavBar();
    this.signalRService.register();
    this.checkLanguage().subscribe((hasChanged) => {
      this.router.navigate([this.sessionService.returnUrl || this.settingsService.homePage()]);
      this.sessionService.returnUrl = undefined;
      this.initializeUserlane();
      this.initializePendo();
      if (hasChanged) {
        location.reload();
      }
    });
  }

  private initializePendo(): void {
    // in your authentication promise handler or callback
    const user = this.sessionService.user;

    pendo.initialize({
      visitor: {
        id: `${user.id}:${user.email}`, // Required if user is logged in
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: user.name, // Recommended if using Pendo Feedback
        // role: // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        username: user.username
      },
      account: {
        id: user.email.split('@')[1]
      }
    });
  }

  private initializeUserlane(): void {
    // identify the user for Userlane
    const currentUser = this.sessionService.user;
    window.Userlane('identify', currentUser.id, {});
    // initialize Userlane with your Property ID
    window.Userlane('init', 'zr65v');
  }

  /**
   * Check if user has different language than is currently active.
   */
  private checkLanguage(): Observable<boolean> {
    const userLanguage = this.sessionService.user.language || this.settingsService.defaultLanguage();
    const activeLanguage = this.translationsService.getLanguage();

    if (userLanguage !== activeLanguage) {
      return this.translationsService.setTranslations(userLanguage);
    }
    return of(false);
  }

  private buildAuth365Url(auth365Info: Auth365Config): string {
    return `${auth365Info.tokenEndpoint}/authorize?\
    client_id=${auth365Info.appId}\
    &response_type=code&response_mode=query&prompt=select_account\
    &redirect_uri=${encodeURI(this.getAzureRedirectUrl())}`;
  }

  private getAzureRedirectUrl(): string {
    return document.location.port
      ? `${document.location.protocol}//${document.location.hostname}:${document.location.port}/login`
      : `${document.location.protocol}//${document.location.hostname}/login`;
  }
}
