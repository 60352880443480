export class AgrUtils {
  /**
   * Recursively removes empty objects and arrays from an object.
   */
  static deleteEmpty(config: object, level: number = 0): object {
    if (level > 3) {
      return config;
    }
    Object.keys(config).forEach((key) => {
      // Some (rare) cases use null value in config json, these should not be deleted
      if (typeof config[key] !== 'object' || config[key] === null) {
        return;
      }
      if (Object.entries(config[key]).length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete config[key];
      } else {
        AgrUtils.deleteEmpty(config[key], level + 1);
        if (Object.entries(config[key]).length === 0) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete config[key];
        }
      }
    });
    return config;
  }

  /**
   * Changes class to JS object and removes all properties that have undefined value.
   */
  static sanitize(obj: object): object {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Parse a string containing a numerical value into a number. The value can be formatted as a number or currency and
   * have any number of leading non-digit values.
   *
   * Specifically written to deal with the nightmare of extracting formatted strings from Excel which can have any
   * kind of formatting attached to it.
   *
   * This method uses best effort to get the proper numerical value
   * @param value The value containing numeric value to extract
   * @param likelyDecimalSeparator The most likely decimal separator. This is used if the decimal point can not be
   * deduced from the following rules of thumb:
   *   1) If a number has two (or more) different non-digits then the last non-digit is the decimal point separator
   *   2) If a number has a non-digit repeated then it must be a thousand separator and no separator is present
   *   3) If a number has one, two, four or more digits after the last and only non-digit then the non-digit is a decimal
   *      separator
   *
   * If none of the above rules apply we can not reliably deduce whether that separator is a thousand
   * separator or a decimal separator. In this case if the only separator is the likelyDecimalSeparator then the number
   * is assumed to have a decimal fraction, otherwise it is not.
   *
   * Note for future: If this mechanism is not robust enough to handle paste from Excel it might be possible to improve it
   * by looking at all values being pasted and infer from it the actual decimal separator used.
   */
  static extractNumerical(value: string, likelyDecimalSeparator: string): number {
    const rawValue = AgrUtils.extractRawNumber(value);
    if (rawValue === undefined) {
      return undefined;
    }
    const nonDigits = AgrUtils.getFirstAndLastNonDigitIdx(rawValue);
    // If there are no non digits, then just return the number
    if (nonDigits.firstIdx === -1 && nonDigits.lastIdx === -1) {
      return Number(rawValue);
    }
    // If the first separator is different from the last separator then the last separator is the decimal separator
    if (rawValue[nonDigits.firstIdx] !== rawValue[nonDigits.lastIdx]) {
      return AgrUtils.convertToNumber(rawValue, nonDigits.lastIdx);
    }
    // If there are at least two (identical) separators then there is no decimal point in the number
    if (nonDigits.firstIdx !== nonDigits.lastIdx) {
      return AgrUtils.convertToNumber(rawValue, -1);
    }
    // If the last separator is not exactly the fourth last character then this separator must be the decimal separator
    if (nonDigits.lastIdx !== rawValue.length - 4) {
      return AgrUtils.convertToNumber(rawValue, nonDigits.lastIdx);
    }
    // If the last separator is the likely decimal separator return it as such
    if (rawValue[nonDigits.lastIdx] === likelyDecimalSeparator) {
      return AgrUtils.convertToNumber(rawValue, nonDigits.lastIdx);
    }
    // Otherwise, assume there is no decimal separator
    return AgrUtils.convertToNumber(rawValue, -1);
  }

  static isSameDay(first: Date, second: Date): boolean {
    if (!first || !second) {
      return false;
    }
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
  }

  private static convertToNumber(value: string, idxOfDecimalPoint: number): number {
    const numString = value
      .split('')
      .map((place, idx) => (idx === idxOfDecimalPoint ? '.' : place === '.' ? ' ' : place))
      .filter((place) => place === '-' || place === '.' || (place.charCodeAt(0) >= 48 && place.charCodeAt(0) <= 57))
      .join('');
    const num = Number(numString);
    if (isNaN(num)) {
      return undefined;
    }
    return num;
  }

  private static getFirstAndLastNonDigitIdx(value: string): { firstIdx: number; lastIdx: number } {
    const splitRawValue = value.split('');
    const firstIdx = splitRawValue.findIndex((place) => place !== '-' && (place.charCodeAt(0) < 48 || place.charCodeAt(0) > 57));
    let lastIdx = splitRawValue.reverse().findIndex((place) => place !== '-' && (place.charCodeAt(0) < 48 || place.charCodeAt(0) > 57));
    if (lastIdx !== -1) {
      lastIdx = splitRawValue.length - lastIdx - 1;
    }
    return { firstIdx, lastIdx };
  }

  private static extractRawNumber(value: string): string {
    const splitValue = value.split('');
    const idxOfFirst = splitValue.findIndex((place) => place === '-' || (place.charCodeAt(0) >= 48 && place.charCodeAt(0) <= 57));
    let idxOfLast = splitValue.reverse().findIndex((place) => place.charCodeAt(0) >= 48 && place.charCodeAt(0) <= 57);
    if (idxOfFirst === -1 || idxOfLast === -1) {
      return undefined;
    }
    idxOfLast = splitValue.length - idxOfLast;
    return value.substring(idxOfFirst, idxOfLast);
  }
}
