import { cloneDeep } from 'lodash';

import { DeQuery } from 'src/app/_core/de-query/model/de-query.model';
import {
  ConfigColumnEditLookup,
  ConfigColumnEditLookupDTO,
  LookupDeQueryFilterValue
} from 'src/app/workspaces/details/config/config-column-edit-lookup.model';
import { ConfigCheckboxes, ConfigCheckboxesDTO } from 'src/app/workspaces/details/config/shared/config-checkboxes.model';
import { ConfigCond, ConfigCondDTO } from 'src/app/workspaces/details/config/shared/config-cond.model';

export interface ConfigColumnEditDTO {
  enabled: boolean;
  caption?: string; // moved to ConfigColumnFormat
  type?: string;
  targetIdColumn?: string;
  targetColumn?: string;
  action?: string;
  actionArgs?: object;
  cond?: ConfigCondDTO;
  lookup?: boolean;
  lookupConfig?: ConfigColumnEditLookupDTO;
  checkboxes?: ConfigCheckboxesDTO;
}

export class ConfigColumnEdit {
  enabled: boolean;
  type?: string;
  targetIdColumn?: string;
  targetColumn?: string;
  action?: string;
  actionArgs?: object = {};
  cond = new ConfigCond();
  lookup?: boolean;
  lookupConfig = new ConfigColumnEditLookup();
  checkboxes = new ConfigCheckboxes();

  constructor(dto?: ConfigColumnEditDTO) {
    if (!dto) {
      return;
    }
    this.enabled = dto.enabled;
    this.type = dto.type;
    this.targetIdColumn = dto.targetIdColumn;
    this.targetColumn = dto.targetColumn;
    this.action = dto.action;
    if (dto.actionArgs) {
      this.actionArgs = {};
      Object.assign(this.actionArgs, dto.actionArgs);
    }
    if (dto.cond) {
      this.cond = new ConfigCond(dto.cond);
    }
    this.lookup = dto.lookup;
    if (dto.lookupConfig) {
      this.lookupConfig = new ConfigColumnEditLookup(dto.lookupConfig);
    }
    if (dto.checkboxes) {
      this.checkboxes = new ConfigCheckboxes(dto.checkboxes);
    }
  }

  getLookupQuery(columnName: string, gridRowData?: {}): DeQuery | undefined {
    if (!this.lookup) {
      return undefined;
    }
    const lookupQuery = this.lookupConfig.query ? (cloneDeep(this.lookupConfig.query) as DeQuery) : { columns: [{ name: columnName }] };
    if (lookupQuery.filters) {
      lookupQuery.filters = lookupQuery.filters.map((filter) => {
        if (filter.values && (filter.values as unknown as LookupDeQueryFilterValue).type === 'fromRow') {
          filter.values = this.findFilterLookupValue((filter.values as unknown as LookupDeQueryFilterValue).name, gridRowData);
        }
        return filter;
      });
    }
    return lookupQuery;
  }

  private findFilterLookupValue(rowName: string, gridRowData: {}): number[] | string[] {
    let lookupValue = gridRowData[rowName];
    if (lookupValue !== null && lookupValue !== undefined) {
      return [lookupValue];
    }
    Object.values(gridRowData).forEach((value) => {
      if (value && value['data'] && value['data'][rowName]) {
        lookupValue = value['data'][rowName];
        return;
      }
    });
    return [lookupValue];
  }
}
