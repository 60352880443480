/* eslint-disable no-unused-vars */
declare global {
  interface Window {
    Cypress: any;
  }
}

/**
 * Expose app instances to be used in Cypress E2E tests.
 */
export function cypressExpose(target: any): void {
  if (!window.Cypress) {
    return;
  }
  if (!window.Cypress.agr) {
    window.Cypress.agr = {};
  }
  window.Cypress.agr[target.constructor.name] = target;
}
