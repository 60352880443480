import { Component, Input } from '@angular/core';

@Component({
  selector: 'agr-loading-dots',
  templateUrl: './loading-dots.component.html'
})
export class LoadingDotsComponent {
  @Input() color: '' | 'colors' | 'yellow' | 'gray' = 'gray';
  @Input() size: '' | 'big' | 'small' = '';
}
