export class DBVersion {
  version: string;
  imageVersion?: string;
  connectionString: string;

  constructor(dto?: DBVersion) {
    if (!dto) {
      return;
    }
    Object.assign(this, dto);
  }
}
