/* eslint-disable max-classes-per-file */
import { Params } from '@angular/router';
import { cloneDeep } from 'lodash';

import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQuery, DeQueryFilter } from 'src/app/_core/de-query/model/de-query.model';
import { WorkspaceFullQueryDrilldownDTO } from 'src/app/workspaces/details/workspace-details.service';

/**
 * View Settings drilldown properties. Saved in DB.
 */
export interface WorkspaceDrilldownDTO {
  name: string;
  locked?: boolean;
  resetLevel?: boolean;
}

/**
 * Drilldown properties. Only saved in local storage.
 */
export interface WorkspaceDrilldownStateDTO {
  name: string;
  selected?: WorkspaceDrilldownOptionDTO;
  closed?: boolean;
}

export interface WorkspaceDrilldownOptionDTO {
  caption?: string;
  value?: string | number;
}

export class WorkspaceDrilldownOption {
  caption: string | undefined = '[no value]';
  value?: string | number | undefined;

  constructor(option: WorkspaceDrilldownOptionDTO) {
    this.caption = option.caption === '' || option.caption === null || option.caption === 'null' ? '[no value]' : option.caption;
    this.value = option.value;
  }

  toDto(): WorkspaceDrilldownOptionDTO {
    return { caption: this.caption, value: this.value };
  }
}

/**
 * Workspace Drilldown.
 * Created from context and update from dto object that comes from view settings
 */
export class WorkspaceDrilldown {
  name: string;
  idColumnName: string;
  caption: string;
  lookupColumn: string;
  resetLevel = false;
  closed = false;
  selected: WorkspaceDrilldownOption = new WorkspaceDrilldownOption({});
  contextType: string;

  showInfo = false;

  additionalQueryFilter?: DeQueryFilter;

  // undefined - options have not been loaded from API
  //        [] - There are no options present (no data)
  options?: WorkspaceDrilldownOption[];

  private locked = false;

  get isLocked(): boolean {
    return this.locked;
  }

  constructor(contextLine: ContextLine, dto?: WorkspaceDrilldownDTO) {
    if (!contextLine) {
      return;
    }

    this.name = contextLine.name;
    this.caption = contextLine.caption;
    this.idColumnName = contextLine.idColumnName;
    this.lookupColumn = contextLine.lookupColumn;
    this.contextType = contextLine.type;

    if (dto) {
      this.updateFromDto(dto);
    }
  }

  // To

  /**
   * Used to save drilldown info to the database
   */
  toDto(): WorkspaceDrilldownDTO {
    return {
      name: this.name,
      locked: this.locked,
      resetLevel: this.resetLevel
    };
  }

  /**
   * Used to save drilldown state in local storage (persistent)
   */
  toStateDto(): WorkspaceDrilldownStateDTO {
    return {
      name: this.name,
      closed: this.locked ? false : this.closed,
      selected: this.selected.toDto()
    };
  }

  toDeQuery(prevFilters: DeQueryFilter[]): DeQuery {
    return {
      columns: [{ name: this.name }],
      filters: this.deQueryFilters(prevFilters)
    };
  }

  toFullQueryQueryParam(): WorkspaceFullQueryDrilldownDTO {
    return {
      name: this.name,
      menuPending: !this.closed,
      lookup_column: this.lookupColumn,
      lastSelectedValue: this.selected.value ? this.selected.value : undefined,
      resetLevel: this.resetLevel
    };
  }

  toDeQueryFilter(): DeQueryFilter {
    return {
      column: { name: this.idColumnName },
      operator: '=',
      values: [this.selected.value]
    };
  }

  // Apply and update model
  updateFromDto(dto: WorkspaceDrilldownDTO): void {
    this.locked = dto.locked !== undefined ? !!dto.locked : false;
    if (this.locked) {
      this.closed = false;
    }
    this.resetLevel = dto.resetLevel !== undefined ? !!dto.resetLevel : false;
  }

  updateFromState(dto: WorkspaceDrilldownStateDTO): void {
    this.selected = dto.selected !== undefined ? new WorkspaceDrilldownOption(dto.selected) : new WorkspaceDrilldownOption({});
    this.closed = this.locked ? false : dto.closed !== undefined ? !!dto.closed : false;
  }

  updateFromUrlParams(params: Params): void {
    const urlValue = params[this.name];
    if (!urlValue) {
      return;
    }
    if (urlValue === 'is-closed' && !this.locked) {
      this.closed = true;
      return;
    }
    this.select(this.findMatchingOption({ value: +urlValue, caption: urlValue }));
  }

  applyFullQuery(drilldownDto: WorkspaceFullQueryDrilldownDTO): void {
    if (!drilldownDto.menu || drilldownDto.menu.length === 0) {
      this.selected = new WorkspaceDrilldownOption({});
      this.options = [];
      return;
    }
    this.options = drilldownDto.menu.map((option) => new WorkspaceDrilldownOption(option));
    this.selected = this.findMatchingOption(this.selected ?? drilldownDto.selected);
  }

  setOptions(options: WorkspaceDrilldownOption[]): void {
    this.options = options;
    this.selected = this.findMatchingOption(this.selected);
  }

  // Actions

  select(option: WorkspaceDrilldownOption): void {
    if (!option) {
      return;
    }
    this.selected = option;
    this.closed = false;
  }

  toggleLocked(): void {
    this.locked = !this.locked;
    if (this.locked) {
      this.closed = false;
    }
  }

  private deQueryFilters(prevFilters: DeQueryFilter[]): DeQueryFilter[] {
    if (this.additionalQueryFilter) {
      return this.resetLevel ? [this.additionalQueryFilter] : cloneDeep(prevFilters).concat([this.additionalQueryFilter]);
    }
    return this.resetLevel ? [] : cloneDeep(prevFilters);
  }

  private findMatchingOption(selectedOption: WorkspaceDrilldownOptionDTO): WorkspaceDrilldownOption {
    if (!this.options || this.options.length === 0) {
      return new WorkspaceDrilldownOption({});
    }
    if (!selectedOption) {
      return this.options[0];
    }
    let foundSelected = this.options.find((option) => option.value === selectedOption.value);
    if (foundSelected) {
      return foundSelected;
    }
    foundSelected = this.options.find((option) => option.caption === selectedOption.caption);
    if (foundSelected) {
      return foundSelected;
    }
    return this.options[0];
  }
}
