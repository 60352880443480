import * as Highcharts from 'highcharts';

export const agrDefaultChartOptions: Highcharts.Options = {
  chart: {
    animation: false,
    type: 'column',
    zoomType: 'x',
    spacingBottom: 2,
    panning: true,
    panKey: 'shift',
    style: {
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    crosshairs: true,
    shared: true,
    valueDecimals: 0
  },
  plotOptions: {
    column: {
      groupPadding: 0.5,
      pointWidth: 5,
      borderWidth: 0,
      opacity: 0.9
    },
    area: {
      marker: {
        enabled: false
      },
      states: {
        hover: {
          enabled: false
        }
      }
    },
    line: {
      states: {
        hover: {
          enabled: false
        }
      }
    },
    series: {
      turboThreshold: 4000,
      animation: false,
      states: {
        inactive: {
          opacity: 1
        }
      }
    }
  },
  yAxis: {
    title: {
      text: undefined
    },
    tickPixelInterval: 30
  },
  xAxis: {
    type: 'datetime',
    labels: {
      rotation: 45,
      align: 'left',
      overflow: 'justify'
    }
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -10,
    y: 0,
    borderWidth: 0
  },
  title: {
    text: undefined
  }
};

export const emptyChartOptions: Highcharts.Options = {
  ...Highcharts.defaultOptions,
  ...{
    title: {
      text: ''
    },
    lang: {
      noData: ''
    },
    credits: {
      enabled: false
    }
  }
};

export const editableColumnSeriesOptions: Highcharts.SeriesColumnOptions = {
  cursor: 'pointer',
  dragDrop: {
    draggableY: true,
    dragMinY: 0,
    dragPrecisionY: 1
  },
  minPointLength: 3,
  opacity: 0.8,
  pointWidth: 10,
  shadow: true,
  stickyTracking: true,
  tooltip: {
    distance: 16
  },
  type: 'column',
  zIndex: 11
};

export const resetColumnSeriesOptions: Highcharts.SeriesColumnOptions = {
  cursor: undefined,
  dragDrop: {
    draggableY: false
  },
  minPointLength: 0,
  pointWidth: 5,
  opacity: 1,
  shadow: false,
  stickyTracking: false,
  tooltip: {
    distance: undefined
  },
  type: 'column',
  zIndex: 5
};
