<!-- grid -->
<ag-grid-angular
  #agGrid
  class="ag-theme-alpine agr-data-grid ag-no-row-groups w-100"
  id="item-card-grid"
  [rowData]="rowData"
  [modules]="gridModules"
  [gridOptions]="gridOptions"
  (gridReady)="onGridReady()"
  (cellValueChanged)="onCellEdited($event)">
</ag-grid-angular>
