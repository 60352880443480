/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
import {
  NumberSymbol,
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercent,
  getCurrencySymbol,
  getLocaleNumberSymbol,
  registerLocaleData
} from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { StoreService } from 'src/app/_core/store.service';

const enum FormatType {
  integer = 1,
  number,
  currency,
  percent,
  percentage,
  date,
  datetime,
  time,
  duration,
  durationHuman,
  id,
  invalid
}

interface Persistent {
  initialLocales: string[]; // Locales that are registered on startup
}

/**
 * TO ADD a locale:
 * 1. add it to the webpackInclude magic comment in registerLocaleData()
 * 2. add it to the const availableLocales array
 * 3. add it to `private defaultLocales` if it is important to make the locale available immediately to all clients
 */
@Injectable({
  providedIn: 'root'
})
export class FormatService {
  availableLocales: Locale[] = availableLocales;
  private registeredLocales = new Set<string>();
  private defaultLocales = ['en', 'en-GB', 'is', 'fo', 'fr', 'de', 'es', 'da', 'nb', 'sv'];
  private persistent: Persistent = {
    initialLocales: this.defaultLocales
  };
  private persistentKey = 'formatService';

  private formatStringToType = {
    id: FormatType.id,
    int: FormatType.integer,
    integer: FormatType.integer,
    number: FormatType.number,
    decimal: FormatType.number,
    float: FormatType.number,
    currency: FormatType.currency,
    percent: FormatType.percent,
    percentage: FormatType.percentage,
    date: FormatType.date,
    datetime: FormatType.datetime,
    time: FormatType.time,
    duration: FormatType.duration,
    durationHuman: FormatType.durationHuman
  };

  constructor(private sessionService: SessionService, private settingsService: SettingsService, private storeService: StoreService) {
    this.loadPersistent();
    this.initialLocaleRegistration();
  }

  get numberLocale(): string {
    return this.getNumberLocale();
  }
  get dateLocale(): string {
    return this.getLocale();
  }
  get decimalSeparator(): string {
    return getLocaleNumberSymbol(this.numberLocale, NumberSymbol.Decimal);
  }
  get thousandSeparator(): string {
    return getLocaleNumberSymbol(this.numberLocale, NumberSymbol.Group);
  }

  /**
   * The one and only API for format service!
   * Formats any number, currency, percentage, date, datetime, time
   * given a correctly structured AGR @formatString and returns a formatted string
   * @param value The value to format
   * @param formatString String delimited by colons like 'currency:USD:en'
   */
  format(value: string | number | Date | boolean, formatString: string): string {
    if (typeof value === 'boolean') {
      return value.toString();
    }
    if (value !== 0 && !value) {
      return '';
    }
    const formatType = this.getFormatType(formatString);
    switch (formatType) {
      case FormatType.integer:
        return formatNumber(Number(value), this.getNumberLocale(), '1.0-0');
      case FormatType.number:
        return formatNumber(Number(value), this.getNumberLocale(), this.getNumberDigitsInfo(formatString));
      case FormatType.percent:
        return formatPercent(Number(value), this.getNumberLocale(), this.getPercentageDigitsInfo(formatString));
      case FormatType.percentage:
        return formatPercent(+(Number(value) / 100).toFixed(8), this.getNumberLocale(), this.getPercentageDigitsInfo(formatString));
      case FormatType.currency:
        return this.formatCurrency(Number(value), formatString);
      case FormatType.date:
        return !value
          ? ''
          : this.isValidDate(value)
          ? formatDate(this.parseDate(value), this.getDateFormat(formatString), this.getLocale())
          : String(value);
      case FormatType.datetime:
        return !value
          ? ''
          : this.isValidDate(value)
          ? formatDate(this.parseDate(value), this.getDateTimeFormat(formatString), this.getLocale())
          : String(value);
      case FormatType.time:
        return !value
          ? ''
          : this.isValidDate(value)
          ? formatDate(this.parseDate(value), this.getTimeFormat(formatString), this.getLocale())
          : String(value);
      case FormatType.duration:
        return this.formatDuration(value, this.getDurationFormat(formatString));
      case FormatType.durationHuman:
        return this.formatHumanizedDuration(value);
      case FormatType.id:
        return String(value);
      case FormatType.invalid:
        return String(value);
      default:
        return String(value);
    }
  }

  /**
   * Gets the locale code from 1. local storage (user) or 2. default settings or 3. fallback to 'en-GB'
   */
  getLocale(): string {
    let localeCode = (this.sessionService.user && this.sessionService.user.locale) || this.settingsService.locale();
    localeCode = localeCode && this.isAvailableLocale(localeCode) ? localeCode : 'en-GB';
    this.registerLocaleData(localeCode);
    return localeCode;
  }

  registerLocaleData(localeCode: string): Promise<void> {
    const isAvailable = this.availableLocales.some((locale) => locale.code === localeCode);
    if (!isAvailable || this.isRegisteredLocale(localeCode)) {
      return undefined;
    }
    this.registeredLocales.add(localeCode);
    this.savePersistent();
    return import(
      /* webpackInclude: /(af|bs|ca|cs|cy|da|da-GL|de|de-AT|de-CH|de-LI|de-LU|el|en|en-AU|en-CA|en-GB|en-IN|en-IE|en-MT|en-NZ|en-ZA|es|es-419|es-AR|es-CL|es-MX|es-PE|et|fo|fi|fr|fr-BE|fr-CA|fr-LU|fr-CH|hu|hr|is|it|it-CH|ja|lt|lv|mk|mt|nb|nn|nl|nl-BE|pl|pt|pt-PT|ro|ro-MD|ru|sr|sl|sk|sv|sv-AX|sv-FI|sw|tr|uk)\.mjs$/ */
      `/node_modules/@angular/common/locales/${localeCode}.mjs`
    ).then((module) => {
      registerLocaleData(module.default);
    });
  }

  private getFormatType(formatString: string): FormatType {
    const formatType = formatString ? this.formatStringToType[formatString.split(':')[0]] : '';
    return formatType ? formatType : FormatType.invalid;
  }

  private formatCurrency(value: number, formatString: string): string {
    const locale = this.getCurrencyLocale(formatString);
    const currencySymbol = this.getCurrencySymbol(formatString);
    const currencyCode = this.getCurrencyCode(formatString);
    const digitsInfo = this.getCurrencyDigitsInfo(formatString);
    return formatCurrency(value, locale, currencySymbol, currencyCode, digitsInfo);
  }

  private getNumberLocale(): string {
    let localeCode = this.sessionService.user.locale;
    localeCode = this.isAvailableLocale(localeCode) ? localeCode : this.settingsService.currencyLocale();
    return this.isAvailableLocale(localeCode) ? localeCode : this.getLocale();
  }

  /**
   * Returns the locale from a formatString or the global @locale if non is present
   * @param formatString e.g 'currency:ISK:is'
   */
  private getCurrencyLocale(formatString: string): string {
    const localeCode = formatString.split(':')[2]; // The position of locale for a currency type
    return this.isAvailableLocale(localeCode) ? localeCode : this.getNumberLocale();
  }

  private getCurrencySymbol(formatString: string): string {
    const currencyCode = this.getCurrencyCode(formatString);
    const locale = this.getCurrencyLocale(formatString);
    return getCurrencySymbol(currencyCode, 'narrow', locale);
  }

  /**
   * Get the ISO-4217 currency designator ('USD', 'EUR', 'ISK')
   * from a currency type format string.
   * Checks if value is a number and ignores e.g currency:2 (AGR 5 formatString)
   * @param formatString e.g 'currency:USD:en'
   */
  private getCurrencyCode(formatString: string): string {
    const currencyCode = formatString.split(':')[1];
    return currencyCode && isNaN(+currencyCode) ? currencyCode.toUpperCase() : this.getCurrency();
  }

  /**
   * Gets the 4th value as fractionDigits from a currency format string and builds a 'digitsInfo' string
   * @param formatString e.g 'currency:USD:en:3'
   */
  private getCurrencyDigitsInfo(formatString: string): string {
    const fractionDigits = Number(formatString.split(':')[3]);
    return !isNaN(fractionDigits) ? `1.${fractionDigits}-${fractionDigits}` : '';
  }

  private getCurrency(): string {
    const currency = this.settingsService.currency();
    return currency ? currency.toUpperCase() : 'GBP';
  }

  private isValidDate(value: string | number | Date): boolean {
    const date = new Date(value);
    if (date instanceof Date && !isNaN(+date)) {
      return true;
    }
    if (moment(value, 'DD-MM-YYYY').isValid()) {
      return true;
    }
    return moment(value).isValid();
  }

  /**
   * Javascript Date expects an ISO 8601 input if date is formatted differently we have to handle that
   */
  private parseDate(value: string | number | Date): Date {
    if (isDateOnlyString(value)) {
      return new Date(`${value}T00:00`);
    } // T00:00 required so date is represented in client time zone
    if (isExpectedInput(value)) {
      return new Date(value);
    }
    return moment(value, 'DD-MM-YYYY').toDate(); // If value is a non ISO string we default to 'DD/MM/YYYY', format

    function isExpectedInput(input: string | number | Date): boolean {
      const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']; // When grouping formatted days new Date().toString() is input
      return typeof input !== 'string' || moment(input, moment.ISO_8601).isValid() || days.includes(input.substr(0, 3));
    }

    function isDateOnlyString(input: string | number | Date): boolean {
      return typeof input === 'string' && moment(input, moment.ISO_8601).isValid() && input.length === 10;
    }
  }

  /**
   * Expects a formatString 'date', 'datetime' or 'time' and returns the substring behind the first colon ':'
   * that should contain, Angular formatDate() parameters i.e ISO-8601 representation of dates.
   * @param formatString e.g 'datetime:d. MMMM y, hh:mm:ss'
   */
  private getDateFormatString(formatString: string): string {
    const indexOfColon = formatString.indexOf(':') + 1;
    return indexOfColon ? formatString.substring(indexOfColon) : '';
  }

  private getDateFormat(formatString: string): string {
    let format = this.getDateFormatString(formatString);
    format = !format ? this.settingsService.localeDateFormat() : format;
    return !format ? 'mediumDate' : format;
  }

  private getDateTimeFormat(formatString: string): string {
    let format = this.getDateFormatString(formatString);
    format = !format ? this.settingsService.localeDateTimeFormat() : format;
    return !format ? 'medium' : format;
  }

  private getTimeFormat(formatString: string): string {
    let format = this.getDateFormatString(formatString);
    format = !format ? this.settingsService.localeTimeFormat() : format;
    return !format ? 'mediumTime' : format;
  }

  private getDurationFormat(formatString: string): string {
    let format = this.getDateFormatString(formatString);
    format = !format ? this.settingsService.localeDurationFormat() : format;
    return !format ? 'HH:mm:ss' : format;
  }

  /**
   * Converts AGR formatString info to an Angular 'digitsInfo' string as
   * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits} e.g '1.3-3'
   * @param formatString e.g. 'number', 'number:2', 'number:2:4'
   */
  private getNumberDigitsInfo(formatString: string): string {
    let minFractionDigits = Math.abs(Number(formatString.split(':')[1]));
    minFractionDigits = isNaN(minFractionDigits) ? undefined : minFractionDigits;
    let maxFractionDigits = Math.abs(Number(formatString.split(':')[2]));
    maxFractionDigits = isNaN(maxFractionDigits) || maxFractionDigits < minFractionDigits ? minFractionDigits : maxFractionDigits;
    return minFractionDigits === undefined
      ? `1.0-${this.getGlobalMaxNumberFractionDigits()}`
      : `1.${minFractionDigits}-${maxFractionDigits}`;
  }

  private getGlobalMaxNumberFractionDigits(): number {
    const maxFractionDigits = Math.abs(Number(this.settingsService.maxNumberFractionDigits()));
    return isNaN(maxFractionDigits) ? 99 : maxFractionDigits;
  }

  private getPercentageDigitsInfo(formatString: string): string {
    let minFractionDigits = Math.abs(Number(formatString.split(':')[1]));
    minFractionDigits = isNaN(minFractionDigits) ? undefined : minFractionDigits;
    let maxFractionDigits = Math.abs(Number(formatString.split(':')[2]));
    maxFractionDigits = isNaN(maxFractionDigits) || maxFractionDigits < minFractionDigits ? minFractionDigits : maxFractionDigits;
    return minFractionDigits === undefined
      ? `1.0-${this.getGlobalMaxPercentageFractionDigits()}`
      : `1.${minFractionDigits}-${maxFractionDigits}`;
  }

  private getGlobalMaxPercentageFractionDigits(): number {
    const maxFractionDigits = Math.abs(Number(this.settingsService.maxPercentageFractionDigits()));
    return isNaN(maxFractionDigits) ? 99 : maxFractionDigits;
  }

  private formatDuration(value: number | string | Date, formatString: string): string {
    const duration = value instanceof Date ? value : !isNaN(+value) ? new Date(+value) : new Date(value);
    if (isNaN(duration.getTime())) {
      return String(value);
    }
    return moment.utc(moment.duration(duration.getTime(), 'milliseconds').asMilliseconds()).locale(this.dateLocale).format(formatString);
  }

  private formatHumanizedDuration(value: number | string | Date): string {
    const duration = value instanceof Date ? value : new Date(value);
    return moment.duration(duration.getTime()).locale(this.dateLocale).humanize();
  }

  private isAvailableLocale(localeCode: string): boolean {
    const isAvailable = this.availableLocales.some((locale) => locale.code === localeCode);
    if (!this.isRegisteredLocale(localeCode)) {
      this.registerLocaleData(localeCode);
    }
    return isAvailable;
  }

  private isRegisteredLocale(localeCode: string): boolean {
    return this.registeredLocales.has(localeCode);
  }

  /**
   * Registers locales in system and user settings
   * Registers locales in default array or in local storage
   */
  private initialLocaleRegistration(): void {
    if (this.persistent.initialLocales.length < this.defaultLocales.length) {
      this.defaultLocales.map((localeCode) => this.registerLocaleData(localeCode));
    } else {
      this.persistent.initialLocales.map((localeCode) => this.registerLocaleData(localeCode));
    }
    this.registerLocaleData(this.settingsService.locale());
    this.registerLocaleData(this.settingsService.currencyLocale());
    if (this.sessionService.user) {
      this.registerLocaleData(this.sessionService.user.locale);
    }
  }

  private loadPersistent(): void {
    this.persistent = this.storeService.load(this.persistentKey, this.persistent) as Persistent;
  }

  private savePersistent(): void {
    this.persistent.initialLocales = [...this.registeredLocales];
    this.storeService.set(this.persistentKey, this.persistent);
  }
}

export interface Locale {
  code: string;
  language: string;
  name: string;
  region?: string;
  caption?: string;
}

const availableLocales: Locale[] = [
  { code: 'af', language: 'Afrikaans', name: 'Afrikaans' },
  { code: 'bs', language: 'Bosnian', name: 'Bosanski' },
  { code: 'ca', language: 'Catalan', name: 'Català' },
  { code: 'cs', language: 'Czech', name: 'Čeština' },
  { code: 'cy', language: 'Welsh', name: 'Cymraeg' },
  { code: 'da-GL', language: 'Danish', name: 'Dansk', region: 'Greenland' },
  { code: 'da', language: 'Danish', name: 'Dansk', region: 'Denmark' },
  { code: 'de-AT', language: 'Austrian German', name: 'Österreichisches Deutsch', region: 'Austria' },
  { code: 'de-CH', language: 'Swiss High German', name: 'Schweizer Hochdeutsch', region: 'Switzerland' },
  { code: 'de-LI', language: 'German', name: 'Deutsch', region: 'Liechtenstein' },
  { code: 'de-LU', language: 'German', name: 'Deutsch', region: 'Luxembourg' },
  { code: 'de', language: 'German', name: 'Deutsch' },
  { code: 'el', language: 'Greek', name: 'Ελληνικά' },
  { code: 'en-AU', language: 'English', name: 'Australian English', region: 'Australia' },
  { code: 'en-CA', language: 'English', name: 'Canadian English', region: 'Canada' },
  { code: 'en-GB', language: 'English', name: 'British English', region: 'Great Britain' },
  { code: 'en-IE', language: 'English', name: 'English', region: 'Ireland' },
  { code: 'en-IN', language: 'English', name: 'English', region: 'India' },
  { code: 'en-MT', language: 'English', name: 'English', region: 'Malta' },
  { code: 'en-NZ', language: 'English', name: 'English', region: 'New Zealand' },
  { code: 'en', language: 'English', name: 'American English', region: 'United States' },
  { code: 'en-ZA', language: 'English', name: 'English', region: 'Southern Africa' },
  { code: 'es-419', language: 'Latin American Spanish', name: 'Español latinoamericano' },
  { code: 'es-AR', language: 'Spanish', name: 'Español', region: 'Argentina' },
  { code: 'es-CL', language: 'Spanish', name: 'Español', region: 'Chile' },
  { code: 'es-MX', language: 'Mexican Spanish', name: 'Español de México', region: 'Mexico' },
  { code: 'es-PE', language: 'Spanish', name: 'Español', region: 'Peru' },
  { code: 'es', language: 'Spanish', name: 'Español' },
  { code: 'et', language: 'Estonian', name: 'Eesti' },
  { code: 'fi', language: 'Finnish', name: 'Suomi' },
  { code: 'fo', language: 'Faroese', name: 'Føroyskt' },
  { code: 'fr-BE', language: 'French', name: 'Français', region: 'Belgium' },
  { code: 'fr-CA', language: 'Canadian French', name: 'Français Canadien', region: 'Canada' },
  { code: 'fr-CH', language: 'French', name: 'Français', region: 'Switzerland' },
  { code: 'fr-LU', language: 'French', name: 'Français', region: 'Luxembourg' },
  { code: 'fr', language: 'French', name: 'Français', region: 'France' },
  { code: 'hr', language: 'Croatian', name: 'Hrvatski' },
  { code: 'hu', language: 'Hungarian', name: 'Magyar' },
  { code: 'is', language: 'Icelandic', name: 'Íslenska' },
  { code: 'it-CH', language: 'Italian', name: 'Italiano', region: 'Switzerland' },
  { code: 'it', language: 'Italian', name: 'Italiano' },
  { code: 'ja', language: 'Japanese', name: '日本語' },
  { code: 'lt', language: 'Lithuanian', name: 'Lietuvių' },
  { code: 'lv', language: 'Latvian', name: 'Latviešu' },
  { code: 'mk', language: 'Macedonian', name: 'Македонски' },
  { code: 'mt', language: 'Maltese', name: 'Malti' },
  { code: 'nb', language: 'Norwegian Bokmål', name: 'Norsk Bokmål' },
  { code: 'nl-BE', language: 'Flemish', name: 'Nederlands (België)', region: 'Belgium' },
  { code: 'nl', language: 'Dutch', name: 'Nederlands', region: 'Netherlands' },
  { code: 'nn', language: 'Norwegian Nynorsk', name: 'Norsk Nynorsk' },
  { code: 'pl', language: 'Polish', name: 'Polski' },
  { code: 'pt-PT', language: 'European Portuguese', name: 'Português Europeu', region: 'Portugal' },
  { code: 'pt', language: 'Portuguese', name: 'Português' },
  { code: 'ro-MD', language: 'Moldavian', name: 'Română (Republica Moldova)' },
  { code: 'ro', language: 'Romanian', name: 'Română' },
  { code: 'ru', language: 'Russian', name: 'Русский' },
  { code: 'sk', language: 'Slovak', name: 'Slovaščina' },
  { code: 'sl', language: 'Slovenian', name: 'Slovenščina' },
  { code: 'sr', language: 'Serbian', name: 'Српски' },
  { code: 'sv-AX', language: 'Swedish', name: 'Svenska', region: 'Åland Islands' },
  { code: 'sv-FI', language: 'Swedish', name: 'Svenska', region: 'Finland' },
  { code: 'sv', language: 'Swedish', name: 'Svenska' },
  { code: 'sw', language: 'Swahili', name: 'Kiswahili' },
  { code: 'tr', language: 'Turkish', name: 'Türkçe' },
  { code: 'uk', language: 'Ukrainian', name: 'українська' }
].map((locale: Locale) => {
  const regionString = locale.region ? `, ${locale.region}` : '';
  locale.caption = `${locale.name} - ${locale.language}${regionString} (${locale.code})`;
  return locale;
});
