<div class="column-label">
  <span>{{ column.caption | agrTranslate }}</span>
  <ng-template #columnDescription>
    {{ column.description | agrTranslate }}
  </ng-template>
  <button class="btn btn-help" [ngbPopover]="columnDescription" popoverTitle="" container="">
    <i class="fa fa-question-circle"></i>
  </button>
</div>

<div class="column-value">{{ column.value | agrFormat: column.type }}</div>
