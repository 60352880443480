import { isPlatformBrowser } from '@angular/common';
import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
/**
 * Used to provide a way to invoke element methods.
 * Based on https://github.com/angular/angular/issues/13818#issuecomment-372276031
 * and the source code of the old Renderer V1.
 */
@Injectable({
  providedIn: 'root'
})
export class AgrRendererService {
  constructor(@Inject(PLATFORM_ID) private platformId: {}) {}

  invokeElementMethod(eleRef: ElementRef, method: string, args: any[] = []): void {
    if (isPlatformBrowser(this.platformId)) {
      const element = eleRef.nativeElement;
      element[method].apply(element, args);
    }
  }
}
