export interface ConfigCheckboxesDTO {
  enabled?: boolean;
  // The below comment is needed to generate schema allowing null (See https://github.com/YousefED/typescript-json-schema/issues/70)
  /** @nullable */
  trueValue?: null | number | string; // The type allows null in json to set value to null in database
  /** @nullable */
  falseValue?: null | number | string; // The type allows null in json to set value to null in database
  disableIfNull?: boolean;
}

export class ConfigCheckboxes {
  enabled: boolean;
  /** @nullable */
  trueValue?: null | number | string; // The type allows null in json to set value to null in database
  /** @nullable */
  falseValue?: null | number | string; // The type allows null in json to set value to null in database
  disableIfNull?: boolean;

  constructor(dto?: ConfigCheckboxesDTO) {
    if (!dto) {
      return;
    }
    this.enabled = dto.enabled;
    this.trueValue = dto.trueValue;
    this.falseValue = dto.falseValue;
    this.disableIfNull = dto.disableIfNull;
  }
}
