import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';

@Injectable()
export class FeatAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private settingsService: SettingsService) {}

  canActivate(route: any, state: RouterStateSnapshot): boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    if (route.data && route.data.expectedFeature && !this.authService.hasFeature(route.data.expectedFeature)) {
      this.router.navigate(['/no-access']);
      return false;
    }
    if (this.settingsService.getValue('start_in_setup')) {
      this.router.navigate(['/erp-setup']);
      return false;
    }

    return true;
  }
}
