export class Location {
  id: number;
  locationNo: string;
  name: string;
  type: string;

  constructor(location?: LocationDTO) {
    if (!location) {
      return;
    }
    this.id = location.id;
    this.locationNo = location.locationNo;
    this.name = location.name;
    this.type = location.locationType;
  }
}

export interface LocationDTO {
  id: number;
  locationNo: string;
  name: string;
  locationType: string;
  locationGroupId?: number;
  createdAt: string; // Date
  updatedAt: string; // Date
}
