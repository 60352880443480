export interface FeatureDTO {
  id: number;
  featureRef: string;
  caption?: string;
  description?: string;
  enabled?: boolean;
  system?: boolean;
  workspace_feature?: boolean;
}

/**
 * Feature
 */
export class Feature {
  id: number;
  featureRef: string;
  caption: string;
  description?: string;
  enabled?: boolean;
  system?: boolean;
  workspaceFeature?: boolean;

  constructor(featureDto?: FeatureDTO) {
    if (!featureDto) {
      return;
    }
    this.id = featureDto.id;
    this.featureRef = featureDto.featureRef;
    this.caption = featureDto.caption;
    this.description = featureDto.description;
    this.enabled = featureDto.enabled;
    this.system = featureDto.system;
    this.workspaceFeature = featureDto.workspace_feature;
  }

  toDto(): FeatureDTO {
    return {
      id: this.id,
      featureRef: this.featureRef,
      caption: this.caption,
      description: this.description,
      enabled: this.enabled,
      system: this.system,
      workspace_feature: this.workspaceFeature
    };
  }
}
