import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { StoreService } from 'src/app/_core/store.service';

export interface Translation {
  id: number;
  key: string;
  value: string;
  language: string;
}

export interface Language {
  id: number;
  code: string; // ISO 639-1 code
  name: string; // Native name of language
  description: string; // English name of language
  caption?: string; // Custom name, combination of code and name.
}

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  static translateService: TranslateService;

  /**
   * Returns the translated value or the key if the value was not found.
   */
  static get(key?: string): string {
    if (!key || !TranslationsService.translateService || !TranslationsService.translateService.instant) {
      return key;
    }
    return TranslationsService.translateAndReplacePlaceholder(key);
  }

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private storeService: StoreService
  ) {
    TranslationsService.translateService = translateService;
  }

  /**
   * Finds '%s' in return value and replaces with whatever comes after the ',' in the translation key.
   * E.g. GOT_X_MINUTES,10 -> Got %s minutes -> Got 10 minutes.
   */
  private static translateAndReplacePlaceholder(key: string): string {
    if (!key) {
      return;
    }
    const keySplit = key.split(',');
    const keyBase = keySplit[0];
    const params: string[] = keySplit.slice(1, keySplit.length);
    if (params.length === 0) {
      return TranslationsService.translateService.instant(keyBase);
    }
    let translation = TranslationsService.translateService.instant(keySplit[0]);
    if (translation === keyBase) {
      return key;
    }
    params.forEach((param) => {
      translation = translation.replace('%s', param);
    });
    return translation;
  }

  /**
   * Initialized at app startup from core.module.ts
   * Get translations and set ngx-translate
   */
  async initialize(): Promise<void> {
    const language = this.getLanguage();
    return new Promise<void>((resolve) => {
      forkJoin([this.setFallbackTranslations(), this.setTranslations(language)]).subscribe(
        () => {
          resolve();
        },
        () => {
          window.alert('AGR Error: Failed to load translations from API\nPotential App Service error!');
          console.error('AGR: Failed to load translations from API - Check App Service');
          resolve();
        }
      );
    });
  }

  getLanguage(): string {
    let lang = this.sessionService.user ? this.sessionService.user.language : undefined;
    lang = lang ? lang : (this.storeService.get('lastUsedLanguage') as string);
    lang = lang ? lang : this.settingsService.defaultLanguage();
    return lang ? lang : 'en';
  }

  /**
   * Activates language for app.
   */
  setTranslations(language: string): Observable<boolean> {
    const isLocal = !this.settingsService.cloudTranslations();
    return this.httpClient.get(`${API_ROOT}/translations/${language}?local=${isLocal}`).pipe(
      map((translations: Translation[]) => {
        this.translateService.setTranslation(language, this.toTranslationsObj(translations));
        this.activateLanguage(language);
        return true;
      })
    );
  }

  /**
   * Save last used language.
   */
  storeLanguage(language: string): void {
    if (language === 'null') {
      return;
    }
    this.storeService.set('lastUsedLanguage', language);
  }

  getAvailableLanguages(): Observable<Language[]> {
    return this.httpClient.get<Language[]>('https://pool.agrdynamics.com/api/languages').pipe(
      map((languages: Language[]) =>
        languages.map((language) => {
          language.caption = `${language.name} (${language.code})`;
          return language;
        })
      )
    );
  }

  /**
   * Sets English as fallback language for app
   */
  private setFallbackTranslations(): Observable<boolean> {
    if (localStorage.getItem('disableFallbackTranslations') === 'true') {
      return of(true).pipe(finalize(() => true));
    }
    return this.httpClient.get(`${API_ROOT}/translations/en?local=true`).pipe(
      map((translations: Translation[]) => {
        this.translateService.setTranslation('en', this.toTranslationsObj(translations));
        this.translateService.setDefaultLang('en');
        return true;
      })
    );
  }

  /**
   * Activates language using ngx-translate and saves lastUsedLanguage in local storage.
   */
  private activateLanguage(language: string): void {
    this.translateService.use(language);
    this.storeLanguage(language);
  }

  private toTranslationsObj(translations: Translation[]): { [key in string]: string } {
    if (!translations || translations.length === 0) {
      return {};
    }
    return translations.reduce((acc, translation) => {
      acc[translation.key] = translation.value;
      return acc;
    }, {});
  }
}
