import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as bowser from 'bowser';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { FormatService } from 'src/app/_core/format.service';
import { UserDTO } from 'src/app/_core/models/user.model';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { AppInfo } from 'src/app/_core/version/app-info.model';
import { VersionService } from 'src/app/_core/version/version.service';

@Component({
  selector: 'agr-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss']
})
export class AboutModalComponent implements OnInit {
  title = 'ABOUT_AGR';
  parser = bowser.getParser(window.navigator.userAgent);
  appInfo = new AppInfo();
  browserName: string;
  browserVersion: string;
  userAgent: string;
  notChrome = false;
  showUserAgentString = false;

  // App and user settings
  user: UserDTO;
  language: string;
  dateLocale: string;
  numberLocale: string;
  serverTimezone: string;
  clientTimezone: string;

  constructor(
    public modal: NgbActiveModal,
    public authService: AuthService,
    private formatService: FormatService,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private versionService: VersionService
  ) {}

  ngOnInit(): void {
    this.getVersionInfo();
    this.getBrowserInfo();
    this.setSettings();
  }

  getVersionInfo(): void {
    this.versionService.getAppInfo().subscribe((appInfo) => (this.appInfo = appInfo));
  }

  getBrowserInfo(): void {
    this.browserName = this.parser.getBrowserName();
    if (this.browserName !== 'Chrome') {
      this.notChrome = true;
    }
    this.browserVersion = this.parser.getBrowserVersion().slice(0, 4);
    this.userAgent = navigator.userAgent;
  }

  closeModal(): void {
    this.modal.close();
  }

  private setSettings(): void {
    this.user = { ...this.sessionService.user };
    this.language = this.user.language ? this.user.language : this.settingsService.defaultLanguage();
    const userLocale = this.formatService.getLocale();
    this.numberLocale = userLocale ? userLocale : this.formatService.numberLocale;
    this.dateLocale = userLocale ? userLocale : this.formatService.dateLocale;
    this.serverTimezone = this.settingsService.serverTimezone() ? this.settingsService.serverTimezone() : 'UTC±0';
    this.clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
