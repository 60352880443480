import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'agr-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./403-and-404.scss']
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
