<div class="column-label d-flex justify-content-between">
  <span>{{ column.caption | agrTranslate }}</span>
  <ng-template #stringColumnDescription>
    <h6>{{ column.caption | agrTranslate }}</h6>
    {{ column.description | agrTranslate }}
  </ng-template>
  <button type="button" class="btn btn-help" [ngbPopover]="stringColumnDescription" popoverTitle="" container="">
    <i class="fa fa-question-circle"></i>
  </button>
  <div *ngIf="bulkUpdate" class="form-check form-switch">
    <input type="checkbox" class="form-check-input" [(ngModel)]="useGlobal" (input)="toggleGlobal()" />
    <label class="w-100 form-check-label"></label>
  </div>
</div>
<div class="column-value-editable" [formGroup]="formGroup" (click)="startEditing()" [ngClass]="{ useGlobal: useGlobal }">
  <span [hidden]="editing"><span [ngClass]="{ edited: hasOverwriteValue(), 'me-2': !hasOverwriteValue() }"></span> {{ getValue() }}</span>
  <input
    [hidden]="!editing"
    type="string"
    (blur)="blur()"
    formControlName="{{ column.name }}"
    (keyup.enter)="updateString()"
    #stringInput
    (keyup.escape)="blur()"
    [agrAutoFocus]="editing" />
</div>
