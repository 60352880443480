import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNumber } from 'lodash';
import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';
import { ItemCardDetailsService } from 'src/app/item-card/details/item-card-details.service';

@Component({
  selector: '[agr-details-number-columns]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './editable-number-columns.component.html',
  styleUrls: ['../details-columns.component.scss']
})
export class EditableNumberColumnsComponent {
  @Input() column: DetailsColumn;
  @Input() itemId: number;
  @Input() formGroup: FormGroup;
  @Input() bulkUpdate: boolean;
  @Output() readonly changed = new EventEmitter();

  editing: Boolean = false;
  maxInput: number;
  useGlobal: Boolean;

  constructor(private itemCardDetailsService: ItemCardDetailsService) {}

  blur(): void {
    this.editing = false;
    const value = this.formGroup.get(this.column.name).value;
    if (value === null) {
      this.formGroup.get(this.column.name).markAsPristine();
      return;
    }
    if (this.bulkUpdate) {
      this.column.overwriteValue = value;
    }
  }

  startEditing(): void {
    this.formGroup.get(this.column.name).setValue(this.getValue());
    this.formGroup.get(this.column.name).markAsTouched();
    this.editing = true;
  }

  updateNumber(): void {
    this.editing = false;
    let value = this.formGroup.get(this.column.name).value;
    value = this.validate(value);
    this.column.overwriteValue = value;
    if (!this.bulkUpdate) {
      this.update();
    }
    this.blur();
  }

  hasOverwriteValue(): boolean {
    return this.bulkUpdate ? this.formGroup.get(this.column.name).dirty : isNumber(this.column.overwriteValue);
  }

  getValue(): number {
    return this.hasOverwriteValue() ? this.column.overwriteValue : this.column.value;
  }

  toggleGlobal(): void {
    this.useGlobal = !this.useGlobal;
    if (this.useGlobal) {
      this.column.overwriteValue = undefined;
      this.formGroup.get(this.column.name).markAsDirty();
    }
    if (!this.useGlobal) {
      this.formGroup.get(this.column.name).markAsPristine();
    }
  }

  private validate(value: any): any {
    if (this.column.type === 'percentage' && value > 100) {
      return 100;
    }
    if (this.column.name === '_confidence_factor' && value > 0 && value < 50) {
      return 50;
    }
    return value;
  }

  private update(): void {
    this.itemCardDetailsService.updateValues(this.column, this.column.overwriteValue, this.itemId).subscribe(() => {
      this.formGroup.get(this.column.name).markAsDirty();
      this.changed.emit();
    });
  }
}
