import { camelCase } from 'lodash';
import { ItemDetailDTO } from 'src/app/item-card/models/item-card-dto.model';
import { Location, LocationDTO } from 'src/app/item-card/models/location.model';

export interface ItemSimpleDTO {
  id: number;
  itemNo: string;
  name: string;
  locationId: number;
  articleNo: string;
  size: string;
  color: string;
  style: string;
  description: string;
  closed: boolean;
  weight: number;
  volume: number;
  salePrice: number;
  wastageDays: number;
  abcGrouping: string;
  createdAt: string;
  extraSafetyStock: number;
  itemGroupId: number;
  masterItemId: number;
  originalItemNo: string;
  primaryVendorId: number;
  responsible: string;
  updatedAt: string;
  location: LocationDTO;
  itemDetail: ItemDetailDTO;
}

export class ItemSimple {
  id: number;
  name: string;
  articleNo: string;
  itemNo: string;
  locationId: number;
  location: Location = new Location();
  imageId: string;
  private itemDTO: ItemSimpleDTO;

  constructor(item?: ItemSimpleDTO) {
    if (!item) {
      return;
    }
    this.id = item.id;
    this.name = item.name;
    this.articleNo = item.articleNo;
    this.itemNo = item.itemNo;
    this.locationId = item.locationId;
    this.location = new Location(item.location);
    this.imageId = item.itemDetail.imageId;
    this.itemDTO = item;
  }

  // eslint-disable-next-line complexity
  getPropertyValue(lookup: string): string {
    if (!lookup || lookup === 'image_id' || lookup.toLowerCase() === 'imageid') {
      return this.imageId;
    }
    let value = this[lookup];
    value = value ? value : this[camelCase(lookup)];
    value = value ? value : this.itemDTO[lookup];
    value = value ? value : this.itemDTO[camelCase(lookup)];
    value = value ? value : this.itemDTO.itemDetail[lookup];
    value = value ? value : this.itemDTO.itemDetail[camelCase(lookup)];
    return value ? `${value}` : '';
  }
}
