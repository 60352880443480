<div class="huge-sync-wrapper u-slow-appear" *ngIf="visible && state">
  <!-- close -->
  <button type="button" class="huge-sync-close btn btn-light btn-sm" (click)="visible = false">
    <i class="fa fa-close"></i>
  </button>
  <!-- sync button -->
  <button type="button" class="huge-sync-btn" [ngClass]="stateClasses[state].border" (click)="clicked.emit()">
    <div>
      <i class="fa fa-refresh" *ngIf="state !== 'disabled'"></i>
      <i class="fa fa-ban" *ngIf="state === 'disabled'"></i>
    </div>
  </button>
  <!-- badge -->
  <div
    *ngIf="hasRowCount || hasCellCount"
    class="badge mt-3 text-white"
    [class.u-cursor-pointer]="hasCellAndRowCount()"
    [ngClass]="stateClasses[state].badge"
    (click)="onBadgeClick()">
    <span *ngIf="!pers.displayCellCount && rowCount > -1">{{ 'ROWS' | agrTranslate }}: {{ rowCount | agrFormat: 'integer' }}</span>
    <span *ngIf="pers.displayCellCount && cellCount > -1">{{ 'CELLS' | agrTranslate }}: {{ cellCount | agrFormat: 'integer' }}</span>
  </div>
</div>
