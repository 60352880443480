import { Injectable } from '@angular/core';

import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormatService } from 'src/app/_core/format.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { agrDefaultChartOptions } from 'src/app/item-card/chart/models/chart-options.model';
import { ItemCardService } from 'src/app/item-card/item-card.service';
import { ChartDataParams } from 'src/app/item-card/models/chart-data-params.model';
import {
  ChartElementTypeDTO,
  ItemCardDTO,
  SerieDataUpdateDTO,
  SerieDataUpdateResponseDTO
} from 'src/app/item-card/models/item-card-dto.model';

interface ItemCardChartData {
  options: Highcharts.Options;
  elementTypes: Map<string, ChartElementTypeDTO>;
}
@Injectable({
  providedIn: 'root'
})
export class ItemCardChartService {
  constructor(private itemCardService: ItemCardService, private formatService: FormatService, private settingsService: SettingsService) {}

  getItemChartSeries(itemId: number, params: ChartDataParams, orderId?: number): Observable<Highcharts.SeriesOptionsType[]> {
    return this.getItemChart(itemId, params, orderId).pipe(map((chart) => chart.options.series));
  }

  getItemChart(itemId: number, params: ChartDataParams, orderId?: number): Observable<ItemCardChartData> {
    // @ts-ignore, getItemCardDto is private for a reason
    return this.itemCardService.getItemCardDto(itemId, params, orderId).pipe(
      map((itemDTO: ItemCardDTO) => {
        let chartOptions = this.getChartDefaultOptions(params);
        chartOptions = Highcharts.merge(chartOptions, itemDTO.chartOptions);
        chartOptions.series = this.getChartSeriesOptions(itemDTO);
        chartOptions.series = this.removeEmptySeries(chartOptions.series);
        this.fixForecastDataPosition(chartOptions.series, params);
        this.fixSafetyStockForOrderPeriod(chartOptions.series, params);
        this.setMinMaxAxisValues(chartOptions);
        const elementTypes = new Map(itemDTO.chartElementTypes.map((elementType) => [elementType.name, elementType]));
        return { elementTypes, options: chartOptions };
      })
    );
  }

  updateSerieData(
    itemId: number,
    params: ChartDataParams,
    chartElementId: number,
    serieDataUpdate: SerieDataUpdateDTO
  ): Observable<SerieDataUpdateResponseDTO> {
    return this.itemCardService.updateSerieData(itemId, params, chartElementId, serieDataUpdate);
  }

  private getChartDefaultOptions(chartParams: ChartDataParams): Highcharts.Options {
    const chartOptions = Highcharts.merge(Highcharts.defaultOptions, agrDefaultChartOptions);
    this.setChartPeriodTickInterval(chartOptions, chartParams);
    this.setForecastBackground(chartOptions, chartParams);
    return chartOptions;
  }

  /**
   * Finds the highcharts_series_options from the chart_elements database table in the DTO object
   * @param itemDTO DTO object from the database and API
   */
  private getChartSeriesOptions(itemDTO: ItemCardDTO): Highcharts.SeriesOptionsType[] {
    return itemDTO.chartElementTypes.map((elementType) => {
      const series = elementType.highchartsSeriesOptions;
      series.data = itemDTO.chartData
        .filter((data) => data.chart_element_id === elementType.id)
        .map((data) => {
          return { x: data.chart_date, y: data.value };
        });
      return series;
    });
  }

  private removeEmptySeries(series: Highcharts.SeriesOptionsType[]): Highcharts.SeriesOptionsType[] {
    return series.filter((serie) => serie.data.length > 0);
  }

  /**
   * Move the forecast data points for the current period (month or week) to the beginning of the period.
   * This logically stacks the adjusted sale and forecasted sale for the current period.
   */
  private fixForecastDataPosition(series: Highcharts.SeriesOptionsType[], params: ChartDataParams): void {
    if (params.periodType === 'day') {
      return;
    }
    series
      .filter((serie) => ['forecast'].includes(serie.id))
      .map((serie) => {
        if (params.periodType === 'week') {
          serie.data
            .filter((point: Highcharts.SeriesColumnDataOptions) => this.isCurrentWeek(point.x))
            .map((point: Highcharts.SeriesColumnDataOptions) => (point.x = this.getStartOfWeekForDate(point.x).getTime()));
        } else if (params.periodType === 'month') {
          serie.data
            .filter((point: Highcharts.SeriesColumnDataOptions) => new Date(point.x).getDate() !== 1)
            .map((point: Highcharts.SeriesColumnDataOptions) => (point.x = new Date(point.x).setDate(1).valueOf()));
        }
      });
  }

  private isCurrentWeek(millisecondsDate: number): boolean {
    const date = new Date(millisecondsDate);
    const currentWeekFirstDate = this.getStartOfWeekForDate(new Date().setHours(0, 0, 0, 0));
    const nextWeekFirstDate = this.getStartOfWeekForDate(new Date().setHours(0, 0, 0, 0) + 604800000);
    return currentWeekFirstDate.getTime() <= date.getTime() && date.getTime() < nextWeekFirstDate.getTime();
  }

  private getStartOfWeekForDate(millisecondsDate: number): Date {
    const date = new Date(millisecondsDate);
    const dateOffset = (date.getDay() + 7 - this.settingsService.startOfWeek()) % 7;
    return new Date(date.setDate(date.getDate() - dateOffset));
  }

  /* eslint-disable id-length */
  /* eslint-disable no-import-assign */
  private setChartPeriodTickInterval(chartOptions: Highcharts.Options, chartParams: ChartDataParams): void {
    // @ts-ignore // https://github.com/highcharts/highcharts/issues/9920#issuecomment-462272538
    Highcharts.dateFormats = {
      W: (timestamp) => {
        return `${TranslationsService.get('WEEK_SHORT')}${this.formatService.format(timestamp, 'date:ww')}`;
      },
      y: (timestamp) => this.formatService.format(timestamp, 'date:yy'),
      b: (timestamp) => this.formatService.format(timestamp, 'date:MMM'),
      d: (timestamp) => this.formatService.format(timestamp, 'date:fullDate')
    };
    const period = chartParams.periodType; // day, week or month
    const interval = { day: 1 * 24 * 3600 * 1000, week: 7 * 24 * 3600 * 1000, month: undefined };
    const xAxis: Highcharts.XAxisOptions =
      // Determines if xAxis is an array or just a single object
      chartOptions.xAxis && (chartOptions.xAxis as Highcharts.XAxisOptions[]).length ? chartOptions.xAxis[0] : chartOptions.xAxis;
    xAxis.startOfWeek = this.settingsService.startOfWeek();
    xAxis.gridLineWidth = 1;
    xAxis.tickPixelInterval = 30;
    xAxis.tickInterval = interval[period];
    xAxis.minTickInterval = 28 * 24 * 3600 * 1000;
    const weekFormat = this.settingsService.hideWeekNumber() ? "%e. %b'%y" : "%W - %b'%y";
    xAxis.dateTimeLabelFormats = {
      day: { main: '%e. %b' },
      week: { main: weekFormat },
      month: { main: "%b, '%y" }
    };
    chartOptions.tooltip.dateTimeLabelFormats.millisecond = this.settingsService.hideWeekNumber() ? '%d' : '%d, %W';
  }

  private setForecastBackground(options: Highcharts.Options, params: ChartDataParams): Highcharts.Options {
    if (params.forecastLength <= 0) {
      return {};
    }
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const endDate = {
      day: now.getTime() + (params.forecastLength + 2) * 24 * 3600 * 1000,
      week: now.getTime() + (params.forecastLength + 1) * 7 * 24 * 3600 * 1000,
      month: Date.UTC(now.getFullYear(), now.getMonth() + params.forecastLength + 1)
    };
    (options.xAxis as Highcharts.XAxisOptions).plotBands = [
      {
        color: '#EEEEEE',
        from: now.getTime(),
        to: endDate[params.periodType]
      }
    ];
  }

  private fixSafetyStockForOrderPeriod(series: Highcharts.SeriesOptionsType[], params: ChartDataParams): void {
    if (params.forecastLength <= 0) {
      return;
    }
    const safetyStockSeries = series.find((serie) => serie.id === 'safety_stock_for_order_period');
    if (!safetyStockSeries || !safetyStockSeries.data || !safetyStockSeries.data.length) {
      return;
    }
    const safetyStockValue = (safetyStockSeries.data[0] as Highcharts.SeriesColumnDataOptions).y;
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const endDate = {
      day: now.getTime() + params.forecastLength * 24 * 3600 * 1000,
      week: now.getTime() + params.forecastLength * 7 * 24 * 3600 * 1000 - now.getDay() * 24 * 3600 * 1000,
      month: Date.UTC(now.getFullYear(), now.getMonth() + params.forecastLength)
    };
    safetyStockSeries.data = [
      { x: now.getTime(), y: safetyStockValue },
      { x: endDate[params.periodType], y: safetyStockValue }
    ];
  }

  private setMinMaxAxisValues(chartOptions: Highcharts.Options): void {
    const xAxis: Highcharts.XAxisOptions =
      // Determines if xAxis is an array or just a single object
      chartOptions.xAxis && (chartOptions.xAxis as Highcharts.XAxisOptions[]).length ? chartOptions.xAxis[0] : chartOptions.xAxis;
    xAxis.max = Math.max(...chartOptions.series.map((serie) => Math.max(...serie.data.map((data) => data.x))));
    xAxis.min = Math.min(...chartOptions.series.map((serie) => Math.min(...serie.data.map((data) => data.x))));
  }
}
