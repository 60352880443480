<div id="about-modal-header" class="modal-header">
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>

<div class="modal-body">
  <div id="about-modal-logo">
    <img src="assets/images/logo-big@2x.png" alt="App logo" />
    <span class="text-gray-500 fw-bold text-center" data-cy="app-version">{{ appInfo.project }}</span>
  </div>

  <!-- Client -->
  <div class="row top-buffer">
    <h5 class="col text-center" translate>CLIENT</h5>
  </div>
  <div id="client-version" class="row">
    <div class="col text-gray-600 text-end" translate>VERSION</div>
    <div class="col" data-cy="client-version">{{ appInfo.client.version }}</div>
  </div>
  <div id="client-build" class="row" *ngIf="appInfo.client.buildInfo">
    <div class="col text-gray-600 text-end" translate>ABOUT_BUILD</div>
    <div class="col">{{ appInfo.client.buildInfo }}</div>
  </div>
  <div id="client-image-version" class="row" *ngIf="appInfo.client.imageVersion">
    <div class="col text-gray-600 text-end" translate>IMAGE_VERSION</div>
    <div class="col" data-cy="client-image-version">{{ appInfo.client.imageVersion }}</div>
  </div>
  <div class="row">
    <div class="col text-gray-600 text-end" translate>TIMEZONE</div>
    <div class="col">{{ clientTimezone }}</div>
  </div>

  <!-- API -->
  <div class="row top-buffer">
    <h5 class="col text-center">API</h5>
  </div>
  <div id="api-version" class="row">
    <div class="col text-gray-600 text-end" translate>VERSION</div>
    <div class="col" data-cy="api-version">{{ appInfo.api.version }}</div>
  </div>
  <div id="api-build" class="row" *ngIf="appInfo.api.buildSuffix !== '0'">
    <div class="col text-gray-600 text-end" translate>ABOUT_BUILD</div>
    <div class="col">{{ appInfo.api.buildSuffix }}</div>
  </div>
  <div id="api-image-verison" class="row" *ngIf="appInfo.api.imageVersion">
    <div class="col text-gray-600 text-end" translate>IMAGE_VERSION</div>
    <div class="col" data-cy="api-image-version">{{ appInfo.api.imageVersion }}</div>
  </div>
  <!-- Database -->
  <div id="database-info">
    <div class="row top-buffer">
      <h5 class="col text-center" translate>DATABASE</h5>
    </div>
    <div class="row">
      <div class="col text-gray-600 text-end" translate>VERSION</div>
      <div class="col" data-cy="database-version">{{ appInfo.database.version }}</div>
    </div>
    <div class="row" *ngIf="appInfo.api.imageVersion">
      <div class="col text-gray-600 text-end" translate>IMAGE_VERSION</div>
      <div class="col">{{ appInfo.database.imageVersion }}</div>
    </div>
    <div class="row">
      <div class="col text-gray-600 text-end" translate>ABOUT_SOURCE</div>
      <div class="col">{{ appInfo.api.dataSource }}</div>
    </div>
    <div class="row">
      <div class="col text-gray-600 text-end" translate>TIMEZONE</div>
      <ng-template #databaseTimezonePopContent>
        {{ 'DATABASE_TIMEZONE_UNDEFINED_INFO' | agrTranslate }}
      </ng-template>
      <div class="col">
        {{ serverTimezone }}
        <button
          *ngIf="serverTimezone === 'UTC±0'"
          type="button"
          id="database-timezone-info"
          class="btn btn-help"
          [ngbPopover]="databaseTimezonePopContent"
          popoverTitle="">
          <i class="fa fa-exclamation-circle"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Browser -->
  <div class="row top-buffer">
    <h5 class="col text-center" translate>BROWSER</h5>
  </div>
  <div id="browser-info" class="row">
    <div class="col text-gray-600 text-end" translate>NAME</div>
    <div class="col" [class.text-danger]="notChrome" data-cy="browser-version">
      {{ browserName }} {{ browserVersion }}
      <button *ngIf="notChrome" type="button" id="not-chrome-info" class="btn btn-help" [ngbPopover]="notChromePopContent">
        <i class="fa fa-question-circle"></i>
      </button>
      <ng-template #notChromePopContent>
        <div class="multiline-popover">
          <p translate>PLEASE_USE_CHROME</p>
        </div>
      </ng-template>
    </div>
  </div>
  <div id="user-agent" class="row">
    <div class="col text-gray-600 text-end" translate>ABOUT_USER_AGENT</div>
    <div class="col small" *ngIf="showUserAgentString" data-cy="user-agent">{{ userAgent }}</div>
    <div class="col agr-link" *ngIf="!showUserAgentString" (click)="showUserAgentString = true" data-cy="user-agent-show-more" translate>
      NAV_SHOW_MORE
    </div>
  </div>

  <!-- Language / Locale / App Settings -->
  <div id="app-settings-info">
    <div class="row top-buffer">
      <h5 class="col text-center" translate>APP_SETTINGS</h5>
    </div>
    <div class="row">
      <div class="col text-gray-600 text-end" translate>LANGUAGE</div>
      <div class="col" data-cy="language">{{ language }}</div>
    </div>
    <div class="row">
      <div class="col text-gray-600 text-end" translate>LOCALE</div>
      <div class="col" data-cy="locale">
        <span ngbTooltip="{{ 'DATE_LOCALE' | agrTranslate }}, {{ 'NUMBER_LOCALE' | agrTranslate }}">
          {{ dateLocale }}, {{ numberLocale }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer"></div>
