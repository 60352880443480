import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/_shared/shared.module';
import { AboutModalComponent } from 'src/app/nav/about-modal/about-modal.component';
import { ClearDataModalComponent } from 'src/app/nav/clear-data-modal/clear-data-modal.component';
import { CustomActionsComponent } from 'src/app/nav/custom-actions/custom-actions.component';
import { ExecutionListComponent } from 'src/app/nav/execution-list/execution-list.component';
import { MyProfileComponent } from 'src/app/nav/my-profile/my-profile.component';
import { NavComponent } from 'src/app/nav/nav.component';

@NgModule({
  declarations: [
    AboutModalComponent,
    ClearDataModalComponent,
    CustomActionsComponent,
    ExecutionListComponent,
    MyProfileComponent,
    NavComponent
  ],
  imports: [CommonModule, FormsModule, NgbModule, ReactiveFormsModule, RouterModule, SharedModule]
})
export class NavModule {}
