import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { ApiVersion } from 'src/app/_core/version/api-version.model';
import { AppInfo, ProjectVersionDTO } from 'src/app/_core/version/app-info.model';
import { ClientVersion } from 'src/app/_core/version/client-version.model';
import { DBVersion } from 'src/app/_core/version/db-version.model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private http: HttpClient) {}

  getAppInfo(): Observable<AppInfo> {
    const appInfo = new AppInfo(this.getClientVersion());

    return forkJoin([this.getApiVersion(), this.getDbVersion(), this.getAllVersions()]).pipe(
      map((result) => {
        appInfo.api = result[0];
        appInfo.database = result[1];
        appInfo.updateFromVersionList(result[2]);
        return appInfo;
      })
    );
  }

  getClientVersion(): ClientVersion {
    return new ClientVersion(environment);
  }

  private getApiVersion(): Observable<ApiVersion> {
    return this.http.get(`${API_ROOT}/about/api`).pipe(map((dto: ApiVersion) => new ApiVersion(dto)));
  }

  private getDbVersion(): Observable<DBVersion> {
    return this.http.get(`${API_ROOT}/about/database`).pipe(map((dto: DBVersion) => new DBVersion(dto)));
  }

  private getAllVersions(): Observable<ProjectVersionDTO[]> {
    return this.http.get<ProjectVersionDTO[]>(`https://pool.agrdynamics.com/api/versions`);
  }
}
