import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { StoreService } from 'src/app/_core/store.service';

/**
 * The sync button is used to overlay AgGrid when it is out of sync.
 * Place right above the ag-grid component in markup.
 *
 * State
 * Primary and warning will only change button and badge color.
 * When state = 'disabled' the button turns gray and gets 'ban' icon but button is still clickable.
 * When state = 'autosync' the button is not visible.
 *
 * Cell & Row Count
 * If cell or row count has value the badge is displayed.
 * When both have value it is possible to toggle between them by clicking the badge.
 */

export type SyncBtnState = 'autosync' | 'primary' | 'warning' | 'disabled';
interface Pers {
  displayCellCount?: boolean;
}

@Component({
  selector: 'agr-sync-button',
  templateUrl: './sync-button.component.html',
  styleUrls: ['./sync-button.component.scss']
})
export class SyncButtonComponent implements OnInit, OnChanges {
  @Input() visible: boolean; // show or hide the component
  @Input() state: SyncBtnState;
  @Input() rowCount?: number;
  @Input() cellCount?: number;
  @Output() readonly clicked = new EventEmitter();
  stateClasses = {
    autosync: { badge: '', border: 'none' },
    primary: { badge: 'bg-primary', border: 'blue' },
    warning: { badge: 'bg-warning', border: 'yellow' },
    disabled: { badge: 'bg-gray-400', border: 'gray' }
  };
  pers: Pers = {
    displayCellCount: false // used to toggle row/cell count in badge
  };
  hasRowCount: boolean;
  hasCellCount: boolean;

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {
    this.pers = this.storeService.load('syncButton', this.pers) as Pers;
  }

  ngOnChanges(): void {
    this.hasRowCount = this.rowCount !== undefined;
    this.hasCellCount = this.cellCount !== undefined;
    if (!this.hasCellAndRowCount()) {
      this.pers.displayCellCount = this.hasCellCount;
    }
  }

  onBadgeClick(): void {
    if (!this.hasCellAndRowCount()) {
      return;
    }
    this.pers.displayCellCount = !this.pers.displayCellCount;
    this.storeService.set('syncButton', this.pers);
  }

  hasCellAndRowCount(): boolean {
    return this.hasRowCount && this.hasCellCount;
  }
}
