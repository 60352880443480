import { Component } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IndexDbCacheService } from 'src/app/_core/de-query/index-db-cache.service';
import { SessionService } from 'src/app/_core/session.service';

@Component({
  selector: 'agr-clear-data-modal',
  templateUrl: './clear-data-modal.component.html'
})
export class ClearDataModalComponent {
  form = this.formBuilder.group({
    clearLocalStorage: [false],
    clearIndexedDb: [true]
  });

  get clearLocalStorage(): AbstractControl {
    return this.form.get('clearLocalStorage');
  }
  get clearIndexedDb(): AbstractControl {
    return this.form.get('clearIndexedDb');
  }

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private indexDbCacheService: IndexDbCacheService,
    private sessionService: SessionService
  ) {}

  onSubmit(): void {
    if (this.clearLocalStorage.value) {
      this.sessionService.clear();
    }
    if (this.clearIndexedDb.value) {
      this.indexDbCacheService.clear();
    }
    this.modal.close();
  }
}
