import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNil, isNumber } from 'lodash';
import { Subscription } from 'rxjs';
import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';
import { ItemCardDetailsService } from 'src/app/item-card/details/item-card-details.service';
@Component({
  selector: '[agr-details-lookup-columns]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './editable-lookup-columns.component.html',
  styleUrls: ['../details-columns.component.scss']
})
export class EditableLookupColumnsComponent implements OnInit {
  @Input() column: DetailsColumn;
  @Input() itemId: number;
  @Input() formGroup: FormGroup;
  @Input() bulkUpdate: boolean;
  @Output() readonly changed = new EventEmitter();
  changedLookupSubscription: Subscription;
  useGlobal: boolean = false;

  constructor(private itemCardDetailsService: ItemCardDetailsService) {}

  ngOnInit(): void {}

  getNextLookupValue(): void {
    const indexOfCurrent = this.column.lookupOptions.findIndex((option) => option.id === this.getValueUsed());
    this.column.overwriteValue = this.column.lookupOptions[(indexOfCurrent + 1) % this.column.lookupOptions.length].id;
    this.formGroup.get(this.column.name).markAsDirty();
    if (!this.bulkUpdate) {
      this.update();
    }
  }

  hasOverwriteValue(): boolean {
    if (isNil(this.column.overwriteValue)) {
      return this.bulkUpdate ? this.formGroup.get(this.column.name).dirty : false;
    }
    return true;
  }

  reset(): void {
    this.column.overwriteValue = undefined;
    this.formGroup.get(this.column.name).markAsPristine();
    if (!this.bulkUpdate) {
      this.update();
    }
  }

  getLookupValue(): string {
    let option = undefined;
    if (!this.column.lookupOptions || (this.bulkUpdate && !this.hasOverwriteValue())) {
      return undefined;
    }
    if (this.useGlobal) {
      option = this.column.lookupOptions.find((option) => option.id === this.getBitId(this.column.globalSettingValue));
    } else {
      let lookupId = this.getValueUsed();
      option = this.column.lookupOptions.find((option) => option.id === this.getBitId(lookupId));
    }
    return option ? option.value : undefined;
  }

  toggleGlobal(): void {
    this.useGlobal = !this.useGlobal;
    if (this.useGlobal) {
      this.column.overwriteValue = undefined;
      this.formGroup.get(this.column.name).markAsDirty();
    }
    if (!this.useGlobal) {
      this.formGroup.get(this.column.name).markAsPristine();
    }
  }

  private getValueUsed(): number {
    let value = this.hasOverwriteValue() ? this.column.overwriteValue : this.column.value ?? this.column.globalSettingValue;
    return this.getBitId(value);
  }

  private getBitId(lookupId: number | boolean): number {
    return isNumber(lookupId) ? lookupId : lookupId ? 1 : 0;
  }

  private update(): void {
    this.itemCardDetailsService.updateValues(this.column, this.column.overwriteValue, this.itemId).subscribe(() => {
      this.changed.emit();
    });
  }
}
