<div class="column-label d-flex justify-content-between">
  <span>{{ column.caption | agrTranslate }}</span>
  <ng-template #dateColumnDescription>
    <h6>{{ column.caption | agrTranslate }}</h6>
    {{ column.description | agrTranslate }}
  </ng-template>
  <button class="btn btn-help" [ngbPopover]="dateColumnDescription" popoverTitle="" container="">
    <i class="fa fa-question-circle"></i>
  </button>
  <div *ngIf="bulkUpdate" class="form-check form-switch">
    <input type="checkbox" class="form-check-input" [(ngModel)]="useGlobal" (input)="toggleGlobal()" />
    <label class="w-100 form-check-label"></label>
  </div>
</div>
<div class="column-value-editable" [formGroup]="formGroup" [ngClass]="{ useGlobal: useGlobal }" (click)="d.toggle(); editing = !editing">
  <span [hidden]="editing">
    <span [ngClass]="{ edited: hasOverwriteValue(), 'me-2': !hasOverwriteValue() }"></span>
    {{ getValue() | agrFormat: column.type }}
  </span>

  <input
    [hidden]="!editing"
    class="form-control"
    formControlName="{{ column.name }}"
    placeholder="{{ getValue() | agrFormat: column.type }}"
    type="text"
    (navigate)="(datePickerToday)"
    (dateSelect)="d.close(); onDateSelected($event)"
    (closed)="close()"
    [minDate]="minDate"
    [maxDate]="maxDate"
    ngbDatepicker
    container="body"
    #d="ngbDatepicker" />

  <button class="btn btn-col" *ngIf="column.overwriteValue && !editing" (click)="reset(); $event.stopPropagation()">
    <i class="fa fa-close"></i>
  </button>
  <span [hidden]="!(bulkUpdate && useGlobal)" [formGroup]="formGroup">{{ column.globalSettingValue }}</span>
</div>
