<div id="login-page">
  <div id="login-box" class="login animated u-slow-appear">
    <img src="assets/images/logo-big@2x.png" alt="App logo" />

    <form validate #loginForm="ngForm" (ngSubmit)="login(loginForm.form.value)" [ngClass]="{ submitted: loginForm.submitted }">
      <div id="login-auth365" class="small text-center mb-2" *ngIf="sessionService.auth365Config" translate>
        LOGIN_WITH_YOUR_MICROSOFT_ACCOUNT
      </div>

      <div id="login-user" class="form-group" *ngIf="!sessionService.auth365Config">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-user"></i>
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'EMAIL_OR_USERNAME' | agrTranslate }}"
            required
            [(ngModel)]="user"
            name="user"
            autofocus
            #userField />
        </div>
      </div>

      <div id="login-password" class="form-group" *ngIf="!sessionService.auth365Config">
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-lock"></i>
          </span>
          <input
            type="text"
            class="form-control"
            name="password"
            type="password"
            placeholder="{{ 'PASSWORD' | agrTranslate }}"
            required
            [(ngModel)]="password"
            #passwordField />
        </div>

        <div class="mt-2" *ngIf="loginError">
          <span id="login-invalid-msg" class="text-danger" translate> LOGIN_UNSUCCESSFUL </span>
          <br />
          <span
            id="login-forgot-password-link"
            class="small text-primary text-underline"
            (click)="showLostPasswordMsg = true"
            [hidden]="showLostPasswordMsg"
            translate>
            FORGOT_YOUR_PASSWORD
          </span>
          <div id="login-forgot-password-msg" class="alert alert-info" [hidden]="!showLostPasswordMsg" translate>
            PLEASE_CONTACT_ADMIN_TO_RESET_YOUR_PASSWORD
          </div>
        </div>
      </div>

      <div class="d-grid">
        <button class="btn btn-primary" id="submit" type="submit" translate>LOG_IN</button>
      </div>
    </form>

    <div id="version-label" [class.fw-bold]="showVersionDetails" (click)="showVersionDetails = !showVersionDetails">
      <span class="small">{{ appInfo.project }}</span>
    </div>
    <div id="version-details" *ngIf="showVersionDetails">
      <div id="client-version-number" class="small">
        {{ 'CLIENT' | agrTranslate }}: {{ appInfo.client.version }}
        <span *ngIf="appInfo.client.buildInfo"> - {{ appInfo.client.buildInfo }}</span>
      </div>
      <div id="api-version-number" class="small">
        API: {{ appInfo.api.version }}
        <span *ngIf="!!appInfo.api.buildSuffix"> - {{ appInfo.api.buildSuffix }}</span>
      </div>
      <div id="database-version-number" class="small">{{ 'DATABASE' | agrTranslate }}: {{ appInfo.database.version }}</div>
    </div>

    <span id="copyright-info" class="small">&copy; AGR Dynamics 2012 - {{ date.getFullYear() }}</span>
  </div>
</div>
