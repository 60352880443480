import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Login, LoginDTO } from 'src/app/_core/authorization/login.model';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { UserDTO } from 'src/app/_core/models/user.model';
import { SessionService } from 'src/app/_core/session.service';

export enum TokenValidityState {
  no,
  expired,
  requiresRefresh,
  refreshing,
  valid
}

@Injectable()
export class AuthService {
  private refreshingToken: boolean;

  constructor(private sessionService: SessionService, private httpClient: HttpClient) {}

  // properties

  get isRefreshingToken(): boolean {
    return this.refreshingToken;
  }

  get token(): string {
    return this.sessionService.token;
  }

  // methods

  classicLogin(username: string, password: string): Observable<any> {
    this.sessionService.clearUserData();
    return this.doClassicLogin({ username, password, grant_type: 'password' });
  }

  auth365Login(code: string, returnUrl: string): Observable<any> {
    this.sessionService.clearUserData();
    return this.doAuth365Login(code, returnUrl);
  }

  logout(): void {
    this.sessionService.clearUserData();
  }

  isLoggedIn(): boolean {
    const user = this.sessionService.user;
    if (!user || [TokenValidityState.no, TokenValidityState.expired].includes(this.checkTokenValidity())) {
      this.sessionService.clearUserData();
      return false;
    }
    return true;
  }

  checkTokenValidity(): TokenValidityState {
    const authData = this.sessionService.authData;
    if (!authData || !authData.accessToken) {
      return TokenValidityState.no;
    }
    if (this.refreshingToken) {
      return TokenValidityState.refreshing;
    }
    const isExpired = new Date().getTime() > authData.expires - 5 * 60 * 1000; // 5 Minutes before
    if (isExpired) {
      return TokenValidityState.requiresRefresh;
    }
    return TokenValidityState.valid;
  }

  refreshToken(): Observable<boolean> {
    this.refreshingToken = true;
    if (this.sessionService.auth365Config) {
      return this.auth365Refresh();
    }
    if (!this.sessionService.authData) {
      this.sessionService.authData = new Login();
    }
    return this.doClassicLogin({ grant_type: 'refresh_token', refresh_token: this.sessionService.authData.refreshToken });
  }

  /**
   * Returns true if provided feature/features is available for user.
   */
  hasFeature(features: string[] | string): boolean {
    if (!features || (features && features.length === 0)) {
      return true;
    } // If no feature-specified everybody has access
    const featureArr = typeof features === 'string' ? features.split(',') : features;
    return featureArr.find((feature) => this.hasSingleFeature(feature)) !== undefined;
  }

  getLoggedInUser(): Observable<UserDTO> {
    return this.httpClient.get(`${API_ROOT}/users/logged-in-user`);
  }

  // private

  private jsonToUrlEncoded(jsonString: {}): string {
    return Object.keys(jsonString)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(jsonString[key])}`;
      })
      .join('&');
  }

  private doClassicLogin(body: any): Observable<boolean> {
    const bodyUrlEnc = this.jsonToUrlEncoded(body);
    const options = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } };
    return this.httpClient.post(`${API_ROOT}/login`, bodyUrlEnc, options).pipe(
      mergeMap((dto: LoginDTO) => {
        this.loginResponse(new Login(dto));
        return this.getLoggedInUserDetails();
      }),
      catchError((error) => {
        this.refreshingToken = false;
        return throwError(error);
      })
    );
  }

  private doAuth365Login(code: string, returnUrl: string): Observable<any> {
    return this.httpClient.post(`${API_ROOT}/auth/login-365`, { code, returnUrl }).pipe(
      mergeMap((dto: LoginDTO) => {
        this.loginResponse(new Login(dto));
        this.sessionService.user = { auth365: true };
        return this.getLoggedInUserDetails();
      })
    );
  }

  private getLoggedInUserDetails(): Observable<boolean> {
    return this.getLoggedInUser().pipe(
      mergeMap((userDto) => {
        const isAuth365 = !!this.sessionService.user?.auth365; // Read value before sessionService.user is overwritten
        this.sessionService.user = { ...userDto, auth365: isAuth365 };
        this.sessionService.currentFeatures = userDto.featuresStringList;
        return of(true);
      })
    );
  }

  private auth365Refresh(): Observable<boolean> {
    const body = {
      code: this.sessionService.authData.refreshToken,
      returnUrl: ''
    };
    return this.httpClient.post(`${API_ROOT}/auth/refresh-365`, body).pipe(
      mergeMap((dto: LoginDTO) => {
        this.loginResponse(new Login(dto));
        return of(true);
      }),
      catchError((error) => {
        this.refreshingToken = false;
        return throwError(error);
      })
    );
  }

  private hasSingleFeature(feature: string): boolean {
    const features = this.sessionService.currentFeatures;
    if (!features) {
      return false;
    }

    return features.indexOf(feature) > -1;
  }

  private loginResponse(login: Login): void {
    this.refreshingToken = false;
    this.sessionService.token = login.accessToken;
    this.sessionService.authData = login;
  }
}
