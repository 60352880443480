import { DeQueryFilter } from 'src/app/_core/de-query/model/de-query.model';
import { WorkspaceFilter, WorkspaceFilterType } from 'src/app/workspaces/details/filters/models/workspace-filter.model';

export class WorkspaceAdvancedFilter extends WorkspaceFilter {
  caption = 'ADVANCED_FILTER';
  name = 'ADVANCED_FILTER';
  idColumnName = 'ADVANCED_FILTER';
  groupCaption = 'Advanced';
  columns: string[] = [];
  value: string;
  filterType: WorkspaceFilterType = 'WorkspaceAdvancedFilter';

  constructor() {
    super();
  }

  toDeQueryFilter(): DeQueryFilter {
    return {
      column: {
        name: this.idColumnName,
        list: this.columns
      },
      values: [this.value],
      operator: 'ADVANCED',
      ui: {
        column: {
          name: this.name
        },
        selectedSubtypeId: undefined,
        fixed: this.locked,
        class: this.filterType
      }
    };
  }

  isFilterEmpty(): boolean {
    return !this.value || this.value.trim() === '';
  }

  updateFromDeQueryFilter(query: DeQueryFilter): void {
    this.locked = !!query.ui.fixed;
    this.columns = query.column.list ? query.column.list : [];
    this.value = query.values && query.values[0] ? `${query.values[0]}` : '';
  }
}
