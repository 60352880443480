import { ColDef } from '@ag-grid-enterprise/all-modules';

export interface ConfigColumnFormatDTO {
  caption?: string;
  format?: string;
  align?: 'left' | 'center' | 'right';
  agGridColumnProperties?: ColDef;
}

export class ConfigColumnFormat {
  caption?: string;
  format?: string;
  align?: 'left' | 'center' | 'right';
  agGridColumnProperties?: ColDef;

  constructor(dto?: ConfigColumnFormatDTO) {
    if (!dto) {
      return;
    }
    this.caption = dto.caption;
    this.format = dto.format;
    this.align = dto.align;
    this.agGridColumnProperties = dto.agGridColumnProperties;
  }
}
