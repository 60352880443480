/* eslint-disable max-classes-per-file */
import { ApplicationRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as draggablePoints from 'highcharts/modules/draggable-points';
// @ts-ignore
draggablePoints(Highcharts);
import { isNil } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { StoreService } from 'src/app/_core/store.service';
import { ItemCardChartService } from 'src/app/item-card/chart/item-card-chart.service';
import {
  editableColumnSeriesOptions,
  emptyChartOptions,
  resetColumnSeriesOptions
} from 'src/app/item-card/chart/models/chart-options.model';
import { ItemDataPoint } from 'src/app/item-card/grid/models/item-data-point.model';
import { ItemCardService } from 'src/app/item-card/item-card.service';
import { ChartDataParams } from 'src/app/item-card/models/chart-data-params.model';
import { ChartElementTypeDTO } from 'src/app/item-card/models/item-card-dto.model';

interface ChartStateDTO {
  serieVisibility: [string, boolean][]; // Key value array
  legendEnabled: boolean;
  showNegative: boolean;
}
class ChartState {
  serieVisibility: Map<string, boolean>;
  legendEnabled: boolean;
  showNegative: boolean;
  constructor(chartState: ChartStateDTO) {
    Object.assign(this, chartState);
    this.serieVisibility = new Map(chartState.serieVisibility);
  }
  toDto(): ChartStateDTO {
    return { ...this, ...{ serieVisibility: Array.from(this.serieVisibility) } };
  }
}

interface DoubleClicker {
  clickedOnce: boolean;
  timer: NodeJS.Timer;
  timeBetweenClicks: number;
}

@Component({
  selector: 'agr-item-card-chart',
  templateUrl: './item-card-chart.component.html',
  styleUrls: ['./item-card-chart.component.scss']
})
export class ItemCardChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chartContainer', { static: true }) chartContainerEl: ElementRef;
  @Input() itemId?: number;
  @Input() orderId?: number;
  @Input() chartParams: ChartDataParams;
  @Input() refreshItemEvent: Observable<void>;
  @Input() refreshDetailsColumnEvent: Observable<string>;
  refreshItemSubscription: Subscription;
  refreshDetailsColumnSubscription: Subscription;

  chart: Highcharts.Chart;
  elementTypes: Map<string, ChartElementTypeDTO> = new Map();
  editMode = false;
  stateKey = 'item-card.chart';
  state = new ChartState({
    serieVisibility: [],
    legendEnabled: true,
    showNegative: false
  });
  doubleClicker: DoubleClicker; // Used to determine single or double click on chart series
  isEmpty = false;
  get isLoading(): boolean {
    return this.isLoadingChart && !!this.itemId;
  }
  private isLoadingChart = true;

  constructor(
    private itemCardChartService: ItemCardChartService,
    private itemCardService: ItemCardService,
    private storeService: StoreService,
    private applicationRef: ApplicationRef,
    private globalEventService: GlobalEventsService
  ) {}

  ngOnInit(): void {
    this.loadState();
    this.refreshItemSubscription = this.refreshItemEvent.subscribe(() => this.updateChart());
    this.refreshDetailsColumnSubscription = this.refreshDetailsColumnEvent.subscribe(() => {
      this.updateChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId || changes.orderId || changes.chartParams) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    this.refreshItemSubscription.unsubscribe();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.editMode ? this.startEditingChart() : this.stopEditingChart();
  }

  toggleLegend(): void {
    this.state.legendEnabled = !this.state.legendEnabled;
    this.chart.options.legend.enabled = this.state.legendEnabled;
    this.chart.legend.update(this.chart.options.legend);
    this.saveState();
  }

  toggleNegative(): void {
    this.state.showNegative = !this.state.showNegative;
    this.applyNegativeValuesVisibility();
    this.saveState();
  }

  private loadState(): void {
    const stateDto = this.storeService.load(this.stateKey, this.state.toDto()) as ChartStateDTO;
    this.state = new ChartState(stateDto);
  }

  private saveState(): void {
    this.storeService.set(this.stateKey, this.state.toDto());
  }

  private updateChart(): void {
    if (!isNil(this.itemId)) {
      this.isEmpty = false;
      this.getItemChart();
    } else {
      this.createChart(emptyChartOptions);
      this.isEmpty = true;
    }
  }

  private getItemChart(): void {
    this.isLoadingChart = true;
    this.itemCardChartService.getItemChart(this.itemId, this.chartParams, this.orderId).subscribe((chart) => {
      this.isLoadingChart = false;
      this.elementTypes = chart.elementTypes;
      this.createChart(chart.options);
      this.applicationRef.tick(); // Refresh DOM when page is in background
    });
  }

  private onChartInstance(chart: Highcharts.Chart): void {
    this.chart = chart;
    this.applyChartSeriesVisibility();
    this.applyNegativeValuesVisibility();
    if (this.editMode) {
      this.startEditingChart();
    }
    this.chart.reflow();
  }

  private createChart(chartOptions: Highcharts.Options): void {
    let options = chartOptions;
    options.legend.enabled = this.state.legendEnabled;
    options = Highcharts.merge(chartOptions, this.addChartEventCallbacks());
    this.chart = new Highcharts.Chart(this.chartContainerEl.nativeElement, options, this.onChartInstance.bind(this));
  }

  private applyChartSeriesVisibility(): void {
    if (!this.chart.options.series) {
      return;
    }
    this.chart.options.series.map((serie: Highcharts.SeriesColumnOptions) => {
      if (!this.state.serieVisibility.has(serie.name)) {
        this.state.serieVisibility.set(serie.name, !isNil(serie.visible) ? serie.visible : true);
      }
      serie.visible = this.state.serieVisibility.get(serie.name);
    });
    this.chart.update(this.chart.options);
  }

  private applyNegativeValuesVisibility(): void {
    (this.chart.options.yAxis as Highcharts.YAxisOptions).min = this.state.showNegative ? undefined : 0;
    this.chart.yAxis.map((yAxis) => yAxis.update(this.chart.options.yAxis as Highcharts.YAxisOptions));
  }

  private addChartEventCallbacks(): Highcharts.Options {
    return { plotOptions: { series: { events: { hide: this.onLegendItemToggle.bind(this), show: this.onLegendItemToggle.bind(this) } } } };
  }

  private onLegendItemToggle(): void {
    this.chart.legend.allItems.map((serie: Highcharts.Series) => {
      this.state.serieVisibility.set(serie.name, serie.visible);
    });
    this.saveState();
  }

  private startEditingChart(): void {
    if (!this.getEditableSeries()) {
      return;
    }
    this.setChartEditMode();
    this.getEditableSeries().forEach((serie) => {
      (this.chart.get(serie) as Highcharts.Series).update(editableColumnSeriesOptions);
      (this.chart.get(serie) as Highcharts.Series).update(this.addEditableSeriesEventCallbacks());
    });
  }

  private stopEditingChart(): void {
    if (!this.getEditableSeries()) {
      return;
    }
    this.resetChartEditMode();
    this.getEditableSeries().forEach((serie) => (this.chart.get(serie) as Highcharts.Series).update(resetColumnSeriesOptions));
  }

  private setChartEditMode(): void {
    this.chart.series.map((serie) => (serie.options.enableMouseTracking = false));
    const mouseTrackingSeries = Array.from(new Set(['sale', 'original_sale', 'forecast', ...this.getEditableSeries()]));
    mouseTrackingSeries.map((serieId) => {
      if (this.chart.get(serieId)) {
        (this.chart.get(serieId) as Highcharts.Series).options.enableMouseTracking = true;
      }
    });
    this.chart.zoomOut(); // Zoom out and then disable zoom
    this.chart.options.chart.zoomType = undefined;
  }

  private resetChartEditMode(): void {
    this.chart.series.map((serie) => (serie.options.enableMouseTracking = true));
    this.chart.options.chart.zoomType = 'x';
  }

  private addEditableSeriesEventCallbacks(): Highcharts.SeriesColumnOptions {
    return { type: 'column', point: { events: { click: this.determineClick.bind(this), drop: this.onPointDrop.bind(this) } } };
  }

  private determineClick(event: Highcharts.SeriesPointClickEventObject): void {
    if (!this.doubleClicker) {
      this.resetDoubleClick();
    }
    if (this.doubleClicker.clickedOnce && this.doubleClicker.timer) {
      this.resetDoubleClick();
      this.onPointDoubleClick(event);
    } else {
      this.doubleClicker.clickedOnce = true;
      this.doubleClicker.timer = setTimeout(() => {
        // if (this.doubleClicker.clickedOnce) { // Clicked once }
        this.resetDoubleClick();
      }, this.doubleClicker.timeBetweenClicks);
    }
  }

  private resetDoubleClick(): void {
    this.doubleClicker = { clickedOnce: false, timer: undefined, timeBetweenClicks: 250 };
  }

  private onPointDoubleClick(event: Highcharts.SeriesPointClickEventObject): void {
    this.resetSale(event.point);
  }
  private onPointDrop(event: Highcharts.SeriesPointDropEventObject): void {
    this.updateSerieData(event.target);
  }

  private resetSale(point: Highcharts.Point): void {
    this.chart.series.filter((serie) => serie.options.id === 'original_sale').map((serie) => serie.setVisible(true));
    const originalSale = this.getOriginalSaleAtPoint(point);
    point.update({ y: originalSale ? originalSale.y : 0 });
    const saleUpdateDto = ItemDataPoint.highchartsPointToUpdateDto(point);
    this.itemCardService.updateSerieData(this.itemId, this.chartParams, 1, saleUpdateDto).subscribe(() => {
      this.globalEventService.refreshOtherItemCards(this.itemId);
      this.refreshChartSeries();
    });
  }

  private updateSerieData(point: Highcharts.Point): void {
    const serieId = point.series.options.id;
    const chartElementId = this.elementTypes.get(serieId).id;
    const dataUpdateDTO = ItemDataPoint.highchartsPointToUpdateDto(point);
    this.itemCardService.updateSerieData(this.itemId, this.chartParams, chartElementId, dataUpdateDTO).subscribe(() => {
      this.globalEventService.refreshOtherItemCards(this.itemId);
      this.refreshChartSeries();
    });
  }

  private getOriginalSaleAtPoint(point: Highcharts.Point): Highcharts.Point {
    const originalSaleSeries = this.chart.get('original_sale') as Highcharts.Series;
    return originalSaleSeries ? originalSaleSeries.data.find((originalSale) => originalSale.x === point.x) : undefined;
  }

  private refreshChartSeries(): void {
    this.itemCardChartService.getItemChartSeries(this.itemId, this.chartParams, this.orderId).subscribe((series) => {
      series.map((serie) => {
        const currentSerie = this.chart.get(serie.id);
        if (currentSerie) {
          (currentSerie as Highcharts.Series).setData(serie.data);
        }
      });
    });
  }

  private getEditableSeries(): string[] {
    return Array.from(this.elementTypes.values())
      .filter((elementType) => elementType.editable)
      .map((elementType) => elementType.name);
  }
}
