/* eslint-disable max-len */
import { formatDate } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';

import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQueryFilter, DeQueryFilterOperator } from 'src/app/_core/de-query/model/de-query.model';
import { TimezoneService } from 'src/app/_core/timezone.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { WorkspaceFilter, WorkspaceFilterType } from 'src/app/workspaces/details/filters/models/workspace-filter.model';

type PeriodType = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
const periodTypes: PeriodType[] = ['DAY', 'WEEK', 'MONTH', 'YEAR'];
type FilterType = 'DATE_RANGE' | 'GREATER_THAN' | 'LESS_THAN';
const filterTypes: FilterType[] = ['DATE_RANGE', 'GREATER_THAN', 'LESS_THAN'];
const emptyPeriodDict: { [key in PeriodType]: undefined } = { DAY: undefined, WEEK: undefined, MONTH: undefined, YEAR: undefined };

export interface WorkspaceDateFilterValue {
  caption: string;
  date: Date;
}

export class WorkspaceDateFilter extends WorkspaceFilter {
  isRelative = true;
  period: PeriodType = 'DAY';
  type: FilterType = 'DATE_RANGE';
  filterType: WorkspaceFilterType = 'WorkspaceDateFilter';
  dateTo: { [key in PeriodType]: Date } = { ...emptyPeriodDict };
  dateFrom: { [key in PeriodType]: Date } = { ...emptyPeriodDict };
  dateFromRelative: { [key in PeriodType]: number } = { ...emptyPeriodDict };
  dateToRelative: { [key in PeriodType]: number } = { ...emptyPeriodDict };

  maxNgbDateFrom?: NgbDateStruct | undefined;
  minNgbDateTo?: NgbDateStruct | undefined;

  // Used when toggling between filter type to restore values
  lastKnownDateFrom?: { [key in PeriodType]: Date } = { ...emptyPeriodDict };
  lastKnownDateTo?: { [key in PeriodType]: Date } = { ...emptyPeriodDict };
  lastKnownDateFromRelative: { [key in PeriodType]: number } = { ...emptyPeriodDict };
  lastKnownDateToRelative: { [key in PeriodType]: number } = { ...emptyPeriodDict };

  // WEEK/MONTH/YEAR - ngSelect values, Date value and label for date
  ngSelectDateFrom: WorkspaceDateFilterValue; // The selected value from @dateFromPeriodValues
  ngSelectDateTo: WorkspaceDateFilterValue; // The selected value from @dateToPeriodValues
  dateFromPeriodValues: WorkspaceDateFilterValue[] = []; // This is changed when dateTo changes and vice versa to limit and avoid overlap
  dateToPeriodValues: WorkspaceDateFilterValue[] = [];
  periodValues: { [key in PeriodType]: WorkspaceDateFilterValue[] } = { DAY: [], WEEK: [], MONTH: [], YEAR: [] };

  constructor(contextLine?: ContextLine) {
    super(contextLine);
    if (!contextLine) {
      return;
    }
    this.setupPeriodValues();
  }

  togglePeriod(): void {
    const nextPeriodIndex = (periodTypes.findIndex((period) => period === this.period) + 1) % periodTypes.length;
    this.period = periodTypes[nextPeriodIndex];
  }

  toggleType(): void {
    const nextTypeIndex = (filterTypes.findIndex((type) => type === this.type) + 1) % filterTypes.length;
    this.type = filterTypes[nextTypeIndex];
    // If statements below rely on the cycle order when toggling between types
    if (this.type === 'GREATER_THAN') {
      periodTypes.forEach((period) => {
        this.lastKnownDateTo[period] = this.dateTo[period];
        this.dateTo[period] = undefined;
        this.lastKnownDateToRelative[period] = this.dateToRelative[period];
        this.dateToRelative[period] = undefined;
      });
    }
    if (this.type === 'LESS_THAN') {
      periodTypes.forEach((period) => {
        this.dateTo[period] = this.lastKnownDateTo[period];
        this.dateToRelative[period] = this.lastKnownDateToRelative[period];
        this.lastKnownDateFrom[period] = this.dateFrom[period];
        this.dateFrom[period] = undefined;
        this.lastKnownDateFromRelative[period] = this.dateFromRelative[period];
        this.dateFromRelative[period] = undefined;
      });
    }
    if (this.type === 'DATE_RANGE') {
      periodTypes.forEach((period) => {
        this.dateFrom[period] = this.lastKnownDateFrom[period];
        this.dateFromRelative[period] = this.lastKnownDateFromRelative[period];
      });
    }
  }

  /**
   * Validates values and fixes them if necessary.
   */
  validateRelative(): void {
    if (!isNil(this.dateFromRelative[this.period])) {
      const isValid = !this.dateToRelative[this.period] || this.dateFromRelative[this.period] <= this.dateToRelative[this.period];
      this.dateFromRelative[this.period] = isValid ? this.dateFromRelative[this.period] : this.dateToRelative[this.period];
    }
    if (!isNil(this.dateToRelative[this.period])) {
      const isValid = !this.dateFromRelative[this.period] || this.dateToRelative[this.period] >= this.dateFromRelative[this.period];
      this.dateToRelative[this.period] = isValid ? this.dateToRelative[this.period] : this.dateFromRelative[this.period];
    }
  }

  /**
   * Does any updates that has to happen on changes
   */
  update(): void {
    this.updateMinMaxDate();
    if (this.isRelative) {
      this.syncFixed();
      this.syncRelative();
    } else {
      this.syncRelative();
      this.syncFixed();
    }
  }

  /**
   * Use to set and update min max values after model change or data received from database/local storage
   */
  updateMinMaxDate(): void {
    // NgbDate for 'DAY' datepicker
    this.updateMinMaxNgbDate();
    // Available Ng-Select options in DateFrom and DateTo
    this.updateDateFromPeriodValues();
    this.updateDateToPeriodValues();
  }

  updateDateFrom(date: Date): void {
    if (date === undefined) {
      return;
    }
    this.dateFrom[this.period] = !this.dateTo[this.period] || date <= this.dateTo[this.period] ? date : this.dateFrom[this.period];
    this.ngSelectDateFrom = this.dateFrom[this.period]
      ? this.periodValues[this.period].find((period) => period.date.getTime() === this.dateFrom[this.period].getTime())
      : undefined;
  }

  updateDateTo(date: Date): void {
    if (date === undefined) {
      return;
    }
    this.dateTo[this.period] = !this.dateFrom[this.period] || date > this.dateFrom[this.period] ? date : this.dateTo[this.period];
    this.ngSelectDateTo = this.dateTo[this.period]
      ? this.periodValues[this.period].find((period) => period.date.getTime() === this.dateTo[this.period].getTime())
      : undefined;
  }

  // eslint-disable-next-line max-lines-per-function
  toDeQueryFilter(): DeQueryFilter {
    const relativePeriods: { [key in PeriodType]: DeQueryFilterOperator } = {
      DAY: 'RELDAYS',
      WEEK: 'RELWEEKS',
      MONTH: 'RELMONTHS',
      YEAR: 'RELYEARS'
    };
    return {
      column: {
        name: this.idColumnName
      },
      values: this.isRelative
        ? getRelativeValues(this.dateFromRelative, this.dateToRelative, this.period, this.type)
        : [toDateString(this.dateFrom[this.period]), toDateString(this.dateTo[this.period])].filter((value) => !!value),
      operator: this.isRelative
        ? relativePeriods[this.period]
        : this.type === 'DATE_RANGE'
        ? 'BETWEEN'
        : this.type === 'GREATER_THAN'
        ? '>='
        : '<=',
      ui: {
        column: {
          name: this.name
        },
        selectedSubtypeId: this.name,
        fixed: this.locked,
        datePeriod: this.period,
        dateType: this.type,
        class: this.filterType
      }
    };

    function getRelativeValues(
      dateFromRelative: { [key in PeriodType]: number },
      dateToRelative: { [key in PeriodType]: number },
      period: PeriodType,
      filterType: FilterType
    ): number[] {
      const infiniteRelativeValues: { [key in PeriodType]: number } = { DAY: 40000, WEEK: 6000, MONTH: 1200, YEAR: 100 };
      const values = [dateFromRelative[period], dateToRelative[period]];
      if (filterType === 'GREATER_THAN') {
        values[1] = infiniteRelativeValues[period];
      }
      if (filterType === 'LESS_THAN') {
        values[0] = -infiniteRelativeValues[period];
      }
      return values;
    }

    function toDateString(date: Date | undefined): string | undefined {
      return date instanceof Date ? TimezoneService.dateToString(date).replace(/-/g, '') : undefined;
    }
  }

  // eslint-disable-next-line complexity
  updateFromDeQueryFilter(filter: DeQueryFilter): void {
    this.isRelative = filter.operator === 'RELPERIODS';
    this.locked = !!filter.ui.fixed;
    this.period = periodTypes.includes(filter.ui.datePeriod as PeriodType) ? (filter.ui.datePeriod as PeriodType) : this.period;
    this.type = filterTypes.includes(filter.ui.dateType as FilterType) ? (filter.ui.dateType as FilterType) : this.type;
    if (this.isRelative) {
      this.dateFromRelative[this.period] = +filter.values[0];
      this.dateToRelative[this.period] = +filter.values[1];
    } else {
      if (filter.values[0]) {
        const dateString = filter.values[0].toString(); // DeQueryFilter value received as string 'YYYYMMDD'
        const date = new Date(+dateString.substr(0, 4), +dateString.substr(4, 2) - 1, +dateString.substr(6, 2));
        filter.operator !== '<=' && filter.operator !== '<' ? this.updateDateFrom(date) : this.updateDateTo(date);
      }
      if (filter.values[1] && filter.operator === 'BETWEEN') {
        const dateTo = filter.values[1].toString();
        this.updateDateTo(new Date(+dateTo.substr(0, 4), +dateTo.substr(4, 2) - 1, +dateTo.substr(6, 2)));
      }
    }
    this.update();
  }

  /**
   * Update relative based on fixed values after fixed changed
   */
  private syncRelative(): void {
    if (this.period === 'DAY') {
      this.dateFromRelative[this.period] = this.dateFrom[this.period]
        ? Math.ceil((this.dateFrom[this.period].getTime() - new Date().getTime()) / (1000 * 3600 * 24))
        : undefined;
      this.dateToRelative[this.period] = this.dateTo[this.period]
        ? Math.ceil((this.dateTo[this.period].getTime() - new Date().getTime()) / (1000 * 3600 * 24))
        : undefined;
      return;
    }
    if (this.periodValues[this.period] === undefined || this.periodValues[this.period].length === 0) {
      return;
    }
    const zeroIndex = Math.floor(this.periodValues[this.period].length / 2);
    this.dateFromRelative[this.period] = this.dateFrom[this.period]
      ? this.periodValues[this.period].findIndex((period) => period.date.getTime() === this.dateFrom[this.period].getTime()) - zeroIndex
      : this.dateFromRelative[this.period];
    this.dateToRelative[this.period] = this.dateTo[this.period]
      ? this.periodValues[this.period].findIndex((period) => period.date.getTime() === this.dateTo[this.period].getTime()) - zeroIndex
      : this.dateToRelative[this.period];
  }

  /**
   * Update fixed based on relative values after relative changed
   */
  private syncFixed(): void {
    if (this.period === 'DAY') {
      this.dateFrom[this.period] =
        this.dateFromRelative[this.period] !== undefined
          ? new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + this.dateFromRelative[this.period]))
          : undefined;
      this.dateTo[this.period] =
        this.dateToRelative[this.period] !== undefined
          ? new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + this.dateToRelative[this.period]))
          : undefined;
      return;
    }
    const zeroIndex = Math.floor(this.periodValues[this.period].length / 2);
    const dateFromPeriodValue = this.periodValues[this.period][zeroIndex + this.dateFromRelative[this.period]];
    this.dateFrom[this.period] = dateFromPeriodValue ? dateFromPeriodValue.date : this.dateFrom[this.period];
    this.ngSelectDateFrom = dateFromPeriodValue;
    const dateToPeriodValue = this.periodValues[this.period][zeroIndex + this.dateToRelative[this.period]];
    this.dateTo[this.period] = dateToPeriodValue ? dateToPeriodValue.date : this.dateTo[this.period];
    this.ngSelectDateTo = dateToPeriodValue;
  }

  private updateMinMaxNgbDate(): void {
    this.maxNgbDateFrom = this.dateTo[this.period]
      ? {
          year: this.dateTo[this.period].getFullYear(),
          month: this.dateTo[this.period].getMonth() + 1,
          day: this.dateTo[this.period].getDate()
        }
      : undefined;
    this.minNgbDateTo = this.dateFrom[this.period]
      ? {
          year: this.dateFrom[this.period].getFullYear(),
          month: this.dateFrom[this.period].getMonth() + 1,
          day: this.dateFrom[this.period].getDate()
        }
      : undefined;
  }

  private updateDateFromPeriodValues(): void {
    this.dateFromPeriodValues = this.dateTo[this.period]
      ? this.periodValues[this.period].filter((date) => date.date < this.dateTo[this.period])
      : this.periodValues[this.period];
  }

  private updateDateToPeriodValues(): void {
    this.dateToPeriodValues = this.dateFrom[this.period]
      ? this.periodValues[this.period].filter((date) => date.date > this.dateFrom[this.period])
      : this.periodValues[this.period];
  }

  private setupPeriodValues(): void {
    // Start and end date is a 20 year range. -10 +10
    const startDate = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setFullYear(new Date().getFullYear() - 10));
    const endDate = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setFullYear(new Date().getFullYear() + 10));

    let startMonday = new Date(startDate.setDate(startDate.getDate() - startDate.getDate() + 1));
    const weekShort = TranslationsService.get('WEEK_SHORT').toUpperCase();
    for (; startMonday < endDate; startMonday = new Date(new Date(startMonday).setDate(startMonday.getDate() + 7))) {
      this.periodValues.WEEK.push({
        caption: `${weekShort}${formatDate(new Date(startMonday), 'ww - d MMM y', 'en')}`,
        date: new Date(startMonday)
      });
    }

    let startMonth = new Date(startDate.setDate(1));
    for (; startMonth < endDate; startMonth = new Date(new Date(startMonth).setMonth(startMonth.getMonth() + 1))) {
      this.periodValues.MONTH.push({ caption: `${formatDate(new Date(startMonth), 'MMM y', 'en')}`, date: new Date(startMonth) });
    }

    let startYear = new Date(new Date(startDate.setMonth(0)).setDate(1));
    for (; startYear < endDate; startYear = new Date(new Date(startYear).setFullYear(startYear.getFullYear() + 1))) {
      this.periodValues.YEAR.push({ caption: `${formatDate(new Date(startYear), 'yyyy', 'en')}`, date: new Date(startYear) });
    }
  }
}
