<div class="container-fluid overflow-auto h-100 position-absolute">
  <!-- Chart toolbar-->
  <div class="chart-toolbar">
    <button
      id="edit-chart-btn"
      class="btn agr-toolbar-btn"
      ngbTooltip="{{ 'EDIT_SALES_HISTORY' | agrTranslate }}"
      placement="left"
      [class.active]="editMode"
      (click)="toggleEditMode()"
      *ngIf="'itemCard.mod' | agrFeature">
      <i class="fa fa-pencil"></i>
    </button>
    <button
      id="legend-chart-btn"
      class="btn agr-toolbar-btn"
      ngbTooltip="{{ 'LEGEND' | agrTranslate }}"
      placement="left"
      [class.active]="state.legendEnabled"
      (click)="toggleLegend()">
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <button
      id="no-negative-chart-btn"
      class="btn agr-toolbar-btn"
      ngbTooltip="{{ 'SHOW_NEGATIVE_VALUES' | agrTranslate }}"
      placement="left"
      [class.active]="state.showNegative"
      (click)="toggleNegative()">
      <i class="fa fa-minus-circle"></i>
    </button>
  </div>

  <agr-loading-dots *ngIf="isLoading" [color]="'yellow'" [size]="'big'" class="position-absolute h-100 w-100" style="z-index: 100">
  </agr-loading-dots>
  <div #chartContainer class="h-100" [ngStyle]="{ opacity: isLoading ? '0.4' : '1.0' }"></div>
</div>

<agr-no-data-to-display *ngIf="isEmpty"></agr-no-data-to-display>
