export class ItemUndeliveredOrder {
  id: number;
  itemId: number;
  purchaseReference: string;
  quantity: number; // Units
  estimatedDeliveryDate: Date;
  expiryDate: Date;
  erpUnits: number;
  connectedUndeliveredUnits: number;

  constructor(order?: ItemUndeliveredOrderDTO) {
    if (!order) {
      return;
    }
    this.id = order.id;
    this.itemId = order.itemId;
    this.purchaseReference = order.referenceNo;
    this.quantity = order.units;
    this.estimatedDeliveryDate = order.estimatedDeliveryDate ? new Date(order.estimatedDeliveryDate) : undefined;
    this.expiryDate = order.expireDate ? new Date(order.expireDate) : undefined;
    this.erpUnits = order.erpUnits;
    this.connectedUndeliveredUnits = order.connectedUndeliveredUnits;
  }
}

export interface ItemUndeliveredOrderDTO {
  id: number;
  itemId: number;
  estimatedDeliveryDate: string;
  referenceNo: string;
  units: number;
  expireDate: string; // Date
  erpUnits: number;
  connectedUndeliveredUnits: number;
}
