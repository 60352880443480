import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, uniqBy } from 'lodash';
import { Observable } from 'rxjs';

import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { BaseConfig } from 'src/app/_core/models/base-config.model';
import { Setting, SettingDTO } from 'src/app/_core/settings/settings.model';
import { TranslationsService } from 'src/app/_core/translations.service';
import { SyncBtnState } from 'src/app/_shared/sync-button/sync-button.component';
import { MainNavigation, SettingsNavigation } from 'src/app/nav/nav.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settings: Setting[] = [];

  constructor(private httpClient: HttpClient) {}

  /**
   * Initialized at app startup from core.module.ts
   */
  async initialize(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(`${API_ROOT}/settings/base`).subscribe(
        (baseConfig: BaseConfig) => {
          this.settings = baseConfig.settings
            .map((setting: SettingDTO) => new Setting(setting))
            .sort((a, b) => (a.key < b.key ? -1 : 1))
            .sort((a, b) => (a.group < b.group ? -1 : 1))
            .filter((setting) => !!setting.group);
          resolve(baseConfig);
        },
        (err) => {
          window.alert('AGR Error: Failed to load settings from API');
          console.error('AGR: Failed to load settings from API');
          reject(err);
        }
      );
    });
  }

  /**
   * Get setting object by name or all settings.
   */
  getSetting(key: string): Setting {
    return this.settings.find((setting) => setting.key === key);
  }

  /**
   * Get all settings.
   */
  getAll(): Setting[] {
    return this.settings;
  }

  /**
   * Get all settings, filtered by caption and/or group.
   */
  getFiltered(searchString: string = ''): Setting[] {
    if (searchString === '') {
      return this.settings;
    }
    return this.settings.filter((setting) => {
      const caption = TranslationsService.get(setting.caption);
      const group = TranslationsService.get(setting.group);
      const key = TranslationsService.get(setting.key);
      return (
        caption.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
        group.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
        key.indexOf(searchString.toLowerCase()) > -1
      );
    });
  }

  /**
   * Get setting value by setting name.
   */
  getValue(name: string): any {
    const setting = this.getSetting(name);
    return setting ? setting.value : undefined;
  }

  /**
   * Update setting in database.
   */
  update(setting: Setting): Observable<any> {
    const settingDTO = Setting.toDto(setting);
    return this.httpClient.put(`${API_ROOT}/settings/${setting.key}`, settingDTO);
  }

  /**
   * Get groups from settings.
   */
  getGroupNames(): string[] {
    if (!this.settings) {
      return [];
    }
    const groupNames = this.settings.map((setting) => setting.group);
    return uniqBy(groupNames, (name) => name);
  }

  /**
   * Get groups from settings, filtered by setting caption.
   */
  getGroupNamesFiltered(caption: string): string[] {
    if (!this.settings) {
      return [];
    }
    const groupNames = this.getFiltered(caption).map((setting) => setting.group);
    return uniqBy(groupNames, (name) => name);
  }

  /**
   * Use upper and lower cell count limits from settings to get sync button state.
   */
  getSyncBtnState(cellCount?: number): SyncBtnState {
    const lowerLimit = this.getValue('sync_btn_lower_limit');
    const upperLimit = this.getValue('sync_btn_upper_limit');
    if (cellCount === undefined || cellCount < 0) {
      return 'disabled';
    }
    if (!cellCount || cellCount < lowerLimit) {
      return 'autosync';
    }
    if (cellCount >= upperLimit) {
      return 'warning';
    }
    return 'primary';
  }

  /**
   * Update Inventory Insights
   */
  runInsights(): Observable<{}> {
    return this.httpClient.post(`${API_ROOT}/settings/run/insights`, {});
  }

  // Specific settings and their functions

  // app
  accentedSort(): boolean {
    return this.getValue('accented_sort');
  }
  onlineCheck(): boolean {
    return this.getValue('online_check');
  }
  serverTimezone(): string {
    return this.getValue('server_timezone');
  }
  isEnterprise(): boolean {
    return this.getValue('agr_system') === 'enterprise';
  }

  // erp setup
  startInSetup(): boolean {
    return this.getValue('start_in_setup');
  }
  disableRetailPages(): boolean {
    return this.getValue('disable_retail_pages');
  }

  // Language and Region settings (locale)
  currency(): string {
    return this.getValue('currency');
  }
  currencyLocale(): string {
    return this.getValue('currency_locale');
  }
  locale(): string {
    return this.getValue('locale');
  }
  localeDateFormat(): string {
    return this.getValue('locale_date_format');
  }
  localeDateTimeFormat(): string {
    return this.getValue('locale_date_time_format');
  }
  localeTimeFormat(): string {
    return this.getValue('locale_time_format');
  }
  localeDurationFormat(): string {
    return this.getValue('locale_duration_format');
  }
  maxNumberFractionDigits(): string {
    return this.getValue('format_number_digits');
  }
  maxPercentageFractionDigits(): string {
    return this.getValue('format_percentage_digits');
  }

  defaultLanguage(): string {
    return this.getValue('language');
  }
  cloudTranslations(): boolean {
    return this.getValue('cloud_translations');
  }

  // Item Card
  imagePrefix(): string {
    return this.getValue('image_prefix');
  }
  imageLookupProperty(): string {
    return this.getValue('image_lookup_property');
  }
  imagePostfix(): string {
    return this.getValue('image_postfix');
  }
  startOfWeek(): number {
    const value = this.getValue('item_card_start_week');
    return isNil(value) || isNaN(+value) || +value > 6 || +value < 0 ? 1 : +value;
  }
  hideWeekNumber(): boolean {
    return this.getValue('item_card_hide_week_number');
  }

  // navigation
  appNavigation(): MainNavigation {
    return new MainNavigation(this.getSetting('app_navigation'));
  }
  settingsNavigation(): SettingsNavigation {
    return new SettingsNavigation(this.getSetting('settings_navigation'));
  }
  homePage(): string {
    return new MainNavigation(this.getSetting('app_navigation')).defaultModule.toDto().homepage;
  }
  documentationUrl(): string {
    return this.getValue('documentation_url');
  }

  orderLogicUndeliveredDays(): number {
    return +this.getValue('order_logic_undelivered_days');
  }
  ordersCountGrid(): boolean {
    return this.getValue('orders_count_grid');
  }

  // Workspace
  workspaceMaxColumns(): number {
    return +this.getValue('workspace_max_columns');
  }
}
