/* eslint-disable complexity */
/* eslint-disable max-lines */
import { ApplicationRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { isNil } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { StoreService } from 'src/app/_core/store.service';
import { DatepickerI18n } from 'src/app/_shared/datepicker-i18n/datepicker-i18n';
import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';
import { ItemCardDetailsService } from 'src/app/item-card/details/item-card-details.service';

import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { ItemCardDetailsPers } from 'src/app/item-card/details/item-card-details-sidebar-pers';

@Component({
  selector: 'agr-item-card-details',
  templateUrl: './item-card-details.component.html',
  styleUrls: ['./item-card-details.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDatepickerI18n, useClass: DatepickerI18n }
  ]
})
export class ItemCardDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() readonly closeSidebar = new EventEmitter();
  @Output() readonly columnChangedEvent = new EventEmitter<any>();
  @Input() itemId?: number;
  @Input() isSidebar = false;
  @Input() refreshItemEvent: Observable<void>;
  @Input() refreshDetailsColumnEvent: Observable<string>;
  refreshItemSubscription: Subscription;
  refreshDetailsColumnSubscription: Subscription;
  columnContext: ContextLine[] = [];
  itemInfoColumns: DetailsColumn[] = [];
  orderLogicSettingsColumns: DetailsColumn[] = [];
  stockInfoColumns: DetailsColumn[] = [];
  forecastSettingsColumns: DetailsColumn[] = [];
  orderRoutesColumns: DetailsColumn[][] = [];
  undeliveredOrdersColumns: DetailsColumn[][] = [];
  expiringStockColumns: DetailsColumn[][] = [];
  itemCardDetailColumns: DetailsColumn[] = [];
  otherColumns: DetailsColumn[] = [];
  orderRouteIndex = 0;

  today = new Date();
  orderLogicUndeliveredDays: number;
  orderLogicUndeliveredDate = new Date();

  form = new FormGroup({});
  globalEventService: any;
  showExpiringStock = true;

  // For sidebar
  persKey = 'item-card.details';
  pers: ItemCardDetailsPers;
  sidebarEditMode = false;
  isLoadingDetails = false;
  isEmpty = true;

  constructor(
    private applicationRef: ApplicationRef,
    private settingsService: SettingsService,
    private itemCardDetailsService: ItemCardDetailsService,
    private storeService: StoreService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadPers();
    this.refreshItemSubscription = this.refreshItemEvent.subscribe(() => this.getItemDetails());
    this.refreshDetailsColumnSubscription = this.refreshDetailsColumnEvent.subscribe((column) => {
      this.columnHasChanged(column);
    });
    this.itemCardDetailsService.getColumns().subscribe((columns) => {
      if (isNaN(this.itemId)) {
        return;
      }
      this.columnContext = columns;
      this.columnContext.forEach((column) => this.form.addControl(column.name, new FormControl()));
      this.getItemDetails();
    });
    this.initUndeliveredOrderLogic();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNil(changes.itemId)) {
      return;
    }
    if (changes.itemId.previousValue !== changes.itemId.currentValue) {
      if (!isNil(changes.itemId.currentValue)) {
        this.isEmpty = false;
        this.isLoadingDetails = true;
        this.itemCardDetailsService.getColumns().subscribe((columns) => {
          this.columnContext = columns;
          this.columnContext.forEach((column) => this.form.addControl(column.name, new FormControl()));
          this.getItemDetails();
        });
      } else {
        this.isEmpty = true;
      }
      this.refreshComponent();
    }
  }

  columnHasChanged(columnName: string): void {
    let contextLine = this.columnContext.find((contextColumn) => contextColumn.name.includes(columnName));
    let contextLines = this.columnContext.filter((contextColumn) => contextColumn.groupName === contextLine.groupName);
    this.itemCardDetailsService.getDetails(contextLines, this.itemId).subscribe((columns: DetailsColumn[]) => {
      this.updateColumns(columns);
    });
  }

  columnChanged(column: DetailsColumn): void {
    this.columnChangedEvent.emit(column.name);
  }

  ngOnDestroy(): void {
    this.refreshItemSubscription.unsubscribe();
  }

  findColumn(columns: DetailsColumn[], columnName: string): DetailsColumn {
    return columns.find((column) => column.name === columnName);
  }

  isEditable(column: DetailsColumn): boolean {
    if (column.config !== undefined) {
      return this.authService.hasFeature('itemCard.mod') && column.isEditable();
    }
    return false;
  }

  // Public functions for Undelivered logic
  // return true of order is passed estimated delivery date
  isUndeliveredDatePassed(undeliveredOrderColumns: DetailsColumn[]): boolean {
    const column = this.findColumn(undeliveredOrderColumns, '_undelivered_est_delivery');
    const estDelivComp = new Date(column.value);
    if (estDelivComp < this.today) {
      return true;
    }
    return false;
  }

  // returns true if order is considered outdated and will be ignored by order logic
  isOutdated(undeliveredOrderColumns: DetailsColumn[]): boolean {
    const column = this.findColumn(undeliveredOrderColumns, '_undelivered_est_delivery');
    const estDelivComp = new Date(column.value);
    if (estDelivComp < this.orderLogicUndeliveredDate) {
      return true;
    }
    return false;
  }

  // public functions for sidebar
  toggleSidebarEditMode(): void {
    this.sidebarEditMode = !this.sidebarEditMode;
    this.refreshComponent();
  }

  close(): void {
    this.closeSidebar.emit('closeSidebar');
  }

  // other public functions
  toggleNextOrderRoute(): void {
    if (this.orderRouteIndex === this.orderRoutesColumns.length - 1) {
      this.orderRouteIndex = 0;
    } else {
      this.orderRouteIndex += 1;
    }
    this.refreshComponent();
  }

  refreshComponent(): void {
    setTimeout(() => {
      this.applicationRef.tick();
    }); // Shake and wake the component, fixes issues with ngbPopover
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  // function for persistent sate
  isHidden(name: string): boolean {
    if (this.isSidebar) {
      return this.pers.isHidden(name);
    }
    return false;
  }

  toggleColumn(column: DetailsColumn): void {
    const groupName = column.groupName;
    this.pers.toggleColumn(column.name, groupName);
    let allGroupColumns = this.getGroupColumns(groupName);
    if (
      !this.pers.sidebarHiddenGroups.includes(groupName) &&
      allGroupColumns.every((column) => this.pers.sidebarHiddenColumns.includes(column.name))
    ) {
      this.pers.toggleGroup(groupName);
    }
  }

  toggleGroup(groupName: string): void {
    this.pers.toggleGroup(groupName, this.getGroupColumns(groupName));
  }

  toggleExpStock(): void {
    this.pers.hideExpStock = !this.pers.hideExpStock;
    this.savePers();
  }

  savePers(): void {
    this.storeService.set(this.persKey, this.pers);
  }

  getGroupColumns(groupName: string): DetailsColumn[] {
    return this.itemCardDetailColumns.filter((column) => column.groupName === groupName);
  }

  // private
  // Gets columns details using de_query and adds to arrays based on data_element_groups.
  private getItemDetails(): void {
    if (!this.columnContext) {
      return;
    }
    this.itemCardDetailsService.getDetails(this.columnContext, this.itemId).subscribe((columns: DetailsColumn[]) => {
      this.isLoadingDetails = false;
      this.itemCardDetailColumns = columns;
      this.setFormValues();
    });
    this.itemCardDetailsService.getOrderRoutes(this.columnContext, this.itemId).subscribe((orderRouteDetails) => {
      this.orderRoutesColumns = orderRouteDetails;
    });
    this.itemCardDetailsService.getExpiringStock(this.columnContext, this.itemId).subscribe((expStockDetails) => {
      this.expiringStockColumns = expStockDetails;
    });
    this.itemCardDetailsService.getUndeliveredOrders(this.columnContext, this.itemId).subscribe((undeliveredOrdersDetails) => {
      this.undeliveredOrdersColumns = undeliveredOrdersDetails;
    });
  }

  private updateColumns(columns: DetailsColumn[]): void {
    columns.map((newColumn) => {
      let oldColumn = this.itemCardDetailColumns.find((oldColumn) => oldColumn.name === newColumn.name);
      if (!this.isEditable(newColumn) || newColumn.overwriteValue !== oldColumn.overwriteValue) {
        this.itemCardDetailColumns.splice(this.itemCardDetailColumns.indexOf(oldColumn), 1, newColumn);
      }
    });
  }

  // get order logic data from settings, used for isUndelivered, isOutdated functions.
  private initUndeliveredOrderLogic(): void {
    this.today = new Date(new Date().setHours(0, 0, 0, 0));
    this.orderLogicUndeliveredDays = this.settingsService.orderLogicUndeliveredDays();
    this.orderLogicUndeliveredDate = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate() - this.orderLogicUndeliveredDays
    );
  }

  private setFormValues(): void {
    this.itemCardDetailColumns.forEach((column) => {
      this.form.get(column.name).setValue(column.overwriteValue !== null ? column.overwriteValue : column.value);
      this.form.get(column.name).markAsTouched();
    });
  }

  private loadPers(): void {
    this.pers = this.storeService.get(this.persKey) as ItemCardDetailsPers;
    if (!this.pers) {
      this.pers = new ItemCardDetailsPers([], [], false);
    }
    this.pers = new ItemCardDetailsPers(this.pers.sidebarHiddenColumns, this.pers.sidebarHiddenGroups, this.pers.hideExpStock);
  }
}
