import { WorkspaceGridRow } from 'src/app/workspaces/details/grid/workspace-grid.component';

export type ConfigCondType = '=' | '==' | '===' | 'equal' | '<>' | '!=' | '!==' | 'notequal' | '>' | '>=' | '<' | '<=' | 'in';

export interface ConfigCondDTO {
  type?: ConfigCondType;
  column?: string;
  value?: number | number[] | string | string[];
}

export class ConfigCond {
  type?: ConfigCondType;
  column?: string;
  value?: number | number[] | string | string[];

  constructor(dto?: ConfigCondDTO) {
    if (!dto) {
      return;
    }
    this.type = dto.type;
    this.column = dto.column;
    this.value = dto.value;
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  hasTruthyCondition(row: WorkspaceGridRow): boolean {
    if (!row) {
      return false;
    }
    const cellValue = this.getCellValue(row, this.column);
    if (!this.type || !this.column || this.value === undefined) {
      return true;
    }

    const value = this.getValue(row);
    if (['=', '==', '===', 'equal'].includes(this.type)) {
      return cellValue === value;
    }
    if (['<>', '!=', '!==', 'notequal'].includes(this.type)) {
      return cellValue !== value;
    }
    if (this.type === '>') {
      return cellValue > value;
    }
    if (this.type === '>=') {
      return cellValue >= value;
    }
    if (this.type === '<') {
      return cellValue < value;
    }
    if (this.type === '<=') {
      return cellValue <= value;
    }
    if (this.type === 'in') {
      return this.includes(cellValue);
    }
  }

  private includes(cellValue: number | string): boolean {
    if (typeof cellValue === 'number') {
      const valueNumberArray = this.value as number[];
      return valueNumberArray.includes(cellValue);
    }
    if (typeof cellValue === 'string') {
      const valueStringArray = this.value as string[];
      return valueStringArray.includes(cellValue);
    }
  }

  private getCellValue(row: WorkspaceGridRow, column: string): string | number {
    if (row[column] === null || row[column] === undefined) {
      return undefined;
    }
    if (typeof row[column] === 'object' && row[column].type === 'lookup') {
      return row[column].data[column];
    }
    return row[column];
  }
  /**
   * Finds column value if ${some_column} syntax is used.
   */
  private getValue(row: WorkspaceGridRow): number | number[] | string | string[] {
    if (typeof this.value === 'string' && this.value.match(/^\$\{.*\}$/)) {
      return row[this.value.substring(2, this.value.length - 1)];
    }
    return this.value;
  }
}
