export interface ConfigColumnLinkDTO {
  targetView?: number | string;
  path?: string;
  columns?: string[];
  map?: ConfigColumnLinkMapDTO[];
  drilldownsToClose?: string[];
}

export interface ConfigColumnLinkMapDTO {
  from: string;
  to: string;
}

export class ConfigColumnLink {
  targetView?: number | string;
  path?: string;
  columns?: string[];
  map?: ConfigColumnLinkMapDTO[];
  drilldownsToClose?: string[];

  constructor(dto?: ConfigColumnLinkDTO) {
    if (!dto) {
      return;
    }
    this.targetView = dto.targetView;
    this.path = dto.path;
    this.columns = dto.columns;
    this.map = dto.map;
    this.drilldownsToClose = dto.drilldownsToClose;
  }
}
