import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { Expression } from 'src/app/_shared/utils/expression.model';
import { ConfigSerie, ConfigSerieBaseDTO } from 'src/app/workspaces/details/config/config-serie.model';

export interface WorkspaceSerieConfig {
  visible?: boolean;
  excluded?: boolean;
}

export interface WorkspaceViewSerieDTO {
  name: string;
  config: ConfigSerieBaseDTO;
}

type SerieAggregation = 'avg' | 'sum' | 'cs';
type SerieType = 'client_calculated' | 'stored';
type RefreshProfile = 'affected_rows_only' | 'rows_and_affected_rows' | 'row';

/**
 * Workspace Serie
 */
export class WorkspaceSerie {
  name: string;
  get caption(): string {
    return this.config.format.caption || this.contextLine.caption;
  }
  description: string;
  groupName: string;
  groupCaption: string;
  readOnly: boolean;
  get format(): string {
    return this.config.format.format || this.contextLine.format || 'decimal:0';
  }
  seriesAffected?: string;

  config = new ConfigSerie();

  serieAggregation?: SerieAggregation;
  serieType?: SerieType;
  refreshProfile?: RefreshProfile;
  jsFormula?: Expression;

  // other
  showInfo = false;
  csExpr;

  private contextLine: ContextLine;

  constructor(contextLine: ContextLine, viewDto?: WorkspaceViewSerieDTO) {
    if (!contextLine) {
      return;
    }
    this.contextLine = contextLine;

    this.name = contextLine.name;
    this.description = contextLine.description;
    this.serieAggregation = contextLine.aggregation as SerieAggregation;
    this.serieType = contextLine.seriesType as SerieType;
    this.refreshProfile = contextLine.refreshProfile as RefreshProfile;
    this.groupName = contextLine.groupName;
    this.groupCaption = contextLine.groupCaption;
    this.readOnly = contextLine.readOnly;
    this.jsFormula = contextLine.seriesJsFormulaValue ? new Expression(contextLine.seriesJsFormulaValue) : undefined;
    this.seriesAffected = contextLine.seriesAffected;

    this.config.setDefaultConfig(contextLine.config as ConfigSerieBaseDTO);

    if (viewDto) {
      this.updateFromDto(viewDto);
    }
  }

  get editable(): boolean {
    return this.config.edit.enabled === undefined ? !this.readOnly : this.config.edit.enabled;
  }

  get isCalculatedSerie(): boolean {
    return this.serieType === 'client_calculated';
  }

  toDto(): WorkspaceViewSerieDTO {
    return {
      name: this.name,
      config: this.config.getViewConfig()
    };
  }

  private updateFromDto(dto: WorkspaceViewSerieDTO): void {
    if (dto.config) {
      this.config.setViewConfig(dto.config);
    }
  }
}
