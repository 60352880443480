// tslint:disable:no-relative-imports
import * as packageJson from '../../package.json';

const env = packageJson; // This is to fix build errors

export const environment = {
  version: env.version,
  buildInfo: env.buildInfo,
  imageVersion: env.imageVersion,
  production: true,
  name: 'prod'
};
