import { isNil } from 'lodash';

import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQueryFilter, DeQueryFilterOperator } from 'src/app/_core/de-query/model/de-query.model';
import { WorkspaceFilter, WorkspaceFilterType } from 'src/app/workspaces/details/filters/models/workspace-filter.model';

export type WorkspaceNumberFilterType =
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUALS'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUALS'
  | 'NUMBER_RANGE';
type NumberFilterDeQueryOperators = Extract<DeQueryFilterOperator, 'BETWEEN' | '=' | 'NOTIN' | '>' | '>=' | '<' | '<='>;

export class WorkspaceNumberFilter extends WorkspaceFilter {
  rangeFrom: number;
  rangeTo: number;
  value: number;
  type: WorkspaceNumberFilterType = 'NUMBER_RANGE';
  readonly filterTypes: WorkspaceNumberFilterType[] = [
    'EQUALS',
    'NOT_EQUALS',
    'GREATER_THAN',
    'GREATER_THAN_OR_EQUALS',
    'LESS_THAN',
    'LESS_THAN_OR_EQUALS',
    'NUMBER_RANGE'
  ];
  filterType: WorkspaceFilterType = 'WorkspaceNumberFilter';

  constructor(contextLine?: ContextLine) {
    super(contextLine);
    if (!contextLine) {
      return;
    }
  }

  selectType(type: WorkspaceNumberFilterType): void {
    this.type = type;
  }

  toDeQueryFilter(): DeQueryFilter {
    return {
      column: {
        name: this.idColumnName
      },
      values: this.type === 'NUMBER_RANGE' ? this.getRange() : [this.value].filter((value) => !isNil(value)),
      operator: this.getOperatorFromType(),
      ui: {
        column: {
          name: this.name
        },
        selectedSubtypeId: this.type,
        fixed: this.locked,
        class: this.filterType
      }
    };
  }

  updateFromDeQueryFilter(query: DeQueryFilter): void {
    const operatorToType: { [Key in NumberFilterDeQueryOperators]: WorkspaceNumberFilterType } = {
      BETWEEN: 'NUMBER_RANGE',
      '>': 'GREATER_THAN',
      '>=': 'GREATER_THAN_OR_EQUALS',
      '<': 'LESS_THAN',
      '<=': 'LESS_THAN_OR_EQUALS',
      '=': 'EQUALS',
      NOTIN: 'NOT_EQUALS'
    };
    this.type = operatorToType[query.operator];
    this.locked = !!query.ui.fixed;
    if (this.type === 'NUMBER_RANGE') {
      this.rangeFrom = query.values && query.values[0] !== null ? +query.values[0] : this.rangeFrom;
      this.rangeTo = query.values && query.values[1] !== null ? +query.values[1] : this.rangeTo;
    } else {
      this.value = query.values && query.values[0] !== null ? +query.values[0] : this.value;
    }
  }

  private getOperatorFromType(): DeQueryFilterOperator {
    const typeToOperator: { [Key in WorkspaceNumberFilterType]: DeQueryFilterOperator } = {
      NUMBER_RANGE: 'BETWEEN',
      GREATER_THAN: '>',
      GREATER_THAN_OR_EQUALS: '>=',
      LESS_THAN: '<',
      LESS_THAN_OR_EQUALS: '<=',
      EQUALS: '=',
      NOT_EQUALS: 'NOTIN'
    };
    let operator = typeToOperator[this.type];
    if (operator === 'BETWEEN') {
      if (!isNil(this.rangeFrom) && isNil(this.rangeTo)) {
        operator = '>=';
      }
      if (isNil(this.rangeFrom) && !isNil(this.rangeTo)) {
        operator = '<=';
      }
    }
    return operator;
  }

  private getRange(): number[] {
    const range = [this.rangeFrom, this.rangeTo];
    range[0] = isNil(range[0]) ? range[1] : range[0];
    range[1] = isNil(range[1]) ? range[0] : range[1];
    return range.filter((value) => !isNil(value));
  }
}
