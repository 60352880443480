<div id="nav-execution-list-dd" class="dropdown h-100">
  <!-- button -->
  <button class="agr-nav-btn" data-bs-toggle="dropdown" ngbTooltip="{{ getTaskTooltip() | translate }}">
    <i class="fa fa-tasks"></i>
    <span
      id="execution-list-badge"
      *ngIf="connectionStatus === 1 && activeExecutionsCount > 0"
      class="animated"
      [ngClass]="{ bounceIn: activeExecutionsCount }">
      {{ activeExecutionsCount }}
    </span>
    <span id="notify-connection-badge" class="animated" *ngIf="connectionStatus !== 1" class="animated">
      <i *ngIf="connectionStatus === 2" class="fa fa-exclamation-triangle"></i>
      <i *ngIf="connectionStatus !== 2" class="fa fa-refresh fa-spin"></i>
    </span>
  </button>

  <!-- drop-down -->
  <div class="dropdown-menu dropdown-menu-right" (click)="stopEvent($event)">
    <div class="dropdown-header border-bottom pb-2">
      <!-- title and clear btn -->
      <div class="agr-mini-tools">
        <div class="amt-area">
          <div class="amt-title">
            <h6 translate>NAV_EXECUTION_LIST</h6>
          </div>
        </div>
        <div class="amt-area">
          <!-- Hide action notifications from other users Button -->
          <button
            id="nav-execution-hide-others"
            class="amt-btn nav-execution-btn"
            (click)="toggleActionsFromOtherUsers($event)"
            ngbTooltip="{{
              (state.showActionsFromOtherUsers ? 'HIDE_ACTIONS_FROM_OTHER_USERS' : 'SHOW_ACTIONS_FROM_OTHER_USERS') | agrTranslate
            }}">
            <i class="fa" [ngClass]="{ 'fa-bell': state.showActionsFromOtherUsers, 'fa-bell-slash': !state.showActionsFromOtherUsers }">
            </i>
          </button>
          <!-- Clear all of your notifications Button -->
          <button
            id="nav-execution-clear-all"
            class="amt-btn nav-execution-btn"
            [disabled]="userActionsCount === 0"
            (click)="clearAllUserActions($event)"
            ngbTooltip="{{ 'CLEAR' | agrTranslate }}">
            <i class="fa fa-ban"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- execution list items -->
    <div id="execution-list-items" class="u-vh-80 u-overflow-y border-bottom" *ngIf="actions.length > 0">
      <button
        type="button"
        *ngFor="let action of actions"
        class="action-item dropdown-item border-bottom"
        routerLink="/{{ !!action.url ? action.url : 'executions' }}">
        <!-- header, action caption and name -->
        <div class="action-item-header">
          <strong>{{ action.type | agrTranslate }}</strong>
          <span class="action-item-from font-italic text-truncate" title="{{ action.caption | agrTranslate }}">
            : {{ action.caption | agrTranslate }}</span
          >
          <!-- Clear action button -->
          <button
            type="button"
            class="agr-btn-close"
            (click)="clearAction(action.id, $event)"
            *ngIf="action.userId === sessionService.user.id && action.progressPercent === 100">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- loading bar-->
        <div id="action-item-loading-bar" *ngIf="!action.progressPercent || action.progressPercent < 100" class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated w-100"></div>
        </div>
        <!-- details  -->
        <div class="action-item-details">
          <span class="small"
            >{{ action.userId === 0 ? ('SCHEDULER' | agrTranslate) : action.userName }}, {{ action.createdAt | agrFromNow }}</span
          >
          <div
            class="badge bg-success pull-right mt-1"
            data-cy="execution-list-success-badge"
            *ngIf="action.success"
            ngbTooltip="{{ 'RUNNING_TIME' | agrTranslate }}: {{ action.duration | agrFormat: 'durationHuman' }}"
            placement="bottom"
            routerLink="/executions"
            (click)="stopEvent($event)"
            translate>
            DONE
          </div>
          <div class="badge bg-danger pull-right me-1 mt-1" *ngIf="action.success === false" routerLink="/executions" translate>ERROR</div>
        </div>
      </button>
    </div>

    <!-- no items message -->
    <div id="execution-list-no-items" class="dropdown-header border-bottom" *ngIf="actions.length === 0" translate>
      NAV_EXEC_NOTHING_TO_SHOW
    </div>

    <!-- show more -->
    <button id="execution-list-show-more" type="button" class="dropdown-item mt-2" routerLink="/executions" translate>NAV_SHOW_MORE</button>
  </div>
</div>
