import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { Observable } from 'rxjs';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private progressRef: NgProgressRef;

  constructor(private ngProgress: NgProgress) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.updateLoadingProgress();
    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  private removeRequest(req: HttpRequest<any>): void {
    const requestIndex = this.requests.indexOf(req);
    if (requestIndex >= 0) {
      this.requests.splice(requestIndex, 1);
    }
    this.updateLoadingProgress();
  }

  private updateLoadingProgress(): void {
    this.progressRef = this.ngProgress.ref();
    if (this.requests.length > 0) {
      this.progressRef.start();
    }
    if (this.requests.length <= 0) {
      this.progressRef.complete();
    }
  }
}
