<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div id="about-modal-header" class="modal-header">
    <h4 class="modal-title" translate>CLEAR_BROWSER_DATA</h4>
    <button type="button" class="btn-close" (click)="modal.close()"></button>
  </div>

  <div class="modal-body">
    <div class="form-group form-check">
      <input type="checkbox" id="clear-local-storage-checkbox" class="form-check-input" formControlName="clearLocalStorage" ngbAutofocus />
      <label class="form-check-label" for="clear-local-storage-checkbox" translate>CLEAR_LOCAL_STORAGE</label>
      <button type="button" class="btn btn-help" ngbPopover="{{ 'CLEAR_LOCAL_STORAGE_i' | agrTranslate }}">
        <i class="fa fa-question-circle"></i>
      </button>
    </div>

    <div class="form-group form-check">
      <input type="checkbox" id="clear-indexed-db-checkbox" class="form-check-input" formControlName="clearIndexedDb" />
      <label class="form-check-label" for="clear-indexed-db-checkbox" translate>CLEAR_INDEXED_DB</label>
      <button type="button" class="btn btn-help" ngbPopover="{{ 'CLEAR_INDEXED_DB_i' | agrTranslate }}">
        <i class="fa fa-question-circle"></i>
      </button>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="modal.close()" translate>CANCEL</button>
    <button type="submit" class="btn btn-success" translate>CONFIRM</button>
  </div>
</form>
