import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { CustomAction, CustomActionDTO } from 'src/app/_core/models/custom-action.model';
import { TranslationsService } from 'src/app/_core/translations.service';
import { ActionExecutionResponseDTO } from 'src/app/nav/execution-list/execution-notification.model';

@Injectable({
  providedIn: 'root'
})
export class CustomActionsService {
  constructor(private httpClient: HttpClient, private toasterService: ToasterService) {}

  getAllCustomActions(): Observable<CustomAction[]> {
    return this.httpClient.get(`${API_ROOT}/custom-actions`).pipe(
      map((customActions: CustomActionDTO[]) => {
        return customActions.map((customAction) => new CustomAction(customAction));
      })
    );
  }

  executeCustomAction(customAction: CustomAction): Observable<ActionExecutionResponseDTO> {
    return this.httpClient.post(`${API_ROOT}/custom-actions/${customAction.id}/execute`, customAction.toActionExecutionRequest()).pipe(
      tap((response: ActionExecutionResponseDTO) => {
        this.createActionResponseMessage(response);
        return response;
      })
    );
  }

  private createActionResponseMessage(response: ActionExecutionResponseDTO): void {
    const toastType = response.ActionExecutionId !== -1 ? 'info' : 'error';
    const toastTitle = TranslationsService.get('CUSTOM_ACTION_STARTED');
    if (toastType === 'info') {
      this.toasterService.pop(toastType, toastTitle);
    } else {
      this.toasterService.pop(toastType, toastTitle, response.LockedStatus);
    }
  }
}
