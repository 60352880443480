<div class="container-fluid">
  <div id="error403" class="agr-layout">
    <img src="assets/images/403-bg.png" class="page-bg" />

    <div class="page-content">
      <img src="assets/images/403-code.png" class="page-code" />
      <h1>{{ 'RESTRICTED_ACCESS' | agrTranslate }}</h1>
      <p class="subtitle">{{ 'THIS_FEATURE_IS_NOT_ALLOWED' | agrTranslate }}</p>
      <a class="btn btn-primary" href="javascript:history.back()" id="back-link">
        <i class="fa fa-arrow-left"></i>
        {{ 'GO_BACK' | agrTranslate }}
      </a>
      <a href="/#/" class="home-link"> ...{{ 'GO_TO_HOME_PAGE' | agrTranslate }}</a>
    </div>
  </div>
</div>
