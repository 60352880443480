import { sortBy } from 'lodash';

import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { WorkspaceAdvancedFilter } from 'src/app/workspaces/details/filters/models/workspace-advanced-filter.model';
import { WorkspaceBooleanFilter } from 'src/app/workspaces/details/filters/models/workspace-boolean-filter.model';
import { WorkspaceDateFilter } from 'src/app/workspaces/details/filters/models/workspace-date-filter.model';
import { WorkspaceFilter } from 'src/app/workspaces/details/filters/models/workspace-filter.model';
import { WorkspaceLookupFilter } from 'src/app/workspaces/details/filters/models/workspace-lookup-filter.model';
import { WorkspaceNumberFilter } from 'src/app/workspaces/details/filters/models/workspace-number-filter.model';
import { WorkspacePeriodFilter } from 'src/app/workspaces/details/filters/models/workspace-period-filter.model';
import { WorkspaceAction } from 'src/app/workspaces/models/workspace-action.model';
import { WorkspaceColumn } from 'src/app/workspaces/models/workspace-column.model';
import { WorkspaceDrilldown } from 'src/app/workspaces/models/workspace-drilldown.model';
import { WorkspaceSerie } from 'src/app/workspaces/models/workspace-serie.model';

export class Context {
  contextLines: ContextLine[];

  constructor(contextLines: ContextLine[] = []) {
    this.contextLines = sortBy(
      contextLines.filter((contextLine) => contextLine.available),
      'caption'
    );
  }

  getColumnOrCalculatedColumn(name: string): ContextLine {
    return this.contextLines.find((line) => {
      return (line.objectType === 'columns' || line.objectType === 'calculatedColumns') && line.name === name;
    });
  }

  getColumn(name: string): ContextLine {
    return this.contextLines.find((line) => line.objectType === 'columns' && line.name === name);
  }

  getCalculatedColumn(name: string): ContextLine {
    return this.contextLines.find((line) => line.objectType === 'calculatedColumns' && line.name === name);
  }

  getSerie(name: string): ContextLine {
    return this.contextLines.find((line) => line.objectType === 'series' && line.name === name);
  }

  getFilter(name: string): ContextLine {
    return this.contextLines.find((line) => line.objectType === 'filters' && line.name === name);
  }

  getAction(name: string): ContextLine {
    return this.contextLines.find((line) => line.objectType === 'planActions' && line.name === name);
  }

  //////////////////////////////////////////

  getAll(): ContextLine[] {
    return this.contextLines;
  }

  /**
   * Returns context lines with 'column' and 'calculatedColumn' types.
   */
  getAllColumns(): ContextLine[] {
    return this.contextLines.filter((line) => line.objectType === 'columns' || line.objectType === 'calculatedColumns');
  }

  /**
   * Returns Workspace Columns with 'column' and 'calculatedColumn' types from contextLines
   */
  getAllWorkspaceColumns(): WorkspaceColumn[] {
    return this.getAllColumns()
      .map((contextLine) => new WorkspaceColumn(contextLine))
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .sort((a, b) => (a.groupName < b.groupName ? -1 : 1));
  }

  /**
   * Returns context lines with 'column' object type.
   */
  getColumns(): ContextLine[] {
    return this.contextLines.filter((line) => line.objectType === 'columns');
  }

  /**
   * Returns Workspace Columns with 'column' object type from contextLines.
   */
  getWorkspaceColumns(): WorkspaceColumn[] {
    return this.getColumns().map((contextLine) => new WorkspaceColumn(contextLine));
  }

  /**
   * Returns context lines with 'calculatedColumns' object type.
   */
  getCalculatedColumns(): ContextLine[] {
    return this.contextLines.filter((line) => line.objectType === 'calculatedColumns');
  }

  getSeries(): ContextLine[] {
    return this.contextLines.filter((line) => line.objectType === 'series');
  }

  getWorkspaceSeries(): WorkspaceSerie[] {
    return this.getSeries()
      .map((contextLine) => new WorkspaceSerie(contextLine))
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .sort((a, b) => (a.groupName < b.groupName ? -1 : 1));
  }

  getFilters(): ContextLine[] {
    return this.contextLines.filter((line) => line.objectType === 'filters');
  }

  getActions(): ContextLine[] {
    return this.contextLines.filter((line) => line.objectType === 'planActions');
  }

  getWorkspaceActions(): WorkspaceAction[] {
    return this.getActions()
      .filter((line) => !!line.actionJson)
      .map((contextLine) => new WorkspaceAction(contextLine));
  }

  getWorkspaceFilters(): WorkspaceFilter[] {
    const columnFilters = this.getFilters().flatMap((filterContext) => {
      const filters: WorkspaceFilter[] = [];
      if (['bit', 'closed'].includes(filterContext.type)) {
        return new WorkspaceBooleanFilter(filterContext);
      }
      if (filterContext.periodGroup) {
        return new WorkspacePeriodFilter(filterContext);
      }
      if (filterContext.type === 'date') {
        filters.push(new WorkspaceDateFilter(filterContext));
      }
      if (['decimal', 'int'].includes(filterContext.type)) {
        filters.push(new WorkspaceNumberFilter(filterContext));
      }
      filters.push(new WorkspaceLookupFilter(filterContext));
      return filters;
    });
    columnFilters.sort((a, b) => (a.name < b.name ? -1 : 1)).sort((a, b) => (a.groupName < b.groupName ? -1 : 1));
    columnFilters.push(new WorkspaceAdvancedFilter());
    return columnFilters;
  }

  getWorkspaceDrilldowns(): WorkspaceDrilldown[] {
    return this.getColumns().map((columnContext) => {
      return new WorkspaceDrilldown(columnContext);
    });
  }
}
