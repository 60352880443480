import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQueryFilter } from 'src/app/_core/de-query/model/de-query.model';
import { WorkspaceFilter, WorkspaceFilterType } from 'src/app/workspaces/details/filters/models/workspace-filter.model';

export class WorkspaceBooleanFilter extends WorkspaceFilter {
  value?: boolean;
  filterType: WorkspaceFilterType = 'WorkspaceBooleanFilter';

  constructor(contextLine?: ContextLine) {
    super(contextLine);
  }

  toDeQueryFilter(): DeQueryFilter {
    return {
      column: {
        name: this.idColumnName
      },
      values: this.value === undefined ? [] : [this.value ? 1 : 0],
      operator: '=',
      ui: {
        column: {
          name: this.name
        },
        selectedSubtypeId: 'none',
        fixed: this.locked,
        class: this.filterType
      }
    };
  }

  updateFromDeQueryFilter(queryFilter: DeQueryFilter): void {
    if (!queryFilter.values) {
      this.value = undefined;
      return;
    }
    this.value = queryFilter.values.length === 1 && queryFilter.values[0] === '1';
  }
}
