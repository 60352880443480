/* eslint-disable max-classes-per-file */
import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQuery } from 'src/app/_core/de-query/model/de-query.model';

export interface WorkspaceViewActionDTO {
  name: string;
}

/**
 * Workspace Action
 */
export class WorkspaceAction {
  name: string;
  caption: string;
  captionPostfix = '';
  description: string;
  showInfo = false;

  // Config from action_json / ActionConfigDTO
  gridSelection: 'all';
  includeAffected: boolean;
  longRunningAction: boolean;
  refreshGridAfterAction: boolean;
  serieOverrideFromArg: string; // Matches name in modal operation selected options field (operations.fields.name)
  operations: WorkspaceActionOperation[] = [];
  get hasModal(): boolean {
    return this.operations.some((operation) => operation.type === 'modal');
  }
  get modalOperation(): WorkspaceActionOperation {
    return this.operations.find((operation) => operation.type === 'modal');
  }
  get inputFields(): WorkspaceActionModalField[] {
    return this.hasModal ? this.modalOperation.fields : [];
  }

  constructor(contextLine: ContextLine) {
    this.name = contextLine.name;
    this.caption = contextLine.caption;
    this.description = contextLine.description;

    const actionConfig = new WorkspaceActionConfig(contextLine.actionJson);
    const clientData = actionConfig.clientData;
    const actionArgs = actionConfig.clientData.actionArgs;

    this.gridSelection = clientData.gridSelection;
    this.includeAffected = actionArgs.includeAffected;
    this.refreshGridAfterAction = actionArgs.refreshGridAfterAction;
    this.serieOverrideFromArg = actionArgs.serieOverride ? actionArgs.serieOverride.readFromArg : undefined;
    this.longRunningAction = actionArgs.longRunningAction;
    this.operations = contextLine.actionJson.operations.map((operation) => new WorkspaceActionOperation(operation));
    this.setCaptionPostfix();
  }

  toDto(): WorkspaceViewActionDTO {
    return { name: this.name };
  }

  toQueryArgs(): ActionArgsDTO {
    const args = {
      includeAffected: this.includeAffected,
      serieOverride: { readFromArg: this.serieOverrideFromArg },
      refreshGridAfterAction: this.refreshGridAfterAction,
      longRunningAction: this.longRunningAction
    };
    this.inputFields.map((field) => {
      args[field.name] = field.value;
    });
    return args;
  }

  // Sets postfix of '...' to the end of actions with modal
  private setCaptionPostfix(): void {
    if (this.hasModal && this.caption.indexOf('...') === -1) {
      this.captionPostfix = '...';
    }
  }
}

type WorkspaceActionOperationType = 'modal' | 'lockAffectedCells' | 'sp' | 'refreshAffectedCells' | 'unlockAffectedCells' | 'refreshView';
type WorkspaceActionModalFieldType = 'select' | 'text' | 'int' | 'string' | 'date';
type WorkspaceActionSelectOption = 'viewDataSeries' | 'planDataSeries';

export class WorkspaceActionOperation {
  name?: string;
  type: WorkspaceActionOperationType;
  fields: WorkspaceActionModalField[] = [];

  constructor(operation?: WorkspaceActionOperationDTO) {
    if (!operation) {
      return;
    }
    this.name = operation.name;
    this.type = operation.type;
    if (this.type === 'modal' && operation.fields) {
      this.fields = operation.fields.map((field) => new WorkspaceActionModalField(field));
    }
  }
}

export class WorkspaceActionModalField {
  name: string;
  caption: string;
  description: string;
  type: WorkspaceActionModalFieldType;
  required: boolean;
  defaultValue: string;
  value: string | number; // Set from form input
  options: WorkspaceActionOptionValue[] = [];
  optionsFrom?: WorkspaceActionSelectOption | undefined; // If !undefined options are set based on this value
  optionsQuery?: WorkspaceActionModalFieldOptionsQuery; // If options will be provided by query
  multiple = false;
  optionsLoaded = false;

  constructor(field: WorkspaceActionModalFieldDTO) {
    this.name = field.name;
    this.caption = field.caption;
    this.description = field.description;
    this.required = field.required;
    this.type = field.type;
    this.defaultValue = field.defaultValue;
    this.optionsQuery = field.optionsQuery;
    this.multiple = !!field.multiple;
    if (field.optionValues instanceof Array) {
      this.options = field.optionValues;
    } else {
      this.optionsFrom = field.optionValues;
    }
  }

  /**
   * Sets option values from view when modal is opened
   */
  setOptionValues(optionValues: WorkspaceActionOptionValue[]): void {
    this.options = optionValues;
  }

  setOptionValuesFromQueryResult(optionValues: object[]): void {
    this.options = optionValues.map((option) => ({
      name: option[this.optionsQuery.nameColumn],
      caption: option[this.optionsQuery.captionColumn]
    }));
    this.optionsLoaded = true;
  }
}

// ActionConfigDTO, contextLine property action_json
export interface WorkspaceActionConfigDTO {
  clientData: ClientDataDTO;
  operations: WorkspaceActionOperationDTO[];
}

export class WorkspaceActionConfig {
  clientData = new ClientData();
  operations: WorkspaceActionOperation[] = [];
  constructor(config?: WorkspaceActionConfigDTO) {
    if (!config) {
      return;
    }
    this.clientData = new ClientData(config.clientData);
    this.operations = config.operations ? config.operations.map((operation) => new WorkspaceActionOperation(operation)) : [];
  }
}

interface WorkspaceActionOperationDTO {
  type?: WorkspaceActionOperationType;
  fields?: WorkspaceActionModalFieldDTO[];
  name?: string;
}

export interface WorkspaceActionModalFieldOptionsQuery {
  nameColumn: string;
  captionColumn: string;
  query: DeQuery;
}

interface WorkspaceActionModalFieldDTO {
  name?: string;
  caption?: string;
  type?: WorkspaceActionModalFieldType;
  optionValues?: WorkspaceActionOptionValue[] | WorkspaceActionSelectOption;
  description?: string;
  required?: boolean;
  defaultValue?: string;
  optionsQuery?: WorkspaceActionModalFieldOptionsQuery;
  multiple?: boolean;
}

interface WorkspaceActionOptionValue {
  name?: string;
  caption?: string;
}

interface ClientDataDTO {
  gridSelection?: 'all';
  actionArgs?: ActionArgsDTO;
}

class ClientData {
  gridSelection?: 'all' | undefined;
  actionArgs = new ActionArgs();
  constructor(data?: ClientDataDTO) {
    if (!data) {
      return;
    }
    this.gridSelection = data.gridSelection;
    this.actionArgs = new ActionArgs(data.actionArgs);
  }
}

interface ActionArgsDTO {
  includeAffected?: boolean;
  serieOverride?: SerieOverrideDTO;
  refreshGridAfterAction?: boolean;
  longRunningAction?: boolean;
}

class ActionArgs {
  includeAffected = false;
  serieOverride = new SerieOverride();
  refreshGridAfterAction = false;
  longRunningAction = false;
  constructor(args?: ActionArgsDTO) {
    if (!args) {
      return;
    }
    this.includeAffected = args.includeAffected;
    this.serieOverride = new SerieOverride(args.serieOverride);
    this.refreshGridAfterAction = args.refreshGridAfterAction;
    this.longRunningAction = args.longRunningAction;
  }
}

interface SerieOverrideDTO {
  readFromArg?: string;
}

class SerieOverride {
  readFromArg = '';
  constructor(override?: SerieOverrideDTO) {
    if (!override) {
      return;
    }
    this.readFromArg = override.readFromArg;
  }
}

export interface ActionResponseDTO {
  result: string;
  caption: string;
  actionId: number;
}
