import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

import { FormatService } from 'src/app/_core/format.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Used to fix timezone issues caused by clients and servers in different time zones
 * also issues caused by timezone information missing in date strings from the API/database
 */
export class TimezoneService {
  static settings: SettingsService;
  static formatService: FormatService;

  /**
   * This function is used for received server time that has no time zone indication e.g. '2019-10-31 T 09:34:44.36'
   *
   * The function assumes that the server is sending time data in UTC time unless otherwise defined in settings 'server_timezone'
   *
   * If this function is used and the value is sent back to the server then use clientTimeToServerTime() function
   */
  static serverTimeToClientTime(value: string | number | Date | undefined): Date | undefined {
    if (!value) {
      return undefined;
    }
    if (typeof value === 'string' && value.indexOf('Z') > -1) {
      return new Date(value);
    } // If UTC indicated don't adjust time
    const timezone = TimezoneService.settings.serverTimezone();
    const serverOffset = timezone ? moment.tz.zone(timezone).utcOffset(new Date(value).getTime()) * 60 * 1000 : 0;
    return new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60 * 1000 - serverOffset));
  }

  /**
   * Converts client local time to server time (UTC) and returns ISO 8601 string for the server
   * e.g 'Thu Oct 31 2019 05:34:44 GMT-0400 (Eastern Daylight Time)' becomes => "2019-10-31 T 09:34:44.360Z"
   */
  static clientTimeToServerTime(value: Date | undefined): string | undefined {
    return value ? value.toISOString() : undefined;
  }

  /**
   * Dates are represented as milliseconds in Highcharts but are dates in the database.
   * A certain millisecond from beginning of time (1970) is represented differently in every time zone e.g.
   * 1577836800000 ms: (1. jan 2020 00:00:00 in Iceland GMT) but (31. december 2019 19:00:00 in New York)
   *
   * This function fixes this so that: If you're in New York this function would shift 1577836800000 to 1577854800000 to be
   * 1st jan 2020 00:00:00 in New York, as we are only interested in the date value of the timestamp.
   *
   * The reason this function is different than serverTimeToClientTime above and inverts the offset calculations can be explained by first
   * understanding that: new Date(0) and new Date('1970-01-01T00:00:00') is not the same time unless the object construction
   * is happening in a UTC timezone. In a non UTC timezone you'd have to add the offset to one object or subtract it from the other.
   */
  static chartDataMillisecondsToClientTime(millisecondsDate: number): number {
    const timezone = TimezoneService.settings.serverTimezone();
    const serverOffset = timezone ? moment.tz.zone(timezone).utcOffset(new Date(millisecondsDate).getTime()) * 60 * 1000 : 0;
    return millisecondsDate - serverOffset + new Date(millisecondsDate).getTimezoneOffset() * 60 * 1000;
  }

  static chartDataMillisecondsToServerTime(millisecondsDate: number): number {
    const timezone = TimezoneService.settings.serverTimezone();
    const serverOffset = timezone ? moment.tz.zone(timezone).utcOffset(new Date(millisecondsDate).getTime()) * 60 * 1000 : 0;
    return millisecondsDate + serverOffset - new Date(millisecondsDate).getTimezoneOffset() * 60 * 1000;
  }

  /**
   * Converts a Date object (which always includes time) but is representing a Date only ... to date ISO format
   * e.g Wed Nov 06 2019 00:00:00 GMT-0500 becomes '2019-11-06'
   */
  static dateToString(value: Date | undefined): string | undefined {
    return !value ? undefined : TimezoneService.formatService.format(value, 'date:yyyy-MM-dd');
  }

  /**
   * Converts a Date object to ISO string in local time which "changes" the actual value, used for display purposes e.g Export to CSV/Excel
   * e.g Wed Mar 03 2020 23:00:00 GMT-0500 becomes '2020-03-03 23:00:00'
   */
  static toISOStringLocal(value: Date): string | undefined {
    if (!value) {
      return undefined;
    }
    if (!(value instanceof Date)) {
      return value;
    }
    return `${TimezoneService.dateToString(value)} ${value.toTimeString().split(' ')[0]}`;
  }

  constructor(private settingsService: SettingsService, private formatService: FormatService) {
    TimezoneService.settings = this.settingsService;
    TimezoneService.formatService = this.formatService;
  }
}
