// imports
import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ResizableDirective, ResizableModule, ResizeHandleDirective } from 'angular-resizable-element';

// declarations
import { AgGridCheckboxHeaderComponent } from 'src/app/_shared/ag-grid/components/ag-grid-checkbox-header.component';
import { AgGridTooltipComponent } from 'src/app/_shared/ag-grid/components/ag-grid-tooltip.component';
import { AgGridLinkRendererComponent } from 'src/app/_shared/ag-grid/components/ag-grid-link-renderer.component';
import { ConfirmModalComponent } from 'src/app/_shared/confirm-modal/confirm-modal.component';
import { DeleteModalComponent } from 'src/app/_shared/delete-modal/delete-modal.component';
import { ColorsComponent } from 'src/app/_shared/dev-utils/colors/colors.component';
import { AgrAutofocusDirective } from 'src/app/_shared/directives/auto-focus.directive';
import { AgrIndeterminateDirective } from 'src/app/_shared/directives/indeterminate.directive';
import { AgrResizableDirective } from 'src/app/_shared/directives/resizable.directive';
import { JsonEditorModalComponent } from 'src/app/_shared/json-editor-modal/json-editor-modal.component';
import { LoadingDotsComponent } from 'src/app/_shared/loading-dots/loading-dots.component';
import { OrdersCreateModalComponent } from 'src/app/_shared/orders-create-modal/orders-create-modal.component';
import { SyncButtonComponent } from 'src/app/_shared/sync-button/sync-button.component';
import { ToolbarBtnComponent } from 'src/app/_shared/toolbar-btn/toolbar-btn.component';

// pipes
import { FeaturePipe } from 'src/app/_shared/pipes/feature.pipe';
import { FilterPipe } from 'src/app/_shared/pipes/filter.pipe';
import { FormatPipe } from 'src/app/_shared/pipes/format.pipe';
import { FromNowPipe } from 'src/app/_shared/pipes/from-now.pipe';
import { ItemNoPipe } from 'src/app/_shared/pipes/item-no.pipe';
import { TranslatePipe } from 'src/app/_shared/pipes/translate.pipe';
import { AgGridBitRendererComponent } from 'src/app/_shared/ag-grid/components/ag-grid-bit-renderer.component';
import { AgGridClosedRendererComponent } from 'src/app/_shared/ag-grid/components/ag-grid-closed-renderer.component';
import { NoDataToDisplayComponent } from 'src/app/_shared/no-data-to-display/no-data-to-display.component';

@NgModule({
  imports: [
    AgGridModule.withComponents([AgGridCheckboxHeaderComponent, AgGridTooltipComponent, AgGridLinkRendererComponent]),
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgJsonEditorModule,
    NgSelectModule,
    ReactiveFormsModule,
    ResizableModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  declarations: [
    AgrAutofocusDirective,
    AgrIndeterminateDirective,
    AgrResizableDirective,
    ConfirmModalComponent,
    DeleteModalComponent,
    JsonEditorModalComponent,
    LoadingDotsComponent,
    NoDataToDisplayComponent,
    OrdersCreateModalComponent,
    SyncButtonComponent,
    ToolbarBtnComponent,
    // pipes
    FeaturePipe,
    FormatPipe,
    FilterPipe,
    FromNowPipe,
    ItemNoPipe,
    TranslatePipe,
    // ag-grid
    AgGridBitRendererComponent,
    AgGridClosedRendererComponent,
    AgGridCheckboxHeaderComponent,
    AgGridLinkRendererComponent,
    AgGridTooltipComponent,
    // dev utils
    ColorsComponent
  ],
  exports: [
    AgrAutofocusDirective,
    AgrIndeterminateDirective,
    AgrResizableDirective,
    ConfirmModalComponent,
    DeleteModalComponent,
    JsonEditorModalComponent,
    LoadingDotsComponent,
    NoDataToDisplayComponent,
    OrdersCreateModalComponent,
    SyncButtonComponent,
    ToolbarBtnComponent,
    // pipes
    FeaturePipe,
    FormatPipe,
    FilterPipe,
    FromNowPipe,
    ItemNoPipe,
    TranslatePipe,
    // dev utils
    ColorsComponent,
    // 3rd party
    CommonModule,
    NgbModule,
    NgSelectModule,
    TranslateModule,
    ResizableDirective,
    ResizeHandleDirective
  ],
  providers: [ResizableDirective, ResizeHandleDirective]
})
export class SharedModule {}
