import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQueryFilter } from 'src/app/_core/de-query/model/de-query.model';

export type WorkspaceFilterType =
  | 'WorkspacePeriodFilter'
  | 'WorkspaceLookupFilter'
  | 'WorkspaceDateFilter'
  | 'WorkspaceNumberFilter'
  | 'WorkspaceAdvancedFilter'
  | 'WorkspaceBooleanFilter'
  | 'WorkspaceTextFilter';

export abstract class WorkspaceFilter {
  name: string;
  caption: string;
  groupName: string;
  groupCaption: string;
  idColumnName: string;
  locked = false;
  contextType: string;
  filterType: WorkspaceFilterType;

  /**
   * When additional filters where added for 6.2 and there were more than one filter for each column, the class name/type
   * was added to query.ui.class, older DeQueryFilters won't have this and should only match a period or lookup filter.
   */
  static isMatch(query: DeQueryFilter, filter: WorkspaceFilter): boolean {
    if (filter.idColumnName !== query.column.name) {
      return false;
    } // early exit if not matched
    if (filter.filterType.includes(query.ui.class) && filter.filterType !== 'WorkspacePeriodFilter') {
      return query.ui.column.name === filter.name;
    }
    return filter.filterType === 'WorkspacePeriodFilter' || filter.filterType === 'WorkspaceLookupFilter';
  }

  constructor(contextLine?: ContextLine) {
    if (!contextLine) {
      return;
    }

    this.name = contextLine.name;
    this.caption = contextLine.caption;
    this.groupName = contextLine.groupName;
    this.groupCaption = contextLine.groupCaption;
    this.idColumnName = contextLine.idColumnName;
    this.contextType = contextLine.type;
  }

  abstract toDeQueryFilter(): DeQueryFilter;
  abstract updateFromDeQueryFilter(query: DeQueryFilter): void;

  isFilterEmpty(): boolean {
    const deQueryFilter = this.toDeQueryFilter();
    return !deQueryFilter.values || deQueryFilter.values.length === 0;
  }

  toggleLock(): void {
    this.locked = !this.locked;
  }
}
