export interface ItemLabelDTO {
  caption: string;
  color: string;
  info: string;
}

export class ItemLabel {
  caption: string;
  color: string;
  info: string;

  constructor(label: ItemLabelDTO) {
    this.caption = label.caption;
    this.color = label.color;
    this.info = label.info;
  }
}
