import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';

export interface LookupOption {
  id: number;
  value: string;
}
export interface Config {
  edit?: {
    enabled?: boolean;
    bulkEnabled?: boolean;
    targetIdColumn?: string;
    dateConditions?: { minDate?: string; maxDate?: string };
    lookupConfig?: {
      query?: { columns: [name: string] };
      fixedOptions?: LookupOption[];
      idColumn?: string;
      nameColumn?: string;
    };
  };
}
/**
 * Contains the data needed for an edit action of a cell
 */
export class DetailsColumn {
  readonly name: string;
  readonly caption: string;
  readonly description: string;
  readonly groupName: string;
  readonly type: string;
  value: any;
  readonly config: Config;
  overwriteValue: any;
  globalSettingValue: any;
  lookupOptions?: LookupOption[];

  constructor(contextLine: ContextLine, value: any, overwriteValue?: any, globalValue?: any) {
    this.name = contextLine.name;
    this.caption = contextLine.caption;
    this.description = contextLine.description;
    this.groupName = contextLine.groupName;
    this.type = contextLine.type;
    this.value = value ?? globalValue;
    this.overwriteValue = overwriteValue;
    this.globalSettingValue = globalValue;
    this.config = contextLine.config.itemDetails;
  }

  isEditable(): boolean {
    return !!this.config?.edit.enabled;
  }

  isBulkUpdateEnabled(): boolean {
    return this.isEditable() ? this.config.edit.bulkEnabled : false;
  }

  isDateColumn(): boolean {
    if (this.type === 'date') {
      return true;
    }
    return false;
  }

  isNumberColumn(): boolean {
    if (
      (this.type === 'int' || this.type === 'currency' || this.type === 'decimal' || this.type === 'percentage') &&
      this.config.edit.lookupConfig === undefined
    ) {
      return true;
    }
    return false;
  }

  isLookupColumn(): boolean {
    if (this.config.edit.lookupConfig !== undefined) {
      return true;
    }
    return false;
  }

  isStringColumn(): boolean {
    if (this.type === 'string' && this.config.edit.lookupConfig === undefined) {
      return true;
    }
    return false;
  }
}
