/* eslint-disable max-len */
/* eslint-disable max-classes-per-file */
import { cloneDeep } from 'lodash';

import { Setting } from 'src/app/_core/settings/settings.model';

export interface AgrNavDTO {
  caption?: string; // Name of button to display. Tries to translate
  path?: string; // Url. Starts without '/'
  feature?: string | string[]; // List of features required to see current element
  divider?: boolean; // Turns the object into dropdown divider
  icon?: string; // Name of font-awesome icon, e.g. "cog"
  submenu?: AgrNavDTO[]; // Turns the element into dropdown
}

interface ModularNavDTO {
  name?: string; // Name of the module
  index?: number; // The index of the module in app dropdown
  color?: string; // color of navbar - see _variables.scss and _navigation.scss or technical manual
  homepage?: string; // Path to homepage when agr logo is clicked, agr logo btn is disabled if not defined
  navigation?: AgrNavDTO[]; // App navigation
}

export class MainNavigation {
  modules: ModularNav[] = [];
  setting: Setting = {};
  get hasModularNavigation(): boolean {
    return this.modules.length > 1;
  }
  get defaultModule(): ModularNav {
    return this.modules.length > 0 ? this.modules[0] : new ModularNav();
  }

  constructor(setting?: Setting) {
    const navDto: ModularNavDTO[] = setting ? setting.value : undefined;
    this.setting = setting;
    this.modules = this.isValidNav(navDto) // If is empty, assign default else read from DTO
      ? navDto.map((nav) => new ModularNav(nav))
      : defaultAppNav.map((nav) => new ModularNav(nav));
    this.validateModules();
    this.modules.sort((a, b) => (a.index >= b.index ? 1 : -1));
  }

  toDto(): ModularNavDTO[] {
    return this.modules.map((module) => module.toDto());
  }

  toSetting(): Setting {
    this.setting.value = this.toDto();
    return this.setting;
  }

  addNav(nav: AgrNav, moduleName?: string): void {
    const module = this.modules.find((mm) => mm.name === moduleName) || this.defaultModule;
    module.addNav(nav);
  }

  private isValidNav(modules: ModularNavDTO[]): boolean {
    const isValid = !(!modules || modules.length === 0 || !(modules instanceof Array));
    if (!isValid) {
      console.warn('AGR: Invalid or empty APP navigation detected. Fix in Settings > System > App navigation');
    }
    return isValid;
  }

  private validateModules(): void {
    if (this.modules.length > 0 && this.modules.every((mod) => mod.isValid())) {
      return;
    }
    console.warn('AGR: Invalid modular APP navigation detected. Fix in Settings > System > App navigation');
    this.modules = this.modules.filter((mod) => mod.isValid());
    if (this.modules.length === 0) {
      this.modules = defaultAppNav.map((nav) => new ModularNav(nav));
    }
  }
}

export class SettingsNavigation {
  navigation: AgrNav[] = [];
  setting: Setting = new Setting();

  constructor(setting?: Setting) {
    const navDto: AgrNavDTO[] = setting ? setting.value : undefined;
    this.setting = setting;
    this.navigation = this.isValidNav(navDto) // If is empty, assign default else read from DTO
      ? (this.navigation = navDto.map((nav) => new AgrNav(nav)))
      : (this.navigation = defaultSettingsNav.map((nav) => new AgrNav(nav)));
    this.navigation = this.navigation.filter((nav) => nav.isValid());
  }

  toDto(): AgrNavDTO[] {
    return this.navigation.map((nav) => nav.toDto());
  }

  toSetting(): Setting {
    this.setting.value = this.toDto();
    return this.setting;
  }

  private isValidNav(navDto: AgrNavDTO[]): boolean {
    const isValid = !(!navDto || navDto.length === 0 || !(navDto instanceof Array));
    if (!isValid) {
      console.warn('AGR: Invalid or empty SETTINGS navigation detected. Fix in Settings > System > Settings navigation');
    }
    return isValid;
  }
}

export class ModularNav {
  name = '';
  index: number | undefined;
  color = '';
  homepage = '';
  navigationAll: AgrNav[] = []; // All menu items
  navigation: AgrNav[] = []; // Available navigation menus based on users feature access
  navigationDropdown: AgrNav[] = []; // Same as navigation, but no sub-menus. Used when filtering in dropdown. Set in nav.component.ts.

  constructor(navDto?: ModularNavDTO) {
    if (!navDto) {
      return;
    }
    this.name = navDto.name ? navDto.name : this.name;
    this.index = !isNaN(navDto.index) ? navDto.index : undefined;
    this.color = navDto.color ? navDto.color : this.color;
    this.homepage = navDto.homepage ? navDto.homepage : this.homepage;
    this.navigationAll =
      navDto.navigation && navDto.navigation.length > 0 ? navDto.navigation.map((nav) => new AgrNav(nav)) : this.navigation;
    this.navigationAll = this.navigationAll.filter((nav) => nav.isValid());
    this.navigation = cloneDeep(this.navigationAll); // Reset later by the nav.component.ts and auth.service
    this.navigationDropdown = cloneDeep(this.navigation);
  }

  toDto(): ModularNavDTO {
    return {
      name: this.name,
      index: this.index,
      color: this.color ? this.color : undefined,
      homepage: this.homepage ? this.homepage : undefined,
      navigation: this.navigationAll.map((nav) => nav.toDto())
    };
  }

  isValid(): boolean {
    return this.navigationAll.length > 0;
  }

  flattenDropdownNavigation(): void {
    this.navigationDropdown = this.navigation.flatMap((nav) => {
      return nav.submenu.length > 0 ? [nav, ...nav.submenu] : [nav];
    });
    this.navigationDropdown = this.navigationDropdown.filter((nav) => !!nav.path);
  }

  resetDropdownNavigation(): void {
    this.navigationDropdown = cloneDeep(this.navigation);
  }

  addNav(nav: AgrNav): void {
    this.addMoreDropdown();
    const moreDropdown = this.addMoreDropdown();
    moreDropdown.submenuAll.push(nav);
    moreDropdown.submenu.push(nav);
    moreDropdown.feature.concat(nav.feature);
  }

  addMoreDropdown(): AgrNav {
    const moreDropdown = this.navigationAll.find((navItem) => navItem.caption === 'MORE');
    if (!moreDropdown) {
      const moreDropdownNav = new AgrNav({ caption: 'MORE' });
      this.navigation.push(moreDropdownNav);
      this.navigationAll.push(moreDropdownNav);
    }
    return this.navigationAll.find((navItem) => navItem.caption === 'MORE');
  }
}

export class AgrNav {
  caption = '';
  path = '';
  feature: string[];
  divider: boolean;
  icon = '';
  submenuAll: AgrNav[] = []; // All submenu items
  submenu: AgrNav[] = []; // Available submenu items based on users feature access

  // eslint-disable-next-line complexity
  constructor(navDto: AgrNavDTO) {
    if (!navDto) {
      return;
    }
    this.caption = navDto.caption ? navDto.caption : this.caption;
    this.path = navDto.path ? navDto.path : this.path;
    this.feature =
      typeof navDto.feature === 'string' ? [navDto.feature] : navDto.feature && navDto.feature.length > 0 ? navDto.feature : [];
    this.feature = this.feature.filter((feature) => !!feature); // Remove empty strings in array, e.g [""]
    this.divider = !!navDto.divider;
    this.icon = navDto.icon ? navDto.icon : this.icon;
    this.submenuAll = navDto.submenu && navDto.submenu.length > 0 ? navDto.submenu.map((nav) => new AgrNav(nav)) : this.submenu;
    this.submenu = cloneDeep(this.submenuAll); // Reset later by the nav.component.ts and auth.service
  }

  toDto(): AgrNavDTO {
    return {
      caption: this.caption,
      path: this.path,
      feature: this.feature ? this.feature : undefined,
      divider: this.divider ? this.divider : undefined,
      icon: this.icon ? this.icon : undefined,
      submenu: this.submenuAll.map((nav) => nav.toDto())
    };
  }

  isValid(): boolean {
    const hasCaptionOrIcon = !!this.caption || !!this.icon;
    const isValid = (!!this.path && hasCaptionOrIcon) || this.divider || (this.submenu.length > 0 && hasCaptionOrIcon);
    if (!isValid) {
      console.warn(
        `AGR: Invalid nav item detected. Possibly missing caption/icon or path. Fix in Settings > System > App/Settings Navigation.`,
        this
      );
    }
    return isValid;
  }
}

const defaultAppNav: ModularNavDTO[] = [
  {
    name: 'INVENTORY',
    index: 0,
    color: 'blue',
    homepage: 'dashboard',
    navigation: [
      {
        caption: 'ITEMS',
        path: 'items',
        feature: ['items']
      },
      {
        caption: 'NAV_REPORTS',
        path: 'reports',
        feature: ['reports']
      },
      {
        caption: 'NAV_PLANS',
        path: 'workspaces/list/plans',
        feature: ['plans']
      },
      {
        caption: 'NAV_ORDERS',
        path: 'orders',
        feature: ['orders']
      },
      {
        caption: 'NAV_ALLOCATION',
        path: 'workspaces/allocation/allocation',
        feature: ['allocation']
      }
    ]
  },
  {
    name: 'PLANNING',
    index: 1,
    color: 'green',
    navigation: [
      {
        caption: 'NAV_MERCHANDISE_FINANCIAL_PLANNING',
        path: 'workspaces/mfp/mfp',
        feature: ['mfp']
      },
      {
        caption: 'NAV_LOCATION_FINANCIAL_PLANNING',
        path: 'workspaces/financial_planner/financial_planner',
        feature: ['financial_planner']
      },
      {
        caption: 'NAV_LOCATION_CLUSTERING',
        path: 'workspaces/location_clustering/lc',
        feature: ['location_clustering']
      }
    ]
  },
  {
    name: 'ASSORTMENT',
    index: 2,
    color: 'orange',
    navigation: [
      {
        caption: 'NAV_RANGE_PLANNING',
        path: 'workspaces/rm/rm',
        feature: ['rm']
      },
      {
        caption: 'NAV_OPTION_PLANNING',
        path: 'workspaces/om/om',
        feature: ['om']
      },
      {
        caption: 'NAV_STORE_ASSORTMENT',
        path: 'workspaces/assortment_operational/assortment',
        feature: ['assortment_operational']
      }
    ]
  }
];

const defaultSettingsNav: AgrNavDTO[] = [
  {
    caption: 'NAV_SETTINGS',
    path: 'settings/view/orders',
    feature: ['settings']
  },
  {
    caption: 'NAV_USER_MANAGEMENT',
    path: 'settings/user-management/users',
    feature: ['userManagement']
  },
  {
    caption: 'NAV_WORKSPACES',
    path: 'workspaces/list/all',
    feature: ['workspaces']
  }
];
