/* eslint-disable max-lines-per-function */
/* eslint-disable max-classes-per-file */
import { ColDef } from '@ag-grid-community/core';
import { get } from 'lodash';

import { AgrUtils } from 'src/app/_shared/utils/agr-utils';
import { ConfigColumnAggregation, ConfigColumnAggregationDTO } from 'src/app/workspaces/details/config/config-column-aggregation.model';
import { ConfigColumnEdit, ConfigColumnEditDTO } from 'src/app/workspaces/details/config/config-column-edit.model';
import { ConfigColumnFormat, ConfigColumnFormatDTO } from 'src/app/workspaces/details/config/config-column-format.model';
import { ConfigColumnLink, ConfigColumnLinkDTO } from 'src/app/workspaces/details/config/config-column-link.model';
import { ConfigColumnRow, ConfigColumnRowDTO } from 'src/app/workspaces/details/config/config-column-row.model';

const appConfig: ConfigColumnBaseDTO = {
  excluded: false,
  visible: true,
  pinned: false,
  format: {
    caption: undefined,
    format: undefined,
    align: undefined,
    agGridColumnProperties: {}
  },
  link: {
    targetView: undefined,
    path: undefined,
    columns: [],
    map: [],
    drilldownsToClose: []
  },
  edit: {
    enabled: false,
    cond: {},
    lookupConfig: {
      fixedOptions: [],
      cache: true
    },
    checkboxes: {
      enabled: false,
      trueValue: 1,
      falseValue: 0,
      disableIfNull: false
    }
  },
  aggregation: {
    enabled: false,
    type: 'sum',
    distMethod: 'proportional',
    action: 'update_value'
  },
  row: {},
  itemDetails: {
    edit: {
      enabled: false,
      lookup: false,
      dateConditions: {},
      lookupConfig: {
        fixedOptions: []
      },
      targetColumnId: undefined,
      agrFormat: undefined
    }
  }
};

//////////////////////////////////////////
/**
 * Config Column.
 *
 * The class wraps view, default and app configs to make getting and setting configs easier.
 * Configs are defined in three places: Columns of workspace view, context line and finally in the app (the default).
 * Config from view will override context line config, which will override app defaults.
 */
export class ConfigColumn {
  excluded?: boolean; // Exclude/include selected column (link icon)
  visible?: boolean; // Not shown in grid (eye icon)
  pinned?: boolean; // Required (lock icon)
  format = new ConfigColumnFormat();
  link = new ConfigColumnLink();
  edit = new ConfigColumnEdit();
  aggregation = new ConfigColumnAggregation();
  row = new ConfigColumnRow();
  jsFormula?: string;
  itemDetails: object;
  hasViewConfig = false; // Used to activate </> button
  jsonColumns?: object | object[];

  private viewConfig;
  private defaultConfig;
  private appConfig = new ConfigColumnBase(appConfig);

  constructor(viewConfigDto?: ConfigColumnBaseDTO, defaultConfigDto?: ConfigColumnBaseDTO) {
    this.viewConfig = new ConfigColumnBase(viewConfigDto);
    this.defaultConfig = new ConfigColumnBase(defaultConfigDto);
    this.assign();
  }

  getViewConfig(): ConfigColumnBase {
    return this.viewConfig;
  }

  getDefaultConfig(): ConfigColumnBase {
    return this.defaultConfig;
  }

  /**
   * Returns config object with none of the empty objects and arrays.
   */
  getViewConfigClean(): ConfigColumnBase {
    const sanitized = AgrUtils.sanitize(this.viewConfig);
    return AgrUtils.deleteEmpty(sanitized) as ConfigColumnBase;
  }

  /**
   * Returns config object with none of the empty objects and arrays.
   */
  getDefaultConfigClean(): ConfigColumnBase {
    const sanitized = AgrUtils.sanitize(this.defaultConfig);
    return AgrUtils.deleteEmpty(sanitized) as ConfigColumnBase;
  }

  //////////////////////////////////////////

  setViewConfig(configDto: ConfigColumnBaseDTO): void {
    this.viewConfig = new ConfigColumnBase(configDto);
    this.assign();
  }

  setDefaultConfig(configDto: ConfigColumnBaseDTO): void {
    this.defaultConfig = new ConfigColumnBase(configDto);
    this.assign();
  }

  togglePinned(): void {
    this.viewConfig.pinned = !this.pinned;
    this.viewConfig.excluded = this.viewConfig.pinned ? false : this.excluded;
    this.viewConfig.visible = this.viewConfig.pinned ? true : this.visible;
    this.cleanOnDefault();
    this.assign();
  }

  toggleVisible(): void {
    this.viewConfig.visible = !this.visible;
    this.viewConfig.excluded = !this.viewConfig.visible ? false : this.excluded;
    this.viewConfig.pinned = !this.viewConfig.visible ? false : this.pinned;
    this.cleanOnDefault();
    this.assign();
  }

  toggleExcluded(): void {
    this.viewConfig.excluded = !this.excluded;
    this.viewConfig.pinned = this.viewConfig.excluded ? false : this.pinned;
    this.viewConfig.visible = this.viewConfig.excluded ? true : this.visible;
    this.cleanOnDefault();
    this.assign();
  }
  /**
   * Removes selected properties from view config if they match app default.
   * This is done so that this.hasViewConfig will not always be true after user toggles config buttons.
   */
  private cleanOnDefault(): void {
    let doCleanUp = true;
    if (this.viewConfig.pinned !== this.appConfig.pinned) {
      doCleanUp = false;
    }
    if (this.viewConfig.excluded !== this.appConfig.excluded) {
      doCleanUp = false;
    }
    if (this.viewConfig.visible !== this.appConfig.visible) {
      doCleanUp = false;
    }
    if (doCleanUp) {
      delete this.viewConfig.pinned;
      delete this.viewConfig.excluded;
      delete this.viewConfig.visible;
    }
  }

  private assign(): void {
    this.excluded = this.get('excluded');
    this.visible = this.get('visible');
    this.pinned = this.get('pinned');
    // Format
    this.format.caption = this.get('format.caption');
    this.format.format = this.get('format.format');
    this.format.align = this.get('format.align');
    this.format.agGridColumnProperties = this.getAgGridColumnProperties();
    // Link
    this.link.targetView = this.get('link.targetView');
    this.link.path = this.get('link.path');
    this.link.columns = this.get('link.columns');
    this.link.map = this.get('link.map');
    this.link.drilldownsToClose = this.get('link.drilldownsToClose');
    // Edit
    this.edit.enabled = this.get('edit.enabled');
    this.edit.type = this.get('edit.type');
    this.edit.targetIdColumn = this.get('edit.targetIdColumn');
    this.edit.targetColumn = this.get('edit.targetColumn');
    this.edit.action = this.get('edit.action');
    this.edit.actionArgs = {};

    Object.assign(this.edit.actionArgs, this.get('edit.actionArgs'));

    this.edit.cond.column = this.get('edit.cond.column');
    this.edit.cond.type = this.get('edit.cond.type');
    this.edit.cond.value = this.get('edit.cond.value');
    this.edit.lookup = this.get('edit.lookup');

    this.edit.lookupConfig.fixedOptions = this.get('edit.lookupConfig.fixedOptions');
    this.edit.lookupConfig.fixedOptionsAtStart = this.get('edit.lookupConfig.fixedOptionsAtStart');
    this.edit.lookupConfig.query = this.get('edit.lookupConfig.query');
    this.edit.lookupConfig.idColumn = this.get('edit.lookupConfig.idColumn');
    this.edit.lookupConfig.valueColumn = this.get('edit.lookupConfig.valueColumn');
    this.edit.lookupConfig.translateOptions = this.get('edit.lookupConfig.translateOptions');
    this.edit.lookupConfig.cache = this.get('edit.lookupConfig.cache');

    this.edit.checkboxes.enabled = this.get('edit.checkboxes.enabled');
    this.edit.checkboxes.trueValue = this.get('edit.checkboxes.trueValue');
    this.edit.checkboxes.falseValue = this.get('edit.checkboxes.falseValue');
    this.edit.checkboxes.disableIfNull = this.get('edit.checkboxes.disableIfNull');
    // Aggregation
    this.aggregation.enabled = this.get('aggregation.enabled');
    this.aggregation.type = this.get('aggregation.type');
    this.aggregation.editable = this.get('aggregation.editable');
    this.aggregation.distMethod = this.get('aggregation.distMethod');
    this.aggregation.action = this.get('aggregation.action');
    this.aggregation.actionArgs = {};
    Object.assign(this.aggregation.actionArgs, this.get('aggregation.actionArgs'));
    this.row.autoRefresh = this.get('row.autoRefresh');
    this.row.refreshWholeGrid = this.get('row.refreshWholeGrid');
    // Other
    this.jsFormula = this.get('jsFormula');
    this.hasViewConfig = JSON.stringify(this.getViewConfigClean()) !== '{}';
    this.itemDetails = this.get('itemDetails');
    this.jsonColumns = this.get('jsonColumns');
  }

  // Private
  /**
   * Get config property following the hierarchy:
   * 1. View config
   * 2. Default config (from data_element_ref_columns)
   * 3. App config
   */
  private get(key: string): any {
    if (get(this.viewConfig, key) !== undefined) {
      return get(this.viewConfig, key);
    }
    if (get(this.defaultConfig, key) !== undefined) {
      return get(this.defaultConfig, key);
    }
    return get(this.appConfig, key);
  }

  private getAgGridColumnProperties(): ColDef {
    if (Object.keys(this.viewConfig.format.agGridColumnProperties || {}).length > 0) {
      return this.viewConfig.format.agGridColumnProperties;
    }
    if (Object.keys(this.defaultConfig.format.agGridColumnProperties || {}).length > 0) {
      return this.defaultConfig.format.agGridColumnProperties;
    }
    return {};
  }
}
//////////////////////////////////////////
export interface ConfigColumnBaseDTO {
  excluded?: boolean;
  visible?: boolean;
  pinned?: boolean;
  link?: ConfigColumnLinkDTO;
  format?: ConfigColumnFormatDTO;
  edit?: ConfigColumnEditDTO;
  aggregation?: ConfigColumnAggregationDTO;
  row?: ConfigColumnRowDTO;
  jsFormula?: string;
  itemDetails?: object;
  jsonColumns?: object | object[];
}
/**
 * Column Config.
 */
class ConfigColumnBase {
  excluded?: boolean;
  visible?: boolean;
  pinned?: boolean;
  format = new ConfigColumnFormat();
  link = new ConfigColumnLink();
  edit = new ConfigColumnEdit();
  aggregation = new ConfigColumnAggregation();
  row = new ConfigColumnRow();
  jsFormula?: string;
  itemDetails?: object;
  jsonColumns?: object | object[];

  constructor(configDto?: ConfigColumnBaseDTO) {
    if (!configDto) {
      return;
    }
    this.excluded = configDto.excluded;
    this.visible = configDto.visible;
    this.pinned = configDto.pinned;
    this.format = new ConfigColumnFormat(configDto.format);
    this.link = new ConfigColumnLink(configDto.link);
    if (configDto.format) {
      this.format = new ConfigColumnFormat(configDto.format);
    }
    if (configDto.edit) {
      this.edit = new ConfigColumnEdit(configDto.edit);
    }
    if (configDto.aggregation) {
      this.aggregation = new ConfigColumnAggregation(configDto.aggregation);
    }
    if (configDto.row) {
      this.row = new ConfigColumnRow(configDto.row);
    }
    this.jsFormula = configDto.jsFormula;
    this.itemDetails = configDto.itemDetails;
    this.jsonColumns = configDto.jsonColumns;
  }
}
