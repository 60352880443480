import { camelCase } from 'lodash';

import { TimezoneService } from 'src/app/_core/timezone.service';
import { ChartDataDTO, ChartElementTypeDTO, SerieDataUpdateDTO } from 'src/app/item-card/models/item-card-dto.model';

/**
 * The properties of an Item that share the same date. A vertical (chart y-point) view of Item Data/Details.
 */
export class ItemDataPoint {
  date: Date;
  sale: number;
  saleComment: string;
  originalSale: number;
  stockHistory: number;
  forecast: number;
  reserved: number;
  knownDemand: number;
  estimatedStock: number;
  safetyStockForOrderPeriod: number;
  undelivered: number;
  orderQty: number;
  purchasePlanOrderQty: number;
  bomDemand: number;
  minStockLevel: number;
  plannerBaseline: number;
  plannerPromo: number;
  stockout: number;
  purchasePlanCalculated: number;
  demandFromStores: number;
  plannedOrderQty: number;

  isAggregatedOverPeriod = false;
  isToday = false;

  static highchartsPointToUpdateDto(point: Highcharts.Point): SerieDataUpdateDTO {
    return {
      newValue: point.y,
      utcDataInt: TimezoneService.chartDataMillisecondsToServerTime(new Date(point.x).getTime()) / 1000
    };
  }

  /**
   * ItemDataPoint is used by the ItemCardGrid and partially with the chart when updating.
   * @param chartDataDTO as constructed within the service from API
   */
  constructor(chartDataDTO?: ChartDataDTO, chartElementTypes?: ChartElementTypeDTO[]) {
    if (!chartDataDTO) {
      return;
    }
    this.date = chartDataDTO.chart_date ? new Date(chartDataDTO.chart_date) : undefined;
    this.update(chartDataDTO, chartElementTypes);
  }

  toSerieDataUpdateDto(): SerieDataUpdateDTO {
    return {
      changeComment: this.saleComment === undefined ? '' : this.saleComment,
      chartItemSelect: undefined, // Not used by API
      forecastLength: 0, // Not used by API
      historyLength: 0, // Not used by API
      newValue: this.getAdjustedSale(),
      utcDataInt: TimezoneService.chartDataMillisecondsToServerTime(this.date.getTime()) / 1000
    };
  }

  update(chartDataDTO: ChartDataDTO, chartElementTypes?: ChartElementTypeDTO[]): void {
    // Map to correct class property based on chart_element_id, return gracefully if no matching ID
    const elementType = chartElementTypes.find((chartElementType) => chartElementType.id === chartDataDTO.chart_element_id);
    if (!elementType) {
      console.warn(`AGR: chart_data ${chartDataDTO} does not belong to any serie (chart_element_type).`);
      return;
    }
    const property = elementType && elementType.name ? camelCase(elementType.name) : 'undefined';
    this[property] = chartDataDTO.value;
    if (chartDataDTO.chart_element_id === 1) {
      this.saleComment = chartDataDTO.description;
    }
    this.isAggregatedOverPeriod = this.isAggregatedOverPeriod || elementType.aggregatedOverPeriod;
    this.setIsToday();
  }

  private getAdjustedSale(): number {
    const undefinedSale = isNaN(this.sale);
    const adjustedSale = this.sale !== this.originalSale;
    if (undefinedSale && this.saleComment) {
      return this.originalSale;
    }
    if (this.saleComment) {
      return this.sale;
    }
    if (undefinedSale || !adjustedSale) {
      return undefined;
    }
    return this.sale;
  }

  private setIsToday(): void {
    const today = new Date();
    this.isToday =
      this.date.getDate() === today.getDate() &&
      this.date.getMonth() === today.getMonth() &&
      this.date.getFullYear() === today.getFullYear();
  }
}
