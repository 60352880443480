import { ConfigActionArgDTO } from 'src/app/workspaces/details/config/shared/config-action-args.model';
import { ConfigCheckboxes } from 'src/app/workspaces/details/config/shared/config-checkboxes.model';

export interface ConfigSerieEditDTO {
  enabled?: boolean;
  readOnlyIfNull: boolean;
  toggleEditableRelativeToCurrent: { [columnName: string]: ConfigSerieFromTo };
  action?: string;
  actionArgs?: ConfigActionArgDTO;
  checkboxes?: ConfigCheckboxes;
}

interface ConfigSerieFromTo {
  from: number;
  to: number;
}

export class ConfigSerieEdit {
  enabled?: boolean;
  readOnlyIfNull: boolean;
  toggleEditableRelativeToCurrent: { [columnName: string]: ConfigSerieFromTo } = {};
  action?: string;
  actionArgs?: ConfigActionArgDTO = {};
  checkboxes?: ConfigCheckboxes = new ConfigCheckboxes();

  constructor(dto?: ConfigSerieEditDTO) {
    if (!dto) {
      return;
    }
    this.enabled = dto.enabled;
    this.readOnlyIfNull = dto.readOnlyIfNull;
    this.toggleEditableRelativeToCurrent = dto.toggleEditableRelativeToCurrent;
    this.action = dto.action;
    if (dto.actionArgs) {
      Object.assign(this.actionArgs, dto.actionArgs);
    }
    this.checkboxes = dto.checkboxes;
  }
}
