import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'agr-toolbar-btn',
  templateUrl: './toolbar-btn.component.html'
})
export class ToolbarBtnComponent implements OnInit {
  // Click event needs to come from button element for [disabled] to work
  @Output() readonly btnClick: EventEmitter<any> = new EventEmitter();

  @Input() btnType?: string; // See btnTypes for available types
  @Input() active?: boolean; // Adds 'active' class
  @Input() disabled?: boolean; // Disables the button
  @Input() highlight?: boolean; // Highlights the button
  @Input() unsavedChanges?: boolean; // Highlights the button to indicate unsaved changes
  // Optional overrides
  @Input() style?: 'success' | 'warning' | 'danger';
  @Input() icon?: string; // Override icon name
  @Input() translationKey?: string; // Override translation key - Used in tooltip
  @Input() postfix?: string; // Override postfix - Postfixed to translation
  @Input() btnId: string; // HTML id of the button (default 'tb-btn-[btnType])
  @Input() placement = 'top'; // Tooltip placement value, overwrite e.g. 'top-left' for first/last btn in toolbars

  /**
   * Key has match with this.btnType
   * type: ['icon name', 'translation key', 'postfix']
   */
  btnTypes = {
    calendar: ['calendar', 'CALENDAR'],
    chart: ['line-chart', 'CHART'],
    close: ['close', ''],
    confirm: ['check', 'CONFIRM', '...'],
    create: ['plus', 'CREATE', '...'],
    delete: ['trash', 'DELETE', '...'],
    edit: ['pencil', 'EDIT', '...'],
    excel: ['file-excel-o', 'EXPORT_TO_EXCEL'],
    filter: ['filter', 'FILTER'],
    history: ['history', 'HISTORY'],
    image: ['image', 'PRODUCT_IMAGE'],
    info: ['info', 'INFO'],
    itemCard: ['bar-chart', 'ITEM_CARD'],
    newWindow: ['external-link', 'OPEN_IN_NEW_WINDOW'],
    order: ['shopping-cart', 'CREATE_ORDER', '...'],
    recalculate: ['calculator', 'RECALCULATE'],
    redo: ['repeat', 'REDO'],
    save: ['save', 'SAVE'],
    start: ['play', 'START'],
    stop: ['stop', 'STOP'],
    sync: ['refresh', 'SYNC_GRID'],
    unconfirm: ['undo', 'UNCONFIRM', '...'],
    undo: ['undo', 'UNDO'],
    view: ['eye', 'VIEW_DETAILS']
  };
  btnClass: string;

  constructor() {}

  ngOnInit(): void {
    this.initializeProperties();
  }

  /**
   * Apply values from predefined button types
   */
  private initializeProperties(): void {
    if (this.btnTypes[this.btnType]) {
      this.icon = this.icon || this.btnTypes[this.btnType][0];
      this.translationKey = this.translationKey || this.btnTypes[this.btnType][1];
      this.postfix = this.postfix !== undefined ? this.postfix : this.btnTypes[this.btnType][2] || '';
    }
    this.btnId = this.btnId || `tb-btn-${this.btnType}`;
    this.btnClass = this.style ? `btn-${this.style}` : 'agr-toolbar-btn-default';
  }
}
