import { TimezoneService } from 'src/app/_core/timezone.service';

/**
 * This object is dynamic in API.
 * Comes from select_action_by_user_id SP.
 */
export interface ExecutionNotificationDTO {
  id: number;
  type: string;
  caption: string;
  createdAt: string; // Date 'YYYY-MM-DDThh:mm:ss.SS'
  durationMinutes: number;
  errorMessage: string;
  progressPercent: number;
  success: boolean;
  url: string;
  userId: number;
  loginName: string; // login username
  userName: string;
  queueId: number;
  referenceId: number;
}

export class ExecutionNotification {
  id: number;
  type: string;
  errorMessage: string;
  duration: number;
  caption: string;
  progressPercent: number;
  success: boolean;
  createdAt: Date;
  url: string;
  loginName: string;
  userName: string;
  userId: number;
  queueId: number;
  referenceId: number;

  constructor(dto: ExecutionNotificationDTO) {
    this.id = dto.id;
    this.type = dto.type;
    this.caption = dto.caption;
    this.createdAt = TimezoneService.serverTimeToClientTime(dto.createdAt);
    this.errorMessage = dto.errorMessage;
    this.progressPercent = dto.progressPercent;
    this.success = dto.success;
    this.duration = Math.round(dto.durationMinutes * 60 * 1000);
    this.url = this.getUrl(this.type);
    this.loginName = dto.loginName;
    this.userName = dto.userName;
    this.userId = dto.userId;
    this.queueId = dto.queueId;
    this.referenceId = dto.referenceId;
  }

  /**
   * The URL provided in select_action_by_user_id SP are designed for AGR 5 and are usually wrong for AGR 6.
   * The front-end should probably be controlling the urls anyway.
   */
  private getUrl(type: string): string {
    if (this.errorMessage) {
      return '/executions';
    }
    if (
      [
        'CREATE_PLANNED_ORDER',
        'CREATE_PLANNED_ITEM_ORDER',
        'CREATE_ORDER',
        'CREATE_ITEMS_ORDER',
        'RECALC_ORDER',
        'CONSTRAIN_ORDER',
        'SPLIT_ORDER'
      ].indexOf(type) > -1
    ) {
      return '/orders';
    }
    return '/executions';
  }
}

export interface ActionExecutionResponseDTO {
  ActionExecutionGuid: string;
  ActionExecutionId: number;
  ActionType: string;
  HasError: boolean;
  ItemsLocked: boolean;
  LockedStatus?: string; // On failed
}
