import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';

import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessGroupDTO } from 'src/app/_core/models/access-group.model';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { UserDTO, UserUpdateResponse } from 'src/app/_core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersModalService {
  constructor(private httpClient: HttpClient) {}

  getAccessGroups(): Observable<AccessGroupDTO> {
    return this.httpClient.get<AccessGroupDTO>(`${API_ROOT}/access-groups`);
  }

  create(formValue: any): Observable<UserUpdateResponse> {
    const user: UserDTO = {
      name: formValue.name,
      username: formValue.username,
      email: formValue.email,
      roles: formValue.roles,
      accessGroups: formValue.accessGroups,
      password: formValue.newPassword.password,
      passwordConfirmation: formValue.newPassword.confirmPassword,
      language: ''
    };
    return this.httpClient.post<UserUpdateResponse>(`${API_ROOT}/users/`, user);
  }

  update(user: UserDTO, formValue: any): Observable<UserUpdateResponse> {
    user.name = formValue.name;
    // formValue.username is not set when field is disabled (when editing user)
    user.username = formValue.username ? formValue.username : user.username;
    user.email = formValue.email;
    user.roles = formValue.roles;
    user.accessGroups = formValue.accessGroups;
    if (get(formValue, 'newPassword.password.length') > 0) {
      user.password = formValue.newPassword.password;
      user.passwordConfirmation = formValue.newPassword.confirmPassword;
    }
    return this.httpClient.put<UserUpdateResponse>(`${API_ROOT}/users/${user.id}`, user);
  }

  // -  Form Validation

  passwordMismatch(newPassword: FormGroup): { mismatch: boolean } {
    return newPassword.get('password').value === newPassword.get('confirmPassword').value ? undefined : { mismatch: true };
  }

  /**
   * Async Validation
   * Checks if username or email exists in DB
   * Doc: https://alligator.io/angular/async-validators/
   */
  asyncValidation(control: FormControl, currentUser: {} = {}, userType: string): Observable<ValidationErrors | undefined> {
    const apiPath = userType === 'email' ? `${API_ROOT}/users/user-exists?email=` : `${API_ROOT}/users/user-exists?username=`;
    return this.httpClient.get<any>(`${apiPath}${control.value}`).pipe(
      map((exists) => {
        if (control.value.length === 0 || control.value === currentUser[userType]) {
          return undefined;
        }
        return exists ? { exists: true } : undefined;
      })
    );
  }
}
