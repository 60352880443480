/**
 * From local environment.ts files
 */
export interface EnvironmentDTO {
  version: string;
  buildInfo: string;
  name: string;
  production: boolean;
  imageVersion: string;
}

export class ClientVersion {
  version: string;
  buildInfo: string;
  versionName: string;
  versionLabel: string;
  imageVersion: string;

  constructor(dto?: EnvironmentDTO) {
    if (!dto) {
      return;
    }
    this.version = dto.version;
    this.buildInfo = dto.buildInfo;
    this.versionName = this.getName(this.version);
    this.versionLabel = this.getLabel(this.version);
    this.imageVersion = dto.imageVersion;
  }

  /**
   * E.g. 6.0
   */
  private getName(version: string): string {
    const split = version.split('.');
    return `${split[0]}.${split[1]}`; // E.g. 6.0.0 -> 6.0
  }

  /**
   * E.g. v6.0
   */
  private getLabel(version: string): string {
    const split = version.split('.');
    return `v${split[0]}.${split[1]}`; // E.g. 6.0.0 -> v6.0
  }
}
