export class ApiVersion {
  version: string;
  buildNumber?: string;
  buildSuffix?: string;
  dataSource?: string;
  imageVersion?: string;

  constructor(dto?: ApiVersion) {
    if (!dto) {
      return;
    }
    Object.assign(this, dto);
  }
}
