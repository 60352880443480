import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';
import { ItemCardDetailsService } from 'src/app/item-card/details/item-card-details.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { isNil, isUndefined } from 'lodash';

@Component({
  selector: '[agr-details-date-columns]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './editable-date-columns.component.html',
  styleUrls: ['../details-columns.component.scss']
})
export class EditableDateColumnsComponent implements OnInit {
  @Input() column: DetailsColumn;
  @Input() itemId: number;
  @Input() formGroup: FormGroup;
  @Input() bulkUpdate: boolean;
  @Output() readonly changed = new EventEmitter();

  editing: Boolean = false;
  today = new Date();
  model: NgbDateStruct;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  datePickerPast: NgbDateStruct = { year: 1901, month: 1, day: 1 };
  datePickerFuture: NgbDateStruct = { year: 2100, month: 1, day: 1 };
  datePickerToday: NgbDateStruct = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

  useGlobal: Boolean;

  constructor(private itemCardDetailsService: ItemCardDetailsService) {}

  ngOnInit(): void {
    this.formGroup.get(this.column.name).setValue(this.column.value);
    this.getMinDate();
    this.getMaxDate();
  }

  getValue(): string {
    return this.column.overwriteValue ? this.column.overwriteValue : this.column.value;
  }

  onDateSelected(date: NgbDate): void {
    const value = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format();
    this.editing = false;
    const invalidValue = value === null; // Validation
    if (invalidValue) {
      this.reset();
      return;
    }
    this.formGroup.get(this.column.name).markAsDirty();
    this.column.overwriteValue = value;
    if (!this.bulkUpdate) {
      this.update();
    }
  }

  hasOverwriteValue(): boolean {
    if (isNil(this.column.overwriteValue) || isUndefined(this.column.overwriteValue)) {
      return this.bulkUpdate ? this.formGroup.get(this.column.name).dirty : false;
    }
    return true;
  }

  reset(): void {
    this.column.overwriteValue = undefined;
    this.formGroup.get(this.column.name).markAsPristine();
    if (!this.bulkUpdate) {
      this.update();
    }
  }

  close(): void {
    this.editing = false;
  }

  toggleGlobal(): void {
    this.useGlobal = !this.useGlobal;
    if (this.useGlobal) {
      this.column.overwriteValue = undefined;
      this.formGroup.get(this.column.name).markAsDirty();
    }
    if (!this.useGlobal) {
      this.formGroup.get(this.column.name).markAsPristine();
    }
  }

  private update(): void {
    this.itemCardDetailsService.updateValues(this.column, this.column.overwriteValue, this.itemId).subscribe(() => {
      this.changed.emit();
    });
  }

  private getMinDate(): void {
    const minDate = this.column.config.edit.dateConditions.minDate;
    this.minDate = minDate ? (minDate === 'today' ? this.datePickerToday : this.setDate(minDate)) : this.datePickerPast;
  }

  private getMaxDate(): void {
    const maxDate = this.column.config.edit.dateConditions.maxDate;
    this.maxDate = maxDate ? (maxDate === 'today' ? this.datePickerToday : this.setDate(maxDate)) : this.datePickerFuture;
  }

  private setDate(date: string): NgbDateStruct {
    return { year: new Date(date).getFullYear(), month: new Date(date).getMonth() + 1, day: new Date(date).getDate() };
  }
}
