export interface LoginDTO {
  access_token: string;
  expires_in: number; // seconds
  refresh_token: string;
}

/**
 * This class is stored as a session/local storage object, make sure it only contains primitive properties.
 */
export class Login {
  accessToken = '';
  refreshToken = '';
  expires = 0; // milliseconds
  loggedIn = 0; // milliseconds

  constructor(dto?: LoginDTO) {
    if (!dto) {
      return;
    }
    this.accessToken = dto.access_token;
    this.refreshToken = dto.refresh_token;
    this.loggedIn = new Date().getTime();
    const expiresIn = dto.expires_in * 1000;
    this.expires = new Date(this.loggedIn + expiresIn).getTime();
  }
}
