import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'agr-ag-grid-boolean-component',
  template: ` <span class="badge {{ bgClass }}" [hidden]="isUndefined">{{ caption | agrTranslate }}</span> `,
  styles: [
    `
      .badge {
        display: inline;
        padding: 2px 6px !important;
        font-size: 12px;
        height: 17px !important;
        position: relative;
        top: -0.5px;
      }
    `
  ]
})
export class AgGridBitRendererComponent implements ICellRendererAngularComp {
  isTrue: boolean;
  isUndefined: boolean;
  bgClass: string;
  caption: string;

  agInit(params: ICellRendererParams): void {
    this.setIsTrue(params.getValue());
    this.isUndefined = this.isTrue === undefined;
    this.bgClass = this.isTrue ? 'bg-primary' : 'bg-light';
    this.caption = this.isTrue ? 'YES' : 'NO';
  }

  setIsTrue(value: boolean | string): void {
    if (value === undefined) {
      return;
    }
    // the value is a string when grouped ('true' | 'false') but otherwise a boolean
    if (typeof value === 'string') {
      this.isTrue = value === 'true' ? true : value === 'false' ? false : undefined;
    } else if (typeof value === 'boolean') {
      this.isTrue = value;
    }
  }

  refresh(): boolean {
    return true;
  }
}
