/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable no-param-reassign */
/* eslint-disable id-length */
import { Component, ComponentFactoryResolver, ComponentRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { NgbDatepickerConfig, NgbModalConfig, NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import jquery from 'jquery';

import { getLocaleFirstDayOfWeek } from '@angular/common';
import { FormatService } from 'src/app/_core/format.service';
import { GlobalEventsService } from 'src/app/_core/global-events.service';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { SignalRService } from 'src/app/_core/signal-r.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { NavComponent } from 'src/app/nav/nav.component';

declare global {
  interface Window {
    Userlane: any;
    heap: any;
  }
}

@Component({
  selector: 'agr-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @ViewChild('nav', { read: ViewContainerRef, static: true }) nav: ViewContainerRef;
  navRef: ComponentRef<NavComponent>;
  toasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    mouseoverTimerStop: true,
    animation: 'fade', // slideUp can brake e2e tests
    limit: 4,
    timeout: {
      error: 9000,
      success: 5000,
      info: 5000
    }
  });

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private formatService: FormatService,
    private globalEventsService: GlobalEventsService,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private ngbModalConfig: NgbModalConfig,
    private ngbPopoverConfig: NgbPopoverConfig,
    private ngbTooltipConfig: NgbTooltipConfig,
    private ngSelectConfig: NgSelectConfig,
    private router: Router,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private signalRService: SignalRService,
    private titleService: Title,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef
  ) {
    // tooltip
    this.ngbTooltipConfig.container = 'body';
    // modal
    this.ngbModalConfig.backdrop = 'static';
    // popover
    this.ngbPopoverConfig.container = 'body';
    // datepicker
    const today = new Date();
    const month = today.getMonth();
    this.ngbDatepickerConfig.startDate = { month: month + 1, year: today.getFullYear() };
    this.ngbDatepickerConfig.showWeekNumbers = true;
    this.ngbDatepickerConfig.firstDayOfWeek = getLocaleFirstDayOfWeek(this.formatService.getLocale());
  }

  ngOnInit(): void {
    // Remove loading indicator. See index.html
    jquery('#app-loading').remove();
    // SignalR
    this.signalRService.createConnection();
    window.onbeforeunload = () => {
      this.signalRService.stopConnection();
    };

    // NgSelect Config
    this.ngSelectConfig.notFoundText = TranslationsService.get('NO_ITEMS_FOUND');
    this.ngSelectConfig.appendTo = 'body';

    this.setNavBarEvents();
    this.setDocumentTitle();
    this.setDemoMode();

    this.initializeUserlane(); // third-party software
    this.initializeHeap(); // third-party software
  }

  @HostListener('window:offline', ['$event']) onOffline(): void {
    if (this.settingsService.onlineCheck()) {
      this.toasterService.pop('warning', TranslationsService.get('CONNECTION_LOST'), TranslationsService.get('CONNECTION_LOST_MSG'));
    }
  }

  private setNavBarEvents(): void {
    this.globalEventsService.showNavBarEvent$.subscribe((showNavbar) => {
      if (this.navRef) {
        this.navRef.destroy();
        this.viewContainerRef.clear();
      }
      if (showNavbar) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(NavComponent);
        this.navRef = this.nav.createComponent(factory);
        this.navRef.changeDetectorRef.detectChanges();
        return;
      }
    });
  }

  /**
   * Used for demos to set fixed date.
   */
  private setDemoMode(): void {
    if (!this.settingsService.getValue('demo_mode')) {
      return;
    }
    const timemachine = require('timemachine');
    timemachine.config({
      dateString: this.settingsService.getValue('demo_mode_date'),
      tick: true
    });
    console.warn(`AGR: Demo mode is active. Current date set to ${new Date().toDateString()}`);
  }

  //////////////////////////////////////////

  /**
   * JS Snippet from Userlane
   * Adds a chatbox/helper on top of software. Responsible: Gunnar
   * https://www.userlane.com/
   */

  private initializeUserlane(): void {
    if (!this.settingsService.getValue('userlane_enabled')) {
      // Set a dummy userlane function so that other places where userlane is used do not error
      window.Userlane = () => 0;
      return;
    }
    this.loadUserlane(window, document, 'script', 'https://cdn.userlane.com/userlane.js', 'Userlane');
    // identify the user for Userlane
    const currentUser = this.sessionService.user;
    window.Userlane('identify', currentUser.id, {});
    // initialize Userlane with your Property ID
    window.Userlane('init', 'zr65v');
  }

  private loadUserlane(
    win: Window & typeof globalThis,
    doc: Document,
    type: string,
    path: string,
    name: string,
    el?: any,
    elCollection?: any
  ): void {
    win['UserlaneCommandObject'] = name;
    win[name] =
      win[name] ||
      function forUserlane(): void {
        (win[name].q = win[name].q || []).push(arguments);
      };
    el = doc.createElement(type);
    elCollection = doc.getElementsByTagName(type)[0];
    el.async = 1;
    el.src = path;
    elCollection.parentNode.insertBefore(el, elCollection);
  }

  //////////////////////////////////////////

  /**
   * JS Snippet from Heap
   * Adds analytics of how users are using the system. Responsible: Gunnar
   * https://heap.io/
   */

  // eslint-disable-next-line max-lines-per-function
  private initializeHeap(): void {
    window.heap = window.heap || [];
    // eslint-disable-next-line max-lines-per-function
    window.heap.load = function forHeap(e: any, t: any): void {
      window.heap.appid = e;
      window.heap.config = t = t || {};
      const heapScript = document.createElement('script');
      heapScript.type = 'text/javascript';
      heapScript.async = !0;
      heapScript.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;
      const a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(heapScript, a);
      for (
        let n = function (e: any): any {
            return function (): void {
              window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty'
          ],
          o = 0;
        o < p.length;
        o += 1
      ) {
        window.heap[p[o]] = n(p[o]);
      }
    };
    window.heap.load('4145477862'); // appId
  }

  //////////////////////////////////////////

  /**
   * Dynamic titles are handled in components (e.g. in report details and workspaces)
   */
  private setDocumentTitle(): void {
    this.router.events.subscribe((route) => {
      if (!(route instanceof RoutesRecognized)) {
        return;
      }
      const title = this.titles[route.url] ? this.titles[route.url] : 'AGR';
      this.titleService.setTitle(title);
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private titles = {
    '/dashboard': TranslationsService.get('NAV_DASHBOARD'),
    '/items': TranslationsService.get('ITEMS'),
    '/orders': TranslationsService.get('ORDERS'),
    '/reports': TranslationsService.get('REPORTS'),
    '/erp-setup/welcome': TranslationsService.get('SETUP_WELCOME_NAME'),
    '/erp-setup/items': TranslationsService.get('SETUP_ITEMS_NAME'),
    '/erp-setup/vendors': TranslationsService.get('SETUP_VENDORS_NAME'),
    '/erp-setup/order-routes': TranslationsService.get('SETUP_ORDER_ROUTES_NAME'),
    '/erp-setup/lead-time': TranslationsService.get('SETUP_LEAD_TIME_NAME'),
    '/erp-setup/customers': TranslationsService.get('SETUP_CUSTOMERS_NAME'),
    '/erp-setup/run': TranslationsService.get('SETUP_RUN_NAME'),
    '/scheduler/counting': TranslationsService.get('NAV_COUNTING'),
    '/scheduler/scheduled-orders': TranslationsService.get('SCHEDULED_ORDERS'),
    '/scheduler/tasks': TranslationsService.get('TASKS'),
    '/scheduler/schedules': TranslationsService.get('SCHEDULES'),
    '/settings/translations': TranslationsService.get('TRANSLATIONS'),
    '/settings/user-management/roles': `${TranslationsService.get('NAV_USER_MANAGEMENT')} - ${TranslationsService.get('ROLES')}`,
    '/settings/user-management/users': `${TranslationsService.get('NAV_USER_MANAGEMENT')} - ${TranslationsService.get('USERS')}`,
    '/settings/view/forecast': `${TranslationsService.get('SETTINGS')} - ${TranslationsService.get('FORECAST')}`,
    '/settings/view/orders': `${TranslationsService.get('SETTINGS')} - ${TranslationsService.get('ORDERS')}`,
    '/settings/view/system': `${TranslationsService.get('SETTINGS')} - System`,
    '/translations': `${TranslationsService.get('TRANSLATIONS')}`,
    '/workspaces/list/all': TranslationsService.get('WORKSPACES'),
    '/workspaces/list/plans': TranslationsService.get('NAV_PLANS'),
    '/workspaces/list/rm': TranslationsService.get('NAV_RANGE_MANAGER'),
    '/workspaces/list/dm': TranslationsService.get('NAV_DEPARTMENT_MANAGER'),
    '/workspaces/list/om': TranslationsService.get('NAV_OPTION_MANAGER'),
    '/workspaces/list/lm': TranslationsService.get('NAV_LOCATION_MANAGER'),
    '/workspaces/list/ppm': TranslationsService.get('PURCHASE_PLAN_MANAGER'),
    '/workspaces/list/rplm': TranslationsService.get('REPLENISHMENT_MANAGER')
  };
}
