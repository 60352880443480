/* eslint-disable max-len */
// eslint-disable-next-line max-classes-per-file
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { ContextLine } from 'src/app/_core/de-query/model/context-line.model';
import { DeQuery, DeQueryFilter } from 'src/app/_core/de-query/model/de-query.model';
import { TimezoneService } from 'src/app/_core/timezone.service';
import { WorkspaceFilter, WorkspaceFilterType } from 'src/app/workspaces/details/filters/models/workspace-filter.model';

export class WorkspacePeriodFilterValue {
  date: Date;
  caption: string;

  constructor(query: {}, periodFilter: WorkspacePeriodFilter) {
    this.caption = query[periodFilter.name];
    const dateId = query[periodFilter.idColumnName];
    const dateString = `${dateId}`; // e.g '20170613'
    this.date = new Date(+dateString.substr(0, 4), +dateString.substr(4, 2) - 1, +dateString.substr(6, 2));
  }
}

const periodTypes = ['DAY', 'WEEK', 'MONTH'];
type PeriodType = 'DAY' | 'WEEK' | 'MONTH' | 'UNKNOWN';

export class WorkspacePeriodFilter extends WorkspaceFilter {
  minDate: Date;
  maxDate: Date;
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  dateFromRelative: number;
  dateToRelative: number;
  isRelative = true;
  type: PeriodType = 'UNKNOWN';
  filterType: WorkspaceFilterType = 'WorkspacePeriodFilter';

  // Relative range
  minDateFromRelative: number; //
  get maxDateFromRelative(): number {
    return this.dateToRelative;
  }
  get minDateToRelative(): number {
    return this.dateFromRelative;
  }
  maxDateToRelative: number;

  // DAY - NgbDateStruct for DatePicker
  minNgbDateFrom: NgbDateStruct = { year: undefined, month: undefined, day: undefined };
  maxNgbDateFrom: NgbDateStruct = { year: undefined, month: undefined, day: undefined };
  minNgbDateTo: NgbDateStruct = { year: undefined, month: undefined, day: undefined };
  maxNgbDateTo: NgbDateStruct = { year: undefined, month: undefined, day: undefined };

  // WEEK/MONTH - ngSelect values, Date value and label for date
  ngSelectDateFrom: WorkspacePeriodFilterValue; // The selected value from @dateFromPeriodValues
  ngSelectDateTo: WorkspacePeriodFilterValue; // The selected value from @dateToPeriodValues
  periodValues: WorkspacePeriodFilterValue[] = []; // Selectable period values, Date Values { date: Date, caption: string }
  dateFromPeriodValues: WorkspacePeriodFilterValue[] = []; // This is changed when dateTo changes and vice versa to limit and avoid overlap
  dateToPeriodValues: WorkspacePeriodFilterValue[] = [];

  constructor(contextLine?: ContextLine) {
    super(contextLine);
    this.setType();
    this.setDefaultValues();
  }

  setDateValues(dates: WorkspacePeriodFilterValue[]): void {
    this.minDate = dates.length > 0 ? dates.reduce((prev, curr) => (prev.date < curr.date ? prev : curr)).date : this.minDate;
    this.maxDate = dates.length > 0 ? dates.reduce((prev, curr) => (prev.date > curr.date ? prev : curr)).date : this.maxDate;
    this.periodValues = dates;
    this.ngSelectDateFrom = this.dateFrom
      ? this.periodValues.find((period) => period.date.getTime() === this.dateFrom.getTime())
      : undefined;
    this.ngSelectDateTo = this.dateTo ? this.periodValues.find((period) => period.date.getTime() === this.dateTo.getTime()) : undefined;
    this.update();
  }

  /**
   * Validates values and fixes them if necessary.
   */
  validateAndUpdate(): void {
    const isValid =
      isNumber(this.dateFromRelative) &&
      isNumber(this.dateToRelative) &&
      isWithin(this.dateFromRelative, this.minDateFromRelative, this.maxDateFromRelative) &&
      isWithin(this.dateToRelative, this.minDateToRelative, this.maxDateToRelative);
    if (!isValid) {
      this.fixInvalid();
    }

    function isWithin(value: number, min: number, max: number): boolean {
      return value >= min && value <= max;
    }
    function isNumber(value: undefined | number): boolean {
      return typeof value === 'number';
    }
  }

  /**
   * Does any updates that has to happen on changes
   */
  update(): void {
    this.updateMinMaxDate();
    if (this.isRelative) {
      this.syncFixed();
      this.syncRelative();
    } else {
      this.syncRelative();
      this.syncFixed();
    }
  }

  /**
   * Validates selected Date and updates min max values so @dateTo and @dateFrom selection doesn't overlap
   * @param date selected Date which should be applied to @dateFrom after validation
   */
  // eslint-disable-next-line complexity
  updateDateFrom(date: Date): void {
    if (date === undefined) {
      return;
    }
    this.dateFrom =
      !this.minDate || (date && date >= this.minDate && date <= this.maxDate && (!this.dateTo || date <= this.dateTo))
        ? date
        : this.dateFrom;
    this.ngSelectDateFrom = this.dateFrom
      ? this.periodValues.find((period) => period.date.getTime() === this.dateFrom.getTime())
      : undefined;
  }

  /**
   * Validates selected Date and updates min max values so @dateTo and @dateFrom selection doesn't overlap
   * @param date selected Date which should be applied to @dateTo after validation
   */
  // eslint-disable-next-line complexity
  updateDateTo(date: Date): void {
    if (date === undefined) {
      return;
    }
    this.dateTo =
      !this.maxDate || (date && date >= this.minDate && date <= this.maxDate && (!this.dateFrom || date >= this.dateFrom))
        ? date
        : this.dateTo;
    this.ngSelectDateTo = this.dateTo ? this.periodValues.find((period) => period.date.getTime() === this.dateTo.getTime()) : undefined;
  }

  /**
   * Use to set and update min max values after model change or data received from database/local storage
   */
  updateMinMaxDate(): void {
    // NgbDate for 'DAY' datepicker
    this.updateMinNgbDateFrom();
    this.updateMaxNgbDateFrom();
    this.updateMinNgbDateTo();
    this.updateMaxNgbDateTo();
    // Relative boundaries
    this.updateMinDateFromRelative();
    this.updateMaxDateToRelative();
    // Available Ng-Select options in DateFrom and DateTo
    this.updateDateFromPeriodValues();
    this.updateDateToPeriodValues();
  }

  updateFromDeQueryFilter(filter: DeQueryFilter): void {
    this.isRelative = filter.operator === 'RELPERIODS';
    this.locked = !!filter.ui.fixed;
    if (this.isRelative) {
      this.dateFromRelative = +filter.values[0];
      this.dateToRelative = +filter.values[1];
    } else {
      const dateFrom = filter.values[0].toString(); // DeQueryFilter value received as string 'YYYYMMDD'
      this.updateDateFrom(new Date(+dateFrom.substr(0, 4), +dateFrom.substr(4, 2) - 1, +dateFrom.substr(6, 2)));
      const dateTo = filter.values[1].toString();
      this.updateDateTo(new Date(+dateTo.substr(0, 4), +dateTo.substr(4, 2) - 1, +dateTo.substr(6, 2)));
    }
    this.update();
  }

  toDeQueryFilter(): DeQueryFilter {
    return {
      column: {
        name: this.idColumnName
      },
      values: this.isRelative
        ? [this.dateFromRelative, this.dateToRelative]
        : [TimezoneService.dateToString(this.dateFrom).replace(/-/g, ''), TimezoneService.dateToString(this.dateTo).replace(/-/g, '')],
      operator: this.isRelative ? 'RELPERIODS' : 'BETWEEN',
      ui: {
        selectedSubtypeId: this.name,
        column: {
          name: this.groupName
        },
        fixed: this.locked,
        class: this.filterType
      }
    };
  }

  getDateValueQuery(): DeQuery {
    // 100 Years is the ultimate lower/upper limit, the limit is also determined by the database date table values.
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100));
    const minDateString = TimezoneService.dateToString(minDate).replace(/-/g, '');
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
    const maxDateString = TimezoneService.dateToString(maxDate).replace(/-/g, '');
    return {
      queryType: 'distinct',
      columns: [{ name: this.idColumnName }, { name: this.name }],
      filters: [
        {
          column: { name: this.idColumnName },
          operator: '>',
          values: [minDateString]
        },
        {
          column: { name: this.idColumnName },
          operator: '<',
          values: [maxDateString]
        }
      ]
    };
  }

  /**
   * Called from isValid() when either relative value is invalid
   */
  private fixInvalid(): void {
    this.dateFromRelative =
      this.dateFromRelative > this.maxDateFromRelative
        ? this.maxDateFromRelative
        : this.dateFromRelative < this.minDateFromRelative
        ? this.minDateFromRelative
        : this.dateFromRelative;
    this.dateToRelative =
      this.dateToRelative > this.maxDateToRelative
        ? this.maxDateToRelative
        : this.dateToRelative < this.minDateToRelative
        ? this.minDateToRelative
        : this.dateToRelative;
    this.syncFixed();
  }

  private setType(): void {
    this.type = this.caption ? (this.caption.toUpperCase() as PeriodType) : undefined;
    if (!periodTypes.includes(this.type)) {
      this.type = 'UNKNOWN';
    }
  }

  private setDefaultValues(): void {
    switch (this.type) {
      case 'DAY':
        this.dateFromRelative = 1;
        this.dateToRelative = 30;
        break;
      case 'WEEK':
        this.dateFromRelative = 1;
        this.dateToRelative = 8;
        break;
      case 'MONTH':
        this.dateFromRelative = 1;
        this.dateToRelative = 12;
        break;
      default:
    }
  }

  // Functions to update min/max values on changes, called within update()

  private updateMinNgbDateFrom(): void {
    if (this.minDate === undefined) {
      return;
    }
    this.minNgbDateFrom = { year: this.minDate.getFullYear(), month: this.minDate.getMonth() + 1, day: this.minDate.getDate() };
  }

  private updateMaxNgbDateFrom(): void {
    const max = this.dateTo ? new Date(this.dateTo) : this.maxDate ? new Date(this.maxDate) : undefined;
    if (max === undefined) {
      return;
    }
    const before = new Date(new Date(max).setDate(max.getDate() - 1));
    this.maxNgbDateFrom = { year: before.getFullYear(), month: before.getMonth() + 1, day: before.getDate() };
  }

  private updateMinNgbDateTo(): void {
    const min = this.dateFrom ? new Date(this.dateFrom) : this.minDate ? new Date(this.minDate) : undefined;
    if (min === undefined) {
      return;
    }
    const after = new Date(new Date(min).setDate(min.getDate() + 1));
    this.minNgbDateTo = { year: after.getFullYear(), month: after.getMonth() + 1, day: after.getDate() };
  }

  private updateMaxNgbDateTo(): void {
    if (this.maxDate === undefined) {
      return;
    }
    this.maxNgbDateTo = { year: this.maxDate.getFullYear(), month: this.maxDate.getMonth() + 1, day: this.maxDate.getDate() };
  }

  private updateMinDateFromRelative(): void {
    if (this.periodValues === undefined || this.periodValues.length === 0) {
      return;
    }
    this.minDateFromRelative = -this.periodValues.filter((date) => date.date < new Date()).length + 1;
  }

  private updateMaxDateToRelative(): void {
    if (this.periodValues === undefined || this.periodValues.length === 0) {
      return;
    }
    this.maxDateToRelative = this.periodValues.filter((date) => date.date > new Date()).length;
  }

  private updateDateFromPeriodValues(): void {
    this.dateFromPeriodValues = this.dateTo ? this.periodValues.filter((date) => date.date <= this.dateTo) : this.periodValues;
  }

  private updateDateToPeriodValues(): void {
    this.dateToPeriodValues = this.dateFrom ? this.periodValues.filter((date) => date.date >= this.dateFrom) : this.periodValues;
  }

  /**
   * Update relative based on fixed values
   */
  private syncRelative(): void {
    if (this.periodValues === undefined || this.periodValues.length === 0) {
      return;
    }
    let zeroIndex = 0; // Current Period index
    for (; zeroIndex < this.periodValues.length - 1; zeroIndex += 1) {
      if (this.periodValues[zeroIndex].date <= new Date() && new Date() <= this.periodValues[zeroIndex + 1].date) {
        break;
      }
    }
    // Fix time when set with text input
    this.dateFrom.setHours(0, 0, 0, 0);
    this.dateTo.setHours(0, 0, 0, 0);
    this.dateFromRelative = this.dateFrom
      ? this.periodValues.findIndex((period) => period.date.getTime() === this.dateFrom.getTime()) - zeroIndex
      : this.dateFromRelative;
    this.dateToRelative = this.dateTo
      ? this.periodValues.findIndex((period) => period.date.getTime() === this.dateTo.getTime()) - zeroIndex
      : this.dateToRelative;
  }

  /**
   * Update fixed based on relative values
   */
  // eslint-disable-next-line complexity
  private syncFixed(): void {
    if (this.periodValues === undefined || this.periodValues.length === 0) {
      return;
    }
    let zeroIndex = 0; // Current Period index
    for (; zeroIndex < this.periodValues.length - 1; zeroIndex += 1) {
      if (this.periodValues[zeroIndex].date <= new Date() && new Date() <= this.periodValues[zeroIndex + 1].date) {
        break;
      }
    }
    const dateFromPeriodValue = this.periodValues[zeroIndex + this.dateFromRelative]
      ? this.periodValues[zeroIndex + this.dateFromRelative]
      : this.periodValues[0];
    this.dateFrom = dateFromPeriodValue ? dateFromPeriodValue.date : this.dateFrom;
    this.ngSelectDateFrom = dateFromPeriodValue;
    const dateToPeriodValue = this.periodValues[zeroIndex + this.dateToRelative]
      ? this.periodValues[zeroIndex + this.dateToRelative]
      : this.periodValues[this.periodValues.length - 1];
    this.dateTo = dateToPeriodValue ? dateToPeriodValue.date : this.dateTo;
    this.ngSelectDateTo = dateToPeriodValue;
  }
}
