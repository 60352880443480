import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxIndexedDB } from 'ngx-indexed-db';
import { Observable, Subject, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import { DeQueryAvailabilityParams } from 'src/app/_core/de-query/context.service';
import { ContextLineDTO } from 'src/app/_core/de-query/model/context-line.model';
import { DeQuery } from 'src/app/_core/de-query/model/de-query.model';
import { GridColumnMetaDataDTO } from 'src/app/_core/de-query/model/grid-column-meta-data';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { AgrUtils } from 'src/app/_shared/utils/agr-utils';
import { DeQueryPayload } from 'src/app/dashboard/element-query-builder';

interface CacheItem {
  obj: any;
  cachedAt: number;
}

@Injectable({
  providedIn: 'root'
})
export class IndexDbCacheService {
  private db: NgxIndexedDB;
  private openPromise: Promise<NgxIndexedDB>;
  private activeRequests: Map<string, Subject<any>> = new Map();

  constructor(private httpClient: HttpClient) {
    this.openPromise = new Promise((resolve, reject) => {
      const db = new NgxIndexedDB('agrIndexedDB', 2); // Increment version number if adding a new object store.
      db.openDatabase(2, (event: IDBVersionChangeEvent) => {
        // Increment version here as well.
        if (event.oldVersion < 1) {
          (event.target as IDBOpenDBRequest).result.createObjectStore('context');
          (event.target as IDBOpenDBRequest).result.createObjectStore('columns');
          (event.target as IDBOpenDBRequest).result.createObjectStore('dashboard-chart');
          (event.target as IDBOpenDBRequest).result.createObjectStore('dashboard-kpi');
        }
        if (event.oldVersion < 2) {
          (event.target as IDBOpenDBRequest).result.createObjectStore('de-query');
        }
      }).then(() => {
        this.db = db;
        resolve(this.db);
      }, reject);
    });
  }

  getGridColumnMetaDataDtos(query: DeQuery, apiObservable: Observable<GridColumnMetaDataDTO[]>): Observable<GridColumnMetaDataDTO[]> {
    // TODO: strip filter values, except for period filters
    const hash = Md5.hashStr(JSON.stringify(query));
    return this.getThroughCacheStore('columns', hash, apiObservable);
  }

  getContextLineDtos(params: DeQueryAvailabilityParams, apiObservable: Observable<ContextLineDTO[]>): Observable<ContextLineDTO[]> {
    const hash = Md5.hashStr(this.stringifyDeQueryAvailabilityParams(params));
    return this.getThroughCacheStore('context', hash, apiObservable);
  }

  getDashboardChart(query: DeQueryPayload, apiObservable: Observable<any[]>): Observable<any[]> {
    const hash = Md5.hashStr(JSON.stringify(query));
    return this.getThroughCacheStore('dashboard-chart', hash, apiObservable);
  }

  getDashboardKpi(query: DeQueryPayload, apiObservable: Observable<{}[]>): Observable<{}[]> {
    const hash = Md5.hashStr(JSON.stringify(query));
    return this.getThroughCacheStore('dashboard-kpi', hash, apiObservable);
  }

  getFromCache(query: DeQuery, apiObservable: Observable<any>, tableSetId?: number): Observable<any> {
    const hashString = !isNaN(tableSetId) ? `${JSON.stringify(query)}${tableSetId}` : JSON.stringify(query);
    const hash = Md5.hashStr(hashString);
    return this.getThroughCacheStore('de-query', hash, apiObservable);
  }

  /**
   * Clear all data in IndexedDB.
   */
  clear(): void {
    this.db.clear('context').catch(() => {});
    this.db.clear('columns').catch(() => {});
    this.db.clear('dashboard-chart').catch(() => {});
    this.db.clear('dashboard-kpi').catch(() => {});
    this.db.clear('de-query').catch(() => {});
    this.httpClient.delete(`${API_ROOT}/cache`).subscribe();
  }

  private stringifyDeQueryAvailabilityParams(params: DeQueryAvailabilityParams): string {
    return JSON.stringify({
      query: this.stripQueryFilterValues(params.query),
      allActions: params.allActions,
      workspaceId: params.workspaceId,
      tableSetId: params.tableSetId
    });
  }

  private stripQueryFilterValues(query: DeQuery): DeQuery {
    if (!query.filters) {
      return query;
    }
    return {
      queryType: query.queryType,
      columns: query.columns,
      filters: query.filters.map((filter) => ({ column: filter.column, operator: filter.operator, values: [0] })),
      series: query.series,
      pivot: query.pivot
    };
  }

  // eslint-disable-next-line max-lines-per-function
  private getThroughCacheStore<ItemType>(store: string, hash: string, notFoundObservable: Observable<ItemType>): Observable<ItemType> {
    if (this.activeRequests.has(`${store}-${hash}`)) {
      return this.activeRequests.get(`${store}-${hash}`).asObservable();
    }
    const subject = new Subject<ItemType>();
    this.activeRequests.set(`${store}-${hash}`, subject);

    from(this.getCachedItemValue(this.getCacheItem(store, hash)))
      .pipe(
        switchMap((itemValue: ItemType) => {
          if (itemValue) {
            subject.next(itemValue);
            this.activeRequests.delete(`${store}-${hash}`);
            subject.complete();
            return of(undefined);
          }
          return notFoundObservable.pipe(
            switchMap((dtos) => {
              this.storeCacheItem(store, hash, dtos)
                .then()
                .finally(() => {
                  subject.next(dtos);
                  this.activeRequests.delete(`${store}-${hash}`);
                  subject.complete();
                });
              return of(undefined);
            })
          );
        })
      )
      .subscribe();

    return subject;
  }

  private async getCachedItemValue<ItemType>(cacheItemPromise: Promise<any>): Promise<ItemType> {
    try {
      const cacheItem: CacheItem = await cacheItemPromise;
      return this.isValid(cacheItem) ? cacheItem.obj : undefined;
    } catch (err) {
      console.warn('AGR: Error getting data from IndexedDB', err);
    }
  }

  /**
   * Cached objects in IndexedDB is only valid for one day.
   */
  private isValid(cacheItem: CacheItem): boolean {
    if (!cacheItem || !cacheItem.cachedAt) {
      return false;
    }
    const cached = new Date(cacheItem.cachedAt);
    const today = new Date();
    return AgrUtils.isSameDay(cached, today);
  }

  private async storeCacheItem(storeName: string, hash: string, value: any): Promise<void> {
    const cacheItem = { obj: value, cachedAt: new Date().getTime() };
    const db = await this.getDb();
    const existingValue = await db.getByKey(storeName, hash);
    if (!existingValue) {
      return db.add(storeName, cacheItem, hash);
    }
    return db.update(storeName, cacheItem, hash);
  }

  private async getCacheItem(storeName: string, hash: string): Promise<any> {
    const db = await this.getDb();
    return db.getByKey(storeName, hash);
  }

  private async getDb(): Promise<NgxIndexedDB> {
    if (this.db) {
      return this.db;
    }
    return this.openPromise;
  }
}
