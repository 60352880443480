import { Inject, Injectable } from '@angular/core';

import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { UserDTO } from 'src/app/_core/models/user.model';

@Injectable({
  providedIn: 'root'
})
/**
 * Store any object in local storage on a key-value pair basis
 */
export class StoreService {
  constructor(@Inject(LOCAL_STORAGE) private storageService: StorageService) {}

  get(key: string): string | number | boolean | {} {
    return this.storageService.get(this.getKey(key));
  }

  set(key: string, value: string | number | boolean | {}): void {
    this.storageService.set(this.getKey(key), value);
  }

  remove(key: string): void {
    this.storageService.remove(this.getKey(key));
  }

  /**
   * Restores saved @object if it exists and is of same type,
   * otherwise returns the @object unchanged and saves it
   * @param key to the local storage
   * @param object default state of object to return if nothing/invalid is retrieved
   * @param overwrite overwrites the stored value if the (persistent/state) object has different properties. Defaults to true
   */
  load(key: string, object: {}, overwrite: boolean = true): {} {
    const stored = this.get(key);
    if (!stored || typeof stored !== 'object' || (overwrite && !this.hasSameProperties(object, stored))) {
      this.set(key, object);
      return object;
    }
    return stored;
  }

  isValidModel(key: string, model: {}): boolean {
    const storedModel = this.get(key);
    const isInvalid = !storedModel || typeof storedModel !== 'object' || !this.hasSameProperties(model, storedModel);
    return !isInvalid;
  }

  private hasSameProperties(a: {}, b: {}): boolean {
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }

  /**
   * Prefixes and returns key with user id so stored values can be user specific
   * Ignores session keys (session.xxx) and globalKeys (data shared between sessions, not user specific)
   */
  private getKey(key: string): string {
    const globalKeys = ['lastUsedLanguage'];
    if (key.includes('session.') || globalKeys.includes(key)) {
      return key;
    }
    const sessionUser = this.storageService.get('session.user') as UserDTO;
    const id = sessionUser && !isNaN(sessionUser.id) ? sessionUser.id : '';
    return `${id}.${key}`;
  }
}
