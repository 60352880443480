/* eslint-disable max-classes-per-file */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'lodash';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from 'src/app/_core/authorization/auth.service';
import { FormatService } from 'src/app/_core/format.service';
import { API_ROOT } from 'src/app/_core/models/api-route.model';
import { User, UserDTO } from 'src/app/_core/models/user.model';
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { TranslationsService } from 'src/app/_core/translations.service';
import { UsersModalService } from 'src/app/settings/users/users-modal/users-modal.service';

@Component({
  selector: 'agr-my-profile',
  templateUrl: './my-profile.component.html'
})
export class MyProfileComponent implements OnInit {
  @ViewChild('content', { static: true }) content;
  modal: NgbModalRef;
  form: FormGroup;
  submitted: boolean;
  user: User;
  userRoles: string;
  userAccessGroups: string;
  accessGroupsActive: boolean;
  editingPassword: boolean;
  availableLanguages = [];
  availableLocales = [];
  now = Date.now();

  disableFallbackTranslations = false;

  /**
   * Add getters for easier form control access
   */
  get name(): AbstractControl {
    return this.form.get('name');
  }
  get language(): AbstractControl {
    return this.form.get('language');
  }
  get locale(): AbstractControl {
    return this.form.get('locale');
  }
  get newPassword(): AbstractControl {
    return this.form.get('newPassword');
  }
  get currentPassword(): AbstractControl {
    return this.form.get('newPassword.currentPassword');
  }
  get password(): AbstractControl {
    return this.form.get('newPassword.password');
  }
  get confirmPassword(): AbstractControl {
    return this.form.get('newPassword.confirmPassword');
  }

  constructor(
    public authService: AuthService,
    public sessionService: SessionService,
    public formatService: FormatService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private settingsService: SettingsService,
    private translationsService: TranslationsService,
    private usersModalService: UsersModalService
  ) {}

  ngOnInit(): void {
    this.user = new User(this.sessionService.user);
    this.getAvailableLanguages();
    this.availableLocales = ['', ...this.formatService.availableLocales]; // Empty value to use default system locale
    this.disableFallbackTranslations = localStorage.getItem('disableFallbackTranslations') === 'true';
  }

  /**
   * Open/close change password well
   */
  showChangePassword(): void {
    this.editingPassword = true;
    this.addPasswordValidators();
  }

  hideChangePassword(): void {
    this.editingPassword = false;
    this.removePasswordValidators();
    this.clearPasswordFields();
  }

  /**
   * Setup modal and form
   */
  open(): void {
    this.submitted = false;
    this.editingPassword = false;
    this.buildForm();
    this.setFormData();
    this.accessGroupsActive = this.settingsService.getValue('user_access_active');
    this.modal = this.ngbModal.open(this.content, { size: 'sm', backdrop: 'static' });
  }

  /**
   * Activates feedback fields or submits the form if valid.
   * Will close the modal if form is pristine.
   */
  // eslint-disable-next-line max-lines-per-function
  onSubmit(): void {
    this.submitted = true; // make feedback elements visible
    if (this.form.invalid || this.form.pending) {
      return;
    }
    if (this.form.pristine) {
      this.modal.close();
      return;
    }

    if (this.editingPassword) {
      this.verifyPassword().subscribe((res: VerifyPasswordDTO) => {
        if (!res.isVerified) {
          this.currentPassword.setErrors({ incorrect: true });
          return;
        }
        this.updateProfile(this.form).subscribe(
          () => {
            this.cleanUp();
          },
          () => this.cleanUp()
        );
      });
    } else {
      this.updateProfile(this.form).subscribe(
        () => {
          this.cleanUp();
        },
        () => this.cleanUp()
      );
    }
  }

  trackLanguage({}: number, language: any): any {
    return language ? language.code : undefined;
  }

  onLocaleChange(): void {
    this.updateLocale();
  }

  onDismissModal(): void {
    this.resetLocale();
    this.modal.dismiss();
  }

  toggleFallbackTranslations(): void {
    this.disableFallbackTranslations = !this.disableFallbackTranslations;
    localStorage.setItem('disableFallbackTranslations', `${this.disableFallbackTranslations}`);
  }

  // private

  private getAvailableLanguages(): void {
    this.translationsService.getAvailableLanguages().subscribe((languages) => {
      this.availableLanguages = languages;
    });
  }

  // - form setup

  private buildForm(): void {
    const configControls = { currentPassword: [''], password: [''], confirmPassword: [''] };
    const options: AbstractControlOptions = { validators: this.passwordMismatch.bind(this) };
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(64)]],
      language: [''],
      locale: [''],
      newPassword: this.formBuilder.group(configControls, options)
    });
  }

  private setFormData(): void {
    this.user = new User(this.sessionService.user);
    this.userRoles = map(this.user.roles, (role) => role.caption).join(', ');
    this.userAccessGroups = map(this.user.accessGroups, (accessGroup) => accessGroup.name).join(', ');
    this.name.setValue(this.user.name);
    this.language.setValue(this.user.language || this.settingsService.defaultLanguage());
    this.locale.setValue(this.user.locale || '');
  }

  // *** Validators ***

  private passwordMismatch(newPassword: FormGroup): { mismatch: boolean } {
    return this.usersModalService.passwordMismatch(newPassword);
  }

  private verifyPassword(): Observable<VerifyPasswordDTO> {
    return this.httpClient.post<VerifyPasswordDTO>(`${API_ROOT}/users/verify-password`, { password: this.currentPassword.value });
  }

  // - toggle change password

  private clearPasswordFields(): void {
    this.currentPassword.setValue('');
    this.password.setValue('');
    this.confirmPassword.setValue('');
  }

  private addPasswordValidators(): void {
    this.currentPassword.setValidators([Validators.required, Validators.minLength(8)]);
    this.currentPassword.updateValueAndValidity();
    this.password.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(64)]);
    this.password.updateValueAndValidity();
  }

  private removePasswordValidators(): void {
    this.currentPassword.setValidators([]);
    this.password.setValidators([]);
    this.confirmPassword.setValidators([]);
  }

  // - form submission

  private updateProfile(form: any): Observable<any> {
    const newPass = form.value.newPassword;
    const user: UserDTO = {
      id: this.user.id,
      userId: this.user.id,
      name: form.value.name,
      language: form.value.language,
      locale: form.value.locale
    };
    if (this.editingPassword) {
      user.editingPassword = true;
      user.currentPassword = newPass.currentPassword;
      user.password = newPass.password;
      user.passwordConfirmation = newPass.confirmPassword;
    }
    return this.httpClient
      .put(`${API_ROOT}/users/profile/${this.user.id}`, user)
      .pipe(mergeMap(() => this.httpClient.delete(`${API_ROOT}/cache`)));
  }

  /**
   * Update session, language and close modal
   */
  private cleanUp(): void {
    this.httpClient.get<any>(`${API_ROOT}/users/logged-in-user`).subscribe((userDto: UserDTO) => {
      this.sessionService.user = userDto;
      if (this.user.language !== userDto.language) {
        this.translationsService.storeLanguage(userDto.language);
        this.cleanLocalStorage();
        location.reload();
      }
      this.modal.close();
    });
  }

  /**
   * When language is changed some data in local storage gets corrupted so we need to clean.
   */
  private cleanLocalStorage(): void {
    localStorage.setItem('items.pers', ''); // clear the view settings
  }

  private updateLocale(): void {
    const user = this.sessionService.user;
    user.locale = this.locale.value;
    this.sessionService.user = user;
    this.formatService.registerLocaleData(user.locale);
  }

  private resetLocale(): void {
    const user = this.sessionService.user;
    user.locale = this.user.locale;
    this.sessionService.user = user;
  }
}
class VerifyPasswordDTO {
  isVerified: boolean;
}
