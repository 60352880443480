<button
  id="{{ btnId }}"
  class="btn agr-toolbar-btn {{ btnClass }}"
  [class.active]="active"
  [class.agr-highlight-btn]="highlight"
  [class.agr-unsaved-changes]="unsavedChanges"
  placement="{{ placement }}"
  ngbTooltip="{{ translationKey | agrTranslate }}{{ postfix }}"
  [disabled]="disabled"
  (click)="btnClick.emit()">
  <i class="fa fa-{{ icon }}"></i>
</button>
