export interface SettingDTO {
  settingKey?: string;
  settingValue?: any;
  defaultValue?: any;
  dataType?: string;
  description?: string;
  caption?: string;
  groupKey?: string;
  insertBy?: string;
  insertDate?: string; // Date
  groupKeyNavigation?: string;
}

/**
 * MBE Report
 */
export class Setting {
  key?: string;
  value?: any;
  defaultValue?: any;
  dataType?: string;
  group?: string;
  caption?: string;
  description?: string;

  static toDto(setting: Setting): SettingDTO {
    const settingDto: SettingDTO = {};
    if (!setting) {
      return {};
    }

    settingDto.settingKey = setting.key;
    settingDto.settingValue = stringifyValue(setting.value, setting.dataType);
    settingDto.defaultValue = setting.defaultValue;
    settingDto.dataType = setting.dataType;
    settingDto.groupKey = setting.group;
    settingDto.caption = setting.caption;
    settingDto.description = setting.description;

    return settingDto;

    function stringifyValue(value: any, dataType: string): string {
      return dataType === 'json' ? JSON.stringify(value) : value.toString();
    }
  }

  // eslint-disable-next-line max-lines-per-function
  constructor(setting?: SettingDTO) {
    if (!setting) {
      return;
    }
    this.key = setting.settingKey;
    this.value = parseValue(setting.settingValue, setting.dataType);
    this.defaultValue = setting.defaultValue;
    this.dataType = setting.dataType;
    this.group = setting.groupKey ? setting.groupKey : '';
    this.caption = setting.caption ? setting.caption : '';
    this.description = setting.description;

    /**
     * Convert value strings to type depending on dataType attribute.
     */
    function parseValue(value: any, dataType: string): any {
      if (!value) {
        return;
      }
      switch (dataType) {
        case 'boolean':
          return value === 'true';
        case 'integer' || 'decimal':
          return +value; // to number
        case 'json':
          return JSON.parse(value);
        default:
          return value;
      }
    }
  }
}
