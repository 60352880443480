import { DeQueryColumn, DeQueryFilterOperator, DeQueryPivot, DeQuerySerie } from 'src/app/_core/de-query/model/de-query.model';

interface LookupOption {
  id: number | string;
  value: number | string;
}

export interface ConfigColumnEditLookupDTO {
  query?: LookupDeQuery;
  idColumn?: string;
  valueColumn?: string;
  fixedOptions?: LookupOption[];
  fixedOptionsAtStart?: boolean;
  translateOptions?: boolean;
  cache?: boolean;
}

export interface LookupDeQueryFilterValue {
  name: string;
  type: 'fromRow';
}

export interface LookupDeQueryFilter {
  column: DeQueryColumn;
  operator: DeQueryFilterOperator;
  values: string[] | number[] | LookupDeQueryFilterValue;
}

export interface LookupDeQuery {
  queryType?: 'distinct' | 'none'; // 'distinct' = no duplicate results, 'none' = no query at all
  columns?: DeQueryColumn[];
  filters?: LookupDeQueryFilter[];
  series?: DeQuerySerie[];
  pivot?: DeQueryPivot;
}

export class ConfigColumnEditLookup {
  query?: LookupDeQuery;
  idColumn?: string;
  valueColumn?: string;
  fixedOptions?: LookupOption[];
  fixedOptionsAtStart?: boolean;
  translateOptions?: boolean;
  cache: boolean;

  constructor(dto?: ConfigColumnEditLookupDTO) {
    if (!dto) {
      return;
    }
    this.query = dto.query;
    this.idColumn = dto.idColumn;
    this.valueColumn = dto.valueColumn;
    this.fixedOptions = dto.fixedOptions;
    this.fixedOptionsAtStart = dto.fixedOptionsAtStart;
    this.translateOptions = dto.translateOptions;
    this.cache = dto.cache;
  }
}
