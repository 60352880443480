import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'agr-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./403-and-404.scss']
})
export class NoAccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
