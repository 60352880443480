// Declarations
import { AppComponent } from 'src/app/app.component';
import { LoginComponent } from 'src/app/login/login.component';

// Angular imports
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// 3rd party imports
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';

// AGR Modules
import { CoreModule } from 'src/app/_core/core.module';
import { FromNowPipe } from 'src/app/_shared/pipes/from-now.pipe';
import { TranslatePipe } from 'src/app/_shared/pipes/translate.pipe';
import { SharedModule } from 'src/app/_shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ItemCardModule } from 'src/app/item-card/item-card.module';
import { NavModule } from 'src/app/nav/nav.module';

// App Init Services
import { SessionService } from 'src/app/_core/session.service';
import { SettingsService } from 'src/app/_core/settings/settings.service';
import { TimezoneService } from 'src/app/_core/timezone.service';
import { TranslationsService } from 'src/app/_core/translations.service';

export function appInit(sessionService: SessionService, settingsService: SettingsService, translationsService: TranslationsService): {} {
  return async () => {
    await sessionService.initialize().then(async () => {
      await settingsService.initialize().then(async () => {
        await translationsService.initialize();
      });
    });
  };
}

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NavModule,
    SharedModule,
    ToasterModule.forRoot(),
    TranslateModule.forRoot({ useDefaultLang: true }),
    NgProgressModule,
    NgProgressRouterModule,
    ItemCardModule
  ],
  providers: [
    Title,
    TranslatePipe,
    FromNowPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [SessionService, SettingsService, TranslationsService, TimezoneService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
