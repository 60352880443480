/* eslint-disable @typescript-eslint/no-this-alias */
import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { ResizableDirective, ResizeEvent } from 'angular-resizable-element';
import { Subscription } from 'rxjs';

export interface ResizableView {
  visible: boolean;
  size: number;
}

@Directive({
  selector: '[agrResizable]'
})
export class AgrResizableDirective extends ResizableDirective implements OnInit, OnDestroy {
  @Input() maxWidth = 10000;
  @Input() minWidth = 220;
  @Input() maxHeight = 10000;
  @Input() minHeight = 220;
  @Input() agrResizable?: string;

  enableGhostResize = true;
  resizeCursorPrecision = 5;
  resizeSubscription: Subscription;
  /**
   * Super() calls the constructor of the parent class that is extended
   */
  constructor(renderer2: Renderer2, elementRef: ElementRef, ngZone: NgZone) {
    super(undefined, renderer2, elementRef, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.resizeSubscription =
      this.agrResizable === 'sidebar'
        ? this.resizing.subscribe((evt) => {
            const that = this;
            this.onHorizontalResizeEnd(evt, that);
          })
        : this.resizing.subscribe((evt) => {
            const that = this;
            this.onVerticalResizeEnd(evt, that);
          });

    this.validateResize = (evt) => {
      const that = this;
      if (this.agrResizable === 'sidebar') {
        return this.validateHorizontal(evt, that);
      }
      return this.validateVertical(evt, that);
    };
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  validateHorizontal(event: ResizeEvent, ref: AgrResizableDirective): boolean {
    return isWithinBounds();

    function isWithinBounds(): boolean {
      const maxWidth = ref.maxWidth > window.innerWidth ? window.innerWidth : ref.maxWidth;
      return !(
        event.rectangle.width &&
        event.rectangle.height &&
        (event.rectangle.width < ref.minWidth || event.rectangle.width > maxWidth)
      );
    }
  }

  validateVertical(event: ResizeEvent, ref: AgrResizableDirective): boolean {
    return isWithinBounds();

    function isWithinBounds(): boolean {
      const maxHeight = ref.maxHeight > window.innerHeight ? window.innerHeight : ref.maxHeight;
      return !(
        event.rectangle.width &&
        event.rectangle.height &&
        (event.rectangle.height < ref.minHeight || event.rectangle.height > maxHeight)
      );
    }
  }

  onHorizontalResizeEnd(event: ResizeEvent, ref: any): void {
    ref.renderer.setStyle(ref.elm.nativeElement, 'width', `${event.rectangle.width}` + 'px');
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  onVerticalResizeEnd(event: ResizeEvent, ref: any): void {
    ref.renderer.setStyle(ref.elm.nativeElement, 'height', `${event.rectangle.height}` + 'px');
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }
}
