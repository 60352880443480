import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from 'src/app/_core/translations.service';

@Pipe({
  name: 'agrTranslate'
})
export class TranslatePipe implements PipeTransform {
  constructor() {}

  transform(key: string): string {
    return TranslationsService.get(key);
  }
}
