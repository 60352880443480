import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';

/**
 * Show data as link.
 * Doc: https://www.ag-grid.com/javascript-grid-cell-rendering-components/#angular-cell-render-components
 * Note: Row groups do not have params.data object.
 */

@Component({
  selector: 'agr-ag-grid-link-renderer',
  template: `<a *ngIf="value" [routerLink]="[url]">{{ value }}</a>`,
  styles: []
})
export class AgGridLinkRendererComponent implements ICellRendererAngularComp {
  value: number | string | Date | boolean;
  url: string;
  // Use colDef.cellRendererParams to add parameters below
  pathRoot: string; // e.g. 'reports'
  urlFields: string[]; // e.g. 'id'

  constructor() {}

  // called on init
  agInit(params: any): void {
    this.value = params.getValue();
    this.url = params.pathRoot;
    this.urlFields = params.urlFields;

    this.url = `/${this.url}`;
    this.urlFields.forEach((field) => {
      const path = params.data ? params.data[field] : params.node.allLeafChildren[0].data[field];
      if (path) {
        this.url = `${this.url}/${path}`;
      }
    });
  }

  refresh(): boolean {
    return true;
  }
}
