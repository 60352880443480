export interface AccessGroupDTO {
  id: number;
  accessGroupName: string;
  refTableId: number;
  accessType: string;
  isInclusive: boolean;
}

export class AccessGroup {
  id: number;
  name: string;
  refTableId: number;
  accessType: string;
  isInclusive: boolean;

  constructor(dto: AccessGroupDTO) {
    this.id = dto.id;
    this.name = dto.accessGroupName;
    this.refTableId = dto.refTableId;
    this.accessType = dto.accessType;
    this.isInclusive = dto.isInclusive;
  }

  toDto(): AccessGroupDTO {
    return {
      id: this.id,
      accessGroupName: this.name,
      refTableId: this.refTableId,
      accessType: this.accessType,
      isInclusive: this.isInclusive
    };
  }
}
