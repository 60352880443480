import { DetailsColumn } from 'src/app/item-card/details/details-columns/details-column-model';

export class ItemCardDetailsPers {
  sidebarHiddenColumns: string[];
  sidebarHiddenGroups: string[];
  hideExpStock: boolean;

  constructor(sidebarHiddenColumns?: string[], sidebarHiddenGroups?: string[], hideExpStock?: boolean) {
    this.sidebarHiddenColumns = sidebarHiddenColumns;
    this.sidebarHiddenGroups = sidebarHiddenGroups;
    this.hideExpStock = hideExpStock;
  }

  // returns true if column or group is hidden in sidebar.
  isHidden(name: string): boolean {
    return this.sidebarHiddenColumns.includes(name) || this.sidebarHiddenGroups.includes(name);
  }
  toggleColumn(name: string, groupName?: string): void {
    if (this.isHidden(name)) {
      this.showColumn(name);
      this.showGroup(groupName);
    } else {
      this.hideColumn(name);
    }
  }

  toggleGroup(groupName: string, columns?: DetailsColumn[]): void {
    const groupColumns = columns ? columns.map((column) => column.name) : [];
    if (this.isHidden(groupName)) {
      this.showGroup(groupName, groupColumns);
    } else {
      this.hideGroup(groupName, groupColumns);
    }
  }

  toggleExpStock(): void {
    this.hideExpStock = !this.hideExpStock;
  }
  private hideColumn(name: string): void {
    this.sidebarHiddenColumns.push(name);
    this.sidebarHiddenColumns = [...new Set(this.sidebarHiddenColumns)];
  }

  private hideColumns(columns: string[]): void {
    this.sidebarHiddenColumns = [...new Set(this.sidebarHiddenColumns.concat(columns))];
  }

  private showColumn(name: string): void {
    this.sidebarHiddenColumns = this.sidebarHiddenColumns.filter((column) => column !== name);
  }

  private showColumns(columns: string[]): void {
    this.sidebarHiddenColumns = this.sidebarHiddenColumns.filter((column) => !columns.includes(column));
  }

  private showGroup(name: string, columns?: string[]): void {
    this.sidebarHiddenGroups = this.sidebarHiddenGroups.filter((group) => group !== name);
    if (columns) {
      this.showColumns(columns);
    }
  }
  private hideGroup(name: string, columns?: string[]): void {
    this.sidebarHiddenGroups.push(name);
    if (columns) {
      this.hideColumns(columns);
    }
  }
}
