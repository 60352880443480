type PeriodType = 'day' | 'week' | 'month';

export interface ChartDataParamsDTO {
  periodType: PeriodType;
  history: number;
  forecast: number;
}

export class ChartDataParams {
  periodType: PeriodType = 'day';
  private history = 0;
  private forecast = 0;

  constructor(params?: ChartDataParamsDTO) {
    if (!params) {
      return;
    }
    this.periodType = params.periodType;
    this.history = params.history;
    this.forecast = params.forecast;
  }

  get historyLength(): number {
    return this.history;
  }
  set historyLength(length: number) {
    if (isNaN(length) || length === null) {
      this.history = 0;
    } else if (length < 0) {
      this.history = -1;
    } else {
      this.history = length;
    }
  }

  get forecastLength(): number {
    return this.forecast;
  }
  set forecastLength(length: number) {
    if (isNaN(length) || length === null) {
      this.forecast = 0;
    } else if (length < 0) {
      this.forecast = -1;
    } else {
      this.forecast = length;
    }
  }

  toDto(): ChartDataParamsDTO {
    return {
      periodType: this.periodType,
      history: this.history,
      forecast: this.forecast
    };
  }
}
