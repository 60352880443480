/* eslint-disable max-classes-per-file */
import { get } from 'lodash';

import { AgrUtils } from 'src/app/_shared/utils/agr-utils';
import { ConfigSerieEdit, ConfigSerieEditDTO } from 'src/app/workspaces/details/config/config-serie-edit.model';
import { ConfigSerieFormat, ConfigSerieFormatDTO } from 'src/app/workspaces/details/config/config-serie-format.model';

/**
 * To add new config:
 * 1. Add to DTO
 * 2. Add to base class
 * 3. Add to Config class
 * 4. Update assign() function
 * 5. Set app default
 */

//////////////////////////////////////////

/**
 * App Config (defaults)
 */
const appConfig: ConfigSerieBaseDTO = {
  excluded: false,
  pinned: false,
  edit: {
    enabled: undefined,
    readOnlyIfNull: false,
    toggleEditableRelativeToCurrent: {},
    action: 'batch_update',
    checkboxes: {
      enabled: false,
      trueValue: 1,
      falseValue: 0,
      disableIfNull: false
    }
  },
  format: {
    caption: undefined,
    format: undefined,
    aggregation: undefined,
    displayDiv: 1
  }
};

//////////////////////////////////////////

/**
 * Config Serie.
 *
 * The class wraps view, default and app configs to make getting and setting configs easier.
 * Configs are defined in three places: Workspace view, context line and finally in the app (the default).
 * Config from view will override default config, which will override app config.
 */
export class ConfigSerie {
  excluded?: boolean; // Exclude serie (link icon)
  pinned?: boolean; // Required (lock icon)
  edit = new ConfigSerieEdit();
  format = new ConfigSerieFormat();

  hasViewConfig = false; // Used to activate </> button

  private viewConfig = new ConfigSerieBase();
  private defaultConfig = new ConfigSerieBase();
  private appConfig = new ConfigSerieBase(appConfig);

  constructor(viewConfigDto?: ConfigSerieBaseDTO, defaultConfigDto?: ConfigSerieBaseDTO) {
    this.viewConfig = new ConfigSerieBase(viewConfigDto);
    this.defaultConfig = new ConfigSerieBase(defaultConfigDto);
    this.assign();
  }

  getViewConfigClean(): ConfigSerieBase {
    const sanitized = AgrUtils.sanitize(this.viewConfig);
    return AgrUtils.deleteEmpty(sanitized) as ConfigSerieBase;
  }

  getViewConfig(): ConfigSerieBase {
    return this.viewConfig;
  }

  getDefaultConfig(): ConfigSerieBase {
    return this.defaultConfig;
  }

  setViewConfig(configDto: ConfigSerieBaseDTO): void {
    this.viewConfig = new ConfigSerieBase(configDto);
    this.assign();
  }

  setDefaultConfig(configDto: ConfigSerieBaseDTO): void {
    this.defaultConfig = new ConfigSerieBase(configDto);
    this.assign();
  }

  togglePinned(): void {
    this.viewConfig.pinned = !this.pinned;
    this.viewConfig.excluded = this.viewConfig.pinned ? false : this.excluded;
    this.cleanOnDefault();
    this.assign();
  }

  toggleExcluded(): void {
    this.viewConfig.excluded = !this.excluded;
    this.viewConfig.pinned = this.viewConfig.excluded ? false : this.pinned;
    this.cleanOnDefault();
    this.assign();
  }

  clean(): void {
    this.viewConfig.excluded = !!this.excluded;
    this.viewConfig.pinned = this.viewConfig.excluded ? false : this.pinned;
    this.cleanOnDefault();
    this.assign();
  }

  private assign(): void {
    this.excluded = this.get('excluded');
    this.pinned = this.get('pinned');
    // Edit
    this.edit.enabled = this.get('edit.enabled');
    this.edit.readOnlyIfNull = this.get('edit.readOnlyIfNull');
    this.edit.toggleEditableRelativeToCurrent = this.get('edit.toggleEditableRelativeToCurrent');
    this.edit.action = this.get('edit.action');
    this.edit.actionArgs = this.get('edit.actionArgs');
    this.edit.checkboxes.enabled = this.get('edit.checkboxes.enabled');
    this.edit.checkboxes.disableIfNull = this.get('edit.checkboxes.disableIfNull');
    this.edit.checkboxes.trueValue = this.get('edit.checkboxes.trueValue');
    this.edit.checkboxes.falseValue = this.get('edit.checkboxes.falseValue');
    // Format
    this.format.caption = this.get('format.caption');
    this.format.format = this.get('format.format');
    this.format.aggregation = this.get('format.aggregation');
    this.format.displayDiv = this.get('format.displayDiv');
    // Other
    this.hasViewConfig = JSON.stringify(this.getViewConfigClean()) !== '{}';
  }

  /**
   * Get config property following the hierarchy:
   * 1. View config
   * 2. Default config (from data_elements)
   * 3. App config
   */
  private get(key: string): any {
    if (get(this.viewConfig, key) !== undefined) {
      return get(this.viewConfig, key);
    }
    if (get(this.defaultConfig, key) !== undefined) {
      return get(this.defaultConfig, key);
    }
    return get(this.appConfig, key);
  }

  /**
   * Removes selected properties from view config if they match app default.
   * This is done so that this.hasViewConfig will not always be true after user toggles config buttons.
   */
  private cleanOnDefault(): void {
    let doCleanUp = true;
    if (this.viewConfig.pinned !== this.appConfig.pinned) {
      doCleanUp = false;
    }
    if (this.viewConfig.excluded !== this.appConfig.excluded) {
      doCleanUp = false;
    }
    if (doCleanUp) {
      delete this.viewConfig.pinned;
      delete this.viewConfig.excluded;
    }
  }
}

//////////////////////////////////////////

export interface ConfigSerieBaseDTO {
  excluded?: boolean;
  pinned?: boolean;
  edit?: ConfigSerieEditDTO;
  format?: ConfigSerieFormatDTO;
  itemDetails?: object;
}

class ConfigSerieBase {
  excluded?: boolean;
  pinned?: boolean;
  edit: ConfigSerieEdit;
  format = new ConfigSerieFormat();
  itemDetails?: object;

  constructor(configDto?: ConfigSerieBaseDTO) {
    if (!configDto) {
      return;
    }
    this.excluded = configDto.excluded;
    this.pinned = configDto.pinned;
    this.edit = configDto.edit;
    this.format = new ConfigSerieFormat(configDto.format);
  }
}
