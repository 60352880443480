export type PeriodType = 'past' | 'present' | 'future' | undefined;

export type GridColumnType = 'column_ds_col' | 'data_series_col' | 'total_col' | 'data_col' | 'column_col';

export type ColumnValueType = 'decimal' | 'int' | 'string' | 'date';

export interface GridColumnMetaDataDTO {
  aggr_as?: string; // One of aggr_as types coming from query.columns
  caption?: string;
  col_id_value?: number;
  column_type?: GridColumnType;
  description?: string;
  is_col_id_range?: boolean;
  lookup?: boolean;
  lookup_column?: string;
  name?: string;
  num?: number;
  rel_id_value?: number;
  type?: ColumnValueType;
}

export class GridColumnMetaData {
  aggrAs?: string;
  caption: string;
  colIdValue?: number;
  columnType: GridColumnType;
  description?: string;
  isColIdRange?: boolean;
  lookup: boolean;
  lookupColumn?: string;
  name: string;
  num: number;
  type: ColumnValueType;
  periodType?: PeriodType;
  relIdValue?: number;

  constructor(dto: GridColumnMetaDataDTO) {
    this.aggrAs = dto.aggr_as;
    this.caption = dto.caption;
    this.colIdValue = dto.col_id_value;
    this.columnType = dto.column_type;
    this.description = dto.description;
    this.isColIdRange = dto.is_col_id_range;
    this.lookup = dto.lookup;
    this.lookupColumn = dto.lookup_column;
    this.name = dto.name;
    this.num = dto.num;
    this.type = dto.type;
    this.relIdValue = dto.rel_id_value;

    if (dto.rel_id_value !== undefined && dto.rel_id_value !== null) {
      this.periodType = dto.rel_id_value < 0 ? 'past' : dto.rel_id_value > 0 ? 'future' : 'present';
    }
  }

  get isDataSeriesTotalOrDataColumn(): boolean {
    return this.columnType === 'total_col' || this.columnType === 'data_col';
  }

  get isDataSeriesDataColumn(): boolean {
    return this.columnType === 'data_col';
  }

  get isColumnColumn(): boolean {
    return this.columnType === 'column_col' || this.columnType === 'column_ds_col';
  }

  get isDataSeriesLabelColumn(): boolean {
    return this.columnType === 'data_series_col';
  }
}
