import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from 'src/app/_core/format.service';

@Pipe({
  name: 'agrFormat'
})
export class FormatPipe implements PipeTransform {
  constructor(private formatService: FormatService) {}

  transform(value: number | string | Date, args?: string): any {
    return this.formatService.format(value, args);
  }
}
